import { useEffect } from "react"; //ok
import { useMap } from "react-leaflet"; //ok
import L from "leaflet"; //ok
import "./leaflet-ruler.css"; //ok
import "./leaflet-ruler";

export default function LeafletRuler() {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    let options = {
      angleUnit: {
        display: '°',           // This is the display value will be shown on the screen. Example: 'Gradian'
        decimal: 2,                 // Bearing result will be fixed to this value.
        factor: null,                // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
        label: 'Ângulo: '
      },
      lengthUnit: {
        factor: 1000,    //  from km to nm
        display: 'M',
        decimal: 0,
        label: 'Distancia:'
      }
    }

    L.control.ruler(options).addTo(map);
  }, [map]);

  return null;
}
