import { Card, Col, Divider, Row, Drawer, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import LoadingImage from "../../../../component/loadingImage";
import CardDark from "../../../../component/cardDark";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Fone",
    dataIndex: "fone",
    key: "fone",
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    key: "longitude",
  },
  {
    title: "Data de Registro",
    dataIndex: "dt_regis",
    key: "dt_regis",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Responsável",
    dataIndex: "responsavel",
    key: "responsavel",
  },
];

function LocalidadesViaveis() {
  const dataInfo = [];
  const [loading, setLoading] = useState(true);
  const [allViabilidade, setAllViabilidade] = useState();

  const confirmationViability = () => {
    const { http } = GetAPI();

    http
      .get("/viabilidade-getall")
      .then((res) => {
        if (res.status) {
          setAllViabilidade(res.data);
          setLoading(false);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    if (allViabilidade === undefined) {
      confirmationViability();
    }
  });

  if (loading === false) {
    allViabilidade.map((item, index) => {
      if (item.viabilidade === "viavel") {
        dataInfo.push({
          id: item.id,
          fone: item.number_phone,
          latitude: item.lat,
          longitude: item.lng,
          dt_regis: item.date_register,
          status: item.viabilidade,
          validade: item.validate_link,
          responsavel: item.responsible,
          key: index,
        });
      }

      return null;
    });
  }

  return (
    <>
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          <CardDark title="Localidades viáveis">
            <Table columns={columns} dataSource={dataInfo} />
          </CardDark>
        </>
      )}
    </>
  );
}

export default LocalidadesViaveis;
