import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function LineChartComponent(props) {

    const labels = props.label
    const options = props.options
    const data = {
        labels,
        datasets: [
            {
                label: props.title,
                data: props.data,
                borderColor: props.borderColor,
                backgroundColor: props.backgroundColor,
            },
        ],
    }

    return (

        <>
            <Line options={options} data={data} width={400} height={300} style={{ maxHeight: 300 }} />
        </>

    );
}

export default LineChartComponent;