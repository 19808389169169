import { Card, Col, Image, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import logo from "./../../../assets/logo/Logo Completo.png";
import { Link, useNavigate } from "react-router-dom";
import background from "./../../../assets/img-back-external/01.jpg";

function RedirecionarCliente() {
  const [count, setCount] = useState(15);
  const [redirect, setRedirect] = useState();
  const navigate = useNavigate();

  const tick = () => {
    setCount(count - 1);
  };

  useEffect(() => {
    if (count > 0) {
      setTimeout(tick, 1000);
    } else {
      window.location.href = "https://www.viaradnet.com.br";
    }
  }, [count]);

  return (
    <>
      <Row
        justify="center"
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: 30,
        }}
      >
        <Col xs={20} sm={22} md={18} lg={18}>
          <Card
            style={{
              marginTop: 30,
              marginBottom: 20,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
              background: "rgba( 255, 255, 255, 0.8 )",
              boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
              backdropFilter: "blur( 1.5px )",
              border: "1px solid rgba( 255, 255, 255, 0.18 )",
            }}
          >
            <center>
              <Image src={logo} preview={false} width={180}></Image>
            </center>

            <h4>
              Conseguimos pegar sua localização, entraremos em contato assim que
              possível.
            </h4>
            <p>
              Você será redirecionado para o site da Radnet Telecom em:{" "}
              <Tag style={{ color: "red", fontSize: 20 }}>{count}</Tag>
            </p>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RedirecionarCliente;
