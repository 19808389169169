import Web from "./web";
import Mobile from "./mobile";

const itemsCadastrarSetor = [
    {
        key: 'cadastrarsetorweb',
        label: `Cadastrar Setor Web`,
        children: <Web></Web>,
    },
    {
        key: 'cadastrarsetormobile',
        label: `Cadastrar Setor Mobile`,
        children: <Mobile ></Mobile>,
    },
];

export default itemsCadastrarSetor;