import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Card, Divider, notification } from "antd";
import { useForm } from "react-hook-form";
//import MonacoEditor from '@uiw/react-monacoeditor';
import Editor from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../../services/http/GetAPI";
import { PostAPI } from "../../../../../services/http/PostAPI";
import LoadingImage from "../../../../../component/loadingImage";
import CardDark from "../../../../../component/cardDark";

function OltAdicionar() {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all", mode: "onChange" });
  const [personName, setPersonName] = useState([]);
  const [allOlts, setAllOlts] = useState();
  const [loading, setLoading] = useState(true);
  const [parametros, setParametros] = useState();
  const [api, contextHolder] = notification.useNotification();

  const onSubmit = (data) => {
    cadastrarTemplate(data, personName, parametros);
    reset();
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const buscarOLT = () => {
    const { http } = GetAPI();

    http
      .get("/olt-getall")
      .then((res) => {
        if (res.status) {
          setAllOlts(res.data);

          setLoading(false);
        }
      })
      .catch(async function (err) {
        setLoading(false);
        console.log(err);
      });
  };

  const cadastrarTemplate = (data, olts, parametros) => {
    const { http } = PostAPI();

    try {
      if (typeof JSON.parse(parametros) === "object") {
        http
          .post("/onu-template-create", {
            descricao: data.descricao,
            parametros: JSON.stringify(JSON.parse(parametros)),
            addcmd: data.AddCmd,
            delcmd: data.DelCmd,
            oltmodo: data.oltmodo,
            slot: data.slot,
            pon: data.pon,
            olts: olts,
            parametros: parametros,
          })
          .then((res) => {
            if (res.status) {
              alertMessage(
                "success",
                "Template Olt",
                "Template editado com sucesso!"
              );
            }
          })
          .catch(async function (err) {
            setLoading(false);
            console.log(err);
            alertMessage(
              "error",
              "Template Olt",
              "Template não pode ser editado com sucesso!"
            );
          });
      }
    } catch (error) {
      alertMessage(
        "error",
        "Adicionar Template",
        "Os parâmetros não são objetos json"
      );
    }
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  useEffect(() => {
    if (allOlts === undefined) {
      buscarOLT();
    }
  }, []);

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <CardDark title="Adicionar ONU Template">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              fullWidth
              style={{ marginTop: "20px", marginBottom: 20 }}
              id="category-editor-form"
            >
              <TextField
                id="outlined-basic"
                label="Descrição"
                required
                variant="outlined"
                size="small"
                {...register("descricao", { required: true })}
                defaultValue={null}
              ></TextField>
              {errors?.descricao?.types?.required && (
                <span style={{ color: "red" }}>Número obrigatório</span>
              )}
            </FormControl>

            <FormLabel component="legend">Parâmetros - <strong style={{ color: "red" }}>(SHIFT+ALT+F) para formatar json</strong></FormLabel>

            <Editor
              title="Parametros"
              height={200}
              theme="vs-dark"
              defaultLanguage="json"
              defaultValue="{}"
              onChange={(e) => {
                setParametros(e);
              }}
            />
            <div style={{ marginTop: 20 }}></div>
            <TextField
              id="outlined-multiline-static"
              label="AddCmd"
              multiline
              rows={5}
              //defaultValue="Default Value"
              fullWidth
              {...register("AddCmd", { required: true })}
            />
            {errors?.AddCmd?.types?.required && (
              <span style={{ color: "red" }}>Número obrigatório</span>
            )}
            <div style={{ marginBottom: 20 }}></div>
            <div style={{ marginTop: 20 }}></div>
            <TextField
              id="outlined-multiline-static"
              label="DelCmd"
              multiline
              rows={5}
              fullWidth
              {...register("DelCmd", { required: true })}
            />
            {errors?.DelCmd?.types?.required && (
              <span style={{ color: "red" }}>Número obrigatório</span>
            )}
            <div style={{ marginBottom: 20 }}></div>
            <FormLabel component="legend">Olt Modo</FormLabel>
            <FormControlLabel
              style={separadorInput20}
              control={<Checkbox defaultChecked={true} />}
              {...register("oltmodo")}
              label="Ativo"
            />

            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="demo-multiple-name-label" size="small">
                OLTs
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={personName}
                onChange={handleChange}
                required
                size="small"
                input={<OutlinedInput label="OLTs" />}
              >
                {allOlts.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="slot" size="small">
                Slot
              </InputLabel>
              <Select
                labelId="slot"
                id="slot"
                label="Slot"
                defaultValue={"Todos"}
                {...register("slot")}
                size="small"
              >
                {["Todos", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="pon" size="small">
                PON
              </InputLabel>
              <Select
                labelId="pon"
                id="pon"
                label="PON"
                defaultValue={"Todos"}
                {...register("pon")}
                size="small"
              >
                {["Todos", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Divider></Divider>

            <Button
              type="submit"
              variant="contained"
              size="small"
            >
              Salvar
            </Button>
          </form>
        </CardDark>
      )}
    </>
  );
}

export default OltAdicionar;

const separadorInput20 = {
  marginBottom: 20,
};
