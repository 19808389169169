import { Card, Col, Divider, Image, Row, Transfer } from "antd";
import * as React from "react";

import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { PostAPI } from "./../../../../../services/http/PostAPI";
import { useEffect } from "react";
import { message } from "antd";
import LoadingImage from "./../../../../../component/loadingImage";
import { useState } from "react";
import CardDark from "./../../../../../component/cardDark";
import gifMobile from "./../../../../../assets/gif/permissions_app_mobile.gif"

function CadastrarPermissoesMobile() {

    const [user, setUser] = useState();
    const [pages, setPages] = useState()
    const [loading, setLoading] = useState(true);
    const [userSelectMobile, setUserSelectMobile] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [allPermMobile, setAllPermMobile] = useState()

    const [targetKeys, setTargetKeys] = useState();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [mockData, setMockData] = useState();
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };


    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const cadastrarPermissoesMobile = () => {
        const { http } = PostAPI();

        http
            .post(`user-permission-create-mobile`, {
                user_id: userSelectMobile,
                permissions: targetKeys,
            })
            .then((res) => {
                if (res.status) {
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                    setUserSelectMobile(res.data[0].id)
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodasPages = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    setPages(pages)

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodasPagesMobile = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall-mobile`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    const mockData2 = res.data.map((item) => ({
                        key: item.id,
                        title: item.name,
                        description: item.name,
                    }));

                    setMockData(mockData2);
                    setTargetKeys([]);

                    setAllPermMobile(pages)

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };


    useEffect(() => {
        if (user === undefined) {
            buscarTodosUsuarios();

        }
        if (pages === undefined) {
            buscarTodasPages()
        }

        if (allPermMobile === undefined) {
            buscarTodasPagesMobile()
        }

        if (user !== undefined && pages !== undefined && allPermMobile !== undefined) {
            setLoading(false);
        }

    }, [user, pages]);

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Cadastrar Permissões Mobile">
                        <Row justify={"center"}>
                            <Col
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                                style={{
                                    paddingBottom: 20,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Image src={gifMobile} preview={false}></Image>
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={1} xl={1}>
                                <Divider type="vertical" style={{ height: "100%" }}></Divider>
                            </Col>
                            <Col sm={24} md={24} lg={18} xl={18}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Usuário</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Usuário"
                                        size="small"
                                        defaultValue={user[Object.keys(user)[0]].id}
                                        onChange={(e) => {
                                            setUserSelectMobile(e.target.value);
                                        }}
                                    >
                                        {user.map((item, key) => {
                                            return <MenuItem value={item.id} key={key}>{item.name}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>

                                <Transfer
                                    style={{
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        marginTop: 10,
                                        marginBottom: 10,
                                        marginRight: 10,
                                        marginLeft: 10,
                                    }}
                                    listStyle={{
                                        width: 500,
                                        height: 400
                                    }}
                                    dataSource={mockData}
                                    titles={['Permissões', 'Permissões Mobile']}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) => item.title}
                                />


                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    cadastrarPermissoesMobile()
                                }}
                            >
                                Salvar
                            </Button>
                        </Row>
                    </CardDark>
                </>
            )}
        </>
    );
}

export default CadastrarPermissoesMobile;