import { Card, Col, Image, Row, Tag } from "antd";
import { TextField, useMediaQuery } from "@mui/material";
import gif from "./../../../../assets/img/17390-happy-user.gif";
import { loadState } from "./../../../../services/localStorage/";

function UserInfo() {
  const matches = useMediaQuery("(max-width:767px)");
  const user = loadState("session_user");

  return (
    <>
      <Card title="Informações Usuário">
        <Row justify={"space-around"}>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={gif}
              preview={false}
              style={matches === true ? marginInput : {}}
            ></Image>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <TextField
                disabled
                id="outlined-disabled"
                label="Id"
                variant="outlined"
                size="small"
                fullWidth
                style={marginInput}
                defaultValue={user.data.id}
              />

              <TextField
                disabled
                id="outlined-disabled"
                label="Nome"
                variant="outlined"
                size="small"
                fullWidth
                style={marginInput}
                defaultValue={user.data.name}
              />

              <TextField
                disabled
                id="outlined-disabled"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                style={marginInput}
                defaultValue={user.data.email}
              />
              {user.data.permission_setor}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default UserInfo;

const marginInput = {
  marginBottom: 20,
};
