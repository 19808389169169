import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

function SetorAtribuirSelect({ items1, items2, submitFunction }) {

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        criteriaMode: "all", mode: "onChange"
    });

    const onSubmit = (data) => {
        submitFunction(data)
        reset();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth style={{ marginBottom: 20 }}>
                    <InputLabel id="usuario" size="small">
                        Usuário
                    </InputLabel>
                    <Select
                        labelId="usuario"
                        id="usuario"
                        label="Usuário"
                        defaultValue={items1[0] ? items1[0].id : null}
                        {...register("usuario", {
                            required: true
                        })}
                        size="small"
                    >
                        {items1.map((item, index) => {
                            return (
                                < MenuItem value={item.id} key={index} >
                                    {item.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {errors?.usuario?.types?.required && (
                        <span style={{ color: "red" }}>Campo obrigatório</span>
                    )}
                </FormControl>


                <FormControl fullWidth style={{ marginBottom: 20 }}>
                    <InputLabel id="setor" size="small">
                        Setor
                    </InputLabel>
                    <Select
                        labelId="setor"
                        id="setor"
                        label="Setor"
                        defaultValue={items2[0] ? items2[0].id : null}
                        {...register("setor", {
                            required: true
                        })}
                        size="small"
                    >
                        {items2.map((item, index) => {
                            return (
                                <MenuItem value={item.id} key={index}>
                                    {item.description}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <Button type="submit" variant="contained" size="small">
                    Enviar
                </Button>
            </form>
        </>
    );
}

export default SetorAtribuirSelect;