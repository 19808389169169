import { Link } from "react-router-dom";

import React from "react";
import { Button, Dropdown } from "antd";
import { BarsOutlined, MessageOutlined } from "@ant-design/icons";

function UserNotifyDropDown() {
  const items = [
    {
      key: "1",
      label: (
        <Link to={"/messages-all"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "green",
            }}
          >
            <BarsOutlined style={{ marginRight: 8 }} />
            Mensagens
          </div>
        </Link>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomRight"
    >
      <Button ghost style={{ marginRight: 18 }}>
        <MessageOutlined />
      </Button>
    </Dropdown>
  );
}

export default UserNotifyDropDown;
