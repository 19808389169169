import { Button, FormControl, TextField } from "@mui/material";
import { Col, Modal, Row } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PutAPI } from "../../../../../services/http/PutAPI";

function ModalEditarTipoFuncionario({ idModalEditar, isModalOpenEditar, setIsModalOpenEditar, getAllTipos, alertMessage, funcEditar }) {

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        criteriaMode: "all", mode: "onChange"
    });

    const onSubmitModal = (data) => {
        editarTipoFuncionario(data)
        setIsModalOpenEditar(false);
    }
    const handleCancelEditar = (e) => {
        reset();
        setIsModalOpenEditar(false);
    };

    const editarTipoFuncionario = (data) => {

        const { http } = PutAPI();

        console.log(data)

        http
            .put(`comissao-editar-tipo-funcionario/${idModalEditar}`, {
                nome: data.nome,
                meta_de_vendas: data.meta_venda,
                meta_de_upgrade: data.meta_upgrade,
                valor_venda: data.valor_venda,
                valor_upgrade: data.valor_upgrade,
            })
            .then((res) => {
                alertMessage("success", "Editar funcionario", "Funcionario editado com sucesso")
                getAllTipos()
            })
            .catch((erro) => {
                console.log(erro)
            });

    }

    return (
        <>
            <Modal
                title="Editar Funcionário"
                open={isModalOpenEditar}
                onCancel={handleCancelEditar}
                destroyOnClose={true}
                preserve={false}
                width={1000}
                footer={[
                    <div>
                        <Button
                            block
                            onClick={handleCancelEditar}
                            variant="outlined"
                            style={{ marginRight: "10px" }}
                            size="small"
                        >
                            cancel
                        </Button>
                        ,
                        <Button
                            block
                            key="submit"
                            variant="contained"
                            onClick={handleSubmit(onSubmitModal)}
                            size="small"
                        >
                            Salvar
                        </Button>
                    </div>,
                ]}
            >
                <form onSubmit={handleSubmit(onSubmitModal)}>
                    <Row gutter={10}>
                        <Col span={24} >
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                                style={{ marginBottom: 20, marginTop: 20 }}
                            >
                                <TextField
                                    label={"Nome Funcionário"}
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    defaultValue={funcEditar?.nome === undefined ? null : funcEditar.nome}
                                    //error={errors.setor !== undefined}
                                    {...register("nome", {
                                        required: true,
                                    })}
                                />
                                {errors?.nome?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Nome é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col span={12}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                                style={{ marginBottom: 20 }}
                            >

                                <TextField
                                    label="Meta de vendas"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    defaultValue={funcEditar?.meta_de_vendas === undefined ? null : funcEditar.meta_de_vendas}
                                    //error={errors.setor !== undefined}
                                    {...register("meta_venda", {
                                        required: true,
                                    })}
                                />
                                {errors?.meta_venda?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Meta Venda é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col span={12}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                                style={{ marginBottom: 20 }}
                            >

                                <TextField
                                    label="Meta de upgrade"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    defaultValue={funcEditar?.meta_de_upgrade === undefined ? null : funcEditar.meta_de_upgrade}
                                    //error={errors.setor !== undefined}
                                    {...register("meta_upgrade", {
                                        required: true,
                                    })}
                                />
                                {errors?.meta_upgrade?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Meta Upgrade é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col span={12}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                                style={{ marginBottom: 20 }}
                            >

                                <TextField
                                    label="Valor Venda"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    defaultValue={funcEditar?.valor_venda === undefined ? null : funcEditar.valor_venda}
                                    //error={errors.setor !== undefined}
                                    {...register("valor_venda", {
                                        required: true,
                                    })}
                                />
                                {errors?.valor_venda?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Valor Venda é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col span={12}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                                style={{ marginBottom: 20 }}
                            >

                                <TextField
                                    label="Valor Upgrade"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    //error={errors.setor !== undefined}
                                    defaultValue={funcEditar?.valor_upgrade === undefined ? null : funcEditar.valor_upgrade}
                                    {...register("valor_upgrade", {
                                        required: true,
                                    })}
                                />
                                {errors?.valor_upgrade?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Valor Upgrade é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </>
    );
}

export default ModalEditarTipoFuncionario;