import { Button, Switch } from "@mui/material";
import { Card, Col, Row, Tag } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";

function PainelDeControle() {

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        criteriaMode: "all", mode: "onChange"
    });

    return (
        <>
            <Card title="Painel de Controle">
                <Row>
                    <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 5, border: "1px solid gray", marginBottom: 20 }} span={24}>
                        <Switch defaultValue={false} {...register("manutencao", {
                            onChange: () => {
                                handleSubmit((data) => console.log(data))()
                            }

                        })} />
                        <Tag color="black" style={{ fontSize: 14 }}>Entrar em modo manutenção geral</Tag>
                    </Col>

                    {/* <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 5, border: "1px solid gray", marginBottom: 20 }} span={24}>
                        <Switch defaultValue={false} {...register("usuario2", {
                            onChange: () => {
                                handleSubmit((data) => console.log(data))()
                            }

                        })} />
                        <Tag color="black" style={{ fontSize: 14 }}>Entrar em modo manutenção geral</Tag>
                    </Col> */}
                </Row>
            </Card>
        </>
    );
}

export default PainelDeControle;