import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { PostAPI } from "./../../../../../services/http/PostAPI";
import { message } from "antd";
import LoadingImage from "./../../../../../component/loadingImage";
import SetorAtribuirSelect from "./../../../../../component/setorAtribuirSelect";
import CardDark from "../../../../../component/cardDark";

function AtribuirSetorWeb() {

    const [loading, setLoading] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();

    const [setoresMobile, setSetoresMobile] = useState();
    const [user, setUser] = useState();
    const [userMobile, setUserMobile] = useState();
    const [usuarioFiltradoMobile, setUsuarioFiltradoMobile] = useState()

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const pegarTodosSetoresMobile = () => {
        const { http } = GetAPI();

        http
            .get(`sector-getall-mobile`)
            .then((res) => {
                if (res.status) {
                    setSetoresMobile(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                    setUserMobile(res.data)

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosUsuariosComSetorMobile = () => {
        const { http } = GetAPI();

        http
            .get(`sector-getallatt-mobile`)
            .then((res) => {
                if (res.status) {

                    let users = []
                    let userFiltred = []

                    for (const item of res.data) {
                        users.push(item.id_user)

                    }

                    for (const item of userMobile) {
                        if (users.indexOf(`${item.id}`) === -1) {
                            userFiltred.push(item)
                        }
                    }

                    setUserMobile(userFiltred)
                    setUsuarioFiltradoMobile(userFiltred)

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const cadastrarUsuarioSetorMobile = (data) => {
        const { http } = PostAPI();

        http
            .post(`sector-setter-mobile/${data.usuario}`, {
                id_user: data.usuario,
                id_setor: data.setor,
            })
            .then((res) => {
                if (res.status) {
                    buscarTodosUsuariosComSetorMobile()
                    success("Setor cadastrado com sucesso");

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data.message);
            });
    };

    useEffect(() => {

        if (setoresMobile === undefined) {
            pegarTodosSetoresMobile()
        }

        if (user === undefined) {
            buscarTodosUsuarios();
        }

        if (user !== undefined && userMobile !== undefined & setoresMobile !== undefined && loading === true) {
            buscarTodosUsuariosComSetorMobile()
        }

        if (user !== undefined && setoresMobile !== undefined && userMobile !== undefined && usuarioFiltradoMobile !== undefined) {
            setLoading(false);
        }

    }, [user, userMobile, setoresMobile, loading, usuarioFiltradoMobile]);

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Atribuir Setor Mobile">
                        <Row justify={"center"}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SetorAtribuirSelect items1={usuarioFiltradoMobile} items2={setoresMobile} submitFunction={cadastrarUsuarioSetorMobile}></SetorAtribuirSelect>
                            </Col>
                        </Row>
                    </CardDark>
                </>
            )}
        </>
    );
}

export default AtribuirSetorWeb;