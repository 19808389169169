import { Tabs } from "antd";
import itemsCadastrarSetor from "./itemsMenu";

function CadastrarSetor() {

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1" items={itemsCadastrarSetor}
      />
    </>
  );
}

export default CadastrarSetor;
