import { Tabs } from "antd";
import itemsAtribuirSetor from "./itemsMenu";

function AtribuirSetor() {

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1" items={itemsAtribuirSetor}
      />
    </>
  );
}

export default AtribuirSetor;
