import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { Card, Col, Divider, Row, Drawer, Table, Image } from "antd";
import { Circle, MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from "react-leaflet";
import { notification } from "antd";
import { icon } from "leaflet";
import { useForm } from "react-hook-form";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import MapPrint from "../../../../component/mapPrint";
import PngIcon from "./../../../../assets/img/placeholder.png";
import PngIcon2 from "./../../../../assets/img/placeholder_cto.png";
import PngIcon3 from "./../../../../assets/img/placeholder_cto2.png";
import LeafletRuler from "../../../../component/rulerMetric";
import CardDark from "../../../../component/cardDark";
import RouterImage from "./../../../../assets/img/137681-networking.gif";
import { PostAPI } from "../../../../services/http/PostAPI";

const ICON = icon({
  iconUrl: PngIcon,
  iconSize: [32, 32],
});

const ICON2 = icon({
  iconUrl: PngIcon2,
  iconSize: [32, 32],
});

const ICON3 = icon({
  iconUrl: PngIcon3,
  iconSize: [32, 32],
});

const columns = [
  {
    title: "Capacidade",
    dataIndex: "capacidade",
    key: "id",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.capacidade}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.capacidade}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Qtd. Clientes",
    dataIndex: "qtdclientes",
    key: "qtdclientes",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.qtdclientes}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.qtdclientes}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Portas Disponiveis",
    dataIndex: "portasdisponiveis",
    key: "portasdisponiveis",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.portasdisponiveis}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.portasdisponiveis}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Distância",
    dataIndex: "distancia",
    key: "distancia",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.distancia}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.distancia}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Nome",
    dataIndex: "nome",
    key: "nome",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.nome}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.nome}</strong>
          </span>
        )}
      </>
    ),
  },
];


const SearchField = ({ apiKey }) => {
  const provider = new OpenStreetMapProvider();

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    searchLabel: "Digite o endereço",
    animateZoom: true,
    marker: {
      icon: ICON,
      draggable: false,
    },
  });

  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

function PesquisarViabilidades() {

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const [map, setMap] = useState(null)
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [center, setCenter] = useState([Number("-15.9028"), Number("-47.776")]);
  const [dataInfo, setDataInfo] = useState();
  const [ctos, setCtos] = useState([]);
  const [mostrar, setMostrar] = useState();
  const [tamanhoC, setTamanhoC] = useState();
  const [address, setAddress] = useState({});
  const [endCom, setEndCom] = useState();

  const onSubmit = (data) => {

    consultarViabilidade(data, address, endCom);

  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };


  const onClose = () => {
    setOpen(false);
  };


  const showDrawer = () => {
    setOpen(true);
  };

  const consultarViabilidade = async (data, address, endCom) => {
    const { http } = PostAPI();


    const zoom = 17


    let coord = data.Coordenadas.split(",").map((i) => { return Number(i) })



    await http
      .post("/viabilidade-consultar", {
        distancia: data.distancia,
        tipo: data.tipo,
        endereco: endCom,
        coor: data.Coordenadas,
        mostrar: data.nomecto,
      })
      .then((res) => {
        if (res.status) {

          console.log(data)
          if (res.data.latlon[0]) {
            setCenter([
              res.data.latlon[0],
              res.data.latlon[1]
            ]);
          } else {
            setCenter([
              res.data.latlon.lat,
              res.data.latlon.lng
            ]);
          }

          const ctos = [];
          res.data.posCtos.map((item, index) => {
            ctos.push({
              id: index,
              capacidade: { capacidade: item.capacidade, id: index },
              qtdclientes: { qtdclientes: item.qtdClientes, id: index },
              portasdisponiveis: {
                portasdisponiveis: item.portasDisponiveis,
                id: index,
              },
              distancia: { distancia: item.distancia + " M", id: index },
              nome: { nome: item.nomeCTO, id: index },
              lat: item.latitude,
              lng: item.longitude,
              key: index,
            });
          });
          map.setView(coord, zoom)
          setDataInfo(ctos);
          setCtos(ctos);
          setMostrar(res.data.mostrar);
          setTamanhoC(Number(res.data.distancia));
        }
      })
      .catch(async function (err) {
        alertMessage(
          "error",
          "Não foi possivel fazer a consulta",
          "Formato de Coordenada/Endereço inválidos"
        );
      });
  };
  const greenOptions = { color: 'lime', fillColor: 'lime' }

  useEffect(() => { }, [center])


  return (
    <>
      {contextHolder}
      <CardDark
        title="Pesquisar viabilidade"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row justify="space-between" align={"middle"}>
            <Col xs={24} sm={24} md={8} lg={6} xl={4}>
              <Image src={RouterImage} preview={false}></Image>
            </Col>
            <Col xs={24} sm={24} md={16} lg={18} xl={20}>
              <Row justify="space-between" gutter={[20, 20]}>
                <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                  <FormControl fullWidth>
                    <>
                      <TextField
                        id="outlined-basic"
                        label="Coordenadas"
                        required
                        variant="outlined"
                        defaultValue={""}
                        size="small"
                        {...register("Coordenadas")}
                      ></TextField>
                    </>
                  </FormControl>
                </Col>

                <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                  <FormControl fullWidth style={separadorInput20}>
                    <TextField
                      id="outlined-basic"
                      label="Distancia em Metros"
                      required
                      variant="outlined"
                      defaultValue="200"
                      size="small"
                      {...register("distancia")}
                    ></TextField>
                  </FormControl>
                </Col>
              </Row>
              {/* <Row justify="space-between">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    {...register("nomecto")}
                    label="Nome da CTO sobre o ícone"
                  />
                </Col>
              </Row> */}

            </Col>
          </Row>

          <Row>
            <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
          </Row>
          <Row justify="end">


            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={showDrawer}
              style={{ float: "right", marginBottom: 0, marginRight: 20 }}
            >
              Dicas
            </Button>


            <Button variant="contained" size="small" type="submit" >
              Pesquisar
            </Button>

            <Drawer
              title={<span style={{ color: "white" }}>DICAS PARA USO DO VIABILIDADE</span>}
              placement="right"
              onClose={onClose}
              open={open}
              style={{ backgroundColor: "#001529" }}
            >
              <Card
                title="Dar zoom"
                style={{ marginBottom: 10, marginTop: 0 }}
              >
                <p>Utilize o scroll do mouse para dar zoom ou tirar zoom</p>
                <p>
                  No celular, utilize os dois dedos juntos na tela e
                  distancie-os para as extremidades do mapa
                </p>
              </Card>

              <Card title="Obter informações adicionais" style={{ marginBottom: 10 }}>
                <p>
                  Clique em cima da CTO para obter informações adicionais
                </p>
              </Card>

              <Card
                title="Endereço coordenada"
                style={{ marginBottom: 10 }}
              >
                <p>
                  Utilize coordenadas no seguinte modelo:
                  -15.9066,-47.763946, removendo qualquer espaço entre os
                  números
                </p>
              </Card>
            </Drawer>
          </Row>
        </form>
      </CardDark >

      <Card>
        <MapContainer
          center={center}
          zoom={17}
          ref={setMap}
          scrollWheelZoom={true}
          style={{
            width: "100%",
            marginTop: 20,
            height: "calc(100vh - 4rem)",
          }}
        >
          <TileLayer
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          <Marker position={center} icon={ICON}>
            <Popup>
              lat: {center[0]} lng: {center[1]}
            </Popup>
          </Marker>
          {tamanhoC !== undefined && (
            <>
              <Circle
                center={center}
                pathOptions={greenOptions}
                radius={tamanhoC}
              />
            </>
          )}
          {ctos.map((i, index) => {

            if (index === 0) {

              return <Marker position={[i.lat, i.lng]} icon={ICON3} key={index}>
                <Popup>
                  <strong>{i.nome.nome}<br /></strong>
                  Distancia: {i.distancia.distancia} <br />
                  Portas disponíveis: {i.portasdisponiveis.portasdisponiveis}<br />
                  Qtd. Clientes: {i.qtdclientes.qtdclientes}
                </Popup>
              </Marker>

            } else {
              return <Marker position={[i.lat, i.lng]} icon={ICON2} key={index}>
                <Popup>
                  <strong>{i.nome.nome}<br /></strong>
                  Distancia: {i.distancia.distancia}<br />
                  Portas disponíveis: {i.portasdisponiveis.portasdisponiveis}<br />
                  Qtd. Clientes: {i.qtdclientes.qtdclientes}
                </Popup>
              </Marker>

            }

          })}
          <SearchField />
          <LeafletRuler />
          <MapPrint
            position="topleft"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
            title="Print"
          />
          <MapPrint
            position="topleft"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
            title="Export as PNG"
            exportOnly
          />
        </MapContainer>
      </Card>

      {
        dataInfo !== undefined && (
          <Card style={{ marginTop: 20 }}>
            <Table columns={columns} dataSource={dataInfo} />
          </Card>
        )
      }
    </>
  );
}
export default PesquisarViabilidades;

const separadorInput20 = {
  marginBottom: 20,
};

