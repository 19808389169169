import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Card, Col, Divider, Row, Image, notification } from "antd";
import { useForm } from "react-hook-form";
import SelectComponent from "../../../../../component/formComponents/select";
import { useLocation } from "react-router-dom";
import gif from "./../../../../../assets/img/36436-wifi-router-animation.gif";
import { GetAPI } from "../../../../../services/http/GetAPI";
import { useEffect, useState } from "react";
import { NodeRequests } from "../../../../../services/http/NodeRequests";
import Autocomplete from "@mui/material/Autocomplete";
import { loadState } from "../../../../../services/localStorage";
//import { Navigate } from "react-router-dom";
import LoadingImage from "../../../../../component/loadingImage";
import { useNavigate } from "react-router-dom";

function AutorizarOlt() {
  const location = useLocation();
  const { olt, onuClicada } = location.state;
  const [onuTemplates, setOnuTemplates] = useState();
  const [loading, setLoading] = useState(true);
  const [selectOnu, setSelectOnu] = useState([]);
  const [allTiposOnu, setAllTiposOnu] = useState();
  const [allCtos, setAllCtos] = useState();
  const [ctoEscolhida, setCtoEscolhida] = useState();
  const [defaultDesc, setDefaultDesc] = useState();
  const permissions = loadState("permissions");
  const [api, contextHolder] = notification.useNotification();
  const [ctosLabel, setCtosLabel] = useState()
  const [contratoLabel, setContratoLabel] = useState()
  const [descricaoState, setDescricaoState] = useState()
  const navigate = useNavigate();

  const sv = [{ id: 1, label: olt.ontsv }];
  const onu = [
    {
      id: 1,
      label: `OLT: ${olt.onte}- ${olt.ip}- Slot: ${olt.slot} PON: ${olt.pon}`,
    },
  ];
  const mode = [
    { id: 1, label: "BRIDGE" },
    { id: 2, label: "PPPoE" },
  ];
  const item = [{ id: 1, label: "item" }];

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.cto = ctoEscolhida.id;
    data.Descricao = defaultDesc;

    autorizarONU(data, olt, onuClicada);
    //reset()
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const buscarOLT = async () => {
    const { http } = GetAPI();

    http
      .get("/onu-template-getall")
      .then((res) => {
        if (res.status) {
          let onuTemp = [];

          res.data.map((item, index) => {

            let olts = item.olts.split(",").map((i) => { return Number(i) })

            console.log(olts)
            console.log(onuClicada.id)

            if (olts.includes(Number(onuClicada.id))) {

              if (olt.pon === item.pon && olt.slot === item.slot) {
                onuTemp.push({
                  id: item.id,
                  label: item.descricao,
                });
              }

              if (olt.pon === "Todos" && olt.slot === item.slot || olt.pon === item.pon && olt.slot === "Todos") {
                onuTemp.push({
                  id: item.id,
                  label: item.descricao,
                });
              }

              if (item.pon === "Todos" && item.slot === "Todos") {
                onuTemp.push({
                  id: item.id,
                  label: item.descricao,
                });
              }

            }

          });

          setOnuTemplates(onuTemp);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const autorizarONU = (data, olt, onu) => {
    const { http } = NodeRequests();

    data.Descricao = descricaoOlt(ctosLabel, contratoLabel)

    http
      .post("/olt-gerenciar/autorizar", {
        X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET,
        olt: olt,
        data: data,
        onu: onu,
      })
      .then((res) => {
        if (res.status) {
          console.log(res);

          if (res.status === 201) {
            alertMessage(
              "success",
              `Cadastrar ONU`,
              <pre>{`${res.data.result}\nONU: ${res.data.name}`}</pre>
            );
            navigate("/olt-gerenciar/onu-autorizadas", {
              state: { cadastro: true },
            });
          } else {
            console.log("deu ruim");
          }
        }
      })
      .catch(async function (err) {
        alertMessage("error", "Cadastrar ONU", "Porta já está sendo usada");
      });
  };

  const buscarAllCtos = () => {
    const { http } = NodeRequests();

    http
      .post("/all-ctos", { X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET })
      .then((res) => {
        if (res.status) {
          let allCtos = [];

          res.data.data.map((item, index) => {
            allCtos.push({
              id: item.id_sgp,
              label: item.ident,
              value: item.id_sgp,
            });
          });

          setAllCtos(allCtos);

        }
      })
      .catch(async function (err) {
        console.log(err);
        //alertMessage('error', 'Cadastrar ONU', err)
      });
  };

  const sincronizarCtos = () => {
    const { http } = NodeRequests();

    setLoading(true);

    http
      .post("/sync-ctos-sgp", {
        X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET,
      })
      .then((res) => {
        if (res.status) {
          alertMessage(
            "success",
            "Sincronizar CTOS",
            "CTOS sincronizadas com sucesso"
          );
          setLoading(false);
          window.location.reload();
        }
      })
      .catch(async function (err) {
        console.log(err);
        //alertMessage('error', 'Cadastrar ONU', err)
      });
  };

  const preencherDescricao = (value) => {
    let arrayCto = value.label.split("/");
    let arrayCtoNum = arrayCto[1].split("-");

    if (!arrayCto[0].includes("CTO-")) {
      setDefaultDesc("CTO-" + arrayCto[0] + "/" + arrayCtoNum[2]);
    } else {
      setDefaultDesc(arrayCto[0] + "/" + arrayCtoNum[2]);
    }
  };

  const buscarTiposOnu = () => {
    const { http } = NodeRequests();

    http
      .post("/onu-type", { X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET })
      .then((res) => {
        if (res.status) {
          let onuType = [];

          res.data.data.map((item, index) => {
            onuType.push({
              id: item.code,
              label: item.code,
            });
          });

          setAllTiposOnu(onuType);
        }
      })
      .catch(async function (err) {
        console.log(err);
        //alertMessage('error', 'Cadastrar ONU', err)
      });
  };

  useEffect(() => {
    if (onuTemplates === undefined) {
      buscarOLT();
    }

    if (allTiposOnu === undefined) {
      buscarTiposOnu();
    }
    if (allCtos === undefined) {
      buscarAllCtos();
    }

    if (onuTemplates !== undefined && allTiposOnu !== undefined && allCtos !== undefined) {
      setLoading(false);
    }


  }, [onuTemplates, allCtos, defaultDesc, ctosLabel, contratoLabel, descricaoState]);

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <Card
          title="Autorizar OLT"
          extra={
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="small"
              onClick={() => {
                sincronizarCtos();
              }}
            >
              Sincronizar ctos SGP
            </Button>
          }
        >
          <Row justify={"space-around"}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Image src={gif} preview={false}></Image>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <SelectComponent
                  register={register}
                  items={onu}
                  defaultValue={1}
                  label="ONU"
                  labelRegister="onu"
                ></SelectComponent>

                <SelectComponent
                  register={register}
                  items={allTiposOnu === undefined ? [] : allTiposOnu}
                  label="Tipo de ONU"
                  labelRegister="tipodeonu"
                ></SelectComponent>

                <SelectComponent
                  register={register}
                  items={onuTemplates === undefined ? [] : onuTemplates}
                  label="ONU Template"
                  labelRegister="onutemplate"
                ></SelectComponent>

                <FormControl fullWidth id="category-editor-form">
                  <TextField
                    id="outlined-basic"
                    label="Phy Address"
                    required
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register("phyaddress")}
                    defaultValue={olt.oltsn}
                  ></TextField>

                  <TextField
                    id="outlined-basic"
                    label="Etiqueta"
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "etiqueta"
                    )} /*defaultValue={oltEdit ? oltEdit.ip : null} */
                  ></TextField>

                  <TextField
                    id="outlined-basic"
                    label="Login"
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "contratoLogin", {
                      onChange: (e) => {
                        setContratoLabel(e.target.value)
                      },
                    }
                    )} /*defaultValue={oltEdit ? oltEdit.port : null}*/
                  ></TextField>
                </FormControl>

                {/*  <SelectComponent register={register} items={sv} label="CTO" labelRegister="cto"></SelectComponent> */}

                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  {...register("cto")}
                  options={allCtos === undefined ? [] : allCtos}
                  style={separadorInput20}
                  onChange={(e, value) => {
                    setCtoEscolhida(value);
                    setCtosLabel(value.label)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="CTO" />
                  )}
                />

                <FormControl fullWidth id="category-editor-form">
                  <TextField
                    id="outlined-basic"
                    label="Porta na CTO"
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "portCTO"
                    )} /*defaultValue={oltEdit ? oltEdit.nome : null}*/
                  ></TextField>
                </FormControl>
                {/* 
                <SelectComponent
                  register={register}
                  items={item}
                  defaultValue={1}
                  label="Bandwidth Profile"
                  labelRegister="bandwidthprofile"
                ></SelectComponent> */}

                <SelectComponent
                  register={register}
                  items={mode}
                  defaultValue={2}
                  label="Modo Acesso"
                  labelRegister="modoacesso"
                ></SelectComponent>

                <FormControl fullWidth id="category-editor-form">
                  {/* <TextField
                    id="outlined-basic"
                    label="Login"
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "Login"
                    )}
                  ></TextField>

                  <TextField
                    id="outlined-basic"
                    label="Senha"
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "Senha"
                    )}
                  ></TextField>

                  <TextField
                    id="outlined-basic"
                    label="Portas"
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "Portas"
                    )}
                  ></TextField> */}

                  <TextField
                    id="outlined-basic"
                    required
                    label="Descrição"
                    value={descricaoOlt(ctosLabel, contratoLabel)}
                    variant="outlined"
                    size="small"
                    style={separadorInput20}
                    {...register(
                      "Descricao",
                    )} /*defaultValue={oltEdit ? oltEdit.port : null}*/
                  ></TextField>
                </FormControl>


                {/* <>
                  <FormLabel component="legend">Acesso Web</FormLabel>
                  <FormControlLabel
                    style={separadorInput20}
                    control={<Checkbox />}
                    {...register("acessoweb")}
                    label="Enable"
                  />

                  <FormControl fullWidth id="category-editor-form">
                    <TextField
                      id="outlined-basic"
                      label="Porta Acesso Web"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "Porta Acesso Web"
                      )}
                    ></TextField>
                  </FormControl>

                  <FormLabel component="legend">Acesso Telnet</FormLabel>
                  <FormControlLabel
                    style={separadorInput20}
                    control={<Checkbox />}
                    {...register("acessotelnet")}
                    label="Enable"
                  />

                  <FormControl fullWidth id="category-editor-form">
                    <TextField
                      id="outlined-basic"
                      label="ONU Login"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "onulogin"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="ONU Password"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "onupassword"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Wifi SSID"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "wifissid"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Wifi Senha"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "wifisenha"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Wifi Canal 2.4"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "wificanal24"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Wifi SSID 5"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "wifissid5"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Wifi Senha 5"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "wifisenha5"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Wifi Canal 5"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "wificanal5"
                      )}
                    ></TextField>
                  </FormControl>

                  <FormLabel component="legend">
                    Wifi Central do Assinante
                  </FormLabel>
                  <FormControlLabel
                    style={separadorInput20}
                    control={<Checkbox />}
                    {...register("wificentraldoassinante")}
                    label="Enable"
                  />

                  <TextField
                    id="outlined-multiline-static"
                    label="Anotações"
                    multiline
                    rows={3}

                    fullWidth
                    style={separadorInput20}
                    {...register("Anotacoes")}
                  />

                  <FormControl fullWidth id="category-editor-form">
                    <TextField
                      id="outlined-basic"
                      label="VOIP Ip"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP Ip"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP Máscara"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP Mascara"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP Gateway"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP Gateway"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="Porta Voip"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "Porta Voip"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP Service Name"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP Service Name"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP Telefone"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP Telefone"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP Usuário"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP Usuario"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP SIP Usuário"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP SIP Usuario"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP SIP Senha"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP SIP Senha"
                      )}
                    ></TextField>

                    <TextField
                      id="outlined-basic"
                      label="VOIP VLAN"
                      variant="outlined"
                      size="small"
                      style={separadorInput20}
                      {...register(
                        "VOIP VLAN"
                      )}
                    ></TextField>
                  </FormControl>

                  <TextField
                    id="outlined-multiline-static"
                    label="Generic data"
                    multiline
                    rows={3}
                    fullWidth
                    style={separadorInput20}
                    {...register("Generic data")}
                  />

                  <TextField
                    id="outlined-multiline-static"
                    label="Info"
                    multiline
                    rows={3}
                    fullWidth
                    style={separadorInput20}
                    {...register("Info")}
                  />
                </> */}


                <Divider></Divider>

                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  size="small"
                >
                  Salvar
                </Button>
              </form>
            </Col>
          </Row>
        </Card >
      )
      }
    </>
  );
}

export default AutorizarOlt;

const descricaoOlt = (label1, label2) => {

  if (label1 === undefined && label2 === undefined || label1 === "" && label2 === "") {
    return ""
  }

  if (label1 !== undefined && label2 === undefined || label1 !== "" && label2 === "") {
    return label1
  }

  if (label2 !== undefined && label1 === undefined || label2 !== "" && label1 === "") {
    return label2
  }

  if (label2 !== undefined && label1 !== undefined || label2 !== "" && label1 !== "") {
    return `${label2}-${label1}`;
  }
}

const separadorInput20 = {
  marginBottom: 20,
};
