import { Tabs } from "antd";
import itemsGerenciarPermissoes from "./itemsMenu";

function GerenciarPermissoes() {

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1" items={itemsGerenciarPermissoes}
      />
    </>
  );
}

export default GerenciarPermissoes;


