import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './drop-file-input.css';

import { ImageConfig } from './config/ImageConfig';
import uploadImg from './assets/cloud-upload-regular-240.png';

const DropFileInputPdf = props => {

    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState([]);
    const [error, setError] = useState()

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];

        if (newFile) {
            const updatedList = [...[], newFile];
            console.log(updatedList)
            if (props.type === updatedList[0].type) {
                setFileList(updatedList);
                props.onFileChange(updatedList);
                setError(null)
            } else {
                setError("Formato de arquivo inválido")
            }
        }
    }

    const fileRemove = (file) => {
        // const updatedList = [...fileList];
        // updatedList.splice(fileList.indexOf(file), 1);
        setFileList([]);
        props.onFileChange(undefined);
    }

    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img src={uploadImg} alt="" />
                    <p>Arraste e solte seus arquivos ou clique para adicionar</p>

                    {error && (
                        <p style={{ color: "red" }}>* {error} *</p>
                    )}
                </div>
                <input type="file" value="" accept=".pdf" onChange={onFileDrop} />
            </div>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            Pronto para o Upload
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item" width={50} height={50}>
                                    <img src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                    <div className="drop-file-preview__item__info">
                                        <span>{item.name}</span>
                                        <span>{item.size}B</span>
                                    </div>
                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }

        </>
    );
}

DropFileInputPdf.propTypes = {
    onFileChange: PropTypes.func
}

export default DropFileInputPdf;
