import { Card, Col, Divider, Row, Modal } from "antd";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material/";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { GetAPI } from "./../../../services/http/GetAPI";
import LoadingImage from "../../../component/loadingImage";
import CardDark from "../../../component/cardDark";

function OltConfigurar() {
  const matches = useMediaQuery("(max-width:992px)");
  const [olt, setOlt] = useState("");
  const [isModalOpen, setIsModalOpen] = useState("");
  const [oltModal, setOltModal] = useState("");
  const [onuType, setOnuType] = useState("");
  const [ponType, setPonType] = useState("GPON");
  const [customProfile, setCustomProfile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allOlts, setAllOlts] = useState();
  const [irTopo, setIrTopo] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeCustomProfile = (event) => {
    setCustomProfile(event.target.checked);
  };

  const handleChangePonType = (event) => {
    setPonType(event.target.value);
  };

  const handleChangeOLT = (event) => {
    setOlt(event.target.value);
  };

  const handleChangeOLTModal = (event) => {
    setOltModal(event.target.value);
  };

  const handleChangeONUType = (event) => {
    setOnuType(event.target.value);
  };

  const buscarOLT = () => {
    const { http } = GetAPI();

    http
      .get("/olt-getall")
      .then((res) => {
        if (res.status) {
          setAllOlts(res.data);
          setLoading(false);
        }
      })
      .catch(async function (err) {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (allOlts === undefined) {
      buscarOLT();
    }

    if (irTopo === undefined) {
      window.scrollTo(0, 0);
      setIrTopo(true);
    }
  }, []);

  return (
    <>
      {loading === true && <LoadingImage />}
      {loading === false && (
        <CardDark title="Pesquisas Olt">
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              style={
                matches === true ? paragrafCenterStyle : paragrafRightStyle
              }
            >
              <p>Buscar OLTS</p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={10}
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <FormControl fullWidth>
                <InputLabel id="todas-olts" size="small">
                  Olts
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  label="Olts"
                  size="small"
                  onChange={handleChangeOLT}
                >
                  {allOlts.map((olt, key) => (
                    <MenuItem value={olt.id} key={key}>
                      {olt.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Button
                variant="contained"
                style={matches === true ? { marginTop: 20 } : { marginTop: 0 }}
                size="small"
              >
                Verificar
              </Button>
            </Col>
          </Row>
          <Divider></Divider>
          <Row style={{ justifyContent: "center" }}>
            <Col>
              <Button variant="contained" onClick={showModal} size="small">
                Adicionar OLT
              </Button>
              <Modal
                title="Adicionar OLT"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <FormControl fullWidth>
                  <InputLabel id="olt" style={separadorInput20} size="small">
                    OLT
                  </InputLabel>
                  <Select
                    labelId="olt"
                    id="olt"
                    label="OLT"
                    onChange={handleChangeOLTModal}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Tipo de PON
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={separadorInput10}
                    onChange={handleChangePonType}
                    defaultValue="GPON"
                  >
                    <FormControlLabel
                      value="GPON"
                      control={<Radio />}
                      label="GPON"
                    />
                    <FormControlLabel
                      value="EPON"
                      control={<Radio />}
                      label="EPON"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Board"
                    variant="outlined"
                    style={separadorInput20}
                    size="small"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Porta"
                    variant="outlined"
                    style={separadorInput20}
                    size="small"
                  />

                  {ponType === "GPON" ? (
                    <TextField
                      id="outlined-basic"
                      label="SN"
                      variant="outlined"
                      style={separadorInput20}
                      size="small"
                    />
                  ) : (
                    <TextField
                      id="outlined-basic"
                      label="MAC"
                      variant="outlined"
                      style={separadorInput20}
                      size="small"
                    />
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    id="onu-type"
                    style={separadorInput20}
                    size="small"
                  >
                    ONU Type
                  </InputLabel>
                  <Select
                    labelId="onu-type"
                    id="onu-type"
                    label="ONU Type"
                    onChange={handleChangeONUType}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  style={separadorInput10}
                  control={<Checkbox onChange={handleChangeCustomProfile} />}
                  label="Use custom profile (For better compatibility with generic ONUs)"
                />
                {customProfile === true && (
                  <FormControl fullWidth>
                    <InputLabel id="olt" style={separadorInput20} size="small">
                      OLT
                    </InputLabel>
                    <Select
                      labelId="olt"
                      id="olt"
                      label="OLT"
                      onChange={handleChangeOLTModal}
                      style={separadorInput20}
                      size="small"
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    ONU mode
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={separadorInput10}
                    onChange={handleChangePonType}
                    defaultValue="GPON"
                  >
                    <FormControlLabel
                      value="GPON"
                      control={<Radio />}
                      label="Routing"
                    />
                    <FormControlLabel
                      value="EPON"
                      control={<Radio />}
                      label="Bridging"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="olt" style={separadorInput20} size="small">
                    User VLAN-ID
                  </InputLabel>
                  <Select
                    labelId="olt"
                    id="olt"
                    label="User VLAN-ID"
                    onChange={handleChangeOLTModal}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="olt" style={separadorInput20} size="small">
                    Zone
                  </InputLabel>
                  <Select
                    labelId="olt"
                    id="olt"
                    label="Zone"
                    onChange={handleChangeOLTModal}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="olt" style={separadorInput20} size="small">
                    ODB (Splitter)
                  </InputLabel>
                  <Select
                    labelId="olt"
                    id="olt"
                    label="ODB (Splitter)"
                    onChange={handleChangeOLTModal}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="olt" style={separadorInput20} size="small">
                    Download Speed
                  </InputLabel>
                  <Select
                    labelId="olt"
                    id="olt"
                    label="Download Speed"
                    onChange={handleChangeOLTModal}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="olt" style={separadorInput20} size="small">
                    Upload Speed
                  </InputLabel>
                  <Select
                    labelId="olt"
                    id="olt"
                    label="Upload Speed"
                    onChange={handleChangeOLTModal}
                    style={separadorInput20}
                    size="small"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                  </Select>
                  <TextField
                    id="outlined-basic"
                    label="Nome"
                    variant="outlined"
                    style={separadorInput20}
                    size="small"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Endereço ou comentário"
                    variant="outlined"
                    style={separadorInput10}
                    size="small"
                  />
                </FormControl>
              </Modal>
            </Col>
          </Row>
        </CardDark>
      )}
    </>
  );
}

export default OltConfigurar;

// CSS

const paragrafRightStyle = {
  textAlign: "right",
};

const paragrafCenterStyle = {
  textAlign: "center",
};

const separadorInput10 = {
  marginBottom: 10,
};

const separadorInput20 = {
  marginBottom: 20,
};
