import React, { useState } from "react";
import { socket } from "./../../view/internalPages/chat/socket";
import { Col, Row } from "antd";
import { Button, FormControl, TextField } from "@mui/material";

export function MyForm() {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    socket.timeout(5000).emit("create-something", value, () => {
      setIsLoading(false);
    });
  }

  return (
    <form onSubmit={onSubmit}>
      <Row justify="space-around" style={{ alignItems: "center" }}>
        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
          <FormControl
            fullWidth
            id="category-editor-form"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              fullWidth={true}
              label={"Testar comando no socket-io"}
              onChange={(e) => setValue(e.target.value)}
              id="outlined-basic"
              variant="outlined"
              size="small"
            />
          </FormControl>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button type="submit" variant="contained" disabled={isLoading}>
            Enviar
          </Button>
        </Col>
      </Row>
    </form>
  );
}
