import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card, Col, Row, Tag } from "antd";
import React, { Component, useEffect, useState } from "react";
import Terminal from "terminal-in-react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { PostAPI } from "../../../../services/http/PostAPI";
import { NodeRequests } from "../../../../services/http/NodeRequests";
import LoadingImage from "../../../../component/loadingImage";

function Cmd() {
  const [olt, setOlt] = useState();
  const [allOlts, setAllOlts] = useState();
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState("NODE");
  const [allCommands, setAllCommands] = useState();

  const enviarComandoNode = (print, cmd) => {
    const { http } = NodeRequests();

    let command = cmd.toString();

    let resultado = command.replaceAll(",", " ");

    return new Promise((resolve, reject) => {
      http
        .post(`/cmd/`, {
          X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET,
          olt: olt,
          cmd: resultado,
        })
        .then((res) => {
          if (res.status) {
            print(res.data.data);
            registarCommand(cmd, service);
            pegarTodosCommandos();

            resolve(true);
          } else {
            console.log(res);
          }
        })
        .catch((erro) => {
          console.log(erro);
        });
    });
  };

  const registarCommand = (cmd, service) => {
    const { http } = PostAPI();

    let command = cmd.toString();

    let resultado = command.replaceAll(",", " ");

    http
      .post(`/ssh-register-command/`, { cmd: resultado, servico: service })
      .then((res) => {
        if (res.status) {
        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const pegarTodosCommandos = () => {
    const { http } = GetAPI();

    http
      .post(`/ssh-getall-commands`)
      .then((res) => {
        if (res.status) {
          setAllCommands(res.data);
          setLoading(false);
        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const enviarComandoLaravel = (print, cmd) => {
    const { http } = PostAPI();

    let command = cmd.toString();

    let resultado = command.replaceAll(",", " ");

    return new Promise((resolve, reject) => {
      http
        .post(`/ssh-command-execute/`, { olt: olt, cmd: resultado })
        .then((res) => {
          if (res.status) {
            print(res.data);
            registarCommand(cmd, service);
            pegarTodosCommandos();

            resolve(true);
          } else {
            console.log(res);
          }
        })
        .catch((erro) => {
          console.log(erro);
        });
    });
  };

  const sshCommand = async (print, cmd) => {
    if (service === "NODE") {
      let resposta = enviarComandoNode(print, cmd);

      return resposta;
    }
    if (service === "LARAVEL") {
      let resposta = enviarComandoLaravel(print, cmd);

      return resposta;
    }
  };

  const buscarOLT = () => {
    const { http } = GetAPI();

    http
      .get("/olt-getall")
      .then((res) => {
        if (res.status) {
          setAllOlts(res.data);
          if (res.data[Object.keys(res.data)[0]]) {
            setOlt(res.data[Object.keys(res.data)[0]].id);
          }
        }
      })
      .catch(async function (err) {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (allOlts === undefined) {
      buscarOLT();
    }

    if (allCommands === undefined) {
      pegarTodosCommandos();
    }
  }, []);

  return (
    <>
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          <Row style={separadorInput20} justify={"space-around"}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={17}
              xl={17}
              style={{ marginBottom: 20 }}
            >
              <FormControl fullWidth>
                <InputLabel id="todas-olts" size="small">
                  Olt
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  label="Olt"
                  defaultValue={
                    allOlts[Object.keys(allOlts)[0]]
                      ? allOlts[Object.keys(allOlts)[0]].id
                      : null
                  }
                  onChange={(e) => {
                    setOlt(e.target.value);
                  }}
                  size="small"
                  required
                >
                  {allOlts.map((olt, key) => {
                    return (
                      <MenuItem value={olt.id} key={key}>
                        {olt.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{ marginBottom: 20 }}
            >
              <FormControl fullWidth>
                <InputLabel id="todas-olts" size="small">
                  SSH
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  label="Olt"
                  defaultValue={"NODE"}
                  onChange={(e) => {
                    setService(e.target.value);
                  }}
                  size="small"
                  required
                >
                  {["LARAVEL", "NODE"].map((olt, key) => {
                    return olt === "LARAVEL" ? (
                      <MenuItem value={olt} key={key} style={{ color: "red" }}>
                        {olt}
                      </MenuItem>
                    ) : (
                      <MenuItem value={olt} key={key} style={{ color: "blue" }}>
                        {olt}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row justify={"space-around"}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={17}
              xl={17}
              style={{ marginBottom: 30 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Terminal
                  color="green"
                  backgroundColor="black"
                  barColor="black"
                  startState="maximised"
                  style={{ fontWeight: "bold", fontSize: "1em" }}
                  commands={{
                    "open-google": () =>
                      window.open("https://www.google.com/", "_blank"),
                    popup: () => alert("Terminal in React"),
                  }}
                  commandPassThrough={(cmd, print) => {
                    if (olt) {
                      sshCommand(print, cmd);
                    } else {
                      print("Olt não foi selecionada");
                    }
                  }}
                  descriptions={{
                    "open-google": "opens google.com",
                    alert: "alert",
                    popup: "alert",
                  }}
                  msg="Terminal Radnet para utilizar comandos no navegador"
                />
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{ marginBottom: 30 }}
            >
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <Card style={{ backgroundColor: "black", color: "lightgreen" }}>
                  <strong>Últimos comandos utilizados</strong>
                </Card>
                {allCommands.map((element) => {
                  //return <Tag>{element}</Tag>

                  return element.servico_disparo === "LARAVEL" ? (
                    <Card size="small" style={{ color: "red" }}>
                      {element.command}
                    </Card>
                  ) : (
                    <Card size="small" style={{ color: "blue" }}>
                      {element.command}
                    </Card>
                  );
                })}
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Cmd;

const separadorInput20 = {
  marginBottom: 20,
};
