import { Image } from "antd";
import manutencao from "./../../../assets/gif/manutencao.gif"

function PaginaManutencao() {

    return (
        <>
            <Image src={manutencao} preview={false}></Image>
            <h1>Pagina manutenção</h1>
        </>
    );
}

export default PaginaManutencao;