import { Card, Table, Modal, Row, Col, Image, notification } from "antd";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, TextField } from "@mui/material";
import SelectComponent from "../../../../component/formComponents/select";
import { PostAPI } from "../../../../services/http/PostAPI";
import { GetAPI } from "../../../../services/http/GetAPI";
import { Link } from "react-router-dom";
import LoadingImage from "../../../../component/loadingImage";
import LocationImage from "./../../../../assets/img/86513-location-forked.gif";
import copy from "copy-to-clipboard";
import CardDark from "../../../../component/cardDark";

function CadastroDeLocalizacao() {
  const columnsLink = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Contato",
      dataIndex: "contato",
      key: "contato",
    },
    {
      title: "Data de cadastro",
      dataIndex: "dt_cadastro",
      key: "dt_cadastro",
    },
    {
      title: "Validade",
      dataIndex: "validade",
      key: "validade",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Responsável",
      dataIndex: "responsavel",
      key: "responsavel",
    },
  ];

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "CTOs",
      dataIndex: "ctos",
      key: "ctos",
      render: (_, record) => (
        <>
          <Link
            to={
              "/viabilidade-cadastro-localizacao/viabilidade-localizacao-link"
            }
            state={{
              lat: record.lat,
              lng: record.lng,
              contato: record.contato,
            }}
          >
            CTOS
          </Link>
        </>
      ),
    },
    {
      title: "Contato",
      dataIndex: "contato",
      key: "contato",
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "lat",
    },
    {
      title: "Longitude",
      dataIndex: "lng",
      key: "lng",
    },
    {
      title: "Data de Registro",
      dataIndex: "date_register",
      key: "date_register",
    },
    {
      title: "Responsável",
      dataIndex: "responsible",
      key: "responsible",
    },
    {
      title: "Ações",
      dataIndex: "acoes",
      key: "acoes",
      render: (_, record) => (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={() => {
                confirmationViability("viavel", record);
              }}
            >
              Viável
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => {
                confirmationViability("inviavel", record);
              }}
            >
              inviável
            </Button>
          </div>
        </>
      ),
    },
  ];

  const dataInfoConfirmed = [];

  const dataInfoNoConfirmed = [];

  const ValidadeLink = [
    { id: "days", label: "Dias(s)" },
    { id: "hours", label: "Hora(s)" },
    { id: "minutes", label: "Minuto(s)" },
  ];
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const [ddd, setDDD] = useState();
  const [valid, setValid] = useState();
  const [err, setErr] = useState();
  const [allLinksNoConfirmed, setAllLinksNoConfirmed] = useState();
  const [allLinksConfirmed, setAllLinksConfirmed] = useState();
  const [loading, setLoading] = useState(true);
  const [copyText, setCopyText] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const handleCopyText = (texto) => {
    copy(texto);
    alertMessage("success", "Copiar link", "Link copiado com sucesso");
  };

  const onSubmit = (data) => {
    if (data.qtd === 1) {
      data.unidade = data.unidade.replate("s", "");
    }

    if (err === undefined) {
      cadastrarLink(data);

      setIsModalOpen(false);
    }
  };

  const normalizeCardNumber = (value) => {
    const ocorrencias = (value.match(/[0-9]/g) || []).length;

    if (ocorrencias !== 11) {
      setErr("Telefone deve conter 11 caracteres");
    } else {
      setErr(undefined);
    }

    return value
      .replace(/\D+/g, "")
      .replace(/^([0-9]{2})?([0-9]{5})?([0-9]{4})$/, "($1) $2-$3");
  };

  const handleCancelEditar = () => {
    reset();
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cadastrarLink = (data) => {
    const { http } = PostAPI();

    http
      .post("/viabilidade-link-register", {
        ddi: data.ddi,
        number_phone: data.fone,
        validate_unity: data.unidade,
        validate_qtd: data.qtd,
      })
      .then((res) => {
        if (res.status) {
          getAllLinksNoConfirmed();
          getAllLinksConfirmed();
          //setTested(true)
          //alertMessage('success', 'Cadastro OLT', 'A conexão feita com sucesso!')
        }
      })
      .catch(async function (err) {
        console.log(err);
        //setTested(undefined)
        //alertMessage('error', 'Cadastro OLT', 'A conexão de teste com a OLT falhou')
      });
  };

  const confirmationViability = (viabilidade, cliente) => {
    const { http } = PostAPI();

    http
      .post("/viabilidade-confirmation", {
        viabilidade: viabilidade,
        cliente: cliente,
      })
      .then((res) => {
        if (res.status) {
          getAllLinksNoConfirmed();
          getAllLinksConfirmed();
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const getAllLinksNoConfirmed = () => {
    const { http } = GetAPI();

    http
      .get("/viabilidade-link-no-confirmed-getall")
      .then((res) => {
        if (res.status) {
          setAllLinksNoConfirmed(res.data);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const getAllLinksConfirmed = () => {
    const { http } = GetAPI();

    http
      .get("/viabilidade-link-confirmed-getall")
      .then((res) => {
        if (res.status) {
          setAllLinksConfirmed(res.data);

        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  useEffect(() => {
    if (allLinksNoConfirmed === undefined) {
      getAllLinksNoConfirmed();
    }

    if (allLinksConfirmed === undefined) {
      getAllLinksConfirmed();
    }

    if (allLinksNoConfirmed !== undefined && allLinksConfirmed !== undefined) {
      setLoading(false);
    }

  }, [allLinksNoConfirmed, allLinksConfirmed]);

  if (loading === false) {
    allLinksNoConfirmed.map((item, index) => {
      dataInfoNoConfirmed.push({
        id: item.id,
        contato: item.number_phone,
        dt_cadastro: item.date_register,
        validade: item.validate_link,
        responsavel: item.responsible,
        status: "Não confirmado",
        key: index,
      });

      return null;
    });

    allLinksConfirmed.map((item, index) => {
      dataInfoConfirmed.push({
        id: item.id,
        contato: item.number_phone,
        lat: item.lat,
        lng: item.lng,
        date_register: item.date_register,
        responsible: item.responsible,
        key: index,
      });

      return null;
    });
  }

  return (
    <>
      {contextHolder}
      <CardDark
        title="Pesquisar Viabilidade Endereço / Coord. Google"
        style={{ marginBottom: 10, paddingTop: 0, paddingBottom: 0 }}
        addComponentHeader={(<Button
          variant="contained"
          size="small"
          style={{ float: "right" }}
          onClick={() => {
            window.location.reload();
          }}
          color="secondary"
        >
          Atualizar página
        </Button>)}
      >
        <Row
          style={{
            alignItems: "center",
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          justify={"center"}
        >
          <Col
            xs={18}
            sm={18}
            md={8}
            lg={6}
            xl={4}
            style={{
              justifyContent: "center",
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Image src={LocationImage} preview={false}></Image>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={18}
            xl={20}
            style={{ marginTop: 0, marginBottom: 0 }}
          >

            <h1 style={{ marginTop: 0 }}>Enviar link para o cliente</h1>
            <p>
              Para pegar a localização do cliente, envie o link automaticamente
              para o cliente ou copie-o e mande pelo WhatsApp, será necessário
              informar o número de celular para o registro da localização e
              quanto tempo o link ficará ativo para o cliente cadastrar sua
              localização por motivos de segurança.
            </p>
            <Button
              variant="contained"
              onClick={() => {
                showModal();
              }}
              size="small"
            >
              Enviar link
            </Button>
          </Col>
        </Row>
      </CardDark>
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          {dataInfoNoConfirmed.length !== 0 && (
            <CardDark title="Links ativos" style={{ marginBottom: 10 }}>
              <Table columns={columnsLink} dataSource={dataInfoNoConfirmed} />
            </CardDark>
          )}

          {dataInfoConfirmed.length !== 0 && (
            <CardDark title="Localizações Cadastradas">
              <Table columns={columns} dataSource={dataInfoConfirmed} />
            </CardDark>
          )}
        </>
      )}

      <Modal
        title="Solicitar Localização do Cliente"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={[
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button
                block
                onClick={() => {
                  handleCopyText(
                    process.env.REACT_APP_BASE_URL_FRONTEND +
                    "/external/viabilidade-localizacao-cliente"
                  );
                }}
                variant="contained"
                color="success"
                size="small"
              >
                Copiar link
              </Button>
            </div>
            <div>
              <Button
                block
                onClick={handleCancelEditar}
                variant="outlined"
                style={{ marginRight: "10px" }}
                size="small"
              >
                cancel
              </Button>
              ,
              <Button
                block
                key="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                size="small"
              >
                Salvar
              </Button>
            </div>
          </div>,
        ]}
      >
        <p>
          Insira o número do cliente (com código de área) no campo abaixo e
          clique em "enviar mensagem" para que o cliente receba o link de acesso
          no WhatsApp com o número da empresa.
        </p>
        <p>
          Lembre-se de inserir a validade do link, ele será o tempo disponível
          para o cliente enviar sua posição.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth id="category-editor-form">
            <Row justify={"space-between"}>
              <Col xs={7} sm={7} md={7} lg={7} xl={7} style={separadorInput20}>
                <TextField
                  id="outlined-basic"
                  label="DDI"
                  error={valid}
                  defaultValue={"55"}
                  variant="outlined"
                  size="small"
                  {...register("ddi", {
                    required: true,
                    minLength: 2,
                    maxLength: 2,
                  })}
                ></TextField>
                {errors?.ddi?.types?.required && (
                  <span style={{ color: "red" }}>Número obrigatório</span>
                )}
                {errors?.ddi?.types?.minLength && (
                  <span style={{ color: "red" }}>Menos de 2 caracteres</span>
                )}
                {errors?.ddi?.types?.maxLength && (
                  <span style={{ color: "red" }}>Mais que 2 caracteres</span>
                )}
              </Col>
              <Col
                xs={16}
                sm={16}
                md={16}
                lg={16}
                xl={16}
                style={separadorInput20}
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Número Telefone"
                  variant="outlined"
                  size="small"
                  {...register("fone")}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = normalizeCardNumber(value);
                  }}
                ></TextField>
                {err !== undefined && (
                  <span style={{ color: "red" }}>{err}</span>
                )}
              </Col>
            </Row>
          </FormControl>
          <Row justify={"space-between"}>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <SelectComponent
                register={register}
                items={ValidadeLink}
                defaultValue={"hours"}
                label="Validade Link"
                labelRegister="unidade"
              ></SelectComponent>
            </Col>

            <Col
              xs={16}
              sm={16}
              md={16}
              lg={16}
              xl={16}
              style={separadorInput20}
            >
              <FormControl fullWidth id="category-editor-form">
                <TextField
                  id="outlined-basic"
                  label="Quantidade"
                  type={"number"}
                  InputProps={{ inputProps: { min: 1, max: 60 } }}
                  variant="outlined"
                  size="small"
                  {...register("qtd", {
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                  })}
                ></TextField>
                {errors?.qtd?.types?.required && (
                  <span style={{ color: "red" }}>Número obrigatório</span>
                )}
                {errors?.qtd?.types?.minLength && (
                  <span style={{ color: "red" }}>Menos de 1 caracteres</span>
                )}
                {errors?.qtd?.types?.maxLength && (
                  <span style={{ color: "red" }}>Mais que 2 caracteres</span>
                )}
              </FormControl>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
}

export default CadastroDeLocalizacao;

const separadorInput20 = {
  marginBottom: 20,
};
