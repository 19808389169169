import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { PostAPI } from "./../../../../../services/http/PostAPI";
import { useForm } from "react-hook-form";
import { message } from "antd";
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import LoadingImage from "./../../../../../component/loadingImage";
import CardDark from "../../../../../component/cardDark";

function AtribuirSetorMobile() {

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        criteriaMode: "all", mode: "onChange"
    });

    const [loading, setLoading] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();

    const [setores, setSetores] = useState();
    const [user, setUser] = useState();
    const [usuarioFiltrado, setUsuarioFiltrado] = useState()

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const onSubmit = (data) => {
        cadastrarUsuarioSetor(data);
        reset();
    };

    const pegarTodosSetores = () => {
        const { http } = GetAPI();

        http
            .get(`sector-getall`)
            .then((res) => {
                if (res.status) {
                    setSetores(res.data);

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosUsuariosComSetor = () => {
        const { http } = GetAPI();

        http
            .get(`sector-getallatt`)
            .then((res) => {
                if (res.status) {

                    let users = []
                    let userFiltred = []

                    for (const item of res.data) {
                        users.push(item.id_user)

                    }

                    for (const item of user) {
                        if (users.indexOf(`${item.id}`) === -1) {
                            userFiltred.push(item)
                        }
                    }

                    setUser(userFiltred)
                    setUsuarioFiltrado(userFiltred)


                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const cadastrarUsuarioSetor = (data) => {
        const { http } = PostAPI();

        http
            .post(`sector-setter/${data.usuario}`, {
                id_user: data.usuario,
                id_setor: data.setor,
            })
            .then((res) => {
                if (res.status) {
                    buscarTodosUsuariosComSetor()
                    success("Setor cadastrado com sucesso");

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data.message);
            });
    };

    useEffect(() => {
        if (setores === undefined) {
            pegarTodosSetores();
        }

        if (user === undefined) {
            buscarTodosUsuarios();
        }

        if (user !== undefined && setores !== undefined && loading === true) {
            buscarTodosUsuariosComSetor()
        }

        if (user !== undefined && setores !== undefined && usuarioFiltrado !== undefined) {
            setLoading(false);
        }

    }, [user, setores, loading, usuarioFiltrado]);


    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Atribuir Setor" style={{ marginBottom: 20 }}>
                        <Row justify={"center"}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <FormControl fullWidth style={{ marginBottom: 20 }}>
                                        <InputLabel id="usuario" size="small">
                                            Usuário
                                        </InputLabel>
                                        <Select
                                            labelId="usuario"
                                            id="usuario"
                                            label="Usuário"
                                            defaultValue={user[0] ? user[0].id : null}
                                            {...register("usuario", {
                                                required: true
                                            })}
                                            size="small"
                                        >
                                            {usuarioFiltrado.map((item, index) => {
                                                return (
                                                    < MenuItem value={item.id} key={index} >
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        {errors?.usuario?.types?.required && (
                                            <span style={{ color: "red" }}>Campo obrigatório</span>
                                        )}
                                    </FormControl>


                                    <FormControl fullWidth style={{ marginBottom: 20 }}>
                                        <InputLabel id="setor" size="small">
                                            Setor
                                        </InputLabel>
                                        <Select
                                            labelId="setor"
                                            id="setor"
                                            label="Setor"
                                            defaultValue={setores[0] ? setores[0].id : null}
                                            {...register("setor", {
                                                required: true
                                            })}
                                            size="small"
                                        >
                                            {setores.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.id} key={index}>
                                                        {item.description}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>

                                    <Button type="submit" variant="contained" size="small">
                                        Enviar
                                    </Button>
                                </form>
                            </Col>
                        </Row>
                    </CardDark>
                </>
            )}
        </>
    );
}

export default AtribuirSetorMobile;