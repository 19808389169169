import { Button } from "@mui/material";
import { Card, Table } from "antd"
import { useEffect, useState } from "react";
import { GetAPI } from "../../../services/http/GetAPI";
import { DeleteAPI } from "../../../services/http/DeleteAPI";

function CurriculosSiteTable() {

    const [loading, setLoading] = useState(true)
    const [curriculos, setCurriculos] = useState()
    const dataInfo = []

    const downloadCurriculo = async (curriculo) => {

        const { http } = GetAPI();

        console.log(curriculo)

        http
            .get(`download-curriculo/${curriculo.name.slice(0, 32)}`, {
                responseType: 'blob'
            })
            .then(async (res) => {
                if (res.status) {

                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute('download', "curriculo.pdf")
                    document.body.appendChild(link)
                    link.click();

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    }

    const deletarCurriculo = async (curriculo) => {
        const { http } = DeleteAPI();

        http
            .delete(`delete-curriculo/${curriculo.name.slice(0, 32)}`)
            .then((res) => {
                if (res.status) {

                    console.log(res.data)
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosCurriculos = async () => {
        const { http } = GetAPI();

        http
            .get(`get-all-curriculos`)
            .then((res) => {
                if (res.status) {

                    console.log(res)

                    setCurriculos(res.data[0])
                    setLoading(false)
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            width: 50,
        },
        {
            title: "Arquivo",
            dataIndex: "name",
            key: "name",
            width: 200,
        },
        {
            title: "Ações",
            key: "operation",
            width: 100,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={async () => {
                            await downloadCurriculo(e)
                        }}
                        color="success"
                        style={buttonsTable}
                    >
                        Download
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={async () => {
                            await deletarCurriculo(e)

                            await buscarTodosCurriculos()
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    useEffect(() => {

        if (curriculos === undefined) {
            buscarTodosCurriculos()
        }

    }, [loading, curriculos, dataInfo])

    if (loading === false) {

        curriculos.map((item, index) => {
            dataInfo.push({
                id: index + 1,
                name: `${item}`.slice(2, 38),
                key: index,
            });

            return null;
        });
    }

    return (
        <>
            {loading === true && (
                <>
                    Carregando...
                </>
            )}
            {loading === false && (
                <>
                    <Card title="Currículos Site">
                        <Table
                            columns={columns}
                            dataSource={dataInfo}
                            scroll={{
                                y: 240,
                            }}
                        />
                    </Card>
                </>
            )}

        </>
    );
}

export default CurriculosSiteTable;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};