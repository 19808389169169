import PrivatePages from "../../component/privatePages";
import Page403 from "../../view/internalPages/403";
import Page404 from "../../view/internalPages/404";
import AtribuirSetor from "../../view/internalPages/configuracoes/atribuirSetor";
import CadastrarPermissoes from "../../view/internalPages/configuracoes/cadastrarPermissoes";
import CadastrarSetor from "../../view/internalPages/configuracoes/cadastrarSetor";
import CadastrarUsuario from "../../view/internalPages/configuracoes/cadastrarUsuario";
import GerenciarAtribuirSetor from "../../view/internalPages/configuracoes/gerenciarAtribuirSetor";
import GerenciarCadastros from "../../view/internalPages/configuracoes/gerenciarCadastros";
import GerenciarPermissoes from "../../view/internalPages/configuracoes/gerenciarPermissoes";
import GerenciarSetor from "../../view/internalPages/configuracoes/gerenciarSetor";
import MessagesAll from "../../view/internalPages/messages/todas";
import OltConfigurar from "../../view/internalPages/olt";
import AdicionarOlt from "../../view/internalPages/olt/add";
import AutorizarOlt from "../../view/internalPages/olt/gerenciarOlt/autorizarOlt";
import Cmd from "../../view/internalPages/olt/cmd";
import GerenciarOlt from "../../view/internalPages/olt/gerenciarOlt";
import OnuAutorizadas from "../../view/internalPages/olt/gerenciarOlt/onuAutorizadas";
import OltTemplate from "../../view/internalPages/olt/template";
import OltAdicionar from "../../view/internalPages/olt/template/adicionar";
import TestePage from "../../view/internalPages/admin/testePage";
import UserInfo from "../../view/internalPages/topNavBar/userInfo";
import CadastroDeLocalizacao from "../../view/internalPages/viabilidade/cadastroDeLocalizacao";
import CtosConfirmed from "../../view/internalPages/viabilidade/cadastroDeLocalizacao/localizacaoCTOSLink";
import LocalidadesInviaveis from "../../view/internalPages/viabilidade/localidadesInviaveis";
import LocalidadesViaveis from "../../view/internalPages/viabilidade/localidadesViaveis";
import PesquisarViabilidades from "../../view/internalPages/viabilidade/pesquisarViabilidade";
import Home from "./../../view/internalPages/home";
import WhatsIsIp from "../../view/internalPages/iptools/whatsIsIp";
import Mtr from "../../view/internalPages/iptools/mtr";
import DnsLookup from "../../view/internalPages/iptools/dnsLookup";
import Ping from "../../view/internalPages/iptools/ping";
import Tracerouter from "../../view/internalPages/iptools/tracerouter";
import Whois from "../../view/internalPages/iptools/whois";
import Dashboard from "../../view/internalPages/dashboard";
import ChatSocketIo from "./../../../src/view/internalPages/chat/socketio";
import ChatVenomBot from "./../../../src/view/internalPages/chat/venom";
import ChatVenomSocket from "../../view/internalPages/chat/chat";
import DashboardTicket from "../../view/internalPages/tickets/dashboard";
import CriarTicket from "../../view/internalPages/tickets/criarTicket";
import TicketsAtivos from "../../view/internalPages/tickets/ticketsAtivos";
import TicketDescription from "../../view/internalPages/tickets/ticketsAtivos/description";
import ChatTicket from "../../view/internalPages/tickets/ticketsAtivos/chatTicket";
import DashboardWebsTelegram from "../../view/internalPages/websTelegram/dashboard";
import ConfiguracaoWebsTelegram from "../../view/internalPages/websTelegram/configuracao";
import Logs from "../../view/internalPages/admin/logs";
import BackupSistema from "../../view/internalPages/admin/backup";
import PaginaManutencao from "../../view/internalPages/manutenção";
import PainelDeControle from "../../view/internalPages/admin/painelDeControle";
import CurriculosSiteTable from "../../view/internalPages/curriculos";
import PlanilhaDeComissao from "../../view/internalPages/comissao";

const Routes = [
  {
    path: "*",
    element: <Page404></Page404>,
    breadcrumbName: "/404",
  },
  {
    path: "/",
    element: <Home></Home>,
    breadcrumbName: "/",
    exact: true,
  },
  {
    path: "/403",
    element: <Page403></Page403>,
    breadcrumbName: "/403",
    exact: true,
  },
  {
    path: "/messages-all",
    element: <MessagesAll></MessagesAll>,
    breadcrumbName: "/messages-all",
    exact: true,
  },
  {
    path: "/user-info",
    element: <UserInfo></UserInfo>,
    breadcrumbName: "/user-info",
    exact: true,
  },
  {
    path: "/setor-cadastrar",
    element: (
      <CadastrarSetor></CadastrarSetor>
    ),
    breadcrumbName: "/setor-cadastrar",
    exact: true,
  },
  {
    path: "/setor-gerenciar",
    element: (
      <GerenciarSetor></GerenciarSetor>
    ),
    breadcrumbName: "/setor-gerenciar",
    exact: true,
  },
  {
    path: "/setor-atribuir",
    element: (
      <AtribuirSetor></AtribuirSetor>
    ),
    breadcrumbName: "/setor-atribuir",
    exact: true,
  },
  {
    path: "/setor-gerenciar-atribuir",
    element: (
      <GerenciarAtribuirSetor></GerenciarAtribuirSetor>
    ),
    breadcrumbName: "/setor-gerenciar-atribuir",
    exact: true,
  },
  {
    path: "/viabilidade-pesquisar",
    element: (
      <PesquisarViabilidades></PesquisarViabilidades>
    ),
    breadcrumbName: "/viabilidade-pesquisar",
    exact: true,
  },
  {
    path: "/viabilidade-cadastro-localizacao",
    element: (
      <CadastroDeLocalizacao></CadastroDeLocalizacao>
    ),
    breadcrumbName: "/viabilidade-cadastro-localizacao",
    exact: true,
  },
  {
    path: "/viabilidade-cadastro-localizacao/viabilidade-localizacao-link",
    element: (
      <CtosConfirmed></CtosConfirmed>
    ),
    breadcrumbName:
      "/viabilidade-cadastro-localizacao/viabilidade-localizacao-link",
    exact: true,
  },
  {
    path: "/viabilidade-localidades-viaveis",
    element: (
      <LocalidadesViaveis></LocalidadesViaveis>
    ),
    breadcrumbName: "/viabilidade-localidades-viaveis",
    exact: true,
  },
  {
    path: "/viabilidade-localidades-inviaveis",
    element: (
      <LocalidadesInviaveis></LocalidadesInviaveis>
    ),
    breadcrumbName: "/viabilidade-localidades-inviaveis",
    exact: true,
  },

  {
    path: "/usuario-adicionar",
    element: (
      <CadastrarUsuario></CadastrarUsuario>
    ),
    breadcrumbName: "/usuario-adicionar",
    exact: true,
  },
  {
    path: "/usuario-gerenciar",
    element: (
      <GerenciarCadastros></GerenciarCadastros>
    ),
    breadcrumbName: "/usuario-gerenciar",
    exact: true,
  },
  {
    path: "/permission-create",
    element: (
      <CadastrarPermissoes></CadastrarPermissoes>
    ),
    breadcrumbName: "/permission-create",
    exact: true,
  },
  {
    path: "/permission-gerenciar",
    element: (
      <GerenciarPermissoes></GerenciarPermissoes>
    ),
    breadcrumbName: "/permission-gerenciar",
    exact: true,
  },
  {
    path: "/olt-gerenciar",
    element: (
      <GerenciarOlt></GerenciarOlt>
    ),
    breadcrumbName: "/olt-gerenciar",
    exact: true,
  },
  {
    path: "/olt-gerenciar/olt-autorizar",
    element: (

      <AutorizarOlt></AutorizarOlt>

    ),
    breadcrumbName: "/olt-gerenciar/olt-autorizar",
    exact: true,
  },
  {
    path: "/olt-gerenciar/onu-autorizadas",
    element: (

      <OnuAutorizadas></OnuAutorizadas>

    ),
    breadcrumbName: "/olt-gerenciar/onu-autorizadas",
    exact: true,
  },
  {
    path: "/olt-template",
    element: (

      <OltTemplate></OltTemplate>

    ),
    breadcrumbName: "/olt-template",
    exact: true,
  },
  {
    path: "/olt-template/adicionar",
    element: (
      <OltAdicionar></OltAdicionar>
    ),
    breadcrumbName: "/olt-template/adicionar",
    exact: true,
  },
  {
    path: "/olt-template/adicionar",
    element: (
      <OltAdicionar></OltAdicionar>
    ),
    breadcrumbName: "/olt-template/adicionar",
    exact: true,
  },
  {
    path: "/olt-configurar",
    element: (
      <OltConfigurar></OltConfigurar>
    ),
    breadcrumbName: "/olt-configurar",
    exact: true,
  },
  {
    path: "/olt-adicionar",
    element: (
      <AdicionarOlt></AdicionarOlt>
    ),
    breadcrumbName: "/olt-create",
    exact: true,
  },
  {
    path: "/teste-page",
    element: <TestePage></TestePage>,
    breadcrumbName: "/teste-page",
    exact: true,
  },
  {
    path: "/cmd",
    element: (
      <Cmd></Cmd>
    ),
    breadcrumbName: "/cmd",
    exact: true,
  },
  {
    path: "/whais-is-ip",
    element: (
      <WhatsIsIp></WhatsIsIp>
    ),
    breadcrumbName: "/whais-is-ip",
    exact: true,
  },
  {
    path: "/mtr",
    element: (
      <Mtr></Mtr>
    ),
    breadcrumbName: "/mtr",
    exact: true,
  },
  {
    path: "/dns-lookup",
    element: (
      <DnsLookup></DnsLookup>
    ),
    breadcrumbName: "/dns-lookup",
    exact: true,
  },
  {
    path: "/ping",
    element: (
      <Ping></Ping>
    ),
    breadcrumbName: "/ping",
    exact: true,
  },
  {
    path: "/tracerouter",
    element: (
      <Tracerouter></Tracerouter>
    ),
    breadcrumbName: "/tracerouter",
    exact: true,
  },
  {
    path: "/who-is",
    element: (
      <Whois></Whois>
    ),
    breadcrumbName: "/who-is",
    exact: true,
  },
  {
    path: "/dashboard",
    element: (
      <Dashboard></Dashboard>
    ),
    breadcrumbName: "/dashboard",
    exact: true,
  },
  {
    path: "/socketio",
    element: (
      <ChatSocketIo></ChatSocketIo>
    ),
    breadcrumbName: "/socketio",
    exact: true,
  },
  {
    path: "/venom-bot",
    element: (
      <ChatVenomBot></ChatVenomBot>
    ),
    breadcrumbName: "/venom-bot",
    exact: true,
  },
  {
    path: "/chat",
    element: (
      <ChatVenomSocket></ChatVenomSocket>
    ),
    breadcrumbName: "/chat",
    exact: true,
  },
  {
    path: "/tickets-dashboard",
    element: <DashboardTicket></DashboardTicket>,
    breadcrumbName: "/tickets-dashboard",
    exact: true,
  },
  {
    path: "/tickets-criar",
    element: <CriarTicket></CriarTicket>,
    breadcrumbName: "/tickets-criar",
    exact: true,
  },
  {
    path: "/tickets-ativos",
    element: <TicketsAtivos></TicketsAtivos>,
    breadcrumbName: "/tickets-ativos",
    exact: true,
  },
  {
    path: "/tickets-ativos/tickets-ticketDescricao",
    element: <TicketDescription></TicketDescription>,
    breadcrumbName: "/tickets-ativos/tickets-ticketDescricao",
    exact: true,
  },
  {
    path: "/tickets-ativos/tickets-chat",
    element: <ChatTicket></ChatTicket>,
    breadcrumbName: "/tickets-ativos/tickets-chat",
    exact: true,
  },
  {
    path: "/webs-telegram-configuracao/",
    element: <ConfiguracaoWebsTelegram></ConfiguracaoWebsTelegram>,
    breadcrumbName: "/webs-telegram/configuracao",
    exact: true,
  },
  {
    path: "/webs-telegram-dashboard/",
    element: <DashboardWebsTelegram></DashboardWebsTelegram>,
    breadcrumbName: "/webs-telegram/dashboard",
    exact: true,
  },
  {
    path: "/logs/",
    element: <Logs></Logs>,
    breadcrumbName: "/logs",
    exact: true,
  },
  {
    path: "/backup/",
    element: <BackupSistema></BackupSistema>,
    breadcrumbName: "/backup",
    exact: true,
  },
  {
    path: "/manutencao/",
    element: <PaginaManutencao></PaginaManutencao>,
    breadcrumbName: "/manutencao",
    exact: true,
  },
  {
    path: "/painel-de-controle/",
    element: <PainelDeControle></PainelDeControle>,
    breadcrumbName: "/painel-de-controle",
    exact: true,
  },
  {
    path: "/curriculo-site/",
    element: <CurriculosSiteTable></CurriculosSiteTable>,
    breadcrumbName: "/curriculo-site/",
    exact: true,
  },
  {
    path: "/comissao-planilha/",
    element: <PlanilhaDeComissao></PlanilhaDeComissao>,
    breadcrumbName: "/comissao-planilha/",
    exact: true,
  },
];

export default Routes;
