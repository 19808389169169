import Web from "./web";
import Mobile from "./mobile";

const itemsAtribuirSetor = [
    {
        key: 'atribuirsetorweb',
        label: `Atribuir Setor Web`,
        children: <Web></Web>,
    },
    {
        key: 'atribuirsetormobile',
        label: `Atribuir Setor Mobile`,
        children: <Mobile></Mobile>,
    },
];

export default itemsAtribuirSetor;