import { Card, Col, Image, Modal, Row, Table } from "antd";
import CardDark from "../../../../component/cardDark";
import {
  BaseFunction,
  GridSheet,
  SheetProvider,
  constructInitialCells,
  prevention,
} from "@gridsheet/react-core";
import { useEffect, useState, useRef } from "react";
import { PostAPI } from "../../../../services/http/PostAPI";
import LoadingImage from "../../../../component/loadingImage";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GetAPI } from "../../../../services/http/GetAPI";
import { notification } from "antd";
import moment from "moment";
import "moment/locale/pt-br";
import { loadState, saveState } from "../../../../services/localStorage";
import { useReactToPrint } from "react-to-print";
import { DeleteAPI } from "../../../../services/http/DeleteAPI";
import ModalTodosComissao from "./modalTodos";
import { DataGrid } from "@mui/x-data-grid";
import Logo from "./../../../../assets/logo/Logo Completo.png";
import { Excel } from "antd-table-saveas-excel";
moment.locale("pt_br");

function ComissaoTodos() {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Decrescimo",
      dataIndex: "decrescimo",
      key: "decrescimo",
    },
    {
      title: "Meta de vendas/Up",
      dataIndex: "metaVendaUp",
      key: "metaVendaUp",
    },
    {
      title: "Venda/Up realizadas",
      dataIndex: "vendaUp_realizadas",
      key: "vendaUp_realizadas",
    },
    {
      title: "Porcetagem venda/up atingida",
      dataIndex: "porc_vendaUp_ating",
      key: "porc_vendaUp_ating",
    },
    {
      title: "Receita venda/Up",
      dataIndex: "receita_venda_up",
      key: "receita_venda_up",
    },
    {
      title: "Valor Venda/Up",
      dataIndex: "valor_venda_up",
      key: "valor_venda_up",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Ações",
      key: "operation",
      render: (e) => (
        <>
          <Button
            variant="contained"
            onClick={() => {
              showModalEditar(e);
            }}
            color="success"
            size="small"
            style={{ margin: 5 }}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              showModalExcluir(e);
            }}
            size="small"
            color="error"
            style={{ margin: 5 }}
          >
            Deletar
          </Button>
        </>
      ),
    },
  ];

  const columnsImprimir = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Meta de vendas/Up",
      dataIndex: "metaVendaUp",
      key: "metaVendaUp",
    },
    {
      title: "Venda/Up realizadas",
      dataIndex: "vendaUp_realizadas",
      key: "vendaUp_realizadas",
    },
    {
      title: "Porcetagem venda/up atingida",
      dataIndex: "porc_vendaUp_ating",
      key: "porc_vendaUp_ating",
    },
    {
      title: "Receita venda/Up",
      dataIndex: "receita_venda_up",
      key: "receita_venda_up",
    },
    {
      title: "Valor Venda/Up",
      dataIndex: "valor_venda_up",
      key: "valor_venda_up",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const [api, contextHolder] = notification.useNotification();
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(true);
  const [ano, setAno] = useState(moment().format("YYYY"));
  const [mes, setMes] = useState(
    moment().format("MMMM")[0].toLocaleUpperCase() +
      moment().format("MMMM").substring(1)
  );
  const [allFunc, setAllFunc] = useState();
  const [todos, setTodos] = useState();
  const [data, setData] = useState([]);
  const [paginacao, setPaginacao] = useState(true);
  const componentRef = useRef();

  const [isModalOpenExcluir, setIsModalOpenExcluir] = useState(false);
  const [idModalExcluir, setIdModalExcluir] = useState();
  const [isModalOpenEditar, setIsModalOpenEditar] = useState(false);
  const [idModalEditar, setIdModalEditar] = useState();
  const [funcEditar, setFuncEditar] = useState();
  const [tipos, setTipos] = useState();
  const [nomes, setNomes] = useState();
  const [allConfigs, setAllConfigs] = useState();

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };

  const showModalEditar = async (e) => {
    for (const item of allFunc) {
      if (Number(e.id) === Number(item.id)) {
        console.log(item);
        setFuncEditar(item);
      }
    }
    setIdModalEditar(e.id);
    setIsModalOpenEditar(true);
  };

  const handleOkExcluir = () => {
    deletarFuncionarioComissao();
    setIsModalOpenExcluir(false);
  };

  const handleCancelExcluir = () => {
    setIsModalOpenExcluir(false);
  };

  const showModalExcluir = (e) => {
    setIdModalExcluir(e.id);
    setIsModalOpenExcluir(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getAllNomes = () => {
    const { http } = GetAPI();

    http
      .get(`comissao-all-funcionarios`)
      .then((res) => {
        if (res.status) {
          setNomes(res.data);
        } else {
          alertMessage(
            "error",
            "Excluir funcionario",
            "Não foi possivel excluido com sucesso"
          );
        }
      })
      .catch((erro) => {
        alertMessage(
          "error",
          "Excluir funcionario",
          "Não foi possivel excluido com sucesso"
        );
      });
  };

  const deletarFuncionarioComissao = () => {
    const { http } = DeleteAPI();

    http
      .delete(`comissao-delete-funcionario/${idModalExcluir}`)
      .then((res) => {
        if (res.status) {
          getAllFuncionarios(mes, ano);
          alertMessage(
            "success",
            "Excluir funcionario",
            "Funcionario excluido com sucesso"
          );
        } else {
          alertMessage(
            "error",
            "Excluir funcionario",
            "Não foi possivel excluido com sucesso"
          );
        }
      })
      .catch((erro) => {
        alertMessage(
          "error",
          "Excluir funcionario",
          "Não foi possivel excluido com sucesso"
        );
      });
  };

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const onSubmit = (data) => {
    cadastrarFuncionarioComissao(data);
  };

  const cadastrarFuncionarioComissao = (data) => {
    const { http } = PostAPI();

    let tipoFuncionario = null;

    let conf_selecionada = null;

    for (const item of allConfigs) {
      if (Number(item.id) === Number(data.config_porc)) {
        conf_selecionada = item;
      }
    }

    let resposta = JSON.parse(conf_selecionada.config);

    let arraya1vendas = [];
    let arraya2vendas = [];
    let arraya1upgrade = [];
    let arraya2upgrade = [];

    resposta.value.vendas[0].map((item) => {
      arraya1vendas.push(item.value);
    });
    resposta.value.vendas[1].map((item) => {
      arraya2vendas.push(item.value);
    });

    resposta.value.upgrade[0].map((item) => {
      arraya1upgrade.push(item.value);
    });
    resposta.value.upgrade[1].map((item) => {
      arraya2upgrade.push(item.value);
    });

    let a1 = arraya1vendas;
    let a2 = arraya2vendas;
    let b1 = arraya1upgrade;
    let b2 = arraya2upgrade;

    for (const item of tipos) {
      if (Number(item.id) === Number(data.tipo_funcionario)) {
        tipoFuncionario = item;
      }
    }

    let labels = [];
    let labelsB = [];
    let porcV = null;
    let porcU = null;

    let valuesVendas = a2.map((item) => {
      return item.slice(0, -1);
    });
    let valuesUpgrade = b2.map((item) => {
      return item.slice(0, -1);
    });

    let receitaVenda = data.venda_realizada * tipoFuncionario.valor_venda;
    let receitaUp = data.upgrade_realizado * tipoFuncionario.valor_upgrade;
    for (let index = 0; index < a1.length; index++) {
      let temp = a1[index].split("a");

      if (temp.length > 1) {
        labels.push([temp[0].trim().slice(0, -1), temp[1].trim().slice(0, -1)]);
      } else {
        labels.push([temp[0].trim().slice(0, -1)]);
      }
    }
    for (let index = 0; index < b1.length; index++) {
      let temp = b1[index].split("a");

      if (temp.length > 1) {
        labelsB.push([
          temp[0].trim().slice(0, -1),
          temp[1].trim().slice(0, -1),
        ]);
      } else {
        labelsB.push([temp[0].trim().slice(0, -1)]);
      }
    }

    let coberturaV = null;
    let coberturaU = null;
    let valRecV = null;
    let valRecU = null;
    let valRecVFilter = null;
    let valRecUFilter = null;
    let decrescimo = null;
    let total = null;

    if (Number(tipoFuncionario.meta_upgrade) !== 0) {
      coberturaV =
        Number(data.venda_realizada) / Number(tipoFuncionario.meta_de_vendas) >
        0
          ? (Number(data.venda_realizada) /
              Number(tipoFuncionario.meta_de_vendas)) *
            100
          : 0;
      coberturaU =
        Number(data.upgrade_realizado) /
          Number(tipoFuncionario.meta_de_upgrade) >
        0
          ? (Number(data.upgrade_realizado) /
              Number(tipoFuncionario.meta_de_upgrade)) *
            100
          : 0;

      valRecV = labels.map((elem, index) => {
        let result = null;

        if (coberturaV >= Number(labels[0][0])) {
          if (elem.length > 1) {
            if (coberturaV >= Number(elem[0]) && coberturaV < Number(elem[1])) {
              result =
                coberturaU >= 100
                  ? receitaVenda * (valuesVendas[index] / 100)
                  : receitaVenda *
                    (valuesVendas[index !== 0 ? index - 1 : 0] / 100);

              porcV =
                coberturaU >= 100
                  ? valuesVendas[index] / 100
                  : valuesVendas[index !== 0 ? index - 1 : 0] / 100;
            }
          } else {
            if (coberturaV >= Number(elem[0])) {
              result =
                coberturaU >= 100
                  ? receitaVenda * (valuesVendas[index] / 100)
                  : receitaVenda *
                    (valuesVendas[index !== 0 ? index - 1 : 0] / 100);
              porcV =
                coberturaU >= 100
                  ? valuesVendas[index] / 100
                  : valuesVendas[index !== 0 ? index - 1 : 0] / 100;
            }
          }
        } else {
          result = 0;
        }

        if (result !== null) {
          return result;
        }
      });
      valRecU = labels.map((elem, index) => {
        let result = null;

        if (coberturaU >= Number(labelsB[0][0])) {
          if (elem.length > 1) {
            if (coberturaU >= Number(elem[0]) && coberturaU < Number(elem[1])) {
              result =
                coberturaV >= 100
                  ? receitaUp * (valuesUpgrade[index] / 100)
                  : receitaUp *
                    (valuesUpgrade[index !== 0 ? index - 1 : 0] / 100);
              porcU =
                coberturaV >= 100
                  ? valuesUpgrade[index] / 100
                  : valuesUpgrade[index !== 0 ? index - 1 : 0] / 100;
            }
          } else {
            if (coberturaU >= Number(elem[0])) {
              result =
                coberturaV >= 100
                  ? receitaUp * (valuesUpgrade[index] / 100)
                  : receitaUp *
                    (valuesUpgrade[index !== 0 ? index - 1 : 0] / 100);
              porcU =
                coberturaV >= 100
                  ? valuesUpgrade[index] / 100
                  : valuesUpgrade[index !== 0 ? index - 1 : 0] / 100;
            }
          }
        } else {
          result = 0;
        }

        if (result !== null) {
          return result;
        }
      });

      valRecVFilter = valRecV.filter((item) => item !== undefined);
      valRecUFilter = valRecU.filter((item) => item !== undefined);
      decrescimo = receitaVenda * (Number(data.decrescimo) / 100);
      total =
        coberturaV >= Number(labels[0][0]) ||
        coberturaU >= Number(labelsB[0][0])
          ? Number(valRecVFilter[0]) +
            Number(valRecUFilter[0]) -
            Number(decrescimo)
          : 0;
    } else {
      coberturaV =
        Number(data.venda_realizada) / Number(tipoFuncionario.meta_venda) > 0
          ? (Number(data.venda_realizada) /
              Number(tipoFuncionario.meta_venda)) *
            100
          : 0;
      coberturaU = 0;
      valRecV = labels.map((elem, index) => {
        let result = null;

        if (coberturaV >= Number(labels[0][0])) {
          if (elem.length > 1) {
            if (coberturaV >= Number(elem[0]) && coberturaV < Number(elem[1])) {
              result = receitaVenda * (valuesVendas[index] / 100);
              porcV = valuesVendas[index] / 100;
            }
          } else {
            if (coberturaV >= Number(elem[0])) {
              result = receitaVenda * (valuesVendas[index] / 100);
              porcV = valuesVendas[index] / 100;
            }
          }
        } else {
          result = 0;
        }

        if (result !== null) {
          return result;
        }
      });
      valRecU = 0;
      porcU = 0;
      valRecVFilter = valRecV.filter((item) => item !== undefined);
      valRecUFilter = 0;
      decrescimo = receitaVenda * (Number(data.decrescimo) / 100);
      total =
        coberturaV >= Number(labels[0][0])
          ? Number(valRecVFilter[0]) + valRecUFilter - Number(decrescimo)
          : 0;
    }

    http
      .post(`comissao-set-funcionario`, {
        nome: data.nome,
        mes: mes,
        ano: ano,
        id_config: conf_selecionada.id,
        id_tipo_func_comissao: tipoFuncionario.id,
        decrescimo: data.decrescimo,
        venda_realizada: data.venda_realizada,
        upgrade_realizada: data.upgrade_realizado,
        porc_venda_ating: porcV,
        porc_upgrade_ating: porcU,
        receita_venda:
          data.venda_realizada * Number(tipoFuncionario.valor_venda),
        receita_upgrade:
          data.upgrade_realizado * Number(tipoFuncionario.valor_upgrade),
        total: total,
      })
      .then((res) => {
        alertMessage(
          "success",
          "Cadastrar comissão",
          "Funcionario cadastrado com sucesso"
        );
        getAllFuncionarios(mes, ano);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const getAllFuncionarios = (dataMes, dataAno) => {
    const { http } = GetAPI();

    http
      .get(`comissao-getall-funcionario/${dataMes}/${dataAno}`)
      .then((res) => {
        let func = [];

        if (res.data.length > 0) {
          for (const item of res.data) {
            let tempF = {};
            tempF.id = item.id;
            tempF.nome = item.nome;
            tempF.decrescimo = item.decrescimo;
            tempF.metaVendaUp =
              item.meta_de_vendas + " / " + item.meta_de_upgrade;
            tempF.vendaUp_realizadas =
              item.venda_realizada + " / " + item.upgrade_realizada;
            tempF.porc_vendaUp_ating =
              item.porc_venda_ating * 100 +
              "%" +
              " / " +
              item.porc_upgrade_ating * 100 +
              "%";
            tempF.receita_venda_up =
              item.receita_venda + " / " + item.receita_upgrade;
            tempF.valor_venda_up =
              item.valor_venda + " / " + item.valor_upgrade;
            tempF.total = "R$ " + item.total;
            func.push(tempF);
          }
        }
        console.log(res.data);
        setAllFunc(res.data);
        setData(func);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const getAllTipos = () => {
    const { http } = GetAPI();

    http
      .get(`comissao-all-tipo-funcionario`)
      .then((res) => {
        if (res.status) {
          setTipos(res.data);
        } else {
          alertMessage(
            "error",
            "Excluir funcionario",
            "Não foi possivel excluido com sucesso"
          );
        }
      })
      .catch((erro) => {
        alertMessage(
          "error",
          "Excluir funcionario",
          "Não foi possivel excluido com sucesso"
        );
      });
  };

  const buscarConfig = () => {
    const { http } = PostAPI();

    http
      .post(`comissao-get-config`, { config: "comissao_config" })
      .then((res) => {
        let resposta = null;

        if (res.data[0]?.config) {
          setConfig(JSON.parse(res.data[0].config));

          setAllConfigs(res.data);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  useEffect(() => {
    if (config === undefined) {
      buscarConfig();
    }

    if (allFunc === undefined && config !== undefined) {
      getAllFuncionarios(mes, ano);
    }

    if (tipos === undefined) {
      getAllTipos();
    }

    if (nomes === undefined) {
      getAllNomes();
    }

    if (
      config !== undefined &&
      allFunc !== undefined &&
      nomes !== undefined &&
      tipos != undefined
    ) {
      setLoading(false);
    }
  }, [
    loading,
    config,
    allFunc,
    todos,
    idModalEditar,
    isModalOpenEditar,
    funcEditar,
    paginacao,
  ]);

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage></LoadingImage>}
      {loading === false && (
        <>
          <Card>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col span={12}>
                <h4 style={{ margin: 0 }}>Configuração Mês/Ano</h4>
              </Col>
              <Col span={12}>
                <Row gutter={12} justify={"flex-end"}>
                  <Col span={12}>
                    <FormControl fullWidth>
                      <InputLabel id="mes" size="small">
                        Mês
                      </InputLabel>
                      <Select
                        labelId="mes"
                        id="mes"
                        label="Mes"
                        defaultValue={
                          moment().format("MMMM")[0].toLocaleUpperCase() +
                          moment().format("MMMM").substring(1)
                        }
                        onChange={(value) => {
                          setMes(value.target.value);
                          getAllFuncionarios(value.target.value, ano);
                        }}
                        size="small"
                      >
                        {[
                          "Janeiro",
                          "Fevereiro",
                          "Março",
                          "Abril",
                          "Maio",
                          "Junho",
                          "Julho",
                          "Agosto",
                          "Setembro",
                          "Outubro",
                          "Novembro",
                          "Dezembro",
                        ].map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="mes" size="small">
                        Ano
                      </InputLabel>
                      <Select
                        labelId="ano"
                        id="ano"
                        label="Ano"
                        defaultValue={moment().format("YYYY")}
                        onChange={(value) => {
                          setAno(value.target.value);
                          getAllFuncionarios(mes, value.target.value);
                        }}
                        size="small"
                      >
                        {[
                          moment().format("YYYY"),
                          moment().subtract(1, "years").format("YYYY"),
                          moment().subtract(2, "years").format("YYYY"),
                          moment().subtract(3, "years").format("YYYY"),
                          moment().subtract(4, "years").format("YYYY"),
                          moment().subtract(5, "years").format("YYYY"),
                        ].map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          <Card
            title={"Cadastrar Venda".toUpperCase()}
            style={{ marginTop: 20 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={10}>
                <Col span={8}>
                  <FormControl fullWidth style={{ marginBottom: 20 }}>
                    <InputLabel id="nome_func" size="small">
                      Nome Funcionario
                    </InputLabel>
                    <Select
                      labelId="nome_func"
                      id="nome_func"
                      label="Nome Funcionario"
                      error={errors.nome !== undefined}
                      defaultValue={
                        nomes[0]?.nome !== undefined
                          ? nomes[0]?.nome
                          : undefined
                      }
                      {...register("nome", {
                        required: true,
                      })}
                      size="small"
                    >
                      {nomes.map((item, index) => {
                        return (
                          <MenuItem value={item.nome} key={index}>
                            {item.nome}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.nome?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo "NOME FUNCIONARIO" é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col span={4}>
                  <FormControl fullWidth style={{ marginBottom: 20 }}>
                    <InputLabel id="nome_func" size="small">
                      Tipo Funcionario
                    </InputLabel>
                    <Select
                      labelId="nome_func"
                      id="nome_func"
                      label="Nome Funcionario"
                      error={errors.tipo_funcionario !== undefined}
                      defaultValue={
                        tipos[0]?.id !== undefined ? tipos[0]?.id : undefined
                      }
                      {...register("tipo_funcionario", {
                        required: true,
                      })}
                      size="small"
                    >
                      {tipos.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={index}>
                            {item.nome}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.tipo_funcionario?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo "TIPO FUNCIONARIO" é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl fullWidth style={{ marginBottom: 20 }}>
                    <InputLabel id="config_porc" size="small">
                      Configuração %
                    </InputLabel>
                    <Select
                      labelId="config_porc"
                      id="config_porc"
                      label="Configuração %"
                      error={errors.config_porc !== undefined}
                      defaultValue={
                        allConfigs[0]?.id !== undefined
                          ? allConfigs[0]?.id
                          : undefined
                      }
                      {...register("config_porc", {
                        required: true,
                      })}
                      size="small"
                    >
                      {allConfigs.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={index}>
                            {item.nome_config}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.config_porc?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo config % é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl
                    fullWidth
                    id="category-editor-form"
                    style={{ marginBottom: 20 }}
                  >
                    <TextField
                      label="Decrescimo %"
                      id="outlined-basic"
                      variant="outlined"
                      defaultValue={0}
                      size="small"
                      error={errors.decrescimo !== undefined}
                      {...register("decrescimo", {
                        required: true,
                      })}
                    />
                    {errors?.decrescimo?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo "DECRESCIMO" é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl
                    fullWidth
                    id="category-editor-form"
                    style={{ marginBottom: 20 }}
                  >
                    <TextField
                      label="Venda Realizada"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      error={errors.venda_realizada !== undefined}
                      {...register("venda_realizada", {
                        required: true,
                      })}
                    />
                    {errors?.venda_realizada?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo "VENDA REALIZADA" é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Col>
                <Col span={3}>
                  <FormControl
                    fullWidth
                    id="category-editor-form"
                    style={{ marginBottom: 20 }}
                  >
                    <TextField
                      label="Upgrade Realizada"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      error={errors.upgrade_realizado !== undefined}
                      {...register("upgrade_realizado", {
                        required: true,
                      })}
                    />
                    {errors?.upgrade_realizado?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo "UPGRADE REALIZADO" é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Button type="submit" variant="contained" size="small">
                  Gravar Informações
                </Button>
              </Row>
            </form>
          </Card>
          <Card
            style={{ marginTop: 20 }}
            title={"Cadastrar Venda configuração".toUpperCase()}
          >
            <Table columns={columns} dataSource={data} />
          </Card>
          <Card
            style={{ marginTop: 20 }}
            title={"Cadastrar Venda imprimir".toUpperCase()}
            extra={
              <>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handlePrint}
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  Imprimir e pdf
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setPaginacao(!paginacao);
                  }}
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  Esconder paginação
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={handleClick}
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  Excel
                </Button>
              </>
            }
          >
            <div ref={componentRef} style={{ padding: 10 }}>
              <Row style={{ marginBottom: 10 }}>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Image src={Logo} preview={false} width={200}></Image>
                </Col>
                <Col span={12}>
                  <h3 style={{ marginTop: 0, paddingTop: 0 }}>
                    PLANILHA DE COMISSÃO DE VENDAS
                  </h3>
                  <p>
                    Mês/Ano referente:{" "}
                    <strong>
                      {mes} / {ano}
                    </strong>
                  </p>
                  <p>
                    Data impressão:{" "}
                    <strong>{moment().format("DD/MM/YYYY")}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    columns={columnsImprimir}
                    dataSource={data}
                    pagination={paginacao}
                  />
                </Col>
              </Row>
            </div>
          </Card>

          <Modal
            title="Excluir usuário"
            open={isModalOpenExcluir}
            onOk={handleOkExcluir}
            onCancel={handleCancelExcluir}
          >
            <p>
              Realmente deseja excluir o registro:{" "}
              <strong style={{ color: "red" }}>{idModalExcluir}</strong>
            </p>
          </Modal>
          <ModalTodosComissao
            isModalOpenEditar={isModalOpenEditar}
            idModalEditar={idModalEditar}
            tipos={tipos}
            setIsModalOpenEditar={setIsModalOpenEditar}
            mes={mes}
            ano={ano}
            allConfigs={allConfigs}
            getAllFuncionarios={getAllFuncionarios}
            alertMessage={alertMessage}
            funcEditar={funcEditar}
          ></ModalTodosComissao>
        </>
      )}
    </>
  );
}

export default ComissaoTodos;
