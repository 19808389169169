import { Card } from "antd";
import React from "react";

export function Events({ events }) {
  return (
    <>
      <Card title="RESPOSTA SOCKET IO" style={{ marginTop: 20 }}>
        {events.length > 0 && (
          <ul>
            {events.map((event, index) => (
              <li
                key={index}
                style={{
                  fontFamily: "Roboto,sans-serif",
                  listStyleType: "none",
                }}
              >
                {event}
              </li>
            ))}
          </ul>
        )}
      </Card>
    </>
  );
}
