import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card, Col, Image, Row, Statistic } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import CountUp from "react-countup";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import video from "./../../../../assets/video/money-analysis-5711529-4773515.gif"

function GraficosComissao() {

    const [ano, setAno] = useState(moment().format("YYYY"))
    const [mes, setMes] = useState(moment().format("MMMM")[0].toLocaleUpperCase() + moment().format("MMMM").substring(1))
    const [func, setFunc] = useState()
    const [funcAnterior, setFuncAnterior] = useState()
    const [infos, setInfos] = useState()
    const [infosAnterior, setInfosAnterior] = useState()
    const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const [loading, setLoading] = useState(true)

    const formatter = (value) => <CountUp end={value} separator="." />

    const getAllFuncionarios = (dataMes, dataAno) => {

        const { http } = GetAPI();

        http
            .get(`comissao-getall-funcionario/${dataMes}/${dataAno}`)
            .then((res) => {

                let functionarios = []
                let vendas = 0
                let upgrade = 0
                let totalVendasUpgrade = 0
                let soma_metas_vendas = 0
                let soma_meta_de_upgrade = 0
                let receita_venda = 0
                let receita_upgrade = 0

                if (res.data.length > 0) {
                    for (const item of res.data) {
                        vendas = vendas + Number(item.venda_realizada)
                        upgrade = upgrade + Number(item.upgrade_realizada)
                        soma_metas_vendas = soma_metas_vendas + Number(item.meta_de_vendas)
                        soma_meta_de_upgrade = soma_meta_de_upgrade + Number(item.meta_de_upgrade)

                        receita_venda = receita_venda + Number(item.receita_venda)
                        receita_upgrade = receita_upgrade + Number(item.receita_upgrade)
                        totalVendasUpgrade = totalVendasUpgrade + Number(item.total)
                        functionarios.push(item)
                    }
                }
                setInfos({ upgrade: upgrade, vendas: vendas, total: totalVendasUpgrade, soma_metas_vendas: soma_metas_vendas, soma_meta_de_upgrade: soma_meta_de_upgrade, receita_venda: receita_venda, receita_upgrade: receita_upgrade })
                setFunc(functionarios)

                for (const [index, item] of meses.entries()) {

                    if (item === dataMes) {

                        if (index === 0) {
                            mesAnteriorRequest(meses[11], dataAno - 1, { upgrade: upgrade, vendas: vendas, total: totalVendasUpgrade, soma_metas_vendas: soma_metas_vendas, soma_meta_de_upgrade: soma_meta_de_upgrade, receita_venda: receita_venda, receita_upgrade: receita_upgrade })
                        } else {
                            mesAnteriorRequest(meses[index - 1], dataAno, { upgrade: upgrade, vendas: vendas, total: totalVendasUpgrade, soma_metas_vendas: soma_metas_vendas, soma_meta_de_upgrade: soma_meta_de_upgrade, receita_venda: receita_venda, receita_upgrade: receita_upgrade })
                        }

                    }
                }
            })
    }

    const mesAnteriorRequest = (dataMes, dataAno, mesAtual) => {

        const { http } = GetAPI();

        http
            .get(`comissao-getall-funcionario/${dataMes}/${dataAno}`)
            .then((res) => {

                let functionarios = []
                let vendas = 0
                let upgrade = 0
                let totalVendasUpgrade = 0
                let soma_metas_vendas = 0
                let soma_meta_de_upgrade = 0
                let receita_venda = 0
                let receita_upgrade = 0

                if (res.data.length > 0) {
                    for (const item of res.data) {

                        vendas = vendas + Number(item.venda_realizada)
                        upgrade = upgrade + Number(item.upgrade_realizada)
                        soma_metas_vendas = soma_metas_vendas + Number(item.meta_de_vendas)
                        soma_meta_de_upgrade = soma_meta_de_upgrade + Number(item.meta_de_upgrade)

                        receita_venda = receita_venda + Number(item.receita_venda)
                        receita_upgrade = receita_upgrade + Number(item.receita_upgrade)
                        totalVendasUpgrade = totalVendasUpgrade + Number(item.total)
                        functionarios.push(item)
                    }
                    //upgrade, vendas, total, soma_metas_vendas, soma_meta_de_upgrade, receita_venda, receita_upgrade

                    soma_metas_vendas = Number(mesAtual.soma_metas_vendas) >= Number(soma_metas_vendas) ? ["positivo", (Number(mesAtual.soma_metas_vendas) / Number(soma_metas_vendas) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.soma_metas_vendas) / Number(soma_metas_vendas) * 100).toFixed(2)]
                    soma_meta_de_upgrade = Number(mesAtual.soma_meta_de_upgrade) >= Number(soma_meta_de_upgrade) ? ["positivo", (Number(mesAtual.soma_meta_de_upgrade) / Number(soma_meta_de_upgrade) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.soma_meta_de_upgrade) / Number(soma_meta_de_upgrade) * 100).toFixed(2)]
                    totalVendasUpgrade = Number(mesAtual.total) >= Number(totalVendasUpgrade) ? ["positivo", (Number(mesAtual.total) / Number(totalVendasUpgrade) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.total) / Number(totalVendasUpgrade) * 100).toFixed(2)]
                    vendas = Number(mesAtual.vendas) >= Number(vendas) ? ["positivo", (Number(mesAtual.vendas) / Number(vendas) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.vendas) / Number(vendas) * 100).toFixed(2)]
                    upgrade = Number(mesAtual.upgrade) >= Number(upgrade) ? ["positivo", (Number(mesAtual.upgrade) / Number(upgrade) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.upgrade) / Number(upgrade) * 100).toFixed(2)]
                    receita_venda = Number(mesAtual.receita_venda) >= Number(receita_venda) ? ["positivo", (Number(mesAtual.receita_venda) / Number(receita_venda) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.receita_venda) / Number(receita_venda) * 100).toFixed(2)]
                    receita_upgrade = Number(mesAtual.receita_upgrade) >= Number(receita_upgrade) ? ["positivo", (Number(mesAtual.receita_upgrade) / Number(receita_upgrade) * 100).toFixed(2)] : ["negativo", (Number(mesAtual.receita_upgrade) / Number(receita_upgrade) * 100).toFixed(2)]

                    setInfosAnterior({ upgrade: upgrade, vendas: vendas, total: totalVendasUpgrade, soma_metas_vendas: soma_metas_vendas, soma_meta_de_upgrade: soma_meta_de_upgrade, receita_venda: receita_venda, receita_upgrade: receita_upgrade })
                } else {
                    setInfosAnterior(undefined)
                }

                setFuncAnterior(functionarios)
            })

    }

    useEffect(() => {

        if (func === undefined) {
            getAllFuncionarios(mes, ano)
        }

        if (func !== undefined && funcAnterior !== undefined) {
            setLoading(false)
        }

    }, [mes, ano, loading, infos, func, funcAnterior, infosAnterior])

    return (
        <>
            {loading === true && (<h1>Carregando</h1>)}
            {loading === false && (<>
                <Card>
                    <Row justify="end" gutter={10}>
                        <Col span={12} style={{ textAlign: "start", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <h3 style={{ margin: 0, padding: 0 }}>{"Configurações gráficos".toLocaleUpperCase()}</h3>
                        </Col>
                        <Col span={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FormControl fullWidth >
                                <InputLabel id="mes" size="small">
                                    Ano
                                </InputLabel>
                                <Select
                                    labelId="ano"
                                    id="ano"
                                    label="Ano"
                                    defaultValue={moment().format("YYYY")}
                                    onChange={(value) => {
                                        setAno(value.target.value)
                                        getAllFuncionarios(mes, value.target.value)
                                    }}
                                    size="small"
                                >
                                    {[
                                        moment().format("YYYY"),
                                        moment().subtract(1, "years").format("YYYY"),
                                        moment().subtract(2, "years").format("YYYY"),
                                        moment().subtract(3, "years").format("YYYY"),
                                        moment().subtract(4, "years").format("YYYY"),
                                        moment().subtract(5, "years").format("YYYY"),
                                    ].map((item, index) => {
                                        return < MenuItem value={item} key={index} >
                                            {item}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Col>
                        <Col span={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FormControl fullWidth >
                                <InputLabel id="mes" size="small">
                                    Mês
                                </InputLabel>
                                <Select
                                    labelId="mes"
                                    id="mes"
                                    label="Mes"
                                    defaultValue={moment().format("MMMM")[0].toLocaleUpperCase() + moment().format("MMMM").substring(1)}
                                    onChange={(value) => {
                                        setMes(value.target.value)
                                        getAllFuncionarios(value.target.value, ano)
                                    }}
                                    size="small"
                                >
                                    {["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"].map((item, index) => {
                                        return < MenuItem value={item} key={index} >
                                            {item}
                                        </MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                </Card>

                <Row gutter={12} style={{ marginTop: 20 }}>
                    <Col span={6} >
                        <Image src={video} style={{ borderRadius: 10 }} preview={false} ></Image>
                    </Col>
                    <Col span={18}>
                        <Row gutter={16} >
                            <Col span={6} >
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Soma metas vendas"
                                        value={infos.soma_metas_vendas}
                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>
                            <Col span={6} >
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Soma metas upgrade"
                                        value={infos.soma_meta_de_upgrade}
                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>


                            {/* <Col span={6}>
                        <Card bordered={false} style={{ height: "100%" }}>
                            <Statistic
                                title="Todas os upgrades realizados"
                                prefix={"R$"}
                                value={infos.total}

                                formatter={formatter}
                            />
                        </Card>
                    </Col> */}

                            <Col span={12}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Todas os upgrades realizados"
                                        prefix={"R$"}
                                        value={infos.total}

                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginTop: 20 }}>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Todas as vendas realizadas"
                                        value={infos.vendas}
                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Todas os upgrades realizados"
                                        value={infos.upgrade}
                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Receita vendas"
                                        value={infos.receita_venda}
                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Receita Upgrade"
                                        value={infos.receita_upgrade}
                                        formatter={formatter}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>


                {infosAnterior !== undefined && (
                    <>
                        <Card style={{ marginTop: 20 }}>
                            <Row justify={"end"}>
                                <Col span={24} style={{ textAlign: "start", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <h3 style={{ margin: 0, padding: 0 }}>{"Comparativo com mês anterior %".toLocaleUpperCase()}</h3>
                                </Col>
                            </Row>
                        </Card>



                        <Row gutter={16} style={{ marginTop: 20 }}>
                            <Col span={6} >
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Soma metas vendas"
                                        value={infosAnterior.soma_metas_vendas[1]}
                                        formatter={formatter}
                                        suffix="%"
                                        prefix={infosAnterior.soma_metas_vendas[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                        valueStyle={{
                                            color: infosAnterior.soma_metas_vendas[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                    />
                                </Card>
                            </Col>
                            <Col span={6} >
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Soma metas upgrade"
                                        value={infosAnterior.soma_meta_de_upgrade[1]}
                                        formatter={formatter}
                                        suffix="%"
                                        prefix={infosAnterior.soma_meta_de_upgrade[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                        valueStyle={{
                                            color: infosAnterior.soma_meta_de_upgrade[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                    />
                                </Card>
                            </Col>

                            <Col span={12}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Total a pagar de Comiissão de vendas"
                                        suffix="%"
                                        prefix={infosAnterior.total[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                        value={infosAnterior.total[1]}
                                        formatter={formatter}
                                        valueStyle={{
                                            color: infosAnterior.total[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                    />
                                </Card>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginTop: 20 }}>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Todas as vendas realizadas"
                                        suffix="%"
                                        prefix={infosAnterior.vendas[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                        value={infosAnterior.vendas[1]}
                                        formatter={formatter}
                                        valueStyle={{
                                            color: infosAnterior.vendas[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                    />
                                </Card>
                            </Col>
                            {/* <ArrowUpOutlined />  <ArrowDownOutlined /> */}
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Todas os upgrades realizados"
                                        suffix="%"
                                        prefix={infosAnterior.upgrade[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                        value={infosAnterior.upgrade[1]}
                                        formatter={formatter}
                                        valueStyle={{
                                            color: infosAnterior.upgrade[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Receita vendas"
                                        suffix="%"
                                        value={infosAnterior.receita_venda[1]}
                                        formatter={formatter}
                                        valueStyle={{
                                            color: infosAnterior.receita_venda[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                        prefix={infosAnterior.receita_venda[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card bordered={false} style={{ height: "100%" }}>
                                    <Statistic
                                        title="Receita Upgrade"
                                        suffix="%"
                                        value={infosAnterior.receita_upgrade[1]}
                                        formatter={formatter}
                                        valueStyle={{
                                            color: infosAnterior.receita_upgrade[0] === "negativo" ? "#cf1322" : "#3f8600",
                                        }}
                                        prefix={infosAnterior.receita_upgrade[0] === "negativo" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                    />
                                </Card>
                            </Col>
                        </Row>


                    </>
                )}

            </>)
            }
        </>
    );
}

export default GraficosComissao;