import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { loadState } from "../../services/localStorage";

function PrivatePages({ children, perm }) {
  let permissions = loadState("permissions");

  return permissions.permission_description.includes(perm) ||
    permissions.permissions_setor_description.includes(perm) ? (
    children
  ) : (
    <Navigate to="/403" />
  );
}

export default PrivatePages;
