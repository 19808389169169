import { TextField } from "@mui/material";
import { Card, Col, Row } from "antd";
import { useState } from "react";

function ExperienciaProfissional({ getValue }) {

    const [empresa, setEmpresa] = useState()
    const [cargo, setCargo] = useState()
    const [descricao, setDescricao] = useState()

    return (
        <Row>
            <Col span={24}>
                <Card>
                    <Row gutter={10}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                            <TextField
                                fullWidth
                                label="Empresa"
                                id="outlined-size-small"
                                size="small"
                                style={{ marginBottom: 20 }}
                                onChange={(e) => {
                                    setEmpresa(e.target.value)
                                    getValue([e.target.value, cargo, descricao])

                                    console.log([e.target.value, cargo, descricao])
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                            <TextField
                                fullWidth
                                label="Cargo"
                                id="outlined-size-small"
                                size="small"
                                style={{ marginBottom: 20 }}
                                onChange={(e) => {
                                    setCargo(e.target.value);
                                    getValue([empresa, e.target.value, descricao])

                                    console.log([empresa, e.target.value, descricao])
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TextField
                                fullWidth
                                label="Descreva suas atribuições dentro da empresa, conquistas alcançadas, suas metas"
                                id="outlined-size-small"
                                size="small"
                                multiline
                                rows={4}
                                style={{ marginBottom: 20 }}
                                onChange={(e) => {
                                    setDescricao(e.target.value);
                                    getValue([empresa, cargo, e.target.value])

                                    console.log([empresa, cargo, e.target.value])
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default ExperienciaProfissional;