import React, { createContext, useState } from "react";

export const AuthContext = createContext();
AuthContext.displayName = "Auth";

export const AuthProvider = ({ children }) => {
  const [emailGlobal, setEmailGlobal] = useState("");
  const [isExternal, setIsExternal] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [codeAuthentication, setCodeAuthentication] = useState("");
  const [latlon, SetLatlon] = useState();
  const [autOlt, setAutOlt] = useState();
  const [ticketsAll, setTicketsAll] = useState();

  return (
    <AuthContext.Provider
      value={{
        emailGlobal,
        setEmailGlobal,
        isExternal,
        setIsExternal,
        isLogged,
        setIsLogged,
        qrCode,
        setQrCode,
        codeAuthentication,
        setCodeAuthentication,
        latlon,
        SetLatlon,
        autOlt,
        setAutOlt,
        ticketsAll,
        setTicketsAll
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
