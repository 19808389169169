import { Button, FormControl, TextField } from "@mui/material";
import { Card, Col, Image, Modal, Row, Table } from "antd";
import { useForm } from "react-hook-form";
import { PostAPI } from "../../../../services/http/PostAPI";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { DeleteAPI } from "../../../../services/http/DeleteAPI";
import gif from "./../../../../assets/video/CPT2405201340-322x322.gif"

function CadastrarFuncionariosComissao() {


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: "Ações",
            key: "operation",
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            showModalExcluir(e);
                        }}
                        size="small"
                        color="error"
                        style={{ margin: 5 }}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        criteriaMode: "all", mode: "onChange"
    });

    const [api, contextHolder] = notification.useNotification();
    const [nomes, setNomes] = useState()


    const [isModalOpenExcluir, setIsModalOpenExcluir] = useState(false);
    const [idModalExcluir, setIdModalExcluir] = useState();

    const showModalExcluir = (e) => {
        setIdModalExcluir(e.id);
        setIsModalOpenExcluir(true);
    };
    const handleOkExcluir = () => {
        deletarNomeFuncionario();
        setIsModalOpenExcluir(false);
    };
    const handleCancelExcluir = () => {
        setIsModalOpenExcluir(false);
    };

    const alertMessage = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };

    const getAllNomes = () => {
        const { http } = GetAPI();

        http
            .get(`comissao-all-funcionarios`)
            .then((res) => {
                if (res.status) {
                    setNomes(res.data)
                } else {
                    alertMessage("error", "Excluir funcionario", "Não foi possivel excluido com sucesso")
                }
            })
            .catch((erro) => {
                alertMessage("error", "Excluir funcionario", "Não foi possivel excluido com sucesso")
            });
    };

    const deletarNomeFuncionario = () => {
        const { http } = DeleteAPI();

        http
            .delete(`comissao-delete-nome-funcionario/${idModalExcluir}`)
            .then((res) => {
                if (res.status) {
                    alertMessage("success", "Excluir nome funcionario", "Funcionario excluido com sucesso")
                    getAllNomes()

                } else {
                    alertMessage("error", "Excluir nome funcionario", "Não foi possivel excluido com sucesso")
                }
            })
            .catch((erro) => {
                alertMessage("error", "Excluir nome funcionario", "Não foi possivel excluido com sucesso")
            });
    };

    const cadastrarNomeFuncionario = (data) => {
        const { http } = PostAPI();

        http
            .post(`comissao-cadastrar-funcionario`, {
                nome: data.nome
            })
            .then((res) => {
                if (res.status) {
                    alertMessage("success", "Cadastrar nome funcionario", "Nome funcionario cadastrado com sucesso")
                    getAllNomes()
                } else {
                    alertMessage("error", "Excluir funcionario", "Não foi possivel excluido com sucesso")
                }
            })
            .catch((erro) => {
                alertMessage("error", "Excluir funcionario", "Não foi possivel excluido com sucesso")
            });
    };

    const onSubmit = (data) => {
        cadastrarNomeFuncionario(data)
    };

    useEffect(() => {

        if (nomes === undefined) {
            getAllNomes()
        }

    }, [nomes])

    return (
        <>
            {contextHolder}
            <Card title={"Cadastrar Funcionário Comissao".toLocaleUpperCase()}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row justify={"center"} >
                        <Col flex={"auto"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                            >

                                <TextField
                                    label={"Nome Funcionário"}
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    //error={errors.setor !== undefined}
                                    {...register("nome", {
                                        required: true,
                                    })}
                                />
                                {errors?.nome?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Nome é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col flex={"200px"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button type="submit" variant="contained" size="small" >
                                Gravar Informações
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Card>
            <Card style={{ marginTop: 20 }} title={"Funcionarios cadastrados".toLocaleUpperCase()}>
                <Row>
                    <Col span={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Image src={gif} preview={false}></Image>
                    </Col>
                    <Col span={16}>
                        <Table columns={columns} dataSource={nomes} />
                    </Col>
                </Row>

            </Card>
            <Modal
                title="Excluir usuário"
                open={isModalOpenExcluir}
                onOk={handleOkExcluir}
                onCancel={handleCancelExcluir}
            >
                <p>
                    Realmente deseja excluir o registro:{" "}
                    <strong style={{ color: "red" }}>{idModalExcluir}</strong>
                </p>
            </Modal>
        </>
    );
}

export default CadastrarFuncionariosComissao;