import { Card, Col, Row, Avatar } from "antd";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AreaChart from "../../../../component/charts/area";
import CardDark from "../../../../component/cardDark";

function DashboardTicket() {
  return (
    <>
      <Row justify={"space-between"} style={{ marginBottom: 20 }}>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
              <div style={{ marginRight: 20, marginLeft: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "#17A2B8" }} shape="square" size={64} icon={<MailOutlineIcon fontSize="large" />} />
              </div>
              <div style={{ display: "block" }}>
                <span>Caixa de entrada</span>
                <br></br>
                <span style={{ fontWeight: "bold" }}>2</span>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
              <div style={{ marginRight: 20, marginLeft: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "#FD7E14" }} shape="square" size={64} icon={<PersonOffIcon fontSize="large" />} />
              </div>
              <div style={{ display: "block" }}>
                <span>Não atribuidos</span>
                <br></br>
                <span style={{ fontWeight: "bold" }}>2</span>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
              <div style={{ marginRight: 20, marginLeft: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "#DC3545" }} shape="square" size={64} icon={<EventBusyIcon fontSize="large" />} />
              </div>
              <div style={{ display: "block" }}>
                <span>Atrasados</span>
                <br></br>
                <span style={{ fontWeight: "bold" }}>2</span>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
              <div style={{ marginRight: 20, marginLeft: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "#FFC107" }} shape="square" size={64} icon={<PersonIcon fontSize="large" />} />
              </div>
              <div style={{ display: "block" }}>
                <span>Meus Tickets</span>
                <br></br>
                <span style={{ fontWeight: "bold" }}>2</span>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
              <div style={{ marginRight: 20, marginLeft: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "#DC3545" }} shape="square" size={64} icon={<VisibilityIcon fontSize="large" />} />
              </div>
              <div style={{ display: "block" }}>
                <span>Vencendo hoje</span>
                <br></br>
                <span style={{ fontWeight: "bold" }}>2</span>
              </div>
            </div>
          </Card>
        </Col>
      </Row >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CardDark title="Relatório" bordered={false}>
            <AreaChart></AreaChart>
          </CardDark>
        </Col>
      </Row>
    </>
  );
}

export default DashboardTicket;
