export const hardwareVersion = [
  "Huawei-EA5800-X2",
  "Huawei-EA5801-GP08",
  "Huawei-EA5801E-GP16",
  "Huawei-MA5600",
  "Huawei-MA5600T",
  "Huawei-MA5603",
  "Huawei-MA5608T",
  "Huawei-MA5680T",
  "Huawei-MA5683T",
  "Huawei-MA5600-X15",
  "Huawei-MA5600-X17",
  "Huawei-MA5800-X2",
  "Huawei-MA5800-X7",
  "Huawei-MA5801E-GP16",
  "ZTE-C220",
  "ZTE-C300",
  "ZTE-C320",
  "ZTE-C350",
  "ZTE-C600",
  "ZTE-C610",
  "ZTE-C620",
  "ZTE-C650",
  "ZTE-C680",
];

export const softwareVersion = [
  "R008",
  "R009",
  "R011",
  "R012",
  "R013",
  "R014",
  "R015",
  "R016",
  "R017",
  "R018",
  "R019",
  "R020",
  "R021",
];