import { useMediaQuery, Button } from "@mui/material";
import { Card, Col, Image, Row } from "antd";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context";
import logo from "./../../../assets/logo/Logo Completo.png";
import { useEffect } from "react";
import { useState } from "react";
import { message, Space } from "antd";
import gpsMobile from "./../../../assets/img/localizacao.jpg";
import gpsPc from "./../../../assets/img/localizacaopc.png";
import background from "./../../../assets/img-back-external/01.jpg";

function LocalizacaoCliente() {
  const { latlon, SetLatlon } = useContext(AuthContext);
  const { isExternal, setIsExternal } = useContext(AuthContext);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const matches = useMediaQuery("(max-width:767px)");

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Email enviado com sucesso",
    });
  };

  const onConfirmRefresh = function (event) {
    if (event.keyCode === 116) {
      event.preventDefault();
    }
  };

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    window.addEventListener("keydown", onConfirmRefresh, {
      capture: true,
    });
    setHeight(ref.current.clientHeight);
  }, [height]);

  return (
    <>
      {contextHolder}
      <Row
        ref={ref}
        justify="center"
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: 50,
        }}
      >
        <Col xs={20} sm={22} md={18} lg={18}>
          <Card
            style={{
              marginTop: 30,
              marginBottom: 20,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
              background: "rgba( 255, 255, 255, 0.8 )",
              boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
              backdropFilter: "blur( 1.5px )",
              border: "1px solid rgba( 255, 255, 255, 0.18 )",
            }}
          >
            <center>
              <Image src={logo} preview={false} width={180}></Image>

              <h4>
                Para que nós da Radnet Telecom possamos verificar a sua
                viabilidade, precisamos que você nôs informe a sua localização
              </h4>
              <p>
                Lembre-se de ligar o GPS (localização) do celular ou do
                computador
              </p>
            </center>

            <Row justify={"center"}>
              {matches === false && (
                <>
                  <Col
                    xs={20}
                    sm={20}
                    md={12}
                    lg={12}
                    xl={12}
                    style={marginFoto}
                  >
                    <Image src={gpsMobile} preview={false} width={400}></Image>
                  </Col>
                  <Col
                    xs={20}
                    sm={20}
                    md={12}
                    lg={12}
                    xl={12}
                    style={marginFoto}
                  >
                    <Image src={gpsPc} preview={false} width={400}></Image>
                  </Col>
                </>
              )}
              {matches === true && (
                <>
                  <Col
                    xs={20}
                    sm={16}
                    md={12}
                    lg={12}
                    xl={12}
                    style={marginFoto}
                  >
                    <Image src={gpsMobile} preview={false} width={400}></Image>
                  </Col>
                  <Col
                    xs={20}
                    sm={16}
                    md={12}
                    lg={12}
                    xl={12}
                    style={marginFoto}
                  >
                    <Image src={gpsPc} preview={false} width={400}></Image>
                  </Col>
                </>
              )}
            </Row>

            <Button
              className="myWonderfulButton"
              variant="contained"
              onClick={() => {
                navigator.geolocation.getCurrentPosition((location) => {
                  SetLatlon(location);
                  navigate("/external/viabilidade-confirmar-localizacao");
                });
              }}
              size="small"
            >
              Enviar localização
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LocalizacaoCliente;

const marginFoto = {
  display: "flex",
  justifyContent: "center",
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  marginLeft: 10,
};
