import { Button } from "@mui/material";
import { Card, Image, Modal, Table, Tag } from "antd";
import { message } from "antd";
import { useEffect, useState } from "react";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { DeleteAPI } from "./../../../../../services/http/DeleteAPI";
import LoadingComponent from "./../../../../../component/loading";
import LoadingImage from "./../../../../../component/loadingImage";
import CardDark from "../../../../../component/cardDark";

function GerenciarAtribuirSetorWeb() {

    const dataInfo = [];
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [allAtt, setAllAtt] = useState();
    const [setoresMobile, setSetoresMobile] = useState();
    const [permissoes, setPermissoes] = useState();
    const [user, setUser] = useState();
    const [idExcluir, setIdExcluir] = useState();
    const [nomeSetor, setNomeSetor] = useState();
    const [nomeUsuario, setNomeUsuario] = useState();
    const [allAttCarregou, setAllAttCarregou] = useState()
    const [setorCarregou, setSetorCarregou] = useState()

    const showModal = (e) => {
        setIdExcluir(e.id);

        setoresMobile.map((element, index) => {
            if (Number(element.id) === Number(e.sector)) {
                setNomeSetor(element.description);
            }
            return null;
        });

        user.map((element, index) => {
            if (Number(element.id) === Number(e.user)) {
                setNomeUsuario(element.name);
            }
            return null;
        });

        setIsModalOpen(true);
    };

    const handleOk = () => {
        deletarUserSetor();
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            width: 50,
        },
        {
            title: "Usuário",
            key: "user",
            width: 100,
            render: (e) => (
                <span>
                    {user.map((element, index) => {
                        return Number(element.id) === Number(e.user) ? (
                            <Tag key={index}>{element.name}</Tag>
                        ) : null;
                    })}
                </span>
            ),
        },
        {
            title: "Setor",
            key: "sector",
            width: 100,
            render: (e) => (
                <span>
                    {console.log(e)}
                    {setoresMobile.map((element, index) => {
                        return Number(element.id) === Number(e.sector) ? (
                            <Tag key={index}>{element.description}</Tag>
                        ) : null;
                    })}
                </span>
            ),
        },
        {
            title: "Permissões",
            key: "permission",
            width: 250,
            render: (e) => (
                <span>
                    {setoresMobile.map((item, index) => {
                        if (Number(e.sector) === Number(item.id)) {
                            return (
                                <span>
                                    {permissoes.map((element, index2) => {
                                        if (item.ids_permission.includes(element.id)) {
                                            return <Tag>{element.description_label}</Tag>;
                                        }
                                    })}
                                </span>
                            );
                        }
                    })}
                </span>
            ),
        },
        {
            title: "Ações",
            key: "operation",
            width: 200,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModal(e);
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const pegarTodosSetoresAtt = () => {

        const { http } = GetAPI();

        http
            .get(`sector-getallatt-mobile`)
            .then((res) => {
                if (res.status) {

                    setAllAtt(res.data);
                    setAllAttCarregou(true)

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });

    };

    const pegarTodosSetores = () => {

        const { http } = GetAPI();

        http
            .get(`sector-getall-mobile`)
            .then((res) => {
                if (res.status) {
                    setSetoresMobile(res.data);
                    setSetorCarregou(true)
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const deletarUserSetor = () => {
        const { http } = DeleteAPI();

        http
            .delete(`sector-mobile-deleteatt-sector/${idExcluir}`)
            .then((res) => {
                if (res.status) {
                    pegarTodosSetoresAtt();
                    success("Setor removido do usuário");
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodasPermissoes = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall-mobile`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    setPermissoes(pages);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    useEffect(() => {
        if (allAtt === undefined) {
            pegarTodosSetoresAtt();

        }

        if (setoresMobile === undefined) {
            pegarTodosSetores();

        }

        if (permissoes === undefined) {
            buscarTodasPermissoes();

        }

        if (user === undefined) {
            buscarTodosUsuarios();
        }

        if (allAttCarregou !== undefined && setorCarregou !== undefined && permissoes !== undefined && user !== undefined) {
            setLoading(false);
        }

    }, [allAtt, setoresMobile, permissoes, user]);

    if (loading === false) {

        allAtt.map((item, index) => {
            dataInfo.push({
                id: item.id,
                user: item.id_user,
                sector: item.id_setor,
                key: index,
            });

            return null;
        });
    }

    return (
        <>
            {contextHolder}
            {/* {loading === true && <LoadingComponent />} */}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Gerenciar Setor Mobile">
                        <Table
                            columns={columns}
                            dataSource={dataInfo}
                            scroll={{
                                y: 240,
                            }}
                        />
                    </CardDark>
                    <Modal
                        title="Excluir setor"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        destroyOnClose={true}
                    >
                        <p>
                            Realmente deseja excluir o setor:{" "}
                            <strong style={{ color: "red" }}>{nomeSetor} </strong> no usuário:{" "}
                            <strong style={{ color: "red" }}>{nomeUsuario} </strong>
                        </p>
                    </Modal>
                </>
            )}
        </>
    );
}

export default GerenciarAtribuirSetorWeb;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};