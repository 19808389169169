import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, indigo } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: "#002050",
    },
    secondary: lime,
    dark: indigo
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>

);
