import { Avatar, Card, Col, Modal, Row, notification } from "antd";
import LoadingImage from "../../../../../component/loadingImage";
import { ExternalAPI } from "../../../../../services/http/ExternalAPI";
import { AuthContext } from "./../../../../../context/";
import { useContext, useEffect, useState } from "react";
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { loadState } from "../../../../../services/localStorage";


function TicketsNotLinked() {

    const [ticketsNotLinked, setTicketsNotLinked] = useState()
    const [loading, setLoading] = useState(true)
    const [scale, setScale] = useState(1)
    const [boxS, setBoxS] = useState(1)
    const [sobre, setSobre] = useState()
    const [itemClicked, setItemClicked] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    let userLogged = loadState("session_user");

    const navigate = useNavigate();


    const someHandler = (index) => {
        setSobre(index)
        setBoxS("0px 0px 22px 0px rgba(0,0,0,0.2)")
        setScale(1.05)
    }

    const someOtherHandler = (index) => {
        setSobre(index)
        setBoxS("0px 0px 0px 0px rgba(0,0,0,0)")
        setScale(1)
    }

    const deletarTicketId = async () => {
        const { http } = ExternalAPI();

        http
            .delete(`ticket-delete/${itemClicked}/${userLogged.data.id}`)
            .then((res) => {

                console.log(res)
                alertMessage(
                    "success",
                    "Exclusão",
                    `Ticket ${itemClicked} excluido com sucesso`
                );
                getAllTicketsNotLinked()
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const alertMessage = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };

    const showModal = (e) => {
        setItemClicked(e.target.dataset.item)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        deletarTicketId()
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setItemClicked(undefined)
        setIsModalOpen(false);
    };

    const getAllTicketsNotLinked = async () => {
        const { http } = ExternalAPI();

        http
            .get(`ticket-getAllTicketsNotLinked`)
            .then((res) => {

                console.log(res)
                setTicketsNotLinked(res.data)
                setLoading(false)
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    useEffect(() => {

        if (ticketsNotLinked === undefined) {
            getAllTicketsNotLinked()
        }

    }, [])



    return (
        <>
            {contextHolder}
            {loading === true && (
                <LoadingImage></LoadingImage>
            )}
            {loading === false && (
                <>
                    <Row>
                        {ticketsNotLinked.map((item, index) => {
                            return (

                                <Col key={index} onMouseEnter={() => someHandler(index)} onMouseLeave={() => someOtherHandler(index)} >
                                    <Card
                                        key={item.id}
                                        style={{
                                            margin: 30,
                                            transform: sobre === index ? `scale(${scale},${scale})` : `scale(${1},${1})`,
                                            boxShadow: sobre === index ? `${boxS}` : `0px 0px 0px 0px rgba(0,0,0,0)`
                                        }}
                                        cover={
                                            <img
                                                alt="example"
                                                src={`${process.env.REACT_APP_BASE_URL_BACKEND}/images/tickets/ticketImage.jpg`}
                                            //src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                                            />

                                        }
                                        actions={[
                                            <EditIcon key="edit" onClick={() => { navigate("/tickets-ativos/tickets-ticketDescricao", { state: item }) }} />,
                                            <ChatIcon key="setting" onClick={() => { navigate("/tickets-ativos/tickets-chat", { state: item }) }} />,
                                            <CloseIcon key="ellipsis" data-item={item.id} onClick={(e) => { showModal(e) }} />,
                                        ]}
                                    >
                                        <Meta
                                            avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                                            title={`${item.id} - ${item.titulo.substring(0, 16)}`}
                                            description={item.description.substring(0, 25)}
                                        />
                                        {itemClicked !== undefined && (
                                            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                                Deseja excluir o Ticket de Id: {itemClicked}
                                            </Modal>
                                        )}

                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </>
            )}
        </>
    );
}

export default TicketsNotLinked;