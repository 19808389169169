import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function SelectComponent({
  items,
  require = null,
  defaultValue = null,
  label,
  register,
  labelRegister,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id="todas-olts" size="small">
        {label}
      </InputLabel>
      <Select
        labelId={label}
        id={label}
        label={label}
        defaultValue={defaultValue ? defaultValue : null}
        {...register(labelRegister)}
        required={require ? true : false}
        size="small"
        style={separadorInput20}
      >
        {items.map((item, key) => (
          <MenuItem value={item.id} key={key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectComponent;

const separadorInput20 = {
  marginBottom: 20,
};
