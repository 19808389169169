import { Card, Col, Image, Row, Table } from "antd";
import CardDark from "../../../../component/cardDark";

import {
  GridSheet,
  constructInitialCells,
  BaseFunction,
  prevention,
  SheetProvider,
  createTableRef,
} from "@gridsheet/react-core";
import { useEffect, useState } from "react";
import { Button, FormControl, TextField } from "@mui/material";
import { GetAPI } from "../../../../services/http/GetAPI";
import { PostAPI } from "../../../../services/http/PostAPI";
import { notification } from "antd";
import LoadingImage from "../../../../component/loadingImage";
import gif from "./../../../../assets/video/CPT2405201323-466x322.gif";
import { useForm } from "react-hook-form";
import { DeleteAPI } from "./../../../../services/http/DeleteAPI";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Descrição",
    dataIndex: "descricao",
    key: "descricao",
  },
  {
    title: "Vendas",
    dataIndex: "vendas",
    key: "vendas",
  },
  {
    title: "Upgrade",
    dataIndex: "upgrade",
    key: "upgrade",
  },
  {
    title: "Ações",
    dataIndex: "acoes",
    key: "acoes",
  },
];

function ConfiguracaoPlanilhaVendas() {
  const [planilhaVendas, setPlanilhaVendas] = useState();
  const [planilhaUpgrade, setPlanilhaUpgrade] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [configAll, setConfigAll] = useState();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const gravarConfiguracoesComissao = (data) => {
    const { http } = PostAPI();

    http
      .post(`comissao-set-config`, {
        nomeconfig: data.nome,
        config: "comissao_config",
        value: { vendas: planilhaVendas, upgrade: planilhaUpgrade },
      })
      .then((res) => {
        buscarConfig();
        alertMessage(
          "success",
          "Salvar Configuração",
          "Configuração salva com sucesso"
        );
      })
      .catch((erro) => {
        alertMessage(
          "error",
          "Salvar Configuração",
          "Não foi possivel salvar as configurações"
        );
      });
  };

  const deletarConf = (data) => {
    const { http } = DeleteAPI();

    http
      .delete(`comissao-delete-config/${data}`)
      .then((res) => {
        buscarConfig();
        alertMessage(
          "success",
          "Deletar Configuração",
          "Configuração deletada com sucesso"
        );
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const buscarConfig = () => {
    const { http } = PostAPI();

    http
      .post(`comissao-get-config`, { config: "comissao_config" })
      .then((res) => {
        if (res.data) {
          setConfigAll(res.data);

          let resp = [];

          for (const item of res.data) {
            let tablejson = JSON.parse(item.config);

            resp.push({
              id: item.id,
              descricao: item.nome_config,
              vendas: (
                <>
                  <table border={1} align="center">
                    {tablejson.value.vendas[0].map((item) => {
                      return (
                        <th
                          style={{
                            textAlign: "center",
                            backgroundColor: "#EEEEEE",
                          }}
                        >
                          {item.value}
                        </th>
                      );
                    })}
                    <tr>
                      {tablejson.value.vendas[1].map((item) => {
                        return (
                          <td style={{ textAlign: "center" }}>{item.value}</td>
                        );
                      })}
                    </tr>
                  </table>
                </>
              ),
              upgrade: (
                <>
                  <table border={1} align="center">
                    {tablejson.value.upgrade[0].map((item) => {
                      return (
                        <th
                          style={{
                            textAlign: "center",
                            backgroundColor: "#EEEEEE",
                          }}
                        >
                          {item.value}
                        </th>
                      );
                    })}
                    <tr>
                      {tablejson.value.upgrade[1].map((item) => {
                        return (
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {item.value}
                          </td>
                        );
                      })}
                    </tr>
                  </table>
                </>
              ),
              acoes: (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      deletarConf(item.id);
                    }}
                  >
                    Deletar
                  </Button>
                </>
              ),
            });
          }

          setDataSource(resp);
        } else {
        }
      })
      .catch((erro) => {
        console.log(erro);
        //error(erro);
      });
  };

  const onSubmit = (data) => {
    gravarConfiguracoesComissao(data);
  };

  useEffect(() => {
    if (configAll === undefined) {
      buscarConfig();
    }

    if (configAll !== undefined) {
      setLoading(false);
    }
  }, [loading, configAll, dataSource]);

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage></LoadingImage>}
      {loading === false && (
        <Card
          title="Configuração da planilha"
          extra={
            <Button
              type="submit"
              variant="contained"
              size="small"
              onClick={handleSubmit(onSubmit)}
            >
              Gravar Informações
            </Button>
          }
        >
          <Row gutter={12}>
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image src={gif} preview={false}></Image>
            </Col>
            <Col span={16}>
              <form>
                <Row>
                  <FormControl fullWidth id="category-editor-form">
                    <TextField
                      label={"Nome da configuração"}
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      //error={errors.setor !== undefined}
                      {...register("nome", {
                        required: true,
                      })}
                    />
                    {errors?.nome?.type === "required" && (
                      <span style={{ color: "red" }}>
                        O campo Nome é obrigatório
                      </span>
                    )}
                  </FormControl>
                </Row>

                <Row justify={"center"}>
                  <h1>Tabela de porcentagem (%) de vendas</h1>
                </Row>
                <Row justify={"center"}>
                  <Col
                    span={24}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <SheetProvider>
                      <GridSheet
                        sheetName={"criteria"}
                        initialCells={constructInitialCells({
                          matrices: {
                            A1: [
                              [
                                "50% a 70%",
                                "70% a 100%",
                                "100% a 150%",
                                "150% a 170%",
                                "170%",
                              ],
                              ["5%", "15%", "30%", "35%", "50%"],
                            ],
                          },
                          cells: {
                            default: {
                              width: 150,
                              height: 50,
                            },
                            1: {
                              style: { backgroundColor: "#ddd" },
                            },
                          },
                        })}
                        options={{
                          headerHeight: 30,
                          onChange: (table) => {
                            setPlanilhaVendas(table.getMatrix());
                          },
                        }}
                      />
                    </SheetProvider>
                  </Col>
                </Row>
                <Row justify={"center"} style={{ marginTop: 50 }}>
                  <h1>Tabela de porcentagem (%) de upgrades</h1>
                </Row>
                <Row justify={"center"}>
                  <Col
                    span={24}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <SheetProvider>
                      <GridSheet
                        sheetName={"criteria"}
                        initialCells={constructInitialCells({
                          matrices: {
                            A1: [
                              [
                                "50% a 70%",
                                "70% a 100%",
                                "100% a 150%",
                                "150% a 170%",
                                "170%",
                              ],
                              ["5%", "15%", "30%", "35%", "50%"],
                            ],
                          },
                          cells: {
                            default: {
                              width: 150,
                              height: 50,
                            },
                            1: {
                              style: { backgroundColor: "#ddd" },
                            },
                          },
                        })}
                        options={{
                          headerHeight: 30,
                          onChange: (table) => {
                            setPlanilhaUpgrade(table.getMatrix());
                          },
                        }}
                      />
                    </SheetProvider>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={24}>
              <Table dataSource={dataSource} columns={columns} />
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}

export default ConfiguracaoPlanilhaVendas;
