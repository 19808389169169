import axios from "axios";

export function UploadExternal() {
    const key = process.env.REACT_APP_KEY_FRONTEND;

    const http = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL_BACKEND}/api`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${key}`,
        },
    });

    return {
        http,
    };
}
