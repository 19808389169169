
import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from "@faker-js/faker";
import { Badge, Card, Col, Descriptions, Image, Row, Tag } from "antd"
import { PostAPI } from '../../../../services/http/PostAPI';
import Images from "./../../../../assets/img-logo-services/"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment"
import {
    SyncOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { useForm } from "react-hook-form";

import { DatePicker, Space } from 'antd';
import LineChartComponent from '../../../../component/charts/lineComponent';
import CardDark from '../../../../component/cardDark';
import dayjs from 'dayjs';
import LoadingImage from '../../../../component/loadingImage';
const { RangePicker } = DatePicker;

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function DashboardWebsTelegram() {

    const [loading, setLoading] = useState(true)
    const [allIncidentes, setAllIncidentes] = useState()
    const [allIncidentesResolvidos, setAllIncidentesResolvidos] = useState()
    const [problemasHoje, setProblemasHoje] = useState()
    const [incidentesPesquisa, setIncidentesPesquisa] = useState()
    const [dataGrafic, setDataGrafic] = useState()
    const [services, setServices] = useState([])
    const [rangeDate, setRangeDate] = useState()
    const [servicesRange, setServicesRange] = useState([])
    const [dataGraficRange, setDataGraficRange] = useState()
    const [diasGraficRange, setDiasGraficRange] = useState()

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "onChange" });

    const gerarArrayVazio = (n) => {
        let range = [];
        for (let i = 0; i <= n; i++) range[i] = 0;
        return range;
    }

    const pesquisarPorData = () => {
        const { http } = PostAPI();
        http
            .post(`incidentes-getIncidentesRangeTime`, { start: rangeDate[0], end: rangeDate[1] })
            .then((res) => {

                let dias = moment(rangeDate[1]).diff(moment(rangeDate[0]), 'days');
                let inicio = moment(rangeDate[0])
                let fim = moment(rangeDate[1])
                let diasGrafic = [inicio.format("YYYY-MM-DD")]
                for (let index = 0; index < dias; index++) {
                    diasGrafic.push(inicio.add(1, 'days').format("YYYY-MM-DD"))
                }
                let servico = []
                let objServico = []
                let dados = []

                for (const i of res.data) {
                    if (i.ocorrencia === 'INCIDENTE') {
                        if (!servico.includes(i.servico)) {
                            servico.push(i.servico)
                            objServico.push(i)
                        } else {
                            let index = servico.indexOf(i.servico)
                        }
                    }
                }

                for (const [indice, i] of servico.entries()) {
                    let arrayVazio = gerarArrayVazio(diasGrafic.length)
                    dados.push(arrayVazio)
                    for (const j of res.data) {
                        if (j.ocorrencia === 'INCIDENTE' && j.servico === i) {
                            for (const [index, k] of diasGrafic.entries()) {
                                if (k === moment(j.data_deteccao).format("YYYY-MM-DD")) {

                                    dados[indice][index] = Number(j.relatos)
                                    //console.log(j.relatos, i)
                                }
                            }
                        }
                    }
                }

                setDiasGraficRange(diasGrafic)
                setServicesRange(servico)
                setDataGraficRange(dados)

            }).catch((erro) => {
                console.log(erro);
            });
    }

    const rangerDate = (date, dateString) => {

        let dia = [dateString[0].slice(0, 2), dateString[1].slice(0, 2)]
        let mes = [dateString[0].slice(3, 5), dateString[1].slice(3, 5)]
        let ano = [dateString[0].slice(6), dateString[1].slice(6)]

        let dateinit = `${ano[0]}-${mes[0]}-${dia[0]}`
        let dateEnd = `${ano[1]}-${mes[1]}-${dia[1]}`

        console.log([dateinit, dateEnd])
        setRangeDate([dateinit, dateEnd])
    };

    const getAllIncidentes = () => {
        const { http } = PostAPI();

        http
            .get(`incidentes-getAll`)
            .then((res) => {
                setAllIncidentes(res.data)

                let problemaHoje = []

                for (const item of res.data) {

                    console.log(res.data)
                    if (moment(item.data_deteccao).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        item.status = "Não resolvido"
                        problemaHoje.push(item)
                    }
                }

                setProblemasHoje(problemaHoje)
                getAllIncidentesResolvidos(problemaHoje)


            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const getAllIncidentesResolvidos = (probs) => {
        const { http } = PostAPI();

        http
            .get(`incidentes-getAllResolvidos`)
            .then((res) => {
                setAllIncidentesResolvidos(res.data)

                let problemaHoje = []


                for (const item of res.data) {
                    if (moment(item.data_resolucao).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        problemaHoje.push(item)
                    }
                }

                let final = []
                let remove = []

                for (const [index, elem] of probs.entries()) {
                    for (const item of problemaHoje) {
                        if (elem.servico === item.servico) {
                            elem.status = "Resolvido"
                            elem.duracao = item.duracao
                            elem.data_resolucao = item.data_resolucao
                            final.push(elem)
                            remove.push(index)
                        }
                    }
                }

                for (const [index, item] of remove.entries()) {
                    probs.splice(item - index, 1)
                }

                for (const item of final) {
                    probs.push(item)
                }

                setProblemasHoje(probs)

                setLoading(false)

            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    useEffect(() => {

        if (allIncidentes === undefined) {
            getAllIncidentes()
        }

    }, [dataGraficRange, diasGraficRange,])


    return (
        <>
            {loading === true && (
                <LoadingImage></LoadingImage>
            )}
            {loading === false && (
                <>
                    <CardDark
                        title="Serviço com problemas no dia de hoje"
                        style={{ marginBottom: 20 }}

                        addComponentHeader={<><span style={{ marginRight: 10 }}>Última atualização às <strong>{moment().format("DD/MM/YYYY HH:mm:ss")}</strong>  </span><Button variant="contained" size='small' color='secondary'
                            onClick={() => { getAllIncidentes() }}><SyncOutlined style={{ marginRight: 10 }} /> <strong>Atualizar</strong></Button></>
                        }>
                        <Row>
                            {problemasHoje.map((item) => {
                                return (
                                    Images.map((elem, key) => {
                                        return (
                                            elem.servico === item.servico ?

                                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6} key={key}>
                                                    <Card key={key} style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} title={elem.servico.toUpperCase()} bodyStyle={{
                                                        minHeight: 220, display: 'flex', alignItems: 'center', backgroundColor: item.status !== "Não resolvido" ? "#7DCDB0" : "#F3998E",
                                                    }} headStyle={{ backgroundColor: item.status !== "Não resolvido" ? "#176963" : "#F44E40", color: 'white' }}>
                                                        <Row justify={'space-between'}>
                                                            <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Card>
                                                                    <Image src={elem.url} preview={false} key={key}></Image>
                                                                </Card>
                                                            </Col>
                                                            <Col span={15}>
                                                                <span><strong>Serviço: </strong>{item.servico}</span><br />
                                                                <span><strong>Relatos: </strong>{item.relatos}</span><br />
                                                                <span><strong>Início da ocorrência: </strong>{moment(item.data_deteccao).format('HH:mm:ss')}</span><br />
                                                                {item.status !== "Não resolvido" ? <><span><strong>Duração: </strong>{item.duracao}</span><br /></> : null}
                                                                {item.status !== "Não resolvido" ? <><span><strong>Final da ocorrência: </strong>{moment(item.data_resolucao).format('HH:mm:ss')}</span><br /></> : null}
                                                                <span><strong>Status: <Tag color={item.status !== "Não resolvido" ? "green" : "red"}>{item.status}</Tag></strong></span><br />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                                : null
                                        )
                                    })
                                )
                            })}
                        </Row>
                    </CardDark >

                    <CardDark style={{ marginTop: 20 }} title={"pesquisar por data"}>
                        <Row justify={"space-between"} style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={20}>
                                <RangePicker style={{ width: '100%' }} onChange={rangerDate} format="DD/MM/YYYY" />
                            </Col>
                            <Col>
                                <Button
                                    key="submit"
                                    variant="contained"
                                    onClick={pesquisarPorData}
                                    size="small"
                                >
                                    <SearchOutlined style={{ marginRight: 10 }} /> Pesquisar
                                </Button>
                            </Col>
                        </Row>
                    </CardDark>
                    {diasGraficRange !== undefined && (

                        <Card title={"Dashboard".toUpperCase()} bodyStyle={{ backgroundColor: "#002140" }} headStyle={{ backgroundColor: '#001529', color: 'white', }} style={{ marginTop: 20 }}>
                            <Row>
                                {servicesRange.map((item, index) => {
                                    return (
                                        Images.map((elem, key) => {
                                            return (
                                                elem.servico === item ?
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} key={key}>
                                                        <Card key={key} style={{ marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, }} bodyStyle={{ backgroundColor: "white", minHeight: 240, maxHeight: 400 }} headStyle={{ backgroundColor: '#1677FF', color: 'white' }}>
                                                            <Row justify={'space-between'}>
                                                                <Col xs={24} sm={24} md={6} lg={7} xl={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Row>
                                                                            <Image src={elem.url} preview={false} key={key}></Image>
                                                                        </Row>
                                                                        <Row style={{ justifyContent: "center", textAlign: 'center' }}>
                                                                            <span>{elem.servico}</span>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={16} lg={17} xl={16}>
                                                                    <LineChartComponent options={options} label={diasGraficRange} data={dataGraficRange[index]} title={"Reclamações"} borderColor={'rgb(255, 99, 132)'} backgroundColor={'rgba(255, 99, 132, 1)'}></LineChartComponent>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    : null
                                            )
                                        })
                                    )
                                })}
                            </Row>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}

export default DashboardWebsTelegram;
