import AllTickets from "./allTickets";
import TicketsNotLinked from "./ticketsNotLinked";
import TicketsLinked from "./ticketLinked";
import TicketsClosed from "./ticketClosed";

const itemsTicketsAtivos = [
    {
        key: 'all',
        label: `Todos Tickets`,
        children: <AllTickets></AllTickets>,
    },
    {
        key: 'abertosr',
        label: `Tickets Abertos s/ responsável`,
        children: <TicketsNotLinked ></TicketsNotLinked>,
    },
    {
        key: 'abertocr',
        label: `Tickets Abertos c/ responsável`,
        children: <TicketsLinked ></TicketsLinked>,
    },
    {
        key: 'fechados',
        label: `Tickets Fechados`,
        children: <TicketsClosed ></TicketsClosed>,
    },
];

export default itemsTicketsAtivos;
