import React, { useEffect, useState } from "react";
import { Image, Layout, Menu } from "antd";
import FooterLayout from "./../../component/footer/";
import HeaderLayout from "../../component/headerApps";
import ContentRoutes from "../internalPages/googleAuthenticate/";
import BreadCrumb from "./../../component/breadcrumb/";
import logo from "./../../assets/logo/logo branco.png";
import faviIcon from "./../../assets/logo/favicon-32x32.png";
import { useMediaQuery } from "@mui/material";
import { itemsNavigationMobile } from "../../component/mobileNavibar";
import { loadState, removeState } from "../../services/localStorage";
import { itemsNavigation } from "../../component/desktopNavbar";
import LoadingImage from "../../component/loadingImage";

const { Content, Sider, Header, Footer } = Layout;

const InternalPagerLayout = () => {

  const pages = loadState('userPages')
  const [collapsed, setCollapsed] = useState(false);
  const [paginas, setPaginas] = useState()
  const [loading, setLoading] = useState(true)
  const mobile = useMediaQuery("(max-width:520px)");

  const organizarPaginas = () => {

    let intPages = pages.map(Number);

    let removerIndices = []

    for (let i = 0; i < itemsNavigation.length; i++) {
      removerIndices.push({ i: itemsNavigation[i].children?.length ? Number(itemsNavigation[i].children?.length) : null })
      if (itemsNavigation[i]?.children) {
        for (let j = 0; j < itemsNavigation[i].children.length; j++) {
          if (itemsNavigation[i].children[j]?.key) {
            if (!intPages.includes(Number(itemsNavigation[i].children[j].key))) {
              removerIndices[i].i = removerIndices[i].i - 1
              delete itemsNavigation[i].children[j]
            }
          }
        }
      }
    }

    for (let i = 0; i < removerIndices.length; i++) {
      if (removerIndices[i].i === 0) {
        console.log(removerIndices[i])
        delete itemsNavigation[i]
      }
    }

    setPaginas(true)
    setLoading(false)
  }

  useEffect(() => {

    if (paginas === undefined) {
      organizarPaginas()
    }

  })

  return (
    <>
      {loading === true && (<LoadingImage></LoadingImage>)}
      {loading === false && (
        <>
          {mobile === false && (
            <Layout style={layoutStyle}>
              <Sider
                width={320}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
              >
                {collapsed === false ? (
                  <div style={divStyle}>
                    <Image src={logo} preview={false}></Image>
                  </div>
                ) : (
                  <div style={divStyleMin}>
                    <Image src={faviIcon} width={30} preview={false}></Image>
                  </div>
                )}

                <Menu
                  theme="dark"
                  defaultSelectedKeys={["1"]}
                  mode="inline"
                  items={itemsNavigation}
                />
              </Sider>

              <Layout>
                <Header>
                  <HeaderLayout></HeaderLayout>
                </Header>
                <Content style={contentStyle}>
                  <BreadCrumb></BreadCrumb>
                  <ContentRoutes></ContentRoutes>
                </Content>
                <Footer>
                  <FooterLayout />
                </Footer>
              </Layout>
            </Layout>
          )}
          {mobile === true && (
            <Layout>
              <Header style={headerMobile}>
                <div style={imgMobile}>
                  <Image src={logo} preview={false}></Image>
                </div>

                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={["1"]}
                  items={itemsNavigationMobile}
                  onClick={(e) => {
                    if (e.key === "3") {
                      removeState("user");
                      removeState("token");
                      removeState("company");
                      removeState("session_user");
                      removeState("2fagoogle");
                      removeState("permissions");
                      removeState("userPages")
                      window.location.reload();
                    }
                  }}
                />
              </Header>

              <Content>
                <BreadCrumb></BreadCrumb>
                <ContentRoutes></ContentRoutes>
              </Content>
              <Footer>
                <FooterLayout />
              </Footer>
            </Layout>
          )}
        </>
      )}
    </>
  );
};

export default InternalPagerLayout;

const imgMobile = {
  float: "left",
  width: 60,
  margin: "0px 20px 0px 0",
};

const headerMobile = {
  position: "sticky",
  top: 0,
  zIndex: 1,
  width: "100%",
};

const contentStyle = {
  margin: "0 16px",
};

const divStyle = {
  margin: 26,
};

const divStyleMin = {
  margin: 26,
};

const layoutStyle = {
  minHeight: "100vh",
};
