import { Button, FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";


function SetorTextField({ submitFunction, valores }) {

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({ criteriaMode: "all", mode: "onChange" });

    const onSubmit = (data) => {
        submitFunction(data, valores)
        reset();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                    fullWidth
                    id="category-editor-form"
                    style={{ marginBottom: 20 }}
                >
                    <TextField
                        label={"Setor"}
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        error={errors.setor !== undefined}
                        {...register("setor", {
                            required: true,
                            minLength: 4,
                        })}
                    />
                    {errors?.setor?.type === "required" && (
                        <span style={{ color: "red" }}>
                            O campo Setor é obrigatório
                        </span>
                    )}
                    {errors?.setor?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                            O Setor deve conter ao menos 4 caracteres
                        </span>
                    )}
                </FormControl>

                <Button type="submit" variant="contained" size="small">
                    Enviar
                </Button>
            </form>
        </>
    );
}

export default SetorTextField;