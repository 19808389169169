import { FormControl, InputLabel, MenuItem, TextField, Select, Button, Checkbox, FormControlLabel } from "@mui/material";
import { Card, Col, Image, Row, Collapse, DatePicker, ConfigProvider, Divider, } from "antd";
import logo from "./../../../assets/logo/Logo Completo.png";
import background from "../../../services/randomImagesBackground";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from 'react';
import axios from 'axios';
import DropFileInputPdf from "../../../component/drop-file-input-pdf";
import AddIcon from '@mui/icons-material/Add';
import ExperienciaProfissional from "../../../component/experienciaProfissional";
import Scrollbars from "react-custom-scrollbars";
import { useForm } from "react-hook-form";
import pt_BR from "antd/lib/locale/pt_BR";
import dayjs from "dayjs";
import { ExternalAPI } from "../../../services/http/ExternalAPI";
import { notification } from "antd";
import { UploadExternal } from "../../../services/http/UploadExternal";
import { useNavigate } from "react-router-dom";

function TrabalheConosco() {

    const { Panel } = Collapse;
    const [files, setFiles] = useState();
    const [experiencia, setExperiencia] = useState([]);
    const [checkedExp, setCheckedExp] = useState(true);
    const [checkedDef, setCheckedDef] = useState(false);
    const [experiencia1, setExperiencia1] = useState()
    const [experiencia2, setExperiencia2] = useState()
    const [experiencia3, setExperiencia3] = useState()
    const [experiencia4, setExperiencia4] = useState()
    const [allExperiencia, setAllExperiencia] = useState([setExperiencia1, setExperiencia2, setExperiencia3, setExperiencia4])
    const [educacao, setEducacao] = useState()
    const [dtNasc, setDtNasc] = useState(null)
    const [err, setErr] = useState();
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const {
        register,
        reset,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ criteriaMode: "all", mode: "onChange" });

    const handleChange = () => {
        setCheckedExp(!checkedExp);
    };

    const onFileChange = (files) => {
        setFiles(files)
    }

    const alertMessage = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };

    const cadastrarCurriculoForm = (data) => {
        const { http } = ExternalAPI();

        http
            .post(`/cadastrar-curriculo-form`, {
                nome: data.nome,
                sobrenome: data.sobrenome,
                endereco: data.endereco,
                telefone: data.phone,
                email: data.email,
                escolaridade: data.escolaridade,
                disponibilidade: data.disponibilidade,
                info_add: data.infoAdd,
                dt_nascimento: data.dtnasc,
                curso: data.curso,
                pcd: data.terdeficiencia,
                pcd_descricao: data.descDef,
                prof_experiencias: [
                    experiencia1?.length ? experiencia1 : [],
                    experiencia2?.length ? experiencia2 : [],
                    experiencia3?.length ? experiencia3 : [],
                    experiencia4?.length ? experiencia4 : [],
                ]
            })
            .then((res) => {

                alertMessage("success", "Currículo", "Currículo cadastrado com sucesso")

                setTimeout(() => { navigate("/external/agradecimento") }, 6000);

            });
    };

    const uploadCurriculo = () => {
        const { http } = UploadExternal();

        let formData = new FormData();

        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("curriculo[]", files[i]);
            }
        }

        http
            .post("cadastrar-curriculo-pdf", formData)
            .then((res) => {
                if (res.status) {
                    console.log(res)
                    alertMessage("success", "Currículo", "Currículo cadastrado com sucesso")

                    setTimeout(() => { navigate("/external/agradecimento") }, 6000);
                } else {

                }
            })
            .catch((erro) => {
                console.log(erro)
            });
    };


    const onSubmit = (data) => {

        if (dtNasc !== null) {

            data.dtnasc = `${dtNasc.$y}-${dtNasc.$M + 1 >= 10 ? dtNasc.$M + 1 : `0${dtNasc.$M + 1}`}-${dtNasc.$D >= 10 ? dtNasc.$D : `0${dtNasc.$D}`}`
            data.terdeficiencia = checkedDef
            data.possuirexperiencia = checkedExp

            cadastrarCurriculoForm(data)

        } else {
            setDtNasc(undefined)
        }


    };

    const normalizeCardNumber = (value) => {
        const ocorrencias = (value.match(/[0-9]/g) || []).length;

        if (ocorrencias !== 11) {
            setErr("Telefone deve conter 11 números");
        } else {
            setErr(undefined);
        }

        return value
            .replace(/\D+/g, "")
            .replace(/^([0-9]{2})?([0-9]{5})?([0-9]{4})$/, "($1) $2-$3");
    };

    return (
        <>
            {contextHolder}
            <ConfigProvider locale={pt_BR}>
                <Row
                    style={{
                        backgroundImage: `url(${background})`,
                        height: "100vh",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        filter: "blur(2.5px)",
                    }}
                >
                </Row>
                <Row
                    gutter={12}
                    style={{
                        position: "absolute",
                        top: "5vh",
                        left: "5vw",
                        width: "90vw",
                        height: "90vh",
                        margin: 0,
                        padding: 0,
                        overflow: "auto"
                    }}
                    justify={"center"}
                >
                    <Scrollbars>
                        <Row justify={"center"}>

                            <Col span={23}>
                                <Card
                                    style={{
                                        marginTop: 50,
                                        marginBottom: 20,
                                        paddingBottom: 20,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >


                                    <Row>
                                        <Col sm={24} md={24} lg={12} xl={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Image src={logo} preview={false} width={"60%"}></Image>
                                        </Col>
                                        <Col sm={24} md={24} lg={12} xl={12} >
                                            <center>
                                                <h2 style={{ fontFamily: "Segoe UI Emoji" }}>
                                                    Nós da Radnet Telecom ficamos imensamente felizes de querer fazer parte da nossa empresa ☺️
                                                </h2>
                                                <h4>
                                                    Para que possamos encaixa-lo(a) da melhor forma, precisamos de algumas informações profissionais suas como: Nome, forma de contato, experiências profissionais etc.
                                                </h4>
                                                <h4>Se preferir, nos envie um currículo no formato PDF ("outros formatos não serão aceitos") ou preencha o formulário abaixo.</h4>
                                                <DropFileInputPdf
                                                    onFileChange={(files) => onFileChange(files)}
                                                    type={"application/pdf"}
                                                />
                                                <Button
                                                    variant={"contained"}
                                                    size="small"
                                                    onClick={() => {
                                                        if (files !== undefined) {
                                                            uploadCurriculo()
                                                        } else {
                                                            alertMessage("error", "Upload", "Nenhum currículo foi carregado")
                                                        }
                                                    }}
                                                >
                                                    Enviar
                                                </Button>
                                            </center></Col>
                                    </Row>


                                </Card>
                            </Col>
                            <Col span={23}>
                                <Card
                                    title={"Currículo"}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 50,
                                        paddingBottom: 20,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Collapse defaultActiveKey={['1', '2', '3', '4']} >
                                            <Panel header="Dados Pessoais" key="1">
                                                <Row gutter={10} >
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Nome"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            error={errors.nome !== undefined}
                                                            {...register("nome", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors?.nome?.type === "required" && (
                                                            <span style={{ color: "red" }}>
                                                                Esse campo é obrigatório
                                                            </span>
                                                        )}

                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Sobrenome"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            error={errors.sobrenome !== undefined}
                                                            {...register("sobrenome", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors?.sobrenome?.type === "required" && (
                                                            <span style={{ color: "red" }}>
                                                                Esse campo é obrigatório
                                                            </span>
                                                        )}

                                                    </Col>
                                                </Row>
                                                <Row gutter={10} >
                                                    <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Endereço"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            error={errors.endereco !== undefined}
                                                            {...register("endereco", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors?.endereco?.type === "required" && (
                                                            <span style={{ color: "red" }}>
                                                                Esse campo é obrigatório
                                                            </span>
                                                        )}

                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Telefone/celular"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            error={errors.phone !== undefined || err !== undefined}
                                                            {...register("phone", { required: true, })}
                                                            onChange={(event) => {
                                                                const { value } = event.target;
                                                                event.target.value = normalizeCardNumber(value);
                                                            }}
                                                        />
                                                        {errors?.phone?.type === "required" && (
                                                            <span style={{ color: "red" }}>
                                                                Esse campo é obrigatório.{" "}
                                                            </span>
                                                        )}
                                                        {err !== undefined && <span style={{ color: "red" }}>{err}</span>}


                                                    </Col>
                                                </Row>

                                                <Row gutter={10} >
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Email"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            type="email"
                                                            error={errors.email !== undefined}
                                                            {...register("email", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors?.email?.type === "required" && (
                                                            <span style={{ color: "red" }}>
                                                                Esse campo é obrigatório
                                                            </span>
                                                        )}
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                                                        <DatePicker size="large" required style={{ width: "100%" }} status={dtNasc === undefined ? "error" : null} placeholder="Data de Nascimento" format={"DD/MM/YYYY"} onChange={(e) => { setDtNasc(e) }} />
                                                        {dtNasc === undefined && (
                                                            <span style={{ color: "red" }}>
                                                                Esse campo é obrigatório
                                                            </span>
                                                        )}



                                                        {/* <TextField
                                                        fullWidth
                                                        label="Data de Nascimento"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        error={errors.email !== undefined}
                                                        {...register("email", {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors?.email?.type === "required" && (
                                                        <span style={{ color: "red" }}>
                                                            Esse campo é obrigatório
                                                        </span>
                                                    )} */}
                                                    </Col>
                                                </Row>
                                            </Panel>
                                            <Panel header="Educação" key="2">
                                                <Row gutter={10}>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label" size="small">Escolaridade</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Escolaridade"
                                                                size="small"
                                                                defaultValue={"Fundamental Incompleto"}
                                                                onClick={(e) => {
                                                                    setEducacao(e.target.innerText)
                                                                }}
                                                                {...register("escolaridade", {
                                                                    required: true,
                                                                })}
                                                            >
                                                                <MenuItem value={"Fundamental Incompleto"}>Fundamental Incompleto</MenuItem>
                                                                <MenuItem value={"Fundamental Completo"}>Fundamental Completo</MenuItem>
                                                                <MenuItem value={"Médio Incompleto"}>Médio Incompleto</MenuItem>
                                                                <MenuItem value={"Médio Completo"}>Médio Completo</MenuItem>
                                                                <MenuItem value={"Superior Incompleto"}>Superior Incompleto</MenuItem>
                                                                <MenuItem value={"Superior Completo"}>Superior Completo</MenuItem>
                                                                <MenuItem value={"Pós-graduação Completa"}>Pós-graduação Completa</MenuItem>
                                                                <MenuItem value={"Mestrado Completa"}>Mestrado Completa</MenuItem>
                                                                <MenuItem value={"Doutorado Completa"}>Doutorado Completa</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    {["Superior Incompleto", "Superior Completo", "Pós-graduação Completa", "Mestrado Completa", "Doutorado Completa",].includes(educacao) && (
                                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                                                            <TextField
                                                                fullWidth
                                                                label="Curso"
                                                                id="outlined-size-small"
                                                                size="small"
                                                                error={errors.curso !== undefined}
                                                                {...register("curso", {
                                                                    required: true,
                                                                })}
                                                            />
                                                            {errors?.curso?.type === "required" && (
                                                                <span style={{ color: "red" }}>
                                                                    Esse campo é obrigatório
                                                                </span>
                                                            )}
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Panel>
                                            <Panel header={`Experiência Profissional ${experiencia.length}/4`} key="3">
                                                <Row gutter={10}>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                                                        <Button
                                                            variant={"outlined"}
                                                            onClick={() => {
                                                                if (experiencia.length <= 3) {
                                                                    setExperiencia([
                                                                        ...experiencia,
                                                                        <ExperienciaProfissional getValue={allExperiencia[experiencia.length]} />
                                                                    ])
                                                                }
                                                                setCheckedExp(false)
                                                            }}
                                                            startIcon={<AddIcon />}
                                                            size="small"
                                                        >
                                                            Adicionar experiência profissional
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 20 }}>
                                                        <FormControlLabel
                                                            control={<Checkbox size="small"
                                                                checked={checkedExp}
                                                                onChange={() => {
                                                                    setExperiencia([])
                                                                    handleChange()
                                                                    setExperiencia1(null)
                                                                    setExperiencia2(null)
                                                                    setExperiencia3(null)
                                                                    setExperiencia4(null)
                                                                }} />}
                                                            label="Não possuo experiencia profissional"
                                                        />
                                                    </Col>
                                                </Row>

                                                {experiencia?.map((item) => {
                                                    return item
                                                })}

                                            </Panel>
                                            <Panel header="Informações adicionais" key="4">

                                                <Row gutter={10}>
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginBottom: 20 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label" size="small">Disponibilidade</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Disponibilidade"
                                                                size="small"
                                                                defaultValue={"manhã e tarde"}
                                                                {...register("disponibilidade")}
                                                            >
                                                                <MenuItem value={"manhã"}>Manhã</MenuItem>
                                                                <MenuItem value={"tarde"}>Tarde</MenuItem>
                                                                <MenuItem value={"noite"}>Noite</MenuItem>
                                                                <MenuItem value={"madrugada"}>Madrugada</MenuItem>
                                                                <MenuItem value={"manhã e tarde"}>Manhã e tarde</MenuItem>
                                                                <MenuItem value={"tarde e noite"}>Tarde e noite</MenuItem>
                                                                <MenuItem value={"noite e madrugada"}>Noite e madrugada</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginBottom: 20 }}>
                                                        <center>
                                                            <FormControlLabel
                                                                control={<Checkbox size="small" checked={checkedDef} onChange={() => { setCheckedDef(!checkedDef) }} />}
                                                                label="Pessoa com deficiência"
                                                            />
                                                        </center>
                                                    </Col>
                                                    {checkedDef === true && (
                                                        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ marginBottom: 20 }}>
                                                            <TextField
                                                                fullWidth
                                                                label="Descreva sua condição"
                                                                id="outlined-size-small"
                                                                size="small"
                                                                error={errors.descDef !== undefined}

                                                                {...register("descDef", {
                                                                    required: true,
                                                                })}
                                                            />
                                                            {errors?.descDef?.type === "required" && (
                                                                <span style={{ color: "red" }}>
                                                                    Esse campo é obrigatório
                                                                </span>
                                                            )}
                                                        </Col>
                                                    )}
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <TextField
                                                            fullWidth
                                                            label="Informações adicionais, não é obrigatório o preenchimento"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            multiline
                                                            rows={4}
                                                            {...register("infoAdd", {
                                                            })}

                                                        />
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>

                                        <Button
                                            type="submit"
                                            style={{ marginTop: 20 }}
                                            variant={"contained"}
                                            size="small"
                                            onClick={() => {
                                                console.log(experiencia1, experiencia2, experiencia3, experiencia4)
                                            }}
                                        >
                                            Enviar
                                        </Button>
                                    </form>
                                </Card>
                            </Col>

                        </Row>
                    </Scrollbars>
                </Row >
            </ConfigProvider>
        </>
    );
}

export default TrabalheConosco;