import { Card, Col, Divider, Row, Statistic, Badge, Descriptions } from "antd";
import { ExternalURL } from "../../../../services/http/ExternalURL";
import { useEffect, useState } from "react";
import LoadingImage from "../../../../component/loadingImage";
import { Button } from "@mui/material";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { icon } from "leaflet";
import PngIcon from "./../../../../assets/img/placeholder.png";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "./../../../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import LeafletRuler from "../../../../component/rulerMetric";
import MapPrint from "../../../../component/mapPrint";
import CardDark from "../../../../component/cardDark";

const ICON = icon({
  iconUrl: PngIcon,
  iconSize: [32, 32],
});

const SearchField = ({ apiKey }) => {
  const provider = new OpenStreetMapProvider();

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    searchLabel: "Digite o endereço",
    animateZoom: true,
    marker: {
      icon: ICON,
      draggable: false,
    },
  });

  const map = useMap();
  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

function WhatsIsIp() {
  const [ip, setIp] = useState();
  const [load, setLoad] = useState(true);
  const [moreInfo, setMoreInfo] = useState(false);
  const [allInfo, setAllInfo] = useState();

  const buscarIp = async () => {
    const { http } = ExternalURL();

    http
      .get("http://ip-api.com/json/")
      .then((res) => {
        if (res.status) {
          buscarIpCompleto(res.data);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const buscarIpCompleto = async (data) => {
    const { http } = ExternalURL();

    http
      .get(
        `http://ip-api.com/json/${data.query}?fields=status,message,country,countryCode,region,regionName,city,lat,lon,isp,org,as,asname,reverse,query`
      )
      .then((res) => {
        if (res.status) {
          console.log(res.data);
          let resposta = res.data;
          let asSlitado = res.data.as.split(" ");
          resposta.as = asSlitado[0];
          setIp(resposta);
          setLoad(false);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    if (ip === undefined) {
      buscarIp();
    }
  }, []);

  return (
    <>
      {load === true && <LoadingImage></LoadingImage>}
      {load === false && (
        <CardDark title="Detalhamento do Ip">
          <Row justify={"center"} style={{ marginBottom: 10 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card style={{ backgroundColor: "#002140" }}>
                <Statistic
                  title={<font color="#fff">Seu ip</font>}
                  value={ip.query}
                  valueStyle={{ color: "#fff" }}
                  style={{ color: "#fff" }}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {moreInfo === false && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setMoreInfo(true);
                  }}
                  style={{ marginTop: 10 }}
                >
                  Mais informações
                </Button>
              )}
            </Col>
          </Row>
          <Row justify={"center"} style={{ marginBottom: 20 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {moreInfo === true && (
                <>
                  <Descriptions
                    title="Detalhes"
                    bordered
                    labelStyle={{ backgroundColor: "#002140", color: "#fff" }}
                  >
                    <Descriptions.Item label="Reverse DNS">
                      {ip.reverse}
                    </Descriptions.Item>
                    <Descriptions.Item label="ISP Name">
                      {ip.isp}
                    </Descriptions.Item>
                    <Descriptions.Item label="AS Name">
                      {ip.asname}
                    </Descriptions.Item>
                    <Descriptions.Item label="AS">
                      <a
                        href={`http://bgp.he.net/${ip.as}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ip.as}
                      </a>
                    </Descriptions.Item>

                    <Descriptions.Item label="Org">{ip.org}</Descriptions.Item>
                    <Descriptions.Item label="Country">
                      {ip.country}
                    </Descriptions.Item>
                    <Descriptions.Item label="Region">
                      {ip.regionName}
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {ip.city}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Status">
                     <Badge status="processing" text="Running" />
                   </Descriptions.Item> */}
                  </Descriptions>

                  {console.log(ip)}
                  <MapContainer
                    center={[ip.lat, ip.lon]}
                    zoom={13}
                    scrollWheelZoom={true}
                    style={{
                      width: "100%",
                      marginTop: 20,
                      height: "calc(100vh - 4rem)",
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[ip.lat, ip.lon]} icon={ICON}>
                      <Popup>
                        lat: {ip.lat} lng: {ip.lon}
                      </Popup>
                    </Marker>
                    <SearchField />
                    <LeafletRuler />
                    <MapPrint
                      position="topleft"
                      sizeModes={["Current", "A4Portrait", "A4Landscape"]}
                      hideControlContainer={false}
                      title="Print"
                    />
                    <MapPrint
                      position="topleft"
                      sizeModes={["Current", "A4Portrait", "A4Landscape"]}
                      hideControlContainer={false}
                      title="Export as PNG"
                      exportOnly
                    />
                  </MapContainer>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}></Col>
          </Row>
        </CardDark>
      )}
    </>
  );
}

export default WhatsIsIp;
