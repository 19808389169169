import { Card, Col, Image, Row } from "antd";
import logo from "./../../../assets/logo/Logo Completo.png";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExternalAPI } from "../../../services/http/ExternalAPI";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import background from "./../../../assets/img-back-external/01.jpg";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { icon } from "leaflet";
import PngIcon from "./../../../assets/img/placeholder.png";

const ICON = icon({
  iconUrl: PngIcon,
  iconSize: [32, 32],
});

function ConfirmarLocalizacao() {
  const {
    register,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onChange" });
  const navigate = useNavigate();
  const [err, setErr] = useState();
  const [center] = useState({ lat: -15.9028, lng: -47.776 });
  const [setMap] = useState(null)
  const markerRef = useRef(null)

  const onSubmit = (data) => {
    if (err === undefined) {
      guardarLocalizacaoCliente(data);
    }
  };

  const guardarLocalizacaoCliente = (data) => {
    const { http } = ExternalAPI();

    console.log(markerRef.current.getLatLng())

    http
      .post(`/viabilidade-getlocation`, {
        lat: markerRef.current.getLatLng().lat,
        lng: markerRef.current.getLatLng().lng,
        phone: data.phone,
      })
      .then((res) => {
        if (res.status) {
          navigate("/external/redirecionar-pagina");
        }
      });
  };

  const normalizeCardNumber = (value) => {
    const ocorrencias = (value.match(/[0-9]/g) || []).length;

    if (ocorrencias !== 11) {
      setErr("Telefone deve conter 11 números");
    } else {
      setErr(undefined);
    }

    return value
      .replace(/\D+/g, "")
      .replace(/^([0-9]{2})?([0-9]{5})?([0-9]{4})$/, "($1) $2-$3");
  };

  return (
    <>
      <Row
        justify="center"
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: 30,
        }}
      >
        <Col xs={20} sm={22} md={18} lg={18}>
          <Card
            style={{
              marginTop: 30,
              marginBottom: 20,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
              background: "rgba( 255, 255, 255, 0.8 )",
              boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
              backdropFilter: "blur( 1.5px )",
              border: "1px solid rgba( 255, 255, 255, 0.18 )",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <center>
                <Image src={logo} preview={false} width={180}></Image>
              </center>

              <h4>
                Informe seu número de telefone para que possamos contata-lo
              </h4>

              <TextField
                fullWidth
                id="outlined-basic"
                label="Número Telefone"
                variant="outlined"
                size="small"
                {...register("phone")}
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = normalizeCardNumber(value);
                }}
              ></TextField>
              {err !== undefined && <span style={{ color: "red" }}>{err}</span>}

              <h4>
                Para ter mais precisão nas informações, utilize o mapa para
                informar sua localização
              </h4>

              {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_KEY_GOOGLE}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={loc}
                  zoom={18}
                  options={{ disableDefaultUI: true, mapTypeId: "satellite" }}
                >
                  <Marker
                    position={loc}
                    draggable={true}
                    onDragEnd={(e) => {
                      setLocClient({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      });
                      setLoc({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    }}
                  />
                </GoogleMap>
              </LoadScript> */}

              <MapContainer
                center={center}
                zoom={17}
                ref={setMap}
                scrollWheelZoom={true}
                style={{
                  width: "100%",
                  marginTop: 20,
                  height: "calc(100vh - 4rem)",
                  marginBottom: 20
                }}
              >
                <TileLayer
                  attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />

                {/* {allViabilidade.map((i, index) => {

                  return <Marker position={[i.lat, i.lng]} icon={ICON} key={index}>
                    <Popup>
                      <strong>{i.number_phone}<br /></strong>
                      Responsável: {i.responsible}<br />
                    </Popup>
                  </Marker>
                })} */}
                <Marker position={[-15.9028, -47.776]} icon={ICON} draggable={true} ref={markerRef}>
                </Marker>

              </MapContainer>

              <Button
                type="submit"
                variant="contained"
                size="small"
              // onClick={() => {
              //     navigate("/external/redirecionar-pagina");
              //     guardarLocalizacaoCliente(loc)
              // }}
              >
                Enviar localização
              </Button>
            </form>
          </Card>
        </Col>
      </Row >
    </>
  );
}

export default ConfirmarLocalizacao;
