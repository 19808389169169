import Web from "./web";
import Mobile from "./mobile";

const itemsGerenciarPermissoes = [
    {
        key: 'gerenciarpermissoesweb',
        label: `Gerenciar Permissoes Web`,
        children: <Web></Web>,
    },
    {
        key: 'gerenciarpermissoesmobile',
        label: `Gerenciar Permissoes Mobile`,
        children: <Mobile ></Mobile>,
    },
];

export default itemsGerenciarPermissoes;