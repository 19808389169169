import { Button } from "@mui/material";
import { Card, Modal, Table, Tag, Transfer } from "antd";
import { useEffect, useState } from "react";
import { message } from "antd";
import { GetAPI } from "../../../../../services/http/GetAPI";
import LoadingImage from "../../../../../component/loadingImage";
import { DeleteAPI } from "../../../../../services/http/DeleteAPI";
import { PutAPI } from "../../../../../services/http/PutAPI";
import CardDark from "../../../../../component/cardDark";

function GerenciarSetorMobile() {

    const dataInfo2 = [];
    const [messageApi, contextHolder] = message.useMessage();
    const [allPermMobile, setAllPermMobile] = useState()
    const [loading, setLoading] = useState(true);
    const [setoresMobile, setSetoresMobile] = useState();
    const [idModalExcluir, setIdModalExcluir] = useState();
    const [idExcluir, setIdExcluir] = useState();
    const [idEditar, setIdEditar] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [targetKeys, setTargetKeys] = useState();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [mockData2, setMockData2] = useState();
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };


    const showModal = (e) => {

        console.log(e)
        setIdModalExcluir(e.description);
        setIdExcluir(e.id);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        deletarSetor(idExcluir);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalEditar = (e) => {

        let arrayPerm = [];

        allPermMobile.map((element, index) => {
            if ((e.permissions.split(',')).indexOf(`${element.id}`) > -1) {
                arrayPerm.push(element.id);
            }
            return null;
        });

        setTargetKeys(arrayPerm);

        setIdEditar(e.id);
        setIsModalOpenEdit(true);
    };

    const handleOkEditar = () => {
        editarSetor(idEditar);
        setIsModalOpenEdit(false);
    };

    const handleCancelEditar = () => {
        setIsModalOpenEdit(false);
    };


    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const columns2 = [
        {
            title: "Id",
            dataIndex: "id",
            width: 30,
        },
        {
            title: "Descrição",
            dataIndex: "description",
            width: 50,
        },
        {
            title: "Permissões",
            key: "permission",
            width: 250,
            render: (e) => (
                <span>
                    {
                        allPermMobile.map((element, index) => {
                            return (e.permissions.split(',')).indexOf(`${element.id}`) > -1 ? (<Tag key={index}>{element.description_label}</Tag>) : null;
                        })
                    }
                </span >
            ),
        },
        {
            title: "Ações",
            key: "operation",
            width: 50,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModalEditar(e);
                        }}
                        color="success"
                        style={buttonsTable}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModal(e);
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const editarSetor = (e) => {

        const { http } = PutAPI();

        http
            .put(`sector-mobile-edit/${idEditar}`, {
                user_id: idEditar,
                ids_permission: targetKeys,
            })
            .then((res) => {
                if (res.status) {
                    buscarTodasPagesMobile();
                    pegarTodosSetoresMobile();

                    success(res.data)
                } else {
                    error(res.data)
                }
            })
            .catch((erro) => {
                error(erro.response.data)
            });
    };

    const deletarSetor = (e) => {

        const { http } = DeleteAPI();

        http
            .delete(`sector-mobile-delete/${e}`)
            .then((res) => {
                if (res.status) {
                    pegarTodosSetoresMobile();
                    success(res.data)
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodasPagesMobile = async () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall-mobile`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    const mockData2 = res.data.map((item) => ({
                        key: item.id,
                        title: item.name,
                        description: item.name,
                    }));

                    setMockData2(mockData2);
                    setTargetKeys([]);

                    setAllPermMobile(pages)

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const pegarTodosSetoresMobile = async () => {
        const { http } = GetAPI();

        http
            .get(`sector-getall-mobile`)
            .then((res) => {
                if (res.status) {
                    setSetoresMobile(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    if (loading === false) {
        setoresMobile.map((item, index) => {
            dataInfo2.push({
                id: item.id,
                description: item.description,
                key: index,
                permissions: item.ids_permission,
                crud: item.permissions
            });
            return null;
        });
    }

    useEffect(() => {

        if (allPermMobile === undefined) {
            buscarTodasPagesMobile()
        }

        if (setoresMobile === undefined) {
            pegarTodosSetoresMobile()
        }

        if (setoresMobile !== undefined && allPermMobile !== undefined) {
            setLoading(false);
        }


    }, [allPermMobile, setoresMobile, targetKeys, mockData2]);



    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title={"Gerenciar Setor Mobile"}>
                        <Table
                            columns={columns2}
                            dataSource={dataInfo2}
                            scroll={{
                                y: 500,
                            }}
                        />
                    </CardDark>
                </>
            )}

            <Modal
                title="Editar setor"
                open={isModalOpenEdit}
                onOk={handleOkEditar}
                onCancel={handleCancelEditar}
                destroyOnClose={true}
                width={1200}
            >
                <Transfer
                    style={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                        marginTop: 10,
                        marginBottom: 10,
                        marginRight: 10,
                        marginLeft: 10,
                    }}
                    listStyle={{
                        width: 500,
                        height: 400
                    }}
                    dataSource={mockData2}
                    titles={['Permissões', 'Permissões Mobile']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    render={(item) => item.title}
                />
            </Modal>
            <Modal
                title="Excluir Setor"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose={true}
            >
                <p>
                    Realmente deseja excluir o setor:{" "}
                    <strong style={{ color: "red" }}>{idModalExcluir}</strong>
                </p>
            </Modal>
        </>
    );
}

export default GerenciarSetorMobile;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};