
import { Card, Table, Tag, Modal, Transfer } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { DeleteAPI } from "./../../../../../services/http/DeleteAPI";
import { PutAPI } from "./../../../../../services/http/PutAPI";
import { Button, useMediaQuery } from "@mui/material";
import * as React from "react";
import { message } from "antd";
import LoadingImage from "./../../../../../component/loadingImage";
import TransferListEdit from "./../../../../../component/formComponents/transferListEdit";
import CardDark from "../../../../../component/cardDark";

function GerenciarPermissoesMobile() {

    const [permissionUser, setPermissionUser] = useState();
    const [permission, setPermission] = useState();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [idPermUser, setIdPermUser] = useState();
    const [idUserClick, setUSerIdClick] = useState();
    const matches = useMediaQuery("(max-width:922px)");
    const [permArray, setPermArray] = useState()
    const [permId, setPermId] = useState();

    const [messageApi, contextHolder] = message.useMessage();

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (e) => {
        let idPermissaoUSer = [];
        let idCrudUser = []

        permissionUser.map((i) => {
            if (e.id === Number(i.user_id)) {
                idPermissaoUSer.push(Number(i.page_id));
            }

            if (e.id === Number(i.user_id)) {
                idCrudUser.push(i.permissions);
            }
        });

        setPermArray(idCrudUser)
        setIdPermUser(idPermissaoUSer);
        setUSerIdClick(e.id);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        salvarEdicaoPermissoes();
        setIdPermUser(undefined)
        setPermId(undefined)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIdPermUser(undefined)
        setPermId(undefined)
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            width: 30,
        },
        {
            title: "Usuário",
            dataIndex: "user",
            width: 40,
        },
        {
            title: "Permissões",
            key: "permission",
            width: 250,
            render: (e) => (
                <span>
                    {permissionUser.map((item, index) => {
                        if (e.id === Number(item.user_id)) {
                            {
                                return (
                                    <Tag key={index}>
                                        {permission.map((element) => {
                                            if (Number(item.page_id) === element.id) {
                                                return element.description_label;
                                            }
                                        })}
                                    </Tag>
                                );
                            }
                        }
                    })}
                </span>
            ),
        },
        {
            title: "Ações",
            key: "operation",
            width: 50,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModal(e);
                        }}
                        color="success"
                        style={buttonsTable}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            deletarTodasPermissoesId(e);
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const dataInfo = [];

    const salvarEdicaoPermissoes = () => {
        const { http } = PutAPI();

        http
            .put(`user-permission-edit/${idUserClick}`, {
                user_id: idUserClick,
                permissions: idPermUser,
            })
            .then((res) => {
                if (res.status) {
                    buscarTodasPermissoesUsuarios();
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodasPermissoesUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-pageall`)
            .then((res) => {
                if (res.status) {
                    setPermissionUser(res.data);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodasPermissoes = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    setPermission(pages);

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const deletarTodasPermissoesId = (e) => {
        const { http } = DeleteAPI();

        http
            .delete(`user-permission-deleteall/${e.id}`)
            .then((res) => {
                if (res.status) {
                    buscarTodasPermissoesUsuarios();
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    useEffect(() => {
        if (permissionUser === undefined) {
            buscarTodasPermissoesUsuarios();
        }
        if (user === undefined) {
            buscarTodosUsuarios();
        }
        if (permission === undefined) {
            buscarTodasPermissoes();
        }

        if (permissionUser !== undefined && user !== undefined && permission !== undefined) {
            setLoading(false);
        }


    }, [permissionUser, user, idPermUser]);

    if (loading === false) {
        user.map((item, index) => {
            dataInfo.push({
                id: item.id,
                user: item.name,
                key: index,
            });
        });
    }

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Gerenciar Permissões" style={{ marginBottom: 20 }}>
                        <Table
                            columns={columns}
                            dataSource={dataInfo}
                            scroll={{
                                y: 500,
                            }}
                        />
                    </CardDark>
                    <Modal
                        title="Editar Permissões"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        destroyOnClose={true}
                        width={1200}
                        style={{ marginBottom: 20 }}
                    >
                        <TransferListEdit
                            labelLeft={"Permissões"}
                            labelRight={"Permissões Usuário"}
                            itensLeft={permission}
                            itensRight={idPermUser}
                            setItemsList={setIdPermUser}
                            itemsList={idPermUser}
                            permArray={permArray}
                            setPermArray={setPermArray}
                        ></TransferListEdit>

                    </Modal>
                </>
            )}
        </>
    );
}

export default GerenciarPermissoesMobile;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};