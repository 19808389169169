import { Card, Col, Row, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import BarChart from "../../../component/charts/bars";
import AreaChart from "../../../component/charts/area";
import LineChart from "../../../component/charts/line";
import PieChart from "../../../component/charts/pie";
import DoughnutChart from "../../../component/charts/doughnut";
import StackedBar from "../../../component/charts/stackedBar";
import { LikeOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
const { Countdown } = Statistic;

const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Dayjs is also OK

const onFinish = () => {
  console.log("finished!");
};
const onChange = (val) => {
  if (typeof val === "number" && 4.95 * 1000 < val && val < 5 * 1000) {
    console.log("changed!");
  }
};
const formatter = (value) => <CountUp end={value} separator="," />

function Dashboard() {
  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Active Users"
              value={112893}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Countdown
              title="Countdown"
              value={Date.now() + 10 * 1000}
              onChange={onChange}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card bordered={false}>
            <Countdown
              title="Million Seconds"
              value={deadline}
              format="HH:mm:ss:SSS"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Countdown title="Countdown" value={deadline} onFinish={onFinish} />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Feedback"
              value={1128}
              prefix={<LikeOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic title="Unmerged" value={93} suffix="/ 100" />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Active"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Idle"
              value={9.3}
              precision={2}
              valueStyle={{
                color: "#cf1322",
              }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={12}>
          <Card bordered={false}>
            <BarChart></BarChart>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false}>
            <AreaChart></AreaChart>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={12}>
          <Card bordered={false}>
            <LineChart></LineChart>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false}>
            <StackedBar></StackedBar>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={6}>
          <Card bordered={false}>
            <DoughnutChart></DoughnutChart>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <PieChart></PieChart>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
