import { Card } from "antd";
import { backgroundColorPrimary, textColorPrimary } from "../../consts/colors";

function CardDark(props) {
    return (
        <Card
            headStyle={{ backgroundColor: backgroundColorPrimary, color: "#fff" }}
            title={props.title.toUpperCase()}
            style={{
                paddingTop: 0,
                paddingBottom: 0,
                marginBottom: 20
            }} extra={props.addComponentHeader}>
            {props.children}
        </Card>
    );
}

export default CardDark;