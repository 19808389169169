import React, { useEffect, useState } from "react";
import UserMenuDropDown from "../dropdownMenu/user";
import UserNotifyDropDown from "../dropdownMenu/notify";
import { Badge, Tag, Image } from "antd";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { faCloudShowersWater } from "@fortawesome/free-solid-svg-icons";
import { ExternalURL } from "../../services/http/ExternalURL";
import { TypeAnimation } from "react-type-animation";
import Ticker from "react-ticker"

function HeaderLayout() {
  const [temp, setTemp] = useState();
  const [dolar, setDolar] = useState();
  const [notice, setNotice] = useState();
  const [loading, setLoading] = useState(true);


  const getNotice = async () => {

    const { http } = ExternalURL();

    await http
      .get(
        `https://servicodados.ibge.gov.br/api/v3/noticias`
      )
      .then((res) => {
        if (res.status) {

          let notices = []

          for (const item of res.data.items) {
            notices.push(item.titulo)
          }

          setNotice(notices);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });

  }

  const getClima = async () => {
    const { http } = ExternalURL();

    await http
      .get(
        `https://api.openweathermap.org/data/2.5/weather?units=metric&lang=pt_br&lat=-15,9028&lon=-47,776&appid=${process.env.REACT_APP_KEY_WEATHER}`
      )
      .then((res) => {
        if (res.status) {
          setTemp(res.data);

        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const getDolar = async () => {
    const { http } = ExternalURL();

    await http
      .get(`https://economia.awesomeapi.com.br/last/USD-BRL`)
      .then((res) => {
        if (res.status) {
          setDolar(res.data);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    if (dolar === undefined) {
      getDolar();
    }
    if (temp === undefined) {
      getClima();
    }

    if (notice === undefined) {
      getNotice()
    }

    if (temp === undefined && dolar === undefined && notice === undefined) {
      setLoading(false);
    }
  }, [dolar, temp, notice]);

  return (
    <div
      style={{
        float: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 15,
      }}
    >
      {loading === false && (
        <>
          {notice !== undefined && (
            <Tag style={{
              color: "white",
              marginRight: 20,
              fontSize: 11,
              padding: 5,
              width: '40vw',
              fontFamily: "arial",
            }}>
              <Ticker speed={1}>
                {({ index }) => (
                  <>
                    {notice.map((item, indice) => {
                      return (
                        <span key={indice} >
                          <span style={{ fontFamily: "IBM Plex Sans Condensed, sans-serif", color: "lightgreen", fontWeight: "bold" }} >
                            {item}{" - - - "}
                          </span>
                        </span>
                      )
                    })}
                  </>
                )}
              </Ticker>
            </Tag>
          )}
          {/* <Tag style={{
            color: "white",
            marginRight: 20,
            fontSize: 7,
            padding: 5,
            width: 400,
            fontFamily: "arial",
          }}>
            <TypeAnimation
              sequence={[
                "Teste para notícias",
                5000,
              ]}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
              style={{ fontSize: "2em", marginRight: 30 }}
            />
          </Tag> */}
          {dolar?.USDBRL?.ask === undefined ? <></> : (
            <Tag
              style={{
                color: "white",
                marginRight: 20,
                fontSize: 13,
                padding: 5,
                fontFamily: "arial",
              }}
            >
              <strong>
                USD{" / "}BRL = {Number(dolar.USDBRL.ask).toFixed(2)}
              </strong>
            </Tag>
          )}

          {temp === undefined ? <></> : (
            <Tag style={{ color: "white", marginRight: 20 }}>
              <>
                <Image
                  src={`https://openweathermap.org/img/wn/${temp.weather[0].icon}.png`}
                  width={30}
                  preview={false}
                ></Image>{" "}
                {temp.main.temp.toFixed(0)}°C {temp.weather[0].description}{" "}
              </>
            </Tag>
          )}

        </>
      )
      }
      <UserNotifyDropDown></UserNotifyDropDown>
      <UserMenuDropDown></UserMenuDropDown>
    </div >
  );
}

export default HeaderLayout;
