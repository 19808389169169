import CadastrarFuncionariosComissao from "./cadastrarFunc";
import CadastrarTipoFuncionario from "./cadastrarTipoFuncionario";
import Config from "./config";
import GraficosComissao from "./graficos";
import Individual from "./individual";
import Todos from "./todos";



const itemsComissaoVendas = [
    {
        key: 'graficoComissao',
        label: `Graficos da Planilha de Comissao`,
        children: <GraficosComissao></GraficosComissao>,
    },
    {
        key: 'planilhacomissaoindividual',
        label: `Planilha de Comissão Individual`,
        children: <Individual ></Individual>,
    },
    {
        key: 'planilhacomissaotodos',
        label: `Planilha de Comissão Todos`,
        children: <Todos ></Todos>,
    },
    {
        key: 'cadastrarfuncionariocomissao',
        label: `Cadastrar Funcionario Comissao`,
        children: <CadastrarFuncionariosComissao></CadastrarFuncionariosComissao>,
    },
    {
        key: 'cadastrarTipoFuncionario',
        label: `Cadastrar Tipo Funcionario`,
        children: <CadastrarTipoFuncionario></CadastrarTipoFuncionario>,
    },
    {
        key: 'configuracaoplanilhacomissao',
        label: `Configurações da Planilha`,
        children: <Config></Config>,
    },
];


export default itemsComissaoVendas;