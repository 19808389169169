import { Card, Col, Image, Row, Tag } from "antd";
import Scrollbars from "react-custom-scrollbars";
import background from "../../../../services/randomImagesBackground";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../../../../assets/logo/Logo Completo.png";

function AgradecimentoTrabalheConosco() {

    const [count, setCount] = useState(15);
    const [redirect, setRedirect] = useState();
    const navigate = useNavigate();

    const tick = () => {
        setCount(count - 1);
    };

    useEffect(() => {
        if (count > 0) {
            setTimeout(tick, 1000);
        } else {
            window.location.href = "https://www.viaradnet.com.br";
        }
    }, [count]);


    return (
        <>
            <Row
                style={{
                    backgroundImage: `url(${background})`,
                    height: "100vh",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    filter: "blur(2.5px)",
                }}
            >
            </Row>
            <Row
                gutter={12}
                style={{
                    position: "absolute",
                    top: "5vh",
                    left: "5vw",
                    width: "90vw",
                    height: "90vh",
                    margin: 0,
                    padding: 0,
                    overflow: "auto"
                }}
                justify={"center"}
            >
                <Scrollbars>
                    <Row justify={"center"}>

                        <Col span={22}>
                            <Card
                                style={{
                                    marginTop: 50,
                                    marginBottom: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                <center>
                                    <Image src={logo} preview={false} width={250} style={{ padding: 20 }}></Image>

                                    <h1 style={{ fontFamily: "Segoe UI Emoji" }}>
                                        Obrigado por cadastrar seu currículo na Radnet Telecom ☺️
                                    </h1>
                                    <h2 style={{ fontFamily: "Segoe UI Emoji" }}>
                                        Você será redirecionado para o site da Radnet Telecom em:{" "}
                                        <Tag style={{ color: "red", fontSize: 20 }}>{count}</Tag>.
                                    </h2>
                                </center>
                            </Card>
                        </Col>
                    </Row>
                </Scrollbars>
            </Row >
        </>
    );
}

export default AgradecimentoTrabalheConosco;