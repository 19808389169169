import React from "react";

import { Button, Result } from 'antd';
import { Link } from "react-router-dom";

function Page403() {


    return (
        <Result
            status="403"
            title="403"
            subTitle="Desculpe, você não está autorizado a acessar essa página."
            extra={
                <Link to={"/"}>
                    <Button type="primary">Voltar para Home</Button>
                </Link>
            }
        />
    )
}

export default Page403;