import { Card, Col, Divider, Image, Popover, Row } from "antd";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { Button, FormControl, TextField } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import user from "./../../../../assets/img/user.png";
import { useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { ChatRequests } from "../../../../services/http/ChatRequests";
import moment from "moment";
import { useForm } from "react-hook-form";

function ChatVenomSocket() {
  const [allMessages, setAllMessages] = useState();
  const [loading, setLoading] = useState(true);
  const [contatoClick, setContatoClick] = useState();
  const [lastMessageUser, setLastMessageUser] = useState();
  const [messageFrom, setMessageFrom] = useState();
  const [alignDown, setAlignDown] = useState(false);
  const [allClients, setAllClients] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  let divDown = useRef(null);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    sendMessage(data);
    reset();
  };

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  const getAllMessages = async () => {
    const { http } = ChatRequests();

    let userMessages = [];

    http
      .get(`venom-all-messages?tel=${process.env.REACT_APP_NUMERO_TEL}`)
      .then((res) => {
        setAllMessages(res.data.res);
        res.data.res.map((item) => {
          let result = userMessages.find((obj) => {
            return obj?.user === item.from;
          });

          if (result === undefined) {
            if (process.env.REACT_APP_NUMERO_TEL !== item.from) {
              userMessages.push({
                user: item.from,
                message: item.message,
                created_at: item.created_at,
              });
            }
          } else {
            const pos = userMessages.map((e) => e.user).indexOf(item.from);
            userMessages[pos].message = item.message;
            userMessages[pos].created_at = item.created_at;
          }
        });
        setLastMessageUser(userMessages);
        setLoading(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const sendMessage = async (data) => {
    const { http } = ChatRequests();

    http
      .post(`venom-send-message`, {
        message: data.message,
        to: contatoClick,
        from: process.env.REACT_APP_NUMERO_TEL,
      })
      .then((res) => {
        console.log(res.data);
        getMessagesFrom(contatoClick);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const getAllClients = async (data) => {
    const { http } = ChatRequests();

    http
      .get(`venom-get-all-clients`)
      .then((res) => {
        console.log(res.data);
        setAllClients(res.data.res);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const getMessagesFrom = async (data) => {
    const { http } = ChatRequests();

    http
      .get(`venom-messages-from?tel=${data}`)
      .then((res) => {
        console.log(res.data);
        setMessageFrom(res.data.res);
        setAlignDown(true);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const getDeviceInfos = async (data) => {
    const { http } = ChatRequests();

    http
      .get(`venom-device-info`)
      .then((res) => {
        console.log(res.data);
        setDeviceInfo(res.data.res);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  useEffect(() => {
    if (allMessages === undefined) {
      getAllMessages();
    }
    if (allClients === undefined) {
      getAllClients(); //fazer carregar as informações dos clientes
    }
    if (deviceInfo === undefined) {
      getDeviceInfos();
    }

    const MINUTE_MS = 5000;

    const interval = setInterval(() => {
      getAllMessages();
      getAllClients();
      getMessagesFrom(contatoClick);
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [messageFrom, lastMessageUser, allClients]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <Card
              style={{
                height: "10vh",
                display: "flex",
                alignItems: "center",
              }}
              bodyStyle={{ padding: "10px", width: "100%" }}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col>
                  {deviceInfo !== undefined && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Image
                        preview={false}
                        src={deviceInfo.eurl}
                        height={36}
                        style={{ borderRadius: 100 }}
                      ></Image>
                      <span style={{ marginLeft: 15 }}>
                        {deviceInfo.displayName}
                      </span>
                    </div>
                  )}
                </Col>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Popover
                    placement="bottomRight"
                    content={content}
                    trigger="click"
                  >
                    <MoreVertIcon></MoreVertIcon>
                  </Popover>
                </Col>
              </Row>
            </Card>
            <Card
              style={{ height: "60vh" }}
              bodyStyle={{ padding: "10px", width: "100%" }}
            >
              <Row>
                <Scrollbars style={{ height: "52vh" }}>
                  {loading === true && <></>}
                  {loading === false && (
                    <>
                      {lastMessageUser.map((item, index) => {
                        return (
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            key={index}
                            style={{
                              marginTop: 7,
                              marginBottom: 7,
                              width: "100%",
                            }}
                            onClick={() => {
                              setContatoClick(item.user);
                              getMessagesFrom(item.user);
                            }}
                          >
                            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                {allClients !== undefined &&
                                  allClients.map((client) => {
                                    if (client.number_phone === item.user) {
                                      return (
                                        <>
                                          <Image
                                            preview={false}
                                            src={client.url_img}
                                            height={44}
                                            style={{ borderRadius: 100 }}
                                          ></Image>
                                        </>
                                      );
                                    }
                                  })}
                              </Col>
                              <Col
                                xs={14}
                                sm={14}
                                md={14}
                                lg={14}
                                xl={14}
                                style={{ paddingLeft: 10 }}
                              >
                                <Row>
                                  <Col>
                                    <span
                                      style={{ fontSize: 15, wordWrap: "wrap" }}
                                    >
                                      {item.message.substring(0, 10)}...
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <span style={{ fontSize: 9 }}>
                                      {moment(item.created_at).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Divider
                              style={{ marginTop: 10, marginBottom: 0 }}
                            ></Divider>
                          </Col>
                        );
                      })}
                    </>
                  )}
                </Scrollbars>
              </Row>
            </Card>
          </Col>
          {messageFrom !== undefined && (
            <Col xs={19} sm={19} md={19} lg={19} xl={19}>
              <Card
                style={{
                  height: "10vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                bodyStyle={{ padding: "10px", width: "100%" }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {allClients.map((item, index) => {
                      if (item.number_phone === contatoClick) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              preview={false}
                              src={item.url_img}
                              height={36}
                              style={{ borderRadius: 100 }}
                            ></Image>
                            <div style={{ paddingLeft: 15 }}>
                              {item.name}
                              <br />
                              {contatoClick}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <SearchIcon></SearchIcon>
                    <Popover
                      placement="bottomRight"
                      content={content}
                      trigger="click"
                    >
                      <MoreVertIcon
                        style={{ marginLeft: "10px" }}
                      ></MoreVertIcon>
                    </Popover>
                  </Col>
                </Row>
              </Card>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card style={{ height: "50vh" }}>
                    <Scrollbars
                      style={{
                        height: "44vh",
                      }}
                      ref={divDown}
                      onUpdate={() => {
                        if (alignDown === true) {
                          divDown.current.scrollToBottom();
                        }
                        setAlignDown(false);
                      }}
                    >
                      {messageFrom.map((item, index) => {
                        return (
                          <>
                            {process.env.REACT_APP_NUMERO_TEL === item.from && (
                              <Row justify={"end"} style={{ marginRight: 20 }}>
                                <Col
                                  style={{
                                    marginBottom: 10,
                                    marginTop: 10,
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 8,
                                    paddingTop: 6,
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                    borderRadius: 5,
                                    justifyContent: "space-between",
                                    maxWidth: "70%",
                                  }}
                                >
                                  <Row justify={"start"}>
                                    <Col>
                                      <span>{item.message}</span>
                                    </Col>
                                  </Row>
                                  <Row justify={"end"}>
                                    <Col>
                                      <sub
                                        style={{
                                          fontSize: 9,
                                          marginLeft: 10,
                                        }}
                                      >
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </sub>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}

                            {process.env.REACT_APP_NUMERO_TEL === item.to && (
                              <Row justify={"start"}>
                                <Col
                                  style={{
                                    marginBottom: 10,
                                    marginTop: 10,
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 8,
                                    paddingTop: 6,
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                    borderRadius: 5,
                                    justifyContent: "space-between",
                                    maxWidth: "70%",
                                  }}
                                >
                                  <Row justify={"start"}>
                                    <Col>
                                      <span>{item.message}</span>
                                    </Col>
                                  </Row>
                                  <Row justify={"end"}>
                                    <Col>
                                      <sub
                                        style={{
                                          fontSize: 9,
                                          marginLeft: 10,
                                        }}
                                      >
                                        {moment(item.created_at).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </sub>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </Scrollbars>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    style={{
                      height: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    bodyStyle={{ padding: "10px", width: "100%" }}
                  >
                    <Row
                      justify={"space-between"}
                      style={{ alignItems: "center" }}
                    >
                      <Col
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignContent: "center",
                        }}
                      >
                        <AttachFileIcon
                          style={{ marginLeft: 15 }}
                        ></AttachFileIcon>
                        <InsertEmoticonIcon
                          style={{ marginLeft: 15 }}
                        ></InsertEmoticonIcon>
                      </Col>
                      <Col xs={14} sm={17} md={16} lg={17} xl={19}>
                        <FormControl fullWidth id="category-editor-form">
                          <TextField
                            fullWidth={true}
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            {...register("message")}
                          />
                        </FormControl>
                      </Col>
                      <Col
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignContent: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          size="small"
                          variant="contained"
                          style={{ marginRight: 15 }}
                        >
                          <SendIcon />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </form>
    </>
  );
}

export default ChatVenomSocket;
