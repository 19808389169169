import Web from "./web";
import Mobile from "./mobile";

const itemsCadastrarPermissoes = [
    {
        key: 'cadastrarpermissoesweb',
        label: `Permissoes Web`,
        children: <Web></Web>,
    },
    {
        key: 'cadastrarpermissoesmobile',
        label: `Permissoes Mobile`,
        children: <Mobile ></Mobile>,
    },
];

export default itemsCadastrarPermissoes;