import React, { useEffect, useContext } from "react";
import Login from "./view/login";
import "./App.css";
import { loadState, saveState } from "./services/localStorage/";
import ExternalPage from "./view/externalPages";
import InternalPage from "./view/internalPages";
import { AuthContext } from "./context";
import { GetCsrfAPI } from "./services/http/GetCsrfAPI";

function App() {
  const { isExternal, isLogged, setIsLogged } = useContext(AuthContext);

  const isToExternalPage = () => {
    if (
      String(window.location.href).indexOf(
        window.location.origin + "/external"
      ) !== -1
    ) {
      return true;
    }

    return false;
  };

  const getCsrf = () => {
    const { http } = GetCsrfAPI();

    http
      .get("/token-get")
      .then((res) => {
        if (res.status) {
          console.log(res.data);
          saveState("crsf", res.data);
        }
      })
      .catch(function () { });
  };

  useEffect(() => {
    let statusLogin = loadState("user");
    let crsf = loadState("crsf");

    if (statusLogin !== undefined) {
      setIsLogged(true);
    }

    if (crsf === undefined) {
      getCsrf();
    }
  }, [isExternal]);

  return (
    <div>
      {isToExternalPage() === true && <ExternalPage />}
      {isLogged === true && isToExternalPage() === false && <InternalPage />}
      {isLogged === false && isToExternalPage() === false && <Login />}
    </div>
  );
}

export default App;
