import { LoadingOutlined } from "@ant-design/icons";
import { Card } from "antd";
const Loading = () => {
  return (
    <div>
      <Card>
        <center>
          <strong>Carregando...</strong>
        </center>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "40px",
            color: "blue",
            paddingTop: 20,
          }}
        >
          <LoadingOutlined />
        </div>
      </Card>
    </div>
  );
};

export default Loading;
