import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LinkMaterial from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoginAPI } from "./../../services/http/Login";
import { saveState, loadState } from "./../../services/localStorage/";
import useKeypress from "./../../services/hook/keypress";
import background from "../../services/randomImagesBackground";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context";
import { useContext } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import LoadingImage from "../../component/loadingImage";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <LinkMaterial
        color="inherit"
        href="https://www.viaradnet.com.br/"
        target="_blank"
      >
        Radnet Telecom
      </LinkMaterial>{" "}
      {new Date().getFullYear()}
      {"."}{" "}
      Designed by André A. Mesquita
    </Typography>
  );
}

const theme = createTheme();

export default function Login(props) {
  const [remenberMe, setRemenberMe] = useState(true);
  const [remenberMeLogin, setRemenberMeLogin] = useState();
  const [erroLogin, setErroLogin] = useState(false);
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { setIsExternal, setIsLogged } = useContext(AuthContext);

  const [messageApi, contextHolder] = message.useMessage();

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Usuário ou senha inválidos",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    verifyLogin(data.get("email"), data.get("password"));
  };

  useKeypress("Enter", () => {
    verifyLogin();
  });

  const verifyLogin = (email, password) => {
    const { http } = LoginAPI();

    http
      .post("/login", { email: email, password: password })
      .then((res) => {
        if (res.status) {
          saveState("user", { isLogged: true });
          saveState("token", { token: res.data.access_token });
          saveState("company", { company: res.data.user.company_id });
          saveState("session_user", { data: res.data.user });
          saveState("userPages", res.data.userPages)
          setIsLogged(true);
          setErroLogin(false);
          navigate("/");

          if (remenberMe === true) {
            saveState("login", { email: email, password: password });
          } else {
            saveState("login", { email: "", password: "" });
          }
        }
      })
      .catch(function (err) {
        error();
        setErroLogin(true);
      });
  };

  useEffect(() => {
    let loginRemenber = loadState("login");
    if (loginRemenber !== undefined) {
      setRemenberMeLogin(loginRemenber);
    } else {
      setRemenberMeLogin({ email: "", password: "" });
    }

    setLoading(false);
  }, []);

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={5}
              md={8}
              lg={9}
              sx={{
                // backgroundImage: "url(https://source.unsplash.com/random)",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={7}
              md={4}
              lg={3}
              component={Paper}
              elevation={6}
              square
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  my: 10,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Logar
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    error={erroLogin === true ? true : false}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    defaultValue={remenberMeLogin.email}
                    autoComplete="email"
                    autoFocus
                  />
                  {window.csrf_token}
                  <TextField
                    error={erroLogin === true ? true : false}
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={remenberMeLogin.password}
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        defaultChecked
                        color="primary"
                        onClick={() => {
                          setRemenberMe(!remenberMe);
                        }}
                      />
                    }
                    label="Lembre-me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Logar
                  </Button>
                  <Grid container>
                    <Grid item xs justifyContent="space-between">
                      <Link
                        to={`/external/recovery-acess`}
                        onClick={() => {
                          setIsExternal(true);
                        }}
                      >
                        Esqueceu sua senha?
                      </Link>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      )}

    </>
  );
}
