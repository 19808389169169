import { Row, Transfer } from "antd";
import { useEffect, useState } from "react";
import { Checkbox } from 'antd';

const TransferListEdit = ({
    labelLeft,
    labelRight,
    itensLeft,
    itensRight,
    setItemsList,
    itemsList,
    permArray,
    setPermArray
}) => {

    const mockData = itensLeft.map((item) => ({
        key: item.id,
        title: item.description_label,
        description: item.description_label,
    }));

    const plainOptions = ['Criar', 'Editar', 'Excluir'];
    const [targetKeys, setTargetKeys] = useState(itensRight); //item selecionados apenas keys
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [permissions, setPermissions] = useState()
    const [primeiroUso, setPrimeiroUso] = useState()

    const onChange2 = (checkedValues, id) => {

        let perms = []
        let obj = []

        for (const i of checkedValues) {
            if (i === "Criar") {
                perms.push(1)
            }
            if (i === "Editar") {
                perms.push(2)
            }
            if (i === "Excluir") {
                perms.push(3)
            }
        }

        for (const i of itemsList) {

            if (id === i.id) {
                i.perm = perms
            }
        }

        setItemsList(itemsList);
        setPermissions(itemsList)

    };

    const onChange = (nextTargetKeys, direction) => {

        let arrayPerms = []
        let perms = permissions

        for (const i of permissions) {
            arrayPerms.push(i.id)
        }


        for (const [index, i] of permissions.entries()) {

            if (!nextTargetKeys.includes(i.id)) {

                perms.splice(index, 1);

            }
        }

        for (const i of nextTargetKeys) {
            if (!arrayPerms.includes(i)) {
                for (const j of itensLeft) {
                    if (j.id === i) {
                        perms.push({ id: i, description_label: j.description_label, perm: [1, 2, 3] })
                    }
                }
            }
        }

        setPermissions(perms)
        setItemsList(perms)
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    useEffect(() => {

        if (primeiroUso === undefined) {

            let itemsList = []
            let idLabels = []

            console.log(permArray)

            for (const [index, i] of itensRight.entries()) {

                for (const j of itensLeft) {

                    if (i === j.id) {
                        idLabels.push(j.description_label)
                    }
                }
            }

            for (const [index, i] of itensRight.entries()) {

                itemsList.push({
                    id: itensRight[index], description_label: idLabels[index], perm: permArray[index].split(",").map((i) => { return Number(i) })
                })
            }

            setPermissions(itemsList)
            setPrimeiroUso(true)

        }

    }, [permissions])

    return (
        <>
            <Transfer
                style={{
                    flexWrap: "wrap",
                    justifyContent: "center",
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10,
                    marginLeft: 10,
                }}
                listStyle={{
                    width: 500,
                    height: 400
                }}
                dataSource={mockData} //item para selecionar
                titles={[labelLeft, labelRight]}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={(item) => item.title}
            />

            {permissions !== undefined && (
                <>
                    {permissions.map((i, index) => {
                        return (
                            <>
                                {i?.perm && (
                                    <Row style={{ backgroundColor: "lightblue", marginBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }} key={index}>
                                        <span style={{ fontSize: 16, padding: 5, fontWeight: "bold" }}>{i.description_label}</span>
                                        <Checkbox.Group options={plainOptions} defaultValue={[i.perm.includes(1) ? 'Criar' : null, i.perm.includes(2) ? 'Editar' : null, i.perm.includes(3) ? 'Excluir' : null]} onChange={(e) => { onChange2(e, i.id) }} />
                                    </Row>
                                )}
                            </>

                        )
                    })}
                </>
            )}

        </>
    );
};
export default TransferListEdit;
