import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card, Col, Row, Tag } from "antd";
import { Divider, Radio, Table } from 'antd';
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'nome', headerName: 'Nome', width: 200 },
];

function TestePage() {

  const [client, setClient] = useState()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()

  const buscarTodosBackups = async () => {
    const { http } = GetAPI();

    http
      .get(`teste-function`)
      .then((res) => {
        if (res.status) {

          setClient(res.data)
          console.log(res.data)
          setData(res.data)

        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  useEffect(() => {

    if (client === undefined) {
      buscarTodosBackups()
    }
    if (client !== undefined) {
      setLoading(false)
    }

  }, [loading, data])

  return (
    <>
      {loading === true && (
        <h1>Loading</h1>
      )}
      {loading === false && (
        <Card title="Alterar em lote">
          <Row>
            <Col span={24}>

              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="usuario" size="small">
                  Status
                </InputLabel>
                <Select
                  labelId="funcionario"
                  id="funcionario"
                  label="Status"
                  size="small"
                  onChange={(item) => {
                    console.log(item)
                  }}
                >
                  <MenuItem value={"Online"}>Online</MenuItem>
                  <MenuItem value={"Suspenso"}>Suspenso</MenuItem>
                  <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col span={24}>

              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="usuario" size="small">
                  Tag
                </InputLabel>
                <Select
                  labelId="funcionario"
                  id="funcionario"
                  label="Tag"
                  size="small"
                  onChange={(item) => {
                    console.log(item)
                  }}
                >
                  <MenuItem value={"teste"}>teste</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col span={24}>

              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="usuario" size="small">
                  Condição
                </InputLabel>
                <Select
                  labelId="funcionario"
                  id="funcionario"
                  label="Condição"
                  size="small"
                  onChange={(item) => {
                    console.log(item)
                  }}
                >
                  <MenuItem value={"teste"}>teste</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
          <div>

            <DataGrid
              rows={data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 100, 1000]}
              checkboxSelection
            />
          </div>
        </Card>
      )}
    </>
  );
}
export default TestePage;



