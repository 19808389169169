import { Button } from "@mui/material";
import Card from "antd/es/card/Card";
import { GetAPI } from "../../../../services/http/GetAPI"
import { useEffect, useState } from "react";
import { Col, Modal, Row, Table } from "antd";
import { DeleteAPI } from "../../../../services/http/DeleteAPI";
import { useForm } from "react-hook-form";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import DropFileInput from "../../../../component/drop-file-input";
import { UploadAPI } from "../../../../services/http/UploadRequest";

function BackupSistema() {

    const dataInfo = [];
    const [pesqBackup, setPesqBackup] = useState()
    const [backups, setBackups] = useState()
    const [loading, setLoading] = useState(true)
    const { register, handleSubmit } = useForm()
    const [files, setFiles] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const onSubmit = (data) => {
        console.log(data)
    }

    const onFileChange = (files) => {
        setFiles(files)
    }


    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            width: 50,
        },
        {
            title: "Usuário",
            dataIndex: "name",
            key: "name",
            width: 200,
        },
        {
            title: "Ações",
            key: "operation",
            width: 100,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={async () => {
                            await downloadBackup(e)
                        }}
                        color="success"
                        style={buttonsTable}
                    >
                        Download
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={async () => {
                            await deletarBackup(e)

                            await buscarTodosBackups()
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const downloadBackup = async (backup) => {

        const { http } = GetAPI();

        http
            .get(`download-backup/${backup.name.slice(0, 26)}`, {

                responseType: 'blob'
            })
            .then(async (res) => {
                if (res.status) {

                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute('download', "backup.tar.gz")
                    document.body.appendChild(link)
                    link.click();

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    }

    const cratebackup = async () => {

        const { http } = GetAPI();

        http
            .get(`backup-database`)
            .then(async (res) => {
                if (res.status) {

                    await buscarTodosBackups()

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    }

    const deletarBackup = async (backup) => {
        const { http } = DeleteAPI();

        http
            .delete(`delete-backup/${backup.name.slice(0, 26)}`)
            .then((res) => {
                if (res.status) {

                    console.log(res.data)
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosBackups = async () => {
        const { http } = GetAPI();

        http
            .get(`verify-backup`)
            .then((res) => {
                if (res.status) {

                    console.log(res)

                    setBackups(res.data[0])
                    setLoading(false)
                    setPesqBackup(true)
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const criarTicket = (data) => {
        const { http } = UploadAPI();

        let formData = new FormData();

        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("sql[]", files[i]);
            }
        }

        http
            .post("restore-database", formData)
            .then((res) => {
                if (res.status) {
                    console.log(res)

                } else {

                }
            })
            .catch((erro) => {
                console.log(erro)
            });
    };

    useEffect(() => {

        if (pesqBackup === undefined) {
            buscarTodosBackups()
        }

    }, [loading, backups, dataInfo])

    if (loading === false) {

        backups.map((item, index) => {
            dataInfo.push({
                id: index + 1,
                name: `${item}`.slice(2, 35),
                key: index,
            });

            return null;
        });
    }

    return (
        <Card title="backup" extra={
            <>
                <Row justify={"center"} style={{ alignItems: "center" }}>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={async () => {
                            await cratebackup()
                        }}
                        style={buttonsTable}
                    >
                        Fazer backup
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                            showModal()
                        }}
                        style={buttonsTable}
                    >
                        Restaurar banco
                    </Button>
                    <Modal title="Upload Arquivo .sql" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                        footer={[
                            <Button
                                onClick={handleCancel}
                                variant="outlined"
                                size="small"
                                style={{ marginRight: "10px" }}
                            >
                                cancel
                            </Button>,
                            <Button
                                size="small"
                                type="primary"
                                variant="contained"
                                onClick={() => { criarTicket() }}
                            //disabled={buttonDisabled}
                            >
                                Salvar
                            </Button>,
                        ]}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                                <div style={{
                                    backgroundColor: "#fff",
                                    padding: "30px",
                                    borderRadius: "20px",
                                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                                }}>
                                    <DropFileInput
                                        onFileChange={(files) => onFileChange(files)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Modal>

                    {/* <form onSubmit={handleSubmit(onSubmit)}>
                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}  {...register('file')} size="small" >
                            Restaurar banco
                            <VisuallyHiddenInput type="file" />
                        </Button>
                    </form> */}
                </Row>
            </>
        }>
            {loading === true && (<>loading</>)}
            {
                loading === false && (
                    <>

                        <Table
                            columns={columns}
                            dataSource={dataInfo}
                            scroll={{
                                y: 240,
                            }}
                        />
                    </>
                )
            }
        </Card >
    );
}

export default BackupSistema;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};

