import Web from "./web";
import Mobile from "./mobile";

const itemsGerenciarSetor = [
    {
        key: 'setorweb',
        label: `Setor Web`,
        children: <Web></Web>,
    },
    {
        key: 'setormobile',
        label: `Setor Mobile`,
        children: <Mobile ></Mobile>,
    },
];

export default itemsGerenciarSetor;
