import { Card, Col, Divider, Row, Transfer } from "antd";
import { useEffect, useState } from "react";
import TransferList from "./../../../../../component/formComponents/transferList";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { PostAPI } from "./../../../../../services/http/PostAPI";
import { useForm, Controller } from "react-hook-form";
import { message } from "antd";
import { Button, FormControl, TextField } from "@mui/material";
import LoadingImage from "./../../../../../component/loadingImage";
import SetorTextField from "./../../../../../component/setorTextField";
import CardDark from "../../../../../component/cardDark";

function CadastrarSetorMobile() {

    const [loading, setLoading] = useState(true);
    const [allPermMobile, setAllPermMobile] = useState()

    const [targetKeys, setTargetKeys] = useState();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [mockData, setMockData] = useState();
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const [messageApi, contextHolder] = message.useMessage();

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const criarSetorMobile = (data, ids) => {
        const { http } = PostAPI();

        http
            .post(`sector-mobile-create`, {
                description: data.setor,
                ids_permission: ids,
            })
            .then((res) => {
                if (res.status) {
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data.message);
            });
    };

    const buscarTodasPagesMobile = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall-mobile`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    const mockData2 = res.data.map((item) => ({
                        key: item.id,
                        title: item.name,
                        description: item.name,
                    }));

                    setMockData(mockData2);
                    setTargetKeys([]);

                    setAllPermMobile(pages)

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    useEffect(() => {

        if (allPermMobile === undefined) {
            buscarTodasPagesMobile()
        }

        if (allPermMobile !== undefined) {
            setLoading(false);
        }

    }, [allPermMobile]);

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Cadastrar Setor Mobile">
                        <Row justify={"center"} style={{ marginBottom: 10 }}>
                            <Col>
                                <Transfer
                                    style={{
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        marginTop: 10,
                                        marginBottom: 10,
                                        marginRight: 10,
                                        marginLeft: 10,
                                    }}
                                    listStyle={{
                                        width: 500,
                                        height: 400
                                    }}
                                    dataSource={mockData}
                                    titles={['Permissões', 'Permissões Mobile']}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) => item.title}
                                />
                            </Col>
                        </Row>
                        <Row justify={"center"}>
                            <Col xs={20} sm={20} md={20} lg={12} xl={12}>
                                <SetorTextField submitFunction={criarSetorMobile} valores={targetKeys}></SetorTextField>
                            </Col>
                        </Row>

                    </CardDark>
                </>
            )}
        </>
    );
}

export default CadastrarSetorMobile;