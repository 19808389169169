import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Card, Col, Row, Tag } from "antd";
import { useEffect, useState, } from "react";
import { useForm } from "react-hook-form";
import { UploadAPI } from "../../../../services/http/UploadRequest";
import { GetAPI } from "../../../../services/http/GetAPI";
import LoadingImage from "../../../../component/loadingImage";
import DropFileInput from './../../../../component/drop-file-input';
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { notification } from "antd";
import { loadState } from "../../../../services/localStorage";
import CardDark from "../../../../component/cardDark";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import MapPrint from "../../../../component/mapPrint";
import { icon } from "leaflet";
import PngIcon from "./../../../../assets/img/placeholder.png";

const ICON = icon({
  iconUrl: PngIcon,
  iconSize: [32, 32],
});


function CriarTicket() {
  const [setores, setSetores] = useState();
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState();
  const [users, setUsers] = useState();
  const [loc, setLoc] = useState([Number("-15.9028"), Number("-47.776")]);
  const [lat, setLat] = useState("-15.9028")
  const [lng, setLng] = useState("-47.776")
  const [latErro, setLatErro] = useState(false)
  const [lngErro, setLngErro] = useState(false)
  const [disableLatlon, setDisableLatlon] = useState(false)
  const user = loadState("session_user");
  const [center, setCenter] = useState({ lat: -15.9028, lng: -47.776 });
  const [map, setMap] = useState(null)

  const [api, contextHolder] = notification.useNotification();

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const onSubmit = (data) => {
    criarTicket(data)
    reset();
  };

  const criarTicket = (data) => {
    const { http } = UploadAPI();
    const key = process.env.REACT_APP_KEY_FRONTEND;
    let formData = new FormData();

    data['lat'] = loc[0];
    data['lng'] = loc[1];

    data['user_id'] = user.data.id

    if (disableLatlon === true) {
      data['lat'] = "";
      data['lng'] = "";
    }

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images[]", files[i]);
      }
    }

    for (const item in data) {
      if (data.hasOwnProperty(item)) {
        formData.append(`form[${item}]`, data[item]);
      }
    }


    http
      .post("ticket-create", formData)
      .then((res) => {
        if (res.status) {
          console.log(res)
          alertMessage(
            "success",
            "Cadastro Ticket",
            "Ticket cadastrado com sucesso!"
          );
        } else {

        }
      })
      .catch((erro) => {
        alertMessage(
          "error",
          "Cadastro Ticket",
          erro
        );
      });
  };

  const buscarTodosUsuarios = () => {
    const { http } = GetAPI();

    http
      .get(`user-getallmobile`)
      .then((res) => {
        if (res.status) {
          setUsers(res.data);
          console.log(res.data);

        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const pegarTodosSetores = () => {
    const { http } = GetAPI();

    http
      .get("sector-getall")
      .then((res) => {
        if (res.status) {
          setSetores(res.data);
          console.log(res.data);
          //success(res.data)

        } else {
          //error(res.data);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const onFileChange = (files) => {
    setFiles(files)
  }


  const handleValidationLatitudeLongitude = (value) => {

    let arrlatlon = value.split(",")

    const reg1 = new RegExp("^([+-])?(?:90(?:\\.0{1,6})?|((?:|[1-8])[0-9])(?:\\.[0-9]{1,6})?)$");
    if (reg1.test(arrlatlon[0])) {
      setLat(arrlatlon[0])
      setLatErro(false)
    } else {
      setLatErro(true)
    }

    const reg2 = new RegExp("^([+-])?(?:180(?:\\.0{1,6})?|((?:|[1-9]|1[0-7])[0-9])(?:\\.[0-9]{1,6})?)$");

    if (reg2.test(arrlatlon[1])) {
      setLng(arrlatlon[1])
      setLngErro(false)
    } else {
      setLngErro(true)
    }

  };

  useEffect(() => {

    if (setores === undefined) {
      pegarTodosSetores();
    }
    if (users === undefined) {
      buscarTodosUsuarios()
    }
    if (users !== undefined && setores !== undefined) {
      setLoading(false);
    }
  }, [users, setores]);

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <CardDark title="Criar ticket">
          <form onSubmit={handleSubmit(onSubmit)}>


            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="setor" size="small">
                Responsavel
              </InputLabel>
              <Select
                labelId="responsavel"
                id="responsavel"
                label="Responsavel"
                defaultValue={0}
                {...register("responsavel")}
                size="small"
              >
                <MenuItem value={0} key={0}>
                  {"Todos"}
                </MenuItem>
                {users.map((item, key) => (
                  <MenuItem value={item.id} key={key + 1}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <TextField
                id="outlined-basic"
                label="Titulo"
                //defaultValue={"55"}
                variant="outlined"
                size="small"
                {...register("titulo", {
                  required: true,
                })}
              ></TextField>
              {errors?.titulo?.types?.required && (
                <span style={{ color: "red" }}>Campo obrigatório</span>
              )}
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="setor" size="small">
                Setor
              </InputLabel>
              <Select
                labelId="setor"
                id="setor"
                label="Setor"
                defaultValue={setores[0] ? setores[0].description : null}
                {...register("setor")}
                size="small"
              >
                {setores.map((item, key) => (
                  <MenuItem value={item.id} key={key}>
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="prioridade" size="small">
                Prioridade
              </InputLabel>
              <Select
                labelId="prioridade"
                id="prioridade"
                label="Prioridade"
                defaultValue={"baixa"}
                {...register("prioridade")}
                size="small"
              >
                {[{ id: 1, value: "baixa", color: "green" }, { id: 2, value: "normal", color: "blue" }, { id: 3, value: "alta", color: "orange" }, { id: 4, value: "urgente", color: "red" }].map((item, key) => (
                  <MenuItem value={item.value} key={key}>
                    <span style={{ color: item.color }}>{item.value.toLocaleUpperCase()}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <TextField
                id="outlined-basic"
                label="Descrição"
                //defaultValue={"55"}
                variant="outlined"
                multiline
                rows={10}
                size="small"
                {...register("descricao", {
                  required: true,
                })}
              ></TextField>
              {errors?.descricao?.types?.required && (
                <span style={{ color: "red" }}>Campo obrigatório</span>
              )}
            </FormControl>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                <div style={{
                  backgroundColor: "#fff",
                  padding: "30px",
                  borderRadius: "20px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                }}>
                  <DropFileInput
                    onFileChange={(files) => onFileChange(files)}
                  />
                </div>
              </Col>
            </Row>

            <Row justifyContent={"space-evenly"}>

              <Col xs={4} sm={4} md={4} lg={4} xl={4} >

                <FormControlLabel control={
                  <Checkbox onClick={() => { setDisableLatlon(!disableLatlon) }} />
                } label="Desabilitar" />

              </Col>
              <Col xs={17} sm={17} md={17} lg={17} xl={17} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FormControl fullWidth style={{ marginBottom: 20, marginRight: 20 }}>
                  <TextField
                    id="outlined-basic"
                    label="Latitude e longitude"
                    defaultValue={"-15.9028,-47.776"}
                    variant="outlined"
                    disabled={disableLatlon}
                    error={[lngErro, latErro].indexOf(true) !== -1 ? true : false}
                    size="small"
                    onChange={(e) => { handleValidationLatitudeLongitude(e.target.value) }}
                  ></TextField>
                  {(lngErro === true || latErro === true) && (
                    <span style={{ color: "red" }}>Valor inválido</span>
                  )}
                </FormControl>
                {console.log([lngErro, latErro])}
                {console.log([lngErro, latErro].indexOf(true))}
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                  key="submit"
                  variant="contained"
                  onClick={() => {
                    setLoc([Number(lat), Number(lng)])
                    map.setView([Number(lat), Number(lng)], 17)
                  }}
                  size="small"
                  style={{ marginBottom: 20 }}
                >
                  Pesquisar
                </Button>
              </Col>
            </Row>
            <Row>
              {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_KEY_GOOGLE}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={loc}
                  mapTypeControl={true}
                  zoom={17}
                  options={{ disableDefaultUI: false, mapTypeId: "satellite" }}
                >
                  <Marker
                    position={loc}
                    draggable={false}
                  />
                </GoogleMap>
              </LoadScript> */}
              <MapContainer
                center={loc}
                zoom={17}
                ref={setMap}
                scrollWheelZoom={true}
                style={{
                  width: "100%",
                  marginTop: 20,
                  height: "calc(100vh - 4rem)",
                  marginBottom: 20
                }}
              >
                <TileLayer
                  attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />



                <Marker position={loc} icon={ICON} >

                </Marker>

              </MapContainer>
            </Row>

            <Row>
              <Button
                key="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                size="small"
              >
                Salvar
              </Button>
            </Row>
          </form>
        </CardDark >
      )
      }

    </>
  );
}

export default CriarTicket;

const containerStyle = {
  width: "100%",
  height: "600px",
  marginBottom: "20px",
};

const styles = {
  input: {
    "&:invalid": {
      border: "red solid 2px"
    }
  }
};