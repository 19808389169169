import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Col, Modal, Row } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PutAPI } from "../../../../../services/http/PutAPI";

function ModalTodosComissao({
  idModalEditar,
  isModalOpenEditar,
  tipos,
  setIsModalOpenEditar,
  mes,
  ano,
  getAllFuncionarios,
  alertMessage,
  funcEditar,
  allConfigs,
}) {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  console.log(funcEditar);

  const onSubmitModal = (data) => {
    setIsModalOpenEditar(false);
    cadastrarFuncionarioComissao(data);
    reset();
  };
  const handleCancelEditar = (e) => {
    setIsModalOpenEditar(false);
    reset();
  };

  const cadastrarFuncionarioComissao = (data) => {
    const { http } = PutAPI();

    let tipoFuncionario = null;

    let conf_selecionada = null;

    for (const item of allConfigs) {
      if (Number(item.id) === Number(data.config_porc)) {
        conf_selecionada = item;
      }
    }

    let resposta = JSON.parse(conf_selecionada.config);

    let arraya1vendas = [];
    let arraya2vendas = [];
    let arraya1upgrade = [];
    let arraya2upgrade = [];

    resposta.value.vendas[0].map((item) => {
      arraya1vendas.push(item.value);
    });
    resposta.value.vendas[1].map((item) => {
      arraya2vendas.push(item.value);
    });

    resposta.value.upgrade[0].map((item) => {
      arraya1upgrade.push(item.value);
    });
    resposta.value.upgrade[1].map((item) => {
      arraya2upgrade.push(item.value);
    });

    let a1 = arraya1vendas;
    let a2 = arraya2vendas;
    let b1 = arraya1upgrade;
    let b2 = arraya2upgrade;

    for (const item of tipos) {
      if (Number(item.id) === Number(data.tipo_funcionario)) {
        tipoFuncionario = item;
      }
    }

    let labels = [];
    let labelsB = [];
    let porcV = null;
    let porcU = null;

    let valuesVendas = a2.map((item) => {
      return item.slice(0, -1);
    });
    let valuesUpgrade = b2.map((item) => {
      return item.slice(0, -1);
    });

    let receitaVenda = data.venda_realizada * data.valor_venda;
    let receitaUp = data.upgrade_realizado * data.valor_upgrade;
    for (let index = 0; index < a1.length; index++) {
      let temp = a1[index].split("a");

      if (temp.length > 1) {
        labels.push([temp[0].trim().slice(0, -1), temp[1].trim().slice(0, -1)]);
      } else {
        labels.push([temp[0].trim().slice(0, -1)]);
      }
    }
    for (let index = 0; index < b1.length; index++) {
      let temp = b1[index].split("a");

      if (temp.length > 1) {
        labelsB.push([
          temp[0].trim().slice(0, -1),
          temp[1].trim().slice(0, -1),
        ]);
      } else {
        labelsB.push([temp[0].trim().slice(0, -1)]);
      }
    }

    let coberturaV = null;
    let coberturaU = null;
    let valRecV = null;
    let valRecU = null;
    let valRecVFilter = null;
    let valRecUFilter = null;
    let decrescimo = null;
    let total = null;

    if (Number(data.meta_upgrade) !== 0) {
      coberturaV =
        Number(data.venda_realizada) / Number(data.meta_venda) > 0
          ? (Number(data.venda_realizada) / Number(data.meta_venda)) * 100
          : 0;
      coberturaU =
        Number(data.upgrade_realizado) / Number(data.meta_upgrade) > 0
          ? (Number(data.upgrade_realizado) / Number(data.meta_upgrade)) * 100
          : 0;

      valRecV = labels.map((elem, index) => {
        let result = null;

        if (coberturaV >= Number(labels[0][0])) {
          if (elem.length > 1) {
            if (coberturaV >= Number(elem[0]) && coberturaV < Number(elem[1])) {
              result =
                coberturaU >= 100
                  ? receitaVenda * (valuesVendas[index] / 100)
                  : receitaVenda *
                    (valuesVendas[index !== 0 ? index - 1 : 0] / 100);

              porcV =
                coberturaU >= 100
                  ? valuesVendas[index] / 100
                  : valuesVendas[index !== 0 ? index - 1 : 0] / 100;
            }
          } else {
            if (coberturaV >= Number(elem[0])) {
              result =
                coberturaU >= 100
                  ? receitaVenda * (valuesVendas[index] / 100)
                  : receitaVenda *
                    (valuesVendas[index !== 0 ? index - 1 : 0] / 100);
              porcV =
                coberturaU >= 100
                  ? valuesVendas[index] / 100
                  : valuesVendas[index !== 0 ? index - 1 : 0] / 100;
            }
          }
        } else {
          result = 0;
        }

        if (result !== null) {
          return result;
        }
      });
      valRecU = labels.map((elem, index) => {
        let result = null;

        if (coberturaU >= Number(labelsB[0][0])) {
          if (elem.length > 1) {
            if (coberturaU >= Number(elem[0]) && coberturaU < Number(elem[1])) {
              result =
                coberturaV >= 100
                  ? receitaUp * (valuesUpgrade[index] / 100)
                  : receitaUp *
                    (valuesUpgrade[index !== 0 ? index - 1 : 0] / 100);
              porcU =
                coberturaV >= 100
                  ? valuesUpgrade[index] / 100
                  : valuesUpgrade[index !== 0 ? index - 1 : 0] / 100;
            }
          } else {
            if (coberturaU >= Number(elem[0])) {
              result =
                coberturaV >= 100
                  ? receitaUp * (valuesUpgrade[index] / 100)
                  : receitaUp *
                    (valuesUpgrade[index !== 0 ? index - 1 : 0] / 100);
              porcU =
                coberturaV >= 100
                  ? valuesUpgrade[index] / 100
                  : valuesUpgrade[index !== 0 ? index - 1 : 0] / 100;
            }
          }
        } else {
          result = 0;
        }

        if (result !== null) {
          return result;
        }
      });

      valRecVFilter = valRecV.filter((item) => item !== undefined);
      valRecUFilter = valRecU.filter((item) => item !== undefined);
      decrescimo = receitaVenda * (Number(data.decrescimo) / 100);
      total =
        coberturaV >= Number(labels[0][0]) ||
        coberturaU >= Number(labelsB[0][0])
          ? Number(valRecVFilter[0]) +
            Number(valRecUFilter[0]) -
            Number(decrescimo)
          : 0;
    } else {
      coberturaV =
        Number(data.venda_realizada) / Number(data.meta_venda) > 0
          ? (Number(data.venda_realizada) / Number(data.meta_venda)) * 100
          : 0;
      coberturaU = 0;
      valRecV = labels.map((elem, index) => {
        let result = null;

        if (coberturaV >= Number(labels[0][0])) {
          if (elem.length > 1) {
            if (coberturaV >= Number(elem[0]) && coberturaV < Number(elem[1])) {
              result = receitaVenda * (valuesVendas[index] / 100);
              porcV = valuesVendas[index] / 100;
            }
          } else {
            if (coberturaV >= Number(elem[0])) {
              result = receitaVenda * (valuesVendas[index] / 100);
              porcV = valuesVendas[index] / 100;
            }
          }
        } else {
          result = 0;
        }

        if (result !== null) {
          return result;
        }
      });
      valRecU = 0;
      porcU = 0;
      valRecVFilter = valRecV.filter((item) => item !== undefined);
      valRecUFilter = 0;
      decrescimo = receitaVenda * (Number(data.decrescimo) / 100);
      total =
        coberturaV >= Number(labels[0][0])
          ? Number(valRecVFilter[0]) + valRecUFilter - Number(decrescimo)
          : 0;
    }

    http
      .put(`comissao-editar-funcionario/${idModalEditar}`, {
        nome: data.nome,
        mes: mes,
        ano: ano,
        id_tipo_func_comissao: tipoFuncionario.id,
        id_config: conf_selecionada.id,
        decrescimo: data.decrescimo,
        venda_realizada: data.venda_realizada,
        upgrade_realizada: data.upgrade_realizado,
        porc_venda_ating: porcV,
        porc_upgrade_ating: porcU,
        editou_meta: true,
        meta_de_vendas: data.meta_venda,
        meta_de_upgrade: data.meta_upgrade,
        valor_venda: data.valor_venda,
        valor_upgrade: data.valor_upgrade,
        receita_venda:
          data.venda_realizada * Number(tipoFuncionario.valor_venda),
        receita_upgrade:
          data.upgrade_realizado * Number(tipoFuncionario.valor_upgrade),
        total: total,
      })
      .then((res) => {
        alertMessage(
          "success",
          "Editar funcionario",
          "Funcionario editado com sucesso"
        );
        getAllFuncionarios(mes, ano);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  return (
    <>
      <Modal
        title="Editar Funcionário"
        open={isModalOpenEditar}
        onCancel={handleCancelEditar}
        destroyOnClose={true}
        preserve={false}
        width={1000}
        footer={[
          <div>
            <Button
              block
              onClick={handleCancelEditar}
              variant="outlined"
              style={{ marginRight: "10px" }}
              size="small"
            >
              cancel
            </Button>
            ,
            <Button
              block
              key="submit"
              variant="contained"
              onClick={handleSubmit(onSubmitModal)}
              size="small"
            >
              Salvar
            </Button>
          </div>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmitModal)}>
          <Row gutter={10}>
            <Col span={6}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20, marginTop: 20 }}
              >
                <TextField
                  label={"Nome Funcionário"}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  defaultValue={
                    funcEditar?.nome === undefined ? null : funcEditar.nome
                  }
                  //error={errors.setor !== undefined}
                  {...register("nome", {
                    required: true,
                  })}
                />
                {errors?.nome?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Nome é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
            <Col span={6}>
              <FormControl
                fullWidth
                style={{ marginBottom: 20, marginTop: 20 }}
              >
                <InputLabel id="config_porc" size="small">
                  Configuração %
                </InputLabel>
                <Select
                  labelId="config_porc"
                  id="config_porc"
                  label="Configuração %"
                  error={errors.config_porc !== undefined}
                  defaultValue={
                    funcEditar?.id_config !== undefined
                      ? funcEditar?.id_config
                      : null
                  }
                  {...register("config_porc", {
                    required: true,
                  })}
                  size="small"
                >
                  {allConfigs.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.nome_config}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors?.config_porc?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo config % é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
            <Col span={6}>
              <FormControl
                fullWidth
                style={{ marginBottom: 20, marginTop: 20 }}
              >
                <InputLabel id="nome_func" size="small">
                  Tipo Funcionario
                </InputLabel>
                <Select
                  labelId="nome_func"
                  id="nome_func"
                  label="Nome Funcionario"
                  error={errors.tipo_funcionario !== undefined}
                  defaultValue={
                    funcEditar?.id_tipo_func_comissao === undefined
                      ? null
                      : funcEditar?.id_tipo_func_comissao
                  }
                  {...register("tipo_funcionario", {
                    required: true,
                  })}
                  size="small"
                >
                  {tipos.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors?.tipo_funcionario?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo "TIPO FUNCIONARIO" é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
            <Col span={6}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20, marginTop: 20 }}
              >
                <TextField
                  label="Decrescimo %"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  defaultValue={
                    funcEditar?.decrescimo === undefined
                      ? null
                      : funcEditar.decrescimo
                  }
                  //error={errors.setor !== undefined}
                  {...register("decrescimo", {
                    required: true,
                  })}
                />
                {errors?.decrescimo?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Decrescimo é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  label="Venda Realizada"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  //error={errors.setor !== undefined}
                  defaultValue={
                    funcEditar?.venda_realizada === undefined
                      ? null
                      : funcEditar.venda_realizada
                  }
                  {...register("venda_realizada", {
                    required: true,
                  })}
                />
                {errors?.venda_realizada?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Venda Realizada é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
            <Col span={12}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  label="Upgrade Realizada"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  //error={errors.setor !== undefined}
                  defaultValue={
                    funcEditar?.upgrade_realizada === undefined
                      ? null
                      : funcEditar.upgrade_realizada
                  }
                  {...register("upgrade_realizado", {
                    required: true,
                  })}
                />
                {errors?.upgrade_realizado?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Upgrade Realizado é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  label="Meta de vendas"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  defaultValue={
                    funcEditar?.meta_de_vendas === undefined
                      ? null
                      : funcEditar.meta_de_vendas
                  }
                  //error={errors.setor !== undefined}
                  {...register("meta_venda", {})}
                />
                {errors?.meta_venda?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Meta Venda é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
            <Col span={12}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  label="Meta de upgrade"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  defaultValue={
                    funcEditar?.meta_de_upgrade === undefined
                      ? null
                      : funcEditar.meta_de_upgrade
                  }
                  //error={errors.setor !== undefined}
                  {...register("meta_upgrade", {})}
                />
                {errors?.meta_upgrade?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Meta Upgrade é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  label="Valor Venda"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  defaultValue={
                    funcEditar?.valor_venda === undefined
                      ? null
                      : funcEditar.valor_venda
                  }
                  //error={errors.setor !== undefined}
                  {...register("valor_venda", {})}
                />
                {errors?.valor_venda?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Valor Venda é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
            <Col span={12}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  label="Valor Upgrade"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  //error={errors.setor !== undefined}
                  defaultValue={
                    funcEditar?.valor_upgrade === undefined
                      ? null
                      : funcEditar.valor_upgrade
                  }
                  {...register("valor_upgrade", {})}
                />
                {errors?.valor_upgrade?.type === "required" && (
                  <span style={{ color: "red" }}>
                    O campo Valor Upgrade é obrigatório
                  </span>
                )}
              </FormControl>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
}

export default ModalTodosComissao;
