import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_BASE_URL_BACKEND_CHAT;

export const socket = io(URL, {
    //autoConnect: false,
    // extraHeaders: {
    //   telConnect: process.env.REACT_APP_NUMERO_TEL,
    // },
});

//export const socket = io(URL);