import { Button, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery } from "@mui/material";
import { Card, Col, Divider, Image, Row } from "antd";
import { useState } from "react";
import { PostAPI } from "../../../../services/http/PostAPI";
import gif from "./../../../../assets/img/57946-profile-user-card.gif";
import { message } from "antd";
import { useForm } from "react-hook-form";
import CardDark from "../../../../component/cardDark";

function CadastrarUsuario() {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const onSubmit = (data) => {

    cadastrarUsuario(data);
  };

  const [userSelect, setUserSelect] = useState("web");
  const [messageApi, contextHolder] = message.useMessage();

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const success = (text) => {
    messageApi.open({
      type: "success",
      content: text,
    });
  };

  const matches = useMediaQuery("(max-width:767px)");
  const [resetComp, setResetComp] = useState(false);

  const cadastrarUsuario = (data) => {
    const { http } = PostAPI();

    http
      .post(`user-create`, {
        name: data.nome,
        email: data.email,
        password: data.senha,
        password_confirmation: data.senhaConf,
        typeAccess: userSelect
      })
      .then((res) => {
        if (res.status) {
          success("Usuário cadastrado com sucesso");
          setResetComp(true);
          reset();
        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        error(erro.response.data.message);
        console.log(erro);
      });
  };

  return (
    <>
      {contextHolder}
      <CardDark title="Cadastro de Usuário">
        <Row justify="center">
          <Col
            sm={12}
            md={7}
            lg={7}
            xl={5}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Image src={gif} preview={false}></Image>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1}>
            <Divider type="vertical" style={{ height: "100%" }}></Divider>
          </Col>

          <Col
            sm={24}
            md={16}
            lg={16}
            xl={18}
            style={matches === true ? stylePaddingForm0 : stylePaddingForm}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  fullWidth={true}
                  label={"Nome"}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  error={errors.nome !== undefined}
                  {...register("nome", {
                    required: true,
                  })}
                />
                {errors?.nome?.types?.required && (
                  <span style={{ color: "red" }}>O nome é obrigatório</span>
                )}
              </FormControl>

              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  fullWidth={true}
                  label={"E-mail"}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="email"
                  error={errors.email !== undefined}
                  {...register("email", {
                    required: true,
                    pattern: /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i,
                  })}
                />
                {errors?.email?.types?.required && (
                  <span style={{ color: "red" }}>O e-mail é obrigatório</span>
                )}
                {errors?.email?.types?.pattern && (
                  <span style={{ color: "red" }}>
                    O e-mail informado não é valido
                  </span>
                )}
              </FormControl>

              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  fullWidth={true}
                  label={"Senha"}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="password"
                  error={errors.senha !== undefined}
                  {...register("senha", {
                    required: true,
                    minLength: 8,
                  })}
                />
                {errors?.senha?.types?.required && (
                  <span style={{ color: "red" }}>A senha é obrigatória</span>
                )}
                {errors?.senha?.types?.minLength && (
                  <span style={{ color: "red" }}>
                    O Setor deve conter ao menos 8 caracteres
                  </span>
                )}
              </FormControl>

              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  fullWidth={true}
                  label={"Confirmação senha"}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="password"
                  error={errors.senhaConf !== undefined}
                  {...register("senhaConf", {
                    required: true,
                    minLength: 8,
                  })}
                />
                {errors?.senhaConf?.types?.required && (
                  <span style={{ color: "red" }}>
                    A confirmação de senha é obrigatória
                  </span>
                )}
                {errors?.senhaConf?.types?.minLength && (
                  <span style={{ color: "red" }}>
                    O Setor deve conter ao menos 8 caracteres
                  </span>
                )}
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="demo-simple-select-label">Tipo de acesso de usuário</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Tipo de acesso de usuário"
                  size="small"
                  defaultValue={"web"}
                  onChange={(e) => {
                    setUserSelect(e.target.value);
                  }}
                >
                  {["todos", "web", "mobile"].map((item, index) => {
                    return <MenuItem value={item} key={index}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <Button variant="contained" type="submit" size="small">
                Salvar
              </Button>
            </form>
          </Col>
        </Row>
      </CardDark>
    </>
  );
}

export default CadastrarUsuario;

const stylePaddingForm = {};

const stylePaddingForm0 = {
  paddingLeft: 0,
};
