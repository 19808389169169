import { useState } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import RoutesMap from "../../../services/routes/InternalRoutes";
import { TextField, useMediaQuery } from "@mui/material";
import {
  Card,
  Col,
  Row,
  notification,
  Alert,
  Space,
  Image,
  Divider,
} from "antd";

import { GetAPI } from "../../../services/http/GetAPI";
import { PostAPI } from "../../../services/http/PostAPI";
import { loadState, saveState } from "../../../services/localStorage";
import React, { useMemo } from "react";
import gif from "./../../../assets/img/114272-security.gif";
import Button from "@mui/material/Button";
import LoadingImage from "../../../component/loadingImage";

function ContentRoutes() {
  const matches = useMediaQuery("(max-width:991px)");

  const [google2faLogado, setGoogle2faLogado] = useState(false);
  const [google2fa, setGoogle2fa] = useState();

  const [codeInput, setCodeInput] = useState("");
  const [code, setCode] = useState();
  const [qrCode, setQrCode] = useState();
  const [loading, setLoading] = useState(true);
  const regUmCaractere = /(?=.*\d)[0-9]{6,}/;
  const [erro, setErro] = useState(false);

  const pegarCodigo = () => {
    const { http } = GetAPI();

    http.get(`/code-gerate`).then((res) => {
      if (res.status) {
        setCode(res.data[0].secret);
        setQrCode(res.data[0].QR_Image);
      } else {
        console.log(res);
      }
    });
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.error({
      message: `Código inválido`,
      description: (
        <Context.Consumer>
          {({ name }) =>
            `O código inserido não pode ser validado, tente novamente`
          }
        </Context.Consumer>
      ),
    });
  };

  const Context = React.createContext({
    name: "Default",
  });

  const authenticacao2fa = () => {
    const { http } = PostAPI();

    http.post(`/code-verify`, { one_time_password: codeInput }).then((res) => {
      if (res.status) {
        if (res.data.res === true) {
          saveState("2fagoogle", true);
          setGoogle2fa(true);
        }

        if (res.data.res === false) {
          openNotification();
        }
      } else {
        console.log(res);
      }
    });
  };

  const htmlQrCode = () => (
    <Col sm={24} md={24} lg={14} xl={14}>
      <center>
        {erro === true ? (
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Alert
              message="Quantidade de caracteres insuficiente"
              type="warning"
            />
          </Space>
        ) : (
          false
        )}
        <p>
          1. Escaneie o seguinte QR code com seu Google Authenticator App ou
          utilize o codigo:
        </p>
        <h3>{code}</h3>
        {<div dangerouslySetInnerHTML={{ __html: qrCode }} />}
      </center>
      <center>
        <p>
          2. Insira o OTP gerado pelo sei Authenticator App. O código gerado é
          valido por apenas 30 segundos
        </p>
      </center>
      <TextField
        fullWidth
        label="Código Google 2FA"
        id="outlined-size-small"
        size="small"
        error={erro === true ? true : false}
        style={{ marginBottom: 20 }}
        onChange={(e) => {
          const { value } = e.target;
          if (
            (!Number.isNaN(value) && regUmCaractere.test(value)) ||
            value === "" ||
            value === "-"
          ) {
            setCodeInput(value);
          } else {
            setCodeInput("");
          }
        }}
      />
    </Col>
  );

  const htmlImagem = () => (
    <>
      <Col xs={0} sm={0} md={0} lg={1} xl={1}>
        <Divider type="vertical" style={{ height: "100%" }}></Divider>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={23}
        lg={7}
        xl={7}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={gif}
          preview={false}
          style={matches === true ? { width: "30vw" } : null}
        ></Image>
      </Col>
    </>
  );

  useEffect(() => {
    setGoogle2fa(loadState("2fagoogle"));

    if (google2fa === undefined) {
      setGoogle2faLogado(false);
    } else {
      setGoogle2faLogado(true);
    }

    if (code === undefined) {
      pegarCodigo();
      setLoading(false);
    }
  }, [google2fa]);
  return (
    <div
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      {contextHolder}
      {/* {google2faLogado === false && (
        <>
          {loading === true && <LoadingImage />}
          {loading === false && (
            <Card title="Autenticação Google 2FA">
              <Row justify="space-between">
                {matches === true ? (
                  <>
                    {htmlImagem()}
                    {htmlQrCode()}
                  </>
                ) : (
                  <>
                    {htmlQrCode()}
                    {htmlImagem()}
                  </>
                )}
              </Row>
              <Divider style={{ marginBottom: 10 }}></Divider>
              <Row>
                <Button
                  onClick={() => {
                    if (codeInput !== "") {
                      authenticacao2fa(codeInput);
                      setErro(false);
                    } else {
                      setErro(true);
                    }
                  }}
                  variant="contained"
                >
                  Validar
                </Button>
              </Row>
            </Card>
          )}
        </>
      )} */}
      {/* {google2faLogado === true && ( */}
      <Routes>
        {RoutesMap.map((rota, index) => {
          return <Route key={index} {...rota}></Route>;
        })}
      </Routes>
      {/* )} */}
    </div>
  );
}

export default ContentRoutes;
