import { Row, Transfer } from "antd";
import { useEffect, useState } from "react";
import { Checkbox } from 'antd';

const TransferList = ({
  labelLeft,
  labelRight,
  itensLeft,
  itensRight,
  setPermArray,
  permArray,
  reset,
  resetSet
}) => {

  const plainOptions = ['Criar', 'Editar', 'Excluir'];
  const [targetKeys, setTargetKeys] = useState(itensRight); //item selecionados apenas keys
  const [mockData, setMockData] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [permissions, setPermissions] = useState()

  const getMock = () => {
    const tempTargetKeys = [];

    const mockData = itensLeft.map((item) => ({
      key: item.id,
      title: item.description_label,
      description: item.description_label,
    }));

    setMockData(mockData);
    setTargetKeys(itensRight);
  };

  const onChange2 = (checkedValues, id) => {

    console.log(permArray)

    let perms = []

    for (const i of checkedValues) {
      if (i === "Criar") {
        perms.push(1)
      }
      if (i === "Editar") {
        perms.push(2)
      }
      if (i === "Excluir") {
        perms.push(3)
      }
    }

    for (const i of permArray) {

      if (id === i.key) {
        i.perm = perms
      }
    }

    console.log(permArray)
    setPermArray(permArray);

  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {

    let permissoes = []
    let obj = []

    console.log(nextTargetKeys)

    for (const i of itensLeft) {

      if (nextTargetKeys.includes(i.id)) {
        permissoes.push(i)
      }

    }

    if (permArray?.length) {

      let indices = []

      for (const i of permArray) {
        indices.push(i.key)
      }

      for (const i of nextTargetKeys) {
        if (!indices.includes(i)) {
          permArray.push({ key: i, perm: [1, 2, 3] })
        }
      }

      for (const [index, j] of permArray.entries()) {
        if (!nextTargetKeys.includes(j.key)) {
          permArray.splice(index, 1);
        }
      }

      console.log(permArray)
      setPermArray(permArray);

    } else {
      for (const i of nextTargetKeys) {
        obj.push({ key: i, perm: [1, 2, 3] })
      }
      console.log(obj)
      setPermArray(obj);
    }

    setPermissions(permissoes)
    setTargetKeys(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const onScroll = (direction, e) => { };

  useEffect(() => {

    if (mockData === undefined) {
      getMock()

    }

    if (reset === true) {
      getMock()
      setPermissions(undefined)
      resetSet(false)
    }

  }, [permissions, permArray, reset])

  return (
    <>
      <Transfer
        style={{
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
          marginRight: 10,
          marginLeft: 10,
        }}
        listStyle={{
          width: 500,
          height: 400
        }}
        dataSource={mockData} //item para selecionar
        titles={[labelLeft, labelRight]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        onScroll={onScroll}
        render={(item) => item.title}
      />

      {permissions !== undefined && (
        <>
          {permissions.map((i, index) => {
            return (
              <Row style={{ backgroundColor: "lightblue", marginBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }} key={index}>
                <span style={{ fontSize: 16, padding: 5, fontWeight: "bold" }}>{i.description_label}</span>
                <Checkbox.Group options={plainOptions} defaultValue={['Criar', 'Editar', 'Excluir']} onChange={(e) => { onChange2(e, i.id) }} />
              </Row>
            )
          })}
        </>
      )}

    </>
  );
};
export default TransferList;
