import Web from "./web";
import Mobile from "./mobile";

const itemsGerenciarAtribuirSetor = [
    {
        key: 'gerenciaratribuirsetorweb',
        label: `Gerenciar Atribuir Setor Web`,
        children: <Web></Web>,
    },
    {
        key: 'gerenciaratribuirsetormobile',
        label: `Gerenciar Atribuir Setor Mobile`,
        children: <Mobile ></Mobile>,
    },
];

export default itemsGerenciarAtribuirSetor;