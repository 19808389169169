import { Badge, Card, Descriptions, Image, Row } from "antd";
import { useEffect, useState } from "react";
import { ChatRequests } from "./../../../../services/http/ChatRequests";
import { ConnectionState } from "../../../../component/chat/ConnectionState";
import { Button } from "@mui/material";
import CardDark from "../../../../component/cardDark";

function VenomBot() {
  const [connected, setConnected] = useState();
  const [qrCode, setQrcode] = useState();

  const verificarConexao = () => {
    const { http } = ChatRequests();

    http
      .post(`venom-isconnected`)
      .then((res) => {
        console.log(res.data);
        setConnected(res.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const solicitarConexao = () => {
    const { http } = ChatRequests();

    http
      .post(`venom-connect`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const solicitarQrCode = () => {
    const { http } = ChatRequests();

    http
      .post(`venom-qrcode`)
      .then((res) => {
        console.log(res.data);
        setQrcode(res.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  useEffect(() => {
    if (connected === undefined) {
      verificarConexao();
    }
  }, []);
  return (
    <>
      <CardDark title="VENOM BOT">
        <Descriptions bordered>
          <Descriptions.Item
            label="Status"
            span={3}
            style={{ textAlign: "end" }}
          >
            <span>
              {connected?.res === true ? (
                <Badge status="success" text="On" />
              ) : (
                <Badge status="error" text="Off" />
              )}
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Verify Conection"
            span={3}
            style={{ textAlign: "end" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                verificarConexao();
              }}
            >
              Verificar conexão
            </Button>
          </Descriptions.Item>
          <Descriptions.Item
            label="Inicial Conection"
            span={3}
            style={{ textAlign: "end" }}
          >
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={() => {
                solicitarConexao();
              }}
            >
              Connectar
            </Button>
          </Descriptions.Item>
          <Descriptions.Item
            label="Get QrCode"
            span={3}
            style={{ textAlign: "end" }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                solicitarQrCode();
              }}
            >
              QrCode
            </Button>
          </Descriptions.Item>
          {qrCode?.res && (
            <Descriptions.Item
              label="Qrcode Conection"
              span={3}
              style={{ textAlign: "end" }}
            >
              <Image src={qrCode.res}></Image>
            </Descriptions.Item>
          )}
        </Descriptions>
      </CardDark>
    </>
  );
}

export default VenomBot;
