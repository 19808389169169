import { Button, TextField } from "@mui/material";
import { Card, Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { ExternalAPI } from "../../../../services/http/ExternalAPI";
import { useState } from "react";
import CardDark from "../../../../component/cardDark";

function Mtr() {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const [resp, setResp] = useState();

  const onSubmit = (data) => {
    mtrRequest(data);
  };

  const mtrRequest = async (data) => {
    const { http } = ExternalAPI();

    http
      .post("/ip-tools-mtr", { ip: data.query })
      .then((res) => {
        if (res.status) {
          setResp(res.data);
          console.log(res.data);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  return (
    <>
      <Card>
        <Row justify="center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <CardDark title="Who is" size="small">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row justify="center" style={{ alignItems: "center" }}>
                  <Col>
                    <TextField
                      id="outlined-basic"
                      label="Ip"
                      required
                      variant="outlined"
                      style={{ marginRight: 10 }}
                      size="small"
                      {...register("ip")}
                    //defaultValue={oltEdit ? oltEdit.nome : null}
                    ></TextField>
                  </Col>
                  <Col>
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      size="small"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      MTR This!
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardDark>
          </Col>
        </Row>
        {resp !== undefined && (
          <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                title={<span style={{ color: "white" }}>Results</span>}
                size="small"
                style={{
                  backgroundColor: "black",
                  color: "green",
                  marginTop: 10,
                }}
              >
                {resp}
              </Card>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
}

export default Mtr;
