import ConfirmarLocalizacao from "../../view/externalPages/confirmarLocalizacao";
import LocalizacaoCliente from "../../view/externalPages/localicacaoCliente";
import RegisterPage from "../../view/externalPages/recuperarLogin";
import RedefinirSenha from "../../view/externalPages/redefinirSenha";
import RedirecionarCliente from "../../view/externalPages/redirecionarCliente";
import Register from "../../view/externalPages/register";
import TrabalheConosco from "../../view/externalPages/trabalheConosco";
import AgradecimentoTrabalheConosco from "../../view/externalPages/trabalheConosco/agradecimento";
import Login from "../../view/login";

const Routes = [
  {
    path: "/",
    element: <Login></Login>,
    exact: true,
  },
  {
    path: "/external/viabilidade-localizacao-cliente",
    element: <LocalizacaoCliente></LocalizacaoCliente>,
    exact: true,
  },
  {
    path: "/external/viabilidade-confirmar-localizacao",
    element: <ConfirmarLocalizacao></ConfirmarLocalizacao>,
    exact: true,
  },
  {
    path: "/external/redirecionar-pagina",
    element: <RedirecionarCliente></RedirecionarCliente>,
    exact: true,
  },
  {
    path: "/external/recovery-acess",
    element: <RegisterPage></RegisterPage>,
    exact: true,
  },
  {
    path: "/external/redefine-password/:token",
    element: <RedefinirSenha></RedefinirSenha>,
    exact: true,
  },
  {
    path: "/external/register",
    element: <Register></Register>,
    exact: true,
  },
  // {
  //   path: "/external/register-confirmation",
  //   element: <ConfirmarRegistro></ConfirmarRegistro>,
  //   exact: true,
  // },
  {
    path: "/external/trabalhe-conosco",
    element: <TrabalheConosco></TrabalheConosco>,
    exact: true,
  },
  {
    path: "/external/agradecimento",
    element: <AgradecimentoTrabalheConosco></AgradecimentoTrabalheConosco>,
    exact: true,
  },
];

export default Routes;
