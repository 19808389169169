import axios from "axios";

export function NodeRequests() {

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL_BACKEND_NODE}/api`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return {
    http,
  };
}
