import { Route, Routes } from "react-router-dom";
import RoutesMap from "./../../services/routes/ExternalRoutes";

function ExternalPages() {
  return (
    <Routes>
      {RoutesMap.map((rota, index) => {
        return <Route key={index} {...rota}></Route>;
      })}
    </Routes>
  );
}

export default ExternalPages;
