import img1 from "./../../assets/img-background/01.png";
import img2 from "./../../assets/img-background/02.png";
import img3 from "./../../assets/img-background/03.png";
import img4 from "./../../assets/img-background/04.png";
import img5 from "./../../assets/img-background/05.png";
import img6 from "./../../assets/img-background/06.png";
import img7 from "./../../assets/img-background/07.png";
import img8 from "./../../assets/img-background/08.png";

const imagesBackground = [img1, img2, img3, img4, img5, img6, img7, img8];

const randomElement =
  imagesBackground[Math.floor(Math.random() * imagesBackground.length)];

export default randomElement;
