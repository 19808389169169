import { Row, Tag } from "antd";
import React from "react";

export function ConnectionState({ isConnected }) {
  return (
    <>
      <Row justify="space-between">
        <span style={{ fontFamily: "Ubuntu, sans-serif", fontSize: 20 }}>
          status
        </span>
        <Tag
          color={String(isConnected) === "true" ? "success" : "error"}
          style={{ paddingTop: 3 }}
        >
          <span style={{ lineHeight: 0 }}>{String(isConnected)}</span>
        </Tag>
      </Row>
    </>
  );
}
