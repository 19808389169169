import {
  UserOutlined,
  HomeOutlined,
  SearchOutlined,
  ControlOutlined,
  DashboardOutlined,
  LoginOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  SlidersOutlined,
  BarsOutlined,
  MessageOutlined,
  BarChartOutlined,
  CommentOutlined,
  RobotOutlined,
  AreaChartOutlined,
  FileSearchOutlined,
  ReadOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import SaveAsIcon from "@mui/icons-material/SaveAs";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import TerminalIcon from "@mui/icons-material/Terminal";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SettingsIcon from '@mui/icons-material/Settings';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import WebIcon from '@mui/icons-material/Web';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TableViewIcon from '@mui/icons-material/TableView';



function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const itemsNavigationMobile = [
  getItem(
    "Home",
    "1",
    <Link to={"/"}>
      <HomeOutlined />
    </Link>
  ),
  getItem("Sistema", "sub1", <SlidersOutlined />, [
    getItem(
      "Informações Usuário",
      "2",
      <Link to={"/user-info"}>
        <UserOutlined />
      </Link>
    ),
    getItem("Deslogar", "3", <LoginOutlined />),
  ]),
  getItem("Mensagens", "sub2", <MessageOutlined />, [
    getItem(
      "Todas",
      "4",
      <Link to={"/messages-all"}>
        <BarsOutlined />
      </Link>
    ),
  ]),


  getItem("Viabilidade", "sub3", <SearchOutlined />, [
    getItem(
      "Pesquisar Viabilidade",
      "5",
      <Link to={"/viabilidade-pesquisar"}>
        <SearchOutlined />
      </Link>
    ),
    getItem(
      "Cadastros de localidade Cliente",
      "6",
      <Link to={"/viabilidade-cadastro-localizacao"}>
        <SaveAsIcon />
      </Link>
    ),
    getItem(
      "Localidades Viáveis",
      "7",
      <Link to={"/viabilidade-localidades-viaveis"}>
        <CheckCircleIcon />
      </Link>
    ),
    getItem(
      "Localidades Inviáveis",
      "8",
      <Link to={"/viabilidade-localidades-inviaveis"}>
        <CancelIcon />
      </Link>
    ),
  ]),
  getItem("Configurações", "sub4", <ControlOutlined />, [
    getItem(
      "Cadastrar usuário",
      "9",
      <Link to={"/usuario-adicionar"}>
        <UserAddOutlined />
      </Link>
    ),
    getItem(
      "Gerenciar Cadastro",
      "10",
      <Link to={"/usuario-gerenciar"}>
        <UsergroupAddOutlined />
      </Link>
    ),
    getItem(
      "Cadastrar Permissões",
      "11",
      <Link to={"/permission-create"}>
        <VerifiedUserIcon />
      </Link>
    ),
    getItem(
      "Editar Permissões",
      "12",
      <Link to={"/permission-gerenciar"}>
        <AdminPanelSettingsIcon />
      </Link>
    ),
    getItem(
      "Cadastrar Setor",
      "13",
      <Link to={"/setor-cadastrar"}>
        <AssignmentIndIcon />
      </Link>
    ),
    getItem(
      "Gerenciar Setor",
      "14",
      <Link to={"/setor-gerenciar"}>
        <AssignmentIcon />
      </Link>
    ),
    getItem(
      "Atribuir Setor",
      "15",
      <Link to={"/setor-atribuir"}>
        <SupervisedUserCircleIcon />
      </Link>
    ),
    getItem(
      "Gerenciar Atribuir Setor",
      "16",
      <Link to={"/setor-gerenciar-atribuir"}>
        <ContactMailIcon />
      </Link>
    ),
  ]),
  getItem("Olt", "sub5", <DashboardOutlined />, [
    getItem(
      "Adicionar OLT",
      "17",
      <Link to={"/olt-adicionar"}>
        <DashboardOutlined />
      </Link>
    ),
    getItem(
      "Cadastrar usuário",
      "18",
      <Link to={"/olt-configurar"}>
        <DashboardOutlined />
      </Link>
    ),
    // getItem(
    //   "Cmd",
    //   "19",
    //   <Link to={"/cmd"}>
    //     <TerminalIcon />
    //   </Link>
    // ),
    getItem(
      "Gerenciar Olt",
      "20",
      <Link to={"/olt-gerenciar"}>
        <WysiwygIcon />
      </Link>
    ),
    getItem(
      "Template Olt",
      "21",
      <Link to={"/olt-template"}>
        <TextSnippetIcon />
      </Link>
    ),
  ]),
  getItem("Ip Tools", "sub6", <SlidersOutlined />, [
    getItem(
      "Whais is ip",
      "22",
      <Link to={"/whais-is-ip"}>
        <TextSnippetIcon />
      </Link>
    ),
    getItem(
      "Mtr",
      "23",
      <Link to={"/mtr"}>
        <TextSnippetIcon />
      </Link>
    ),
    getItem(
      "DNS Lookup",
      "24",
      <Link to={"/dns-lookup"}>
        <TextSnippetIcon />
      </Link>
    ),
    getItem(
      "Ping",
      "25",
      <Link to={"/ping"}>
        <TextSnippetIcon />
      </Link>
    ),
    getItem(
      "Tracerouter",
      "26",
      <Link to={"/tracerouter"}>
        <TextSnippetIcon />
      </Link>
    ),
    getItem(
      "Who is",
      "27",
      <Link to={"/who-is"}>
        <TextSnippetIcon />
      </Link>
    ),
  ]),


  getItem("Dashboard", "sub7", <BarChartOutlined />, [
    getItem(
      "Estatisticas",
      "28",
      <Link to={"/dashboard"}>
        <BarChartOutlined />
      </Link>
    ),
  ]),
  getItem("Chat", "sub8", <CommentOutlined />, [
    getItem(
      "Socket-io",
      "29",
      <Link to={"/socketio"}>
        <CommentOutlined />
      </Link>
    ),
    getItem(
      "Venom-bot",
      "30",
      <Link to={"/venom-bot"}>
        <CommentOutlined />
      </Link>
    ),
    getItem(
      "Chat",
      "31",
      <Link to={"/chat"}>
        <CommentOutlined />
      </Link>
    ),
  ]),
  getItem("Tickets", "sub9", <ConfirmationNumberIcon />, [
    getItem(
      "Dashboard",
      "32",
      <Link to={"/tickets-dashboard"}>
        <DashboardIcon />
      </Link>
    ),
    getItem(
      "Criar",
      "33",
      <Link to={"/tickets-criar"}>
        <DashboardIcon />
      </Link>
    ),
    getItem(
      "Tickerts Ativos",
      "34",
      <Link to={"/tickets-ativos"}>
        <DashboardIcon />
      </Link>
    ),
  ]),
  getItem("WebsTelegram", "sub10", <RobotOutlined />, [
    getItem(
      "Quedas Serviços(hoje)",
      "35",
      <Link to={"/webs-telegram-dashboard"}>
        <LeaderboardIcon />
      </Link>
    ),
    getItem(
      "Configuração",
      "36",
      <Link to={"/webs-telegram-configuracao"}>
        <SettingsIcon />
      </Link>
    ),
  ]),
  getItem("Logs", "sub11", <FileSearchOutlined />, [
    getItem(
      "Logs Laravel",
      "37",
      <Link to={"/logs"}>
        <ReadOutlined />
      </Link>
    ),
    getItem(
      "Teste Page",
      "38",
      <Link to={"/teste-page"}>
        <ReadOutlined />
      </Link>
    ),
    getItem(
      "backup",
      "39",
      <Link to={"/backup"}>
        <ReadOutlined />
      </Link>
    ),
    getItem(
      "Painel de Controle",
      "40",
      <Link to={"/painel-de-controle"}>
        <ReadOutlined />
      </Link>
    ),
  ]),
  getItem("Site", "sub12", <WebIcon />, [
    getItem(
      "Curriculos",
      "41",
      <Link to={"/curriculo-site/"}>
        <PictureAsPdfIcon />
      </Link>
    ),
  ]),
  getItem("Comissão", "sub13", <AttachMoneyIcon />, [
    getItem(
      "Planilha de Comissão",
      "42",
      <Link to={"/comissao-planilha/"}>
        <TableViewIcon />
      </Link>
    ),
  ]),
];
