import { Tabs } from "antd";
import itemsGerenciarAtribuirSetor from "./itemsMenu";


function GerenciarAtribuirSetor() {

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1" items={itemsGerenciarAtribuirSetor}
      />
    </>
  );
}

export default GerenciarAtribuirSetor;

const buttonsTable = {
  marginRight: 5,
  marginBottom: 5,
  marginTop: 5,
  marginLeft: 5,
};
