import * as React from "react";
import { Button, Card, Col, Image, Row } from "antd";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context";
import background from "../../../services/randomImagesBackground";
import logo from "./../../../assets/logo/Logo Completo.png";
import { FormControl } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { RedefinirSenhaAPI } from "../../../services/http/RedefinirSenhaAPI";
import { useState } from "react";
import { message, Space } from "antd";
import { useEffect } from "react";
import { Divider, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

function RedefinirSenha() {
  const { isExternal, setIsExternal } = useContext(AuthContext);
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [erroLogin, setErroLogin] = useState(false);
  const [umCaractere, setUmcaractere] = useState(false);
  const [umLetraMinuscula, setLetraMinuscula] = useState(false);
  const [umLetraMaiuscula, setLetraMaiuscula] = useState(false);
  const [umCaractereEspecial, setCaractereEspecial] = useState(false);
  const [oitoCaracteres, setOitoCaracteres] = useState(false);

  const regUmCaractere = /(?=.*\d)[0-9]{1,}/;
  const reg =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/;
  const regLetraMinuscula = /(?=.*[a-z]){1,}/;
  const regLetraMaiuscula = /(?=.*[A-Z]){1,}/;
  const regCaractereEspecial = /(?=.*[$*&@#]){1,}/;
  const regOitoCaracteres = /[0-9a-zA-Z$*&@#]{8,}/;

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Senha cadastrada com sucesso",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Senhas divergentes ou requisitos não atendidos",
    });
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const mudarSenha = () => {
    if (senha === confSenha && senha !== "") {
      recoveryLogin(senha);
      success();
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      error();

      setErroLogin(true);
    }
  };

  const recoveryLogin = (senha) => {
    const { http } = RedefinirSenhaAPI();

    http.post(`user-redefine-email/${token}`, { senha: senha }).then((res) => {
      if (res.status) {
      }
    });
  };

  useEffect(() => { }, [umCaractere]);

  return (
    <>
      {contextHolder}
      <Row style={rowStyleBg}></Row>
      <Row justify="center" style={rowStyle}>
        <Col md={16} lg={14}>
          <Card style={cardStyle}>
            <center>
              <Image src={logo} preview={false} width={180}></Image>
              <h1 style={{ fontFamily: "Segoe UI Emoji" }}>
                Vamos redefinir sua senha? ☺️
              </h1>
              <p>
                Crie uma senha forte para segurança da sua conta, ela dever ter
                os seguintes itens:
              </p>

              <Row
                style={{
                  justifyContent: "center",
                  marginBottom: 12,
                }}
              >
                {umCaractere === false ? (
                  <Tag color="red" style={{ marginBottom: 4 }}>
                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos um número
                  </Tag>
                ) : (
                  <Tag color="green" style={{ marginBottom: 4 }}>
                    <CheckCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos um número
                  </Tag>
                )}

                {umLetraMinuscula === false ? (
                  <Tag color="red" style={{ marginBottom: 4 }}>
                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos uma letra minúscula
                  </Tag>
                ) : (
                  <Tag color="green" style={{ marginBottom: 4 }}>
                    <CheckCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos uma letra minúscula
                  </Tag>
                )}

                {umLetraMaiuscula === false ? (
                  <Tag color="red" style={{ marginBottom: 4 }}>
                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos uma letra maiúscula
                  </Tag>
                ) : (
                  <Tag color="green" style={{ marginBottom: 4 }}>
                    <CheckCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos uma letra maiúscula
                  </Tag>
                )}

                {umCaractereEspecial === false ? (
                  <Tag color="red" style={{ marginBottom: 4 }}>
                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos um caractere especial
                  </Tag>
                ) : (
                  <Tag color="green" style={{ marginBottom: 4 }}>
                    <CheckCircleOutlined style={{ marginRight: 4 }} />
                    Conter ao menos um caractere especial
                  </Tag>
                )}

                {oitoCaracteres === false ? (
                  <Tag color="red" style={{ marginBottom: 4 }}>
                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                    Conter pelo menos oito caracteres ao total
                  </Tag>
                ) : (
                  <Tag color="green" style={{ marginBottom: 4 }}>
                    <CheckCircleOutlined style={{ marginRight: 4 }} />
                    Conter pelo menos oito caracteres ao total
                  </Tag>
                )}
              </Row>
            </center>

            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: 20 }}
            >
              <InputLabel htmlFor="outlined-adornment-password" size="small">
                Nova senha
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                size="small"
                type={showPassword ? "text" : "password"}
                error={erroLogin === true ? true : false}
                onChange={(e) => {
                  const { value } = e.target;
                  if (
                    (!Number.isNaN(value) && reg.test(value)) ||
                    value === "" ||
                    value === "-"
                  ) {
                    setSenha(e.target.value);
                  }

                  regUmCaractere.test(value)
                    ? setUmcaractere(true)
                    : setUmcaractere(false);

                  regLetraMinuscula.test(value)
                    ? setLetraMinuscula(true)
                    : setLetraMinuscula(false);

                  regLetraMaiuscula.test(value)
                    ? setLetraMaiuscula(true)
                    : setLetraMaiuscula(false);

                  regCaractereEspecial.test(value)
                    ? setCaractereEspecial(true)
                    : setCaractereEspecial(false);

                  regOitoCaracteres.test(value)
                    ? setOitoCaracteres(true)
                    : setOitoCaracteres(false);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Nova senha"
              />
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: 20 }}
            >
              <InputLabel htmlFor="outlined-adornment-password" size="small">
                Confirme a nova senha
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                size="small"
                onChange={(e) => {
                  const { value } = e.target;
                  if (
                    (!Number.isNaN(value) && reg.test(value)) ||
                    value === "" ||
                    value === "-"
                  ) {
                    setConfSenha(e.target.value);
                  }
                }}
                error={erroLogin === true ? true : false}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirme a nova senha"
              />
            </FormControl>

            <Button
              onClick={() => {
                setIsExternal(false);
                mudarSenha();
              }}
            >
              Salvar
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RedefinirSenha;

const rowStyleBg = {
  backgroundImage: `url(${background})`,
  height: "100vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  filter: "blur(2.5px)",
};

const rowStyle = {
  position: "absolute",
  top: 0,
  height: "100vh",
  width: "100vw",
};

const cardStyle = {
  marginTop: 30,
  marginBottom: 10,
  marginLeft: 20,
  marginRight: 20,
  paddingLeft: 10,
  paddingRight: 10,
};

const senhaErro = {
  color: "red",
};

const senhaValida = {
  color: "green",
};
