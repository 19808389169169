import { Card, Table, Modal, Row, Col } from "antd";
import { useLocation } from "react-router-dom";
import {
  Autocomplete,

  DrawingManager,
  GoogleMap,
  InfoWindow,
  LoadScript,

} from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, FormControl, TextField } from "@mui/material";
import { PostAPI } from "../../../../../services/http/PostAPI";
import LoadingImage from "../../../../../component/loadingImage";
import { MapContainer, TileLayer, Marker, Tooltip, Circle } from "react-leaflet";
import { icon } from "leaflet";
import PngIcon from "./../../../../../assets/img/placeholder.png";
import PngIcon2 from "./../../../../../assets/img/placeholder_cto.png";
import PngIcon3 from "./../../../../../assets/img/placeholder_cto2.png";
import tool from "./tooltip"
import TooltipLeaflet from "../../../../../component/TooltipLeaflet";
import CardDark from "../../../../../component/cardDark";

const ICON = icon({
  iconUrl: PngIcon,
  iconSize: [32, 32],
});

const ICON2 = icon({
  iconUrl: PngIcon2,
  iconSize: [32, 32],
});

const ICON3 = icon({
  iconUrl: PngIcon3,
  iconSize: [32, 32],
});

const columns = [
  {
    title: "Capacidade",
    dataIndex: "capacidade",
    key: "id",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.capacidade}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.capacidade}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Qtd. Clientes",
    dataIndex: "qtdclientes",
    key: "qtdclientes",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.qtdclientes}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.qtdclientes}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Portas Disponiveis",
    dataIndex: "portasdisponiveis",
    key: "portasdisponiveis",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.portasdisponiveis}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.portasdisponiveis}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Distância",
    dataIndex: "distancia",
    key: "distancia",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.distancia}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.distancia}</strong>
          </span>
        )}
      </>
    ),
  },
  {
    title: "Nome",
    dataIndex: "nome",
    key: "nome",
    render: (e) => (
      <>
        {e.id !== 0 && <span>{e.nome}</span>}
        {e.id === 0 && (
          <span style={{ color: "green" }}>
            <strong>{e.nome}</strong>
          </span>
        )}
      </>
    ),
  },
];

function CtosConfirmed() {
  const location = useLocation();
  const { lat, lng, contato } = location.state;
  const [loading, setLoading] = useState(true);
  const [ctos, setCtos] = useState();
  const [load, setLoad] = useState();
  const [tamanhoC, setTamanhoC] = useState(200);
  const [dataInfo, setDataInfo] = useState();
  const [mostrar, setMostrar] = useState([]);
  const [map, setMap] = useState(null)
  const [modificou, setModificou] = useState()
  const [aparecer, setAparecer] = useState([])


  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const onSubmit = (data) => {
    trazerCtos(data);
  };

  const [loc, setLoc] = useState({
    lat: Number(lat),
    lng: Number(lng),
  });

  const [posicao, setPosicao] = useState(`${lat},${lng}`);

  const carregou = () => {
    setLoad(true);
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
    marginBottom: "10px",
  };

  const trazerCtos = async (data) => {
    const { http } = PostAPI();

    await http
      .post("/viabilidade-consultar", {
        distancia: data.metros,
        tipo: 1,
        endereco: "",
        coor: posicao,
        mostrar: true,
      })
      .then((res) => {
        if (res.status) {
          const ctos = [];
          res.data.posCtos.map((item, index) => {
            ctos.push({
              id: index,
              capacidade: { capacidade: item.capacidade, id: index },
              qtdclientes: { qtdclientes: item.qtdClientes, id: index },
              portasdisponiveis: {
                portasdisponiveis: item.portasDisponiveis,
                id: index,
              },
              distancia: { distancia: item.distancia + " M", id: index },
              nome: { nome: item.nomeCTO, id: index },
              lat: item.latitude,
              lng: item.longitude,
              key: index,
            });
          });
          setDataInfo(ctos);
          setCtos(ctos);
          setTamanhoC(Number(res.data.distancia));
          setMostrar(res.data.mostrar);
          setLoading(false);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const consultarViabilidade = async () => {
    const { http } = PostAPI();

    await http
      .post("/viabilidade-consultar", {
        distancia: 200,
        tipo: 1,
        endereco: "",
        coor: posicao,
        mostrar: true,
      })
      .then((res) => {
        if (res.status) {
          const ctos = [];
          res.data.posCtos.map((item, index) => {
            ctos.push({
              id: index,
              capacidade: { capacidade: item.capacidade, id: index },
              qtdclientes: { qtdclientes: item.qtdClientes, id: index },
              portasdisponiveis: {
                portasdisponiveis: item.portasDisponiveis,
                id: index,
              },
              distancia: { distancia: item.distancia + " M", id: index },
              nome: { nome: item.nomeCTO, id: index },
              lat: item.latitude,
              lng: item.longitude,
              key: index,
            });
          });
          setDataInfo(ctos);
          setCtos(ctos);
          setTamanhoC(Number(res.data.distancia));
          setMostrar(res.data.mostrar);
          setLoading(false);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const eventHandlers = useMemo(
    () => ({
      click(e) {
        setAparecer(aparecer => [...aparecer, e.latlng.lat])
      },
    }),
    [],
  )

  useEffect(() => {
    if (ctos === undefined) {
      consultarViabilidade();
    }

  }, [aparecer]);

  return (
    <>
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          <CardDark title="Raio de pesquisa" style={{ marginBottom: 10 }}>
            <h4 style={{ float: "right", color: "red", marginTop: 0 }}>
              * Raio de pesquisa inicial é 200m
            </h4>
            <h4 style={{ marginTop: 0 }}>
              Latitude: <span style={{ color: "red" }}>{lat}</span> Longitude:{" "}
              <span style={{ color: "blue" }}>{lng}</span>
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                fullWidth
                id="category-editor-form"
                style={{ marginBottom: 20 }}
              >
                <TextField
                  id="outlined-basic"
                  label="Distância em metros"
                  defaultValue={"200"}
                  variant="outlined"
                  size="small"
                  {...register("metros", {
                    required: true,
                    minLength: 1,
                    maxLength: 4,
                  })}
                ></TextField>
                {errors?.metros?.types?.required && (
                  <span style={{ color: "red" }}>Número obrigatório</span>
                )}
                {errors?.metros?.types?.minLength && (
                  <span style={{ color: "red" }}>Menos de 1 caracteres</span>
                )}
                {errors?.metros?.types?.maxLength && (
                  <span style={{ color: "red" }}>Mais que 4 caracteres</span>
                )}
              </FormControl>
              <Button variant="contained" type="submit" size="small">
                Pesquisar
              </Button>
            </form>
          </CardDark>
          {/* <LoadScript
            googleMapsApiKey={process.env.REACT_APP_KEY_GOOGLE}
            onLoad={carregou}
            libraries={["drawing", "places"]}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={loc}
              zoom={18}
              options={{ disableDefaultUI: true, mapTypeId: "satellite" }}
            >
              <Marker position={loc} />

              {load === true &&
                ctos.map((item, index) => {
                  return (
                    <>
                      {index === 0 && (
                        <Marker
                          animation={window.google.maps.Animation.DROP}
                          icon={{
                            path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                            fillColor: "green",
                            fillOpacity: 1,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 2,
                            anchor: window.google.maps.Point(0, 0),
                          }}
                          position={{ lat: item.lat, lng: item.lng }}
                          label={
                            mostrar === true
                              ? {
                                  text: item.nome.nome,
                                  fontSize: "14px",
                                  color: "white",
                                  fontWeight: "600",
                                  fontFamily: "arial",
                                }
                              : null
                          }
                        />
                      )}
                      {index !== 0 && (
                        <Marker
                          animation={window.google.maps.Animation.DROP}
                          icon={{
                            path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                            fillColor: "blue",
                            fillOpacity: 1,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 2,
                            anchor: window.google.maps.Point(0, 0),
                          }}
                          position={{ lat: item.lat, lng: item.lng }}
                          label={
                            mostrar === true
                              ? {
                                  text: item.nome.nome,
                                  fontSize: "14px",
                                  color: "white",
                                  fontWeight: "600",
                                  fontFamily: "arial",
                                }
                              : null
                          }
                        />
                      )}
                    </>
                  );
                })}
              <Circle
                radius={tamanhoC}
                center={loc}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.3,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.2,
                }}
              ></Circle>
            </GoogleMap>
          </LoadScript> */}
          <MapContainer
            center={loc}
            zoom={17}
            ref={setMap}
            scrollWheelZoom={true}
            style={{
              width: "100%",
              marginTop: 20,
              height: "calc(100vh - 4rem)",
            }}
          >
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />

            <Marker position={loc} icon={ICON}>
            </Marker>

            <Circle
              radius={tamanhoC}
              center={loc}
              pathOptions={{ color: 'lime', fillColor: 'lime' }}

            ></Circle>
            {ctos !== undefined && (
              <>
                {ctos.map((item, index) => {
                  return <>
                    {index === 0 && (<>
                      <Marker position={[item.lat, item.lng]} icon={ICON2} key={index} eventHandlers={eventHandlers}>
                        <TooltipLeaflet direction="bottom" offset={[0, 20]} opacity={0.8} content={item.nome.nome} mostrar={aparecer} loc={item.lat}></TooltipLeaflet>
                      </Marker>

                    </>)}
                    {index !== 0 && (<>
                      {console.log(aparecer)}
                      <Marker position={[item.lat, item.lng]} icon={ICON3} key={index} eventHandlers={eventHandlers}>
                        <TooltipLeaflet direction="bottom" offset={[0, 20]} opacity={0.8} content={item.nome.nome} mostrar={aparecer} loc={item.lat}></TooltipLeaflet>
                        {/* <Tooltip direction="bottom" offset={[0, 20]} opacity={0.8} permanent>
                        
                          {item.nome.nome}
                        </Tooltip> */}
                      </Marker>
                    </>)}
                  </>
                })}
              </>
            )}

          </MapContainer>
          {dataInfo !== undefined && (
            <Card style={{ marginTop: 10 }}>
              <Table columns={columns} dataSource={dataInfo} />
            </Card>
          )}
        </>
      )
      }
    </>
  );
}


export default CtosConfirmed;
