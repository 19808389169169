import axios from "axios";

export function ExternalURL() {
  const http = axios.create({
    baseURL: "",
    headers: {
      "Content-type": "application/json",
    },
  });

  return {
    http,
  };
}
