import { Card, Col, Row, Avatar } from "antd";
import itemsTicketsAtivos from "./itensMenu"
import { Tabs } from 'antd';
import CardDark from "../../../../component/cardDark";


function TicketsAtivos() {

    return (
        <>
            <CardDark title="Tickets Ativos">
                <Row justify={"start"} style={{ marginBottom: 20 }}>
                    <Col>
                        <Tabs defaultActiveKey="1" items={itemsTicketsAtivos} />
                    </Col>
                </Row>
            </CardDark>
        </>

    );
}

export default TicketsAtivos;