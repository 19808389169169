import { Tabs } from "antd";
import itemsCadastrarPermissoes from "./itemMenu";

function CadastrarPermissoes() {

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1" items={itemsCadastrarPermissoes}
      />
    </>
  );
}

export default CadastrarPermissoes;
