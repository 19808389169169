import { useEffect, useState } from "react";
import { Tooltip } from "react-leaflet";

function TooltipLeaflet({ direction, offset, opacity, content, mostrar, loc }) {

    useEffect(() => { }, [
        loc
    ])

    return (
        <>
            {!mostrar.includes(loc) && (
                <Tooltip direction={direction} offset={offset} opacity={opacity} permanent>
                    {content}
                </Tooltip>
            )}
        </>
    );
}

export default TooltipLeaflet;