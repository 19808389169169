import { Card, Modal, Table, Tabs, Tag } from "antd";
import itemsGerenciarSetor from "./itemsMenu";

function GerenciarSetor() {

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1" items={itemsGerenciarSetor}
      />
    </>
  );
}

export default GerenciarSetor;


