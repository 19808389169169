import { Card, Col, Divider, Row, Modal, notification } from "antd";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
  useMediaQuery,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material/";
import { useEffect, useState } from "react";
import { PostAPI } from "../../../../services/http/PostAPI";
import { hardwareVersion, softwareVersion } from "../../../../consts/olt";
import { useForm } from "react-hook-form";
import CardDark from "../../../../component/cardDark";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function AdicionarOlt() {
  const matches = useMediaQuery("(max-width:710px)");
  const [api, contextHolder] = notification.useNotification();

  const [ip, setIp] = useState();
  const [port, setPort] = useState();
  const [user, setUser] = useState();
  const [pass, setPass] = useState();
  const [tested, setTested] = useState();
  const [irTopo, setIrTopo] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ponType: "GPON",
    },
  });
  const onSubmit = (data) => {
    cadastrarOlt(data);
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const cadastrarOlt = (data) => {
    const { http } = PostAPI();

    http
      .post("/olt-create", {
        nome: data.nome,
        olt_ip: data.olt_ip,
        telnet_tcp_port: data.telnet_tcp_port,
        olt_telnet_username: data.olt_telnet_username,
        olt_telnet_password: data.olt_telnet_password,
        snmp_read_only_community: data.snmp_read_only_community,
        snmp_read_write_community: data.snmp_read_write_community,
        snmp_udp_port: data.snmp_udp_port,
        iptv_module: data.iptvModule,
        olt_hardware_version: data.olt_hardware_version,
        olt_software_version: data.olt_software_version,
        supported_pon_type: data.ponType,
      })
      .then((res) => {
        if (res.status) {
          alertMessage(
            "success",
            "Cadastro OLT",
            "OLT cadastrada com successo!"
          );
          reset();
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const testarOlt = () => {
    const { http } = PostAPI();

    if (
      ip !== undefined ||
      port !== undefined ||
      user !== undefined ||
      pass !== undefined
    ) {
      http
        .post("/ssh-test-connection", {
          ip: ip,
          port: port,
          user: user,
          pass: pass,
        })
        .then((res) => {
          if (res.status) {
            setTested(true);
            alertMessage(
              "success",
              "Cadastro OLT",
              "A conexão feita com sucesso!"
            );
          }
        })
        .catch(async function (err) {
          setTested(undefined);
          alertMessage(
            "error",
            "Cadastro OLT",
            "A conexão de teste com a OLT falhou"
          );
        });
    } else {
      alertMessage(
        "error",
        "Testar conexão",
        "Faltam informações para testar a conexão"
      );
    }
  };

  useEffect(() => {
    if (irTopo === undefined) {
      window.scrollTo(0, 0);
      setIrTopo(true);
    }
  }, []);

  return (
    <>
      {contextHolder}
      <CardDark title="Adicionar OLT" id="topo">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row style={rowPrincipal}>
            <Col xs={24} sm={24} md={24} lg={18} xl={12}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Nome"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("nome")}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="OLT IP"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("olt_ip")}
                  onChange={(e) => {
                    setIp(e.target.value);
                  }}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="Telnet TCP Port"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("telnet_tcp_port")}
                  onChange={(e) => {
                    setPort(e.target.value);
                  }}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="OLT Telnet Username"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("olt_telnet_username")}
                  onChange={(e) => {
                    setUser(e.target.value);
                  }}
                ></TextField>

                {/* <TextField
                  id="outlined-basic"
                  label="OLT Telnet Password"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("olt_telnet_password")}
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                ></TextField> */}

                <FormControl variant="outlined" style={separadorInput20}>
                  <InputLabel htmlFor="outlined-adornment-password" size="small">OLT Telnet Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    {...register("olt_telnet_password")}
                    onChange={(e) => {
                      setPass(e.target.value);
                    }}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="OLT Telnet Password"
                  />
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="SNMP Read-Only Community"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("snmp_read_only_community")}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="SNMP Read-Write Community"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("snmp_read_write_community")}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="SNMP UDP Port"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("snmp_udp_port")}
                ></TextField>
              </FormControl>
              <FormLabel component="legend">IPTV Module</FormLabel>
              <FormControlLabel
                style={separadorInput20}
                control={<Checkbox />}
                {...register("iptvModule")}
                label="Enable"
              />

              <FormControl fullWidth style={separadorInput20}>
                <InputLabel id="todas-olts" size="small">
                  OLT Hardware Version
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  label="OLT Hardware Version"
                  {...register("olt_hardware_version")}
                  size="small"
                  required
                >
                  {hardwareVersion.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={separadorInput20}>
                <InputLabel id="todas-olts" size="small">
                  OLT Software Version
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  //value={10}
                  label="OLT Software Version"
                  {...register("olt_software_version")}
                  required
                  size="small"
                >
                  {softwareVersion.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Tipo de PON
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  style={separadorInput20}
                  {...register("ponType")}
                  defaultValue="GPON"
                >
                  <FormControlLabel
                    value="GPON"
                    control={<Radio {...register("ponType")} />}
                    label="GPON"
                  />
                  <FormControlLabel
                    value="EPON"
                    control={<Radio {...register("ponType")} />}
                    label="EPON"
                  />
                  <FormControlLabel
                    value="GPON+EPON"
                    control={<Radio {...register("ponType")} />}
                    label="GPON+EPON"
                  />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
          <Divider></Divider>
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={18}
              xl={12}
              style={matches === true ? styleButtonsColumn : styleButtons}
            >
              <div style={matches === true ? styleButtonsColumn : styleButtons}>
                <Button
                  variant="contained"
                  size="small"
                  style={matches === true ? marginBottom10 : marginRight10}
                  type="submit"
                  disabled={tested === undefined ? true : false}
                >
                  Cadastrar
                </Button>
              </div>
              <div
                style={
                  matches === true
                    ? {
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }
                    : {}
                }
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    testarOlt();
                  }}
                >
                  Testar Conexão
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </CardDark>
    </>
  );
}

export default AdicionarOlt;

//CSS

const marginBottom10 = {
  marginBottom: 10,
};

const marginRight10 = {
  marginRight: 10,
};

const styleButtons = {
  display: "flex",
  justifyContent: "space-between",
};

const styleButtonsColumn = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const rowPrincipal = {
  alignItems: "center",
  justifyContent: "center",
};
const separadorInput20 = {
  marginBottom: 20,
};
