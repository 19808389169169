import { Card, Col, Divider, Image, Row, Table, Tabs, Transfer } from "antd";
import CardDark from "../../../component/cardDark";
import { useForm } from "react-hook-form";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import itemsComissaoVendas from "./itemsMenu";


function PlanilhaDeComissao() {

    return (
        <>
            <Tabs
                type="card"
                defaultActiveKey="1"
                destroyInactiveTabPane={true}
                items={itemsComissaoVendas}
            />
        </>
    );
}

export default PlanilhaDeComissao;