import { socket } from "../socket";
import React, { useEffect, useState } from "react";
import { MyForm } from "../../../../component/chat/Form";
import { Events } from "../../../../component/chat/Events";
import { Card, Col, Divider, Row, Badge, Descriptions } from "antd";
import { Button } from "@mui/material";
import CardDark from "../../../../component/cardDark";

function ChatSocketIo() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  function connect() {
    socket.connect();
  }

  function disconnect() {
    socket.disconnect();
  }

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents((previous) => [...previous, value]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, []);
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CardDark title="SOCKET IO">
            <Descriptions bordered>
              <Descriptions.Item
                label="Status"
                span={3}
                style={{ textAlign: "end" }}
              >
                <span>
                  {isConnected === true ? (
                    <Badge status="success" text="On" />
                  ) : (
                    <Badge status="error" text="Off" />
                  )}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label="Controls"
                span={3}
                style={{ textAlign: "end" }}
              >
                {isConnected === true ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={disconnect}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    Disconnect
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={connect}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    Connect
                  </Button>
                )}
              </Descriptions.Item>
            </Descriptions>
          </CardDark>
        </Col>
      </Row>

      <CardDark title="TESTE BACKEND" style={{ marginTop: 20 }}>
        <MyForm />
      </CardDark>
      {fooEvents.length > 0 && (
        <>
          <Events events={fooEvents} />
        </>
      )}
    </>
  );
}

export default ChatSocketIo;
