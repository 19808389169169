import img1 from "./logos/whatsapp.png";
import img2 from "./logos/wildrift.png";
import img3 from "./logos/roblox.png";
import img4 from "./logos/lol.png";
import img5 from "./logos/valorant.png";
import img6 from "./logos/rainbowsix.png";
import img7 from "./logos/vivo.png";
import img8 from "./logos/telegram.png";
import img9 from "./logos/dota.png";
import img10 from "./logos/santander.png";
import img11 from "./logos/uber.png";
import img12 from "./logos/anydesk.png";
import img13 from "./logos/alelo.png";
import img14 from "./logos/nfe.png";
import img15 from "./logos/canva.png";
import img16 from "./logos/caixa_economica.png";
import img17 from "./logos/spotify.png";
import img18 from "./logos/discord.png";
import img19 from "./logos/tim.png";
import img20 from "./logos/instagram.png";
import img21 from "./logos/mercadolivre.png";
import img22 from "./logos/Banco_Central_Do_Brasil.png";
import img23 from "./logos/banco-inter.png";
import img24 from "./logos/freefire.png";
import img25 from "./logos/picpay.png";
import img26 from "./logos/youtube.png";
import img27 from "./logos/x.png";
import img28 from "./logos/claro.png";
import img29 from "./logos/nubank.png";
import img30 from "./logos/itau.png";
import img31 from "./logos/ifood.png";
import img32 from "./logos/ecac.png";
import img33 from "./logos/call_of_duty.png";
import img34 from "./logos/esocial.png";
import img35 from "./logos/Playstation Network.png";
import img36 from "./logos/bradesco.png";
import img37 from "./logos/banco do brasil.png";
import img38 from "./logos/c6-bank.png";
import img39 from "./logos/xbox live.png";
import img40 from "./logos/Fifa-ea.png";
import img41 from "./logos/facebook.png";
import img42 from "./logos/amazon.png";
import img43 from "./logos/netflix.png";
import img44 from "./logos/blizzard.png";
import img45 from "./logos/gmail.png";
import img46 from "./logos/gta5.png";
import img47 from "./logos/google drive.png";
import img48 from "./logos/google.png";
import img49 from "./logos/microsoft teams.png";



const imagesBackground = [
    { servico: 'Whatsapp', url: img1 },
    { servico: 'League of Legends: Wild Rift', url: img2 },
    { servico: 'Roblox', url: img3 },
    { servico: 'League of Legends', url: img4 },
    { servico: 'Valorant', url: img5 },
    { servico: 'Rainbow Six', url: img6 },
    { servico: 'Vivo', url: img7 },
    { servico: 'Telegram', url: img8 },
    { servico: 'Dota 2', url: img9 },
    { servico: 'Banco Santander', url: img10 },
    { servico: 'Uber', url: img11 },
    { servico: 'Anydesk', url: img12 },
    { servico: 'Alelo', url: img13 },
    { servico: 'Nota fiscal eletrônica', url: img14 },
    { servico: 'Canva', url: img15 },
    { servico: 'Caixa Econômica Federal', url: img16 },
    { servico: 'Spotify', url: img17 },
    { servico: 'Discord', url: img18 },
    { servico: 'TIM', url: img19 },
    { servico: 'Instagram', url: img20 },
    { servico: 'Mercado Livre', url: img21 },
    { servico: 'Banco Central', url: img22 },
    { servico: 'Banco Inter', url: img23 },
    { servico: 'Free Fire', url: img24 },
    { servico: 'PicPay', url: img25 },
    { servico: 'Youtube', url: img26 },
    { servico: 'X (Twitter)', url: img27 },
    { servico: 'Claro', url: img28 },
    { servico: 'Nubank', url: img29 },
    { servico: 'Banco Itaú', url: img30 },
    { servico: 'iFood', url: img31 },
    { servico: 'eCAC', url: img32 },
    { servico: 'Call of Duty', url: img33 },
    { servico: 'eSocial', url: img34 },
    { servico: 'Playstation Network', url: img35 },
    { servico: 'Bradesco', url: img36 },
    { servico: 'Banco do Brasil', url: img37 },
    { servico: 'C6 Bank', url: img38 },
    { servico: 'Xbox Live', url: img39 },
    { servico: 'Fifa', url: img40 },
    { servico: 'Facebook', url: img41 },
    { servico: 'Amazon', url: img42 },
    { servico: 'Netflix', url: img43 },
    { servico: 'Blizzard Battle.net', url: img44 },
    { servico: 'Google', url: img45 },
    { servico: 'GTA 5', url: img46 },
    { servico: 'Google Drive', url: img47 },
    { servico: 'Google', url: img48 },
    { servico: 'Microsoft Teams', url: img49 },
];

export default imagesBackground;