import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
  useMediaQuery,
  Divider,
  Menu,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material/";
import { Card, Modal, Table, notification, Dropdown, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { DeleteAPI } from "../../../../services/http/DeleteAPI";
import { useForm } from "react-hook-form";
import { hardwareVersion, softwareVersion } from "../../../../consts/olt";
import { PostAPI } from "../../../../services/http/PostAPI";
import { PutAPI } from "../../../../services/http/PutAPI";
import { NodeRequests } from "../../../../services/http/NodeRequests";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LoadingImage from "../../../../component/loadingImage";
import CardDark from "../../../../component/cardDark";
import { loadState } from "../../../../services/localStorage";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function GerenciarOlt() {
  const dataInfo = [];
  let dataOlt = [];

  const [ip, setIp] = useState();
  const [port, setPort] = useState();
  const [user, setUser] = useState();
  const [pass, setPass] = useState();
  const [loading, setLoading] = useState(true);
  const [allOlts, setAllOlts] = useState();
  const [isModalOpenExcluir, setIsModalOpenExcluir] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenONU, setIsModalOpenONU] = useState();
  const [idModalExcluir, setIdModalExcluir] = useState();
  const [nomeModalExcluir, setNomeModalExcluir] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [oltEdit, setOltEdit] = useState();
  const [infoOlt, setInfoOlt] = useState();
  const [irTopo, setIrTopo] = useState();
  const [editOlt, setEditOlt] = useState();
  const [onuClicada, setOnuClicada] = useState();
  const navigate = useNavigate();
  let userInfo = loadState("userPages");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    editarOlt(data, oltEdit.id);
    setButtonDisabled(true);
    setIsModalOpenEdit(false);
    reset();
    buscarOLT();
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseEditar = (e) => {
    showModalEditar(e);
    setAnchorEl(null);
  };

  const handleCloseExcluir = (e) => {
    showModalExcluir(e);
    setAnchorEl(null);
  };

  const handleCloseNaoAutorizadas = (e) => {
    showModalONU(e);
    setAnchorEl(null);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Nome",
      dataIndex: "nome",
      width: 100,
    },
    {
      title: "Ip",
      dataIndex: "ip_view",
      width: 130,
    },
    {
      title: "Porta",
      dataIndex: "port_view",
      width: 70,
    },
    {
      title: "Tipo de PON",
      dataIndex: "pon_type",
      width: 150,
    },
    {
      title: "Ações",
      key: "operation",
      width: 200,
      render: (e) => (
        <>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            color="success"
            size="small"
            disableElevation
            onClick={(event) => {
              handleClick(event)
              setEditOlt(e)
            }}

            endIcon={<KeyboardArrowDownIcon />}
          >
            Opções
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {

                navigate("onu-autorizadas", { state: { cadastro: false, onuAnterior: e } });
              }}
              disableRipple
            >
              <CheckCircleIcon style={{ color: "blue" }} />
              <span style={{ color: "blue" }}>ONU autorizadas</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseNaoAutorizadas(e);
              }}
              disableRipple
            >
              <CancelIcon style={{ color: "secundary" }} />
              <span style={{ color: "secundary" }}>ONU não autorizadas</span>
            </MenuItem>
            {userInfo.includes('9') && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={() => {
                    handleCloseEditar(e);
                  }}
                  disableRipple
                >
                  <EditIcon style={{ color: "green" }} />
                  <span style={{ color: "green" }}>Editar</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseExcluir(editOlt);
                  }}
                  disableRipple
                >
                  <FileCopyIcon style={{ color: "red" }} />
                  <span style={{ color: "red" }}>Excluir</span>
                </MenuItem>
              </>
            )}

          </StyledMenu>
        </>
      ),
    },
  ];

  const columnsOlt = [
    {
      title: "Nº",
      dataIndex: "number",
      width: 50,
    },
    {
      title: "Slot",
      key: "slot",
      width: 50,
      render: (_, e) => <span>{e.slot}</span>,
    },
    {
      title: "Pon",
      key: "pon",
      width: 50,
      render: (_, e) => <span>{e.pon}</span>,
    },
    {
      title: "Oltsn",
      key: "oltsn",
      width: 170,
      render: (_, e) => {
        return (
          <div>
            <Link
              to={"/olt-gerenciar/olt-autorizar"}
              state={{ olt: e, onuClicada: editOlt }}
            >
              <Tag>{e.oltsn}</Tag>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Vendorid",
      dataIndex: "vendorid",
      width: 100,
    },
    {
      title: "Ontsv",
      dataIndex: "ontsv",
      width: 150,
    },
    {
      title: "Onte",
      dataIndex: "onte",
      width: 100,
    },
  ];

  const showModalEditar = (e) => {
    setShowPassword(false)
    setOltEdit(e);
    setPort(e.port);
    setIp(e.ip);
    setUser(e.user);
    setPass(e.pass);
    setIsModalOpenEdit(true);
  };
  const handleOkEditar = () => {
    setIsModalOpenEdit(false);
  };
  const handleCancelEditar = () => {
    reset();
    setIsModalOpenEdit(false);
  };

  const showModalExcluir = (e) => {
    setIdModalExcluir(e.id);
    setNomeModalExcluir(e.nome);
    setIsModalOpenExcluir(true);
  };
  const handleOkExcluir = () => {
    excluirOlt(idModalExcluir);
    setIsModalOpenExcluir(false);
  };
  const handleCancelExcluir = () => {
    reset();
    setIsModalOpenExcluir(false);
  };

  const showModalONU = async (e) => {
    setOnuClicada(e);
    await buscarOltNaoAutorizadas(e);
    setIsModalOpenONU(true);
  };
  const handleOkONU = () => {
    setIsModalOpenONU(false);
  };
  const handleCancelONU = () => {
    reset();
    setInfoOlt(undefined);
    dataOlt = [];
    setIsModalOpenONU(false);
  };

  const buscarOLT = () => {
    const { http } = GetAPI();

    http
      .get("/olt-getall")
      .then((res) => {
        if (res.status) {
          setAllOlts(res.data);

          setLoading(false);
        }
      })
      .catch(async function (err) {
        setLoading(false);
        console.log(err);
      });
  };

  const buscarOltNaoAutorizadas = async (olt) => {
    const { http } = NodeRequests();

    await http
      .post(`/olt-verificar/${editOlt.id}`, {
        X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET,
      })
      .then((res) => {
        if (res.status) {
          setInfoOlt(res.data.olts);
        }
      })
      .catch(async function (err) {
        setLoading(false);
        console.log(err);
      });

  };

  const excluirOlt = (id) => {
    const { http } = DeleteAPI();

    http
      .delete(`olt-delete/${id}`)
      .then((res) => {
        if (res.status) {
          alertMessage(
            "success",
            "Exclusão de OLT",
            "OLT excluída com sucesso"
          );
          buscarOLT();
        } else {
          alertMessage("error");
        }
      })
      .catch((erro) => {
        alertMessage(
          "error",
          "Exclusão de OLT",
          "OLT não pode ser excluída com sucesso"
        );
      });
  };

  const testarOlt = (data) => {
    const { http } = PostAPI();

    http
      .post("/ssh-test-connection", {
        ip: ip,
        port: port,
        user: user,
        pass: pass,
      })
      .then((res) => {
        if (res.status) {
          setButtonDisabled(false);
          alertMessage("success", "Editar OLT", "Olt editada com sucesso!");
        }
      })
      .catch(async function (err) {
        setButtonDisabled(true);
        alertMessage("error", "Editar OLT", "A edição da Olt falhou!");
      });
  };

  const editarOlt = (data, id) => {
    const { http } = PutAPI();

    http
      .put(`/olt-edit/${id}`, {
        nome: data.nome,
        olt_ip: data.olt_ip,
        telnet_tcp_port: data.telnet_tcp_port,
        olt_telnet_username: data.olt_telnet_username,
        olt_telnet_password: data.olt_telnet_password,
        snmp_read_only_community: data.snmp_read_only_community,
        snmp_read_write_community: data.snmp_read_write_community,
        snmp_udp_port: data.snmp_udp_port,
        iptv_module: data.iptvModule,
        olt_hardware_version: data.olt_hardware_version,
        olt_software_version: data.olt_software_version,
        supported_pon_type: data.ponType,
      })
      .then((res) => {
        if (res.status) {
          alertMessage("success", "Editar OLT", "Olt editada com sucesso!");
          reset();
        }
      })
      .catch(async function (err) {
        alertMessage("error", "Editar OLT", "A edição da Olt falhou!");
      });
  };

  useEffect(() => {
    if (allOlts === undefined) {
      buscarOLT();
    }

    if (irTopo === undefined) {
      window.scrollTo(0, 0);
      setIrTopo(true);
    }
  }, [oltEdit]);

  if (loading === false) {
    allOlts.map((item, index) => {

      let oltdisplay = item.olt_ip.split(".")

      dataInfo.push({
        id: item.id,
        nome: item.nome,
        ip: item.olt_ip,
        ip_view: `* * *. * * * .${oltdisplay[2]}.${oltdisplay[3]}`,
        key: index,
        iptv_module: item.iptv_module === 1 ? true : false,
        hardware_version: item.olt_hardware_version,
        software_version: item.olt_software_version,
        user: item.olt_telnet_username,
        pass: item.olt_telnet_password,
        ro: item.snmp_read_only_community,
        rw: item.snmp_read_write_community,
        udp_port: item.snmp_udp_port,
        pon_type: item.supported_pon_type,
        port_view: "* *",
        port: item.telnet_tcp_port,
      });

      return null;
    });
  }

  if (infoOlt !== undefined) {
    infoOlt.map((item, index) => {
      let slotPon = item.fsp.split("/");

      dataOlt.push({
        key: index,
        number: item.number,
        slot: slotPon[1],
        pon: slotPon[2],
        oltsn: item.oltsn,
        vendorid: item.vendorid,
        ontsv: item.ontsv,
        onte: item.onte,
        ip: item.ip,
      });
    });
  }

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          <CardDark title="Gerenciar Olt">
            <Table
              columns={columns}
              dataSource={dataInfo}
              scroll={{
                y: 240,
              }}
            />
          </CardDark>
          <Modal
            title="Editar OLT"
            open={isModalOpenEdit}
            onOk={handleOkEditar}
            onCancel={handleCancelEditar}
            destroyOnClose={true}
            footer={[
              <Button
                block
                onClick={handleCancelEditar}
                variant="outlined"
                style={{ marginRight: "10px" }}
              >
                cancel
              </Button>,
              <Button
                block
                key="submit"
                type="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={buttonDisabled}
              >
                Salvar
              </Button>,
            ]}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                fullWidth
                style={{ marginTop: "20px" }}
                id="category-editor-form"
              >
                <TextField
                  id="outlined-basic"
                  label="Nome"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("nome")}
                  defaultValue={editOlt ? editOlt.nome : null}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="OLT IP"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("olt_ip")}
                  onChange={(e) => {
                    setIp(e.target.value);
                  }}
                  defaultValue={editOlt ? editOlt.ip : null}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="Telnet TCP Port"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("telnet_tcp_port")}
                  onChange={(e) => {
                    setPort(e.target.value);
                  }}
                  defaultValue={editOlt ? editOlt.port : null}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="OLT Telnet Username"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("olt_telnet_username")}
                  onChange={(e) => {
                    setUser(e.target.value);
                  }}
                  defaultValue={editOlt ? editOlt.user : null}
                ></TextField>

                {/* <TextField
                  id="outlined-basic"
                  label="OLT Telnet Password"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("olt_telnet_password")}
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                  defaultValue={oltEdit ? oltEdit.pass : null}
                ></TextField> */}

                <FormControl variant="outlined" style={separadorInput20}>
                  <InputLabel htmlFor="outlined-adornment-password" size="small">OLT Telnet Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    required
                    defaultValue={editOlt ? editOlt.pass : null}
                    {...register("olt_telnet_password")}
                    onChange={(e) => {
                      setPass(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="OLT Telnet Password"
                  />
                </FormControl>




                <TextField
                  id="outlined-basic"
                  label="SNMP Read-Only Community"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("snmp_read_only_community")}
                  defaultValue={editOlt ? editOlt.ro : null}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="SNMP Read-Write Community"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("snmp_read_write_community")}
                  defaultValue={editOlt ? editOlt.rw : null}
                ></TextField>

                <TextField
                  id="outlined-basic"
                  label="SNMP UDP Port"
                  required
                  variant="outlined"
                  style={separadorInput20}
                  size="small"
                  {...register("snmp_udp_port")}
                  defaultValue={editOlt ? editOlt.udp_port : null}
                ></TextField>
              </FormControl>
              <FormLabel component="legend">IPTV Module</FormLabel>
              <FormControlLabel
                style={separadorInput20}
                control={
                  <Checkbox
                    defaultChecked={editOlt ? editOlt.iptv_module : false}
                  />
                }
                {...register("iptvModule")}
                label="Enable"
              />

              <FormControl fullWidth style={separadorInput20}>
                <InputLabel id="todas-olts" size="small">
                  OLT Hardware Version
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  label="OLT Hardware Version"
                  {...register("olt_hardware_version")}
                  defaultValue={editOlt ? editOlt.hardware_version : null}
                  size="small"
                  required
                >
                  {hardwareVersion.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={separadorInput20}>
                <InputLabel id="todas-olts" size="small">
                  OLT Software Version
                </InputLabel>
                <Select
                  labelId="todas-olts"
                  id="todas-olts"
                  //value={10}
                  label="OLT Software Version"
                  defaultValue={editOlt ? editOlt.software_version : null}
                  {...register("olt_software_version")}
                  required
                  size="small"
                >
                  {softwareVersion.map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Tipo de PON
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  style={separadorInput20}
                  {...register("ponType")}
                  defaultValue="GPON"
                >
                  <FormControlLabel
                    value="GPON"
                    control={<Radio {...register("ponType")} />}
                    label="GPON"
                  />
                  <FormControlLabel
                    value="EPON"
                    control={<Radio {...register("ponType")} />}
                    label="EPON"
                  />
                  <FormControlLabel
                    value="GPON+EPON"
                    control={<Radio {...register("ponType")} />}
                    label="GPON+EPON"
                  />
                </RadioGroup>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    testarOlt();
                  }}
                >
                  Testar conexão
                </Button>
              </FormControl>
            </form>
          </Modal>
          <Modal
            title="Excluir setor"
            open={isModalOpenExcluir}
            onOk={handleOkExcluir}
            onCancel={handleCancelExcluir}
            destroyOnClose={true}
          >
            <p>
              Realmente deseja excluir a OLT:{" "}
              <strong style={{ color: "red" }}>{nomeModalExcluir}</strong>
            </p>
          </Modal>
          <Modal
            title="ONUs não autorizadas"
            open={isModalOpenONU}
            onOk={handleOkONU}
            onCancel={handleCancelONU}
            destroyOnClose={true}
            width={1000}
          >
            <Table
              columns={columnsOlt}
              dataSource={dataOlt ? dataOlt : []}
              scroll={{
                y: 240,
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
}

export default GerenciarOlt;

const buttonsTable = {
  marginRight: 5,
  marginBottom: 5,
  marginTop: 5,
  marginLeft: 5,
};

const separadorInput20 = {
  marginBottom: 20,
};
