import { Card, Col, Divider, Row, Drawer, Space, Table, Tag } from "antd";
import { useEffect, useState, useRef } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
//import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import LoadingImage from "../../../../component/loadingImage";
import { useReactToPrint } from "react-to-print";
import { Excel } from "antd-table-saveas-excel";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import CardDark from "../../../../component/cardDark";
import { Circle, MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from "react-leaflet";
import { icon } from "leaflet";
import PngIcon from "./../../../../assets/img/placeholder.png";
import PngIcon2 from "./../../../../assets/img/placeholder_cto.png";
import PngIcon3 from "./../../../../assets/img/placeholder_cto2.png";
import MapPrint from "../../../../component/mapPrint";


const ICON = icon({
  iconUrl: PngIcon,
  iconSize: [32, 32],
});

const ICON2 = icon({
  iconUrl: PngIcon2,
  iconSize: [32, 32],
});

const ICON3 = icon({
  iconUrl: PngIcon3,
  iconSize: [32, 32],
});


const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#002050",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#99e472",
    },
  },
});

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Fone",
    dataIndex: "fone",
    key: "fone",
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    key: "longitude",
  },
  {
    title: "Data de Registro",
    dataIndex: "dt_regis",
    key: "dt_regis",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Responsável",
    dataIndex: "responsavel",
    key: "responsavel",
  },
];

function LocalidadesInviaveis() {
  const dataInfo = [];
  const [loading, setLoading] = useState(true);
  const [allViabilidade, setAllViabilidade] = useState();
  const [load, setLoad] = useState(false);
  const containerStyle = { width: "100%", height: "600px" };
  const [center, setCenter] = useState({ lat: -15.9028, lng: -47.776 });
  const [map, setMap] = useState(null)

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataInfo, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const carregou = () => {
    setLoad(true);
  };

  const confirmationViability = () => {
    const { http } = GetAPI();

    http
      .get("/viabilidade-getall")
      .then((res) => {
        if (res.status) {

          console.log(res.data)
          setAllViabilidade(res.data);
          setLoading(false);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    if (allViabilidade === undefined) {
      confirmationViability();
    }
  });

  if (loading === false) {
    allViabilidade.map((item, index) => {
      if (item.viabilidade === "inviavel") {
        dataInfo.push({
          id: item.id,
          fone: item.number_phone,
          latitude: item.lat,
          longitude: item.lng,
          dt_regis: item.date_register,
          status: item.viabilidade,
          validade: item.validate_link,
          responsavel: item.responsible,
          key: index,
        });
      }

      return null;
    });
  }
  return (
    <>
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          <CardDark title="Localidades Inviáveis">
            <Table columns={columns} dataSource={dataInfo} ref={componentRef} />

            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                size="small"
                onClick={handlePrint}
                style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
              >
                Imprimir e pdf
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleClick}
                style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
              >
                Excel
              </Button>
            </ThemeProvider>
          </CardDark>

          <div style={{ marginTop: 20 }}>
            {/* <LoadScript
              googleMapsApiKey={process.env.REACT_APP_KEY_GOOGLE}
              onLoad={carregou}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                id="drawing-manager-example"
                center={center}
                zoom={17}
                options={{
                  mapTypeControl: false,
                  mapTypeId: "satellite",
                  disableDefaultUI: true,
                  gestureHandling: "greedy",
                }}
              >
                {load === true && <Marker position={center} />}
                {load === true &&
                  dataInfo.map((item) => {
                    return (
                      <Marker
                        animation={window.google.maps.Animation.DROP}
                        icon={{
                          path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                          fillColor: "blue",
                          fillOpacity: 1,
                          strokeWeight: 0,
                          rotation: 0,
                          scale: 2,
                          anchor: window.google.maps.Point(0, 0),
                        }}
                        position={{
                          lat: Number(item.latitude),
                          lng: Number(item.longitude),
                        }}
                      />
                    );
                  })}
              </GoogleMap>
            </LoadScript> */}

            <MapContainer
              center={center}
              zoom={17}
              ref={setMap}
              scrollWheelZoom={true}
              style={{
                width: "100%",
                marginTop: 20,
                height: "calc(100vh - 4rem)",
              }}
            >
              <TileLayer
                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />

              {allViabilidade.map((i, index) => {

                return <Marker position={[i.lat, i.lng]} icon={ICON} key={index}>
                  <Popup>
                    <strong>{i.number_phone}<br /></strong>
                    Responsável: {i.responsible}<br />
                  </Popup>
                </Marker>
              })}
              <MapPrint
                position="topleft"
                sizeModes={["Current", "A4Portrait", "A4Landscape"]}
                hideControlContainer={false}
                title="Print"
              />
              <MapPrint
                position="topleft"
                sizeModes={["Current", "A4Portrait", "A4Landscape"]}
                hideControlContainer={false}
                title="Export as PNG"
                exportOnly
              />
            </MapContainer>
          </div>
        </>
      )
      }
    </>
  );
}

export default LocalidadesInviaveis;
