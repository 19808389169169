import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
} from "@mui/material";
import {
  Card,
  Modal,
  Table,
  notification,
  Dropdown,
  Space,
  Tag,
  Row,
  Col,
  Divider,
} from "antd";
import { useState, useRef, useEffect } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { GetAPI } from "../../../../services/http/GetAPI";
import { DeleteAPI } from "../../../../services/http/DeleteAPI";
import { useForm } from "react-hook-form";
import Editor from "@monaco-editor/react";
import { PostAPI } from "../../../../services/http/PostAPI";
import LoadingImage from "../../../../component/loadingImage";
import CardDark from "../../../../component/cardDark";

function OltTemplate() {
  const dataInfo = [];

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [onuTemplate, setOnuTemplate] = useState();
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(true);
  const [allOlts, setAllOlts] = useState();
  const [personName, setPersonName] = useState([]);
  const [parametros, setParametros] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [editTemp, setEditTemp] = useState();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all", mode: "onChange" });
  const onSubmit = (data) => {
    editarTemplate(data, personName, parametros);

    reset();
    setIsModalOpenEdit(false);
    /*        editarOlt(data, oltEdit.id)
               setButtonDisabled(true)
               setIsModalOpenEdit(false);
               buscarOLT() */
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const [isModalOpenExcluir, setIsModalOpenExcluir] = useState(false);
  const [idModalExcluir, setIdModalExcluir] = useState();

  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [idModalEditar, setIdModalEditar] = useState();

  const showModalEditar = (e) => {
    console.log(e);
    setIdModalEditar(e);
    e?.olts.split(",").map((i) => { setPersonName([...personName, Number(i)]); })
    setIsModalOpenEdit(true);
  };
  const handleOkEditar = () => {
    setIsModalOpenEdit(false);
  };
  const handleCancelEditar = () => {
    //reset()
    setIsModalOpenEdit(false);
  };

  const showModalExcluir = (e) => {
    setIdModalExcluir(e.id);
    setIsModalOpenExcluir(true);
  };
  const handleOkExcluir = () => {
    deletarTemplate(idModalExcluir);
    setIsModalOpenExcluir(false);
  };
  const handleCancelExcluir = () => {
    setIsModalOpenExcluir(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Procurar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fechar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "AddCmd",
      dataIndex: "add_cmd",
      key: "add_cmd",
    },
    {
      title: "Delcmd",
      dataIndex: "del_cmd",
      key: "del_cmd",
    },
    {
      title: "Parâmetros",
      dataIndex: "parametros",
      key: "parametros",
    },
    {
      title: "Ações",
      key: "operation",
      width: 150,
      render: (_, e) => (
        <>
          <Button
            variant="contained"
            onClick={() => {
              showModalEditar(e);
            }}
            color="success"
            style={buttonsTable}
            size="small"
          >
            Editar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              showModalExcluir(e);
            }}
            color="error"
            size="small"
            style={buttonsTable}
          >
            Excluir
          </Button>
        </>
      ),
    },
  ];

  const editarTemplate = (data, olts, parametros) => {
    const { http } = PostAPI();

    let arrayOlts = olts.filter(function (este, i) {
      return olts.indexOf(este) === i;
    });

    try {
      if (typeof JSON.parse(parametros) === "object") {
        if (parametros.length !== 0) {
          http
            .put(`/onu-template-edit/${idModalEditar.id}`, {
              descricao: data.descricao,
              parametros: JSON.stringify(JSON.parse(parametros)),
              addcmd: data.AddCmd,
              delcmd: data.DelCmd,
              pon: data.pon,
              slot: data.slot,
              oltmodo: data.oltmodo,
              olts: arrayOlts,
            })
            .then((res) => {
              if (res.status) {
                buscarTemplates();
                alertMessage(
                  "success",
                  "Template Olt",
                  "Template editado com sucesso!"
                );
              }
            })
            .catch(async function (err) {
              console.log(err);
              alertMessage(
                "error",
                "Template Olt",
                "Template não pode ser editado com sucesso!"
              );
            });
        } else {
        }
      }
    } catch (error) {
      alertMessage(
        "error",
        "Template Olt Editar",
        "Os parâmetros não são objetos json"
      );
    }
  };

  const buscarTemplates = () => {
    const { http } = GetAPI();

    http
      .get("/onu-template-getall")
      .then((res) => {
        if (res.status) {
          setOnuTemplate(res.data);
        }
      })
      .catch(async function (err) {
        //setLoading(false)
        console.log(err);
      });
  };

  const buscarOLT = () => {
    const { http } = GetAPI();

    http
      .get("/olt-getall")
      .then((res) => {
        if (res.status) {
          setAllOlts(res.data);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  const deletarTemplate = (id) => {
    const { http } = DeleteAPI();

    http
      .delete(`/onu-template-delete/${id}`)
      .then((res) => {
        if (res.status) {
          //setAllOlts(res.data)

          alertMessage(
            "success",
            "Template Olt",
            "Template excluído com sucesso!"
          );
          buscarTemplates();
        }
      })
      .catch(async function (err) {
        //setLoading(false)

        alertMessage(
          "error",
          "Template Olt",
          "Template não pode ser excluído com sucesso!"
        );
      });
  };

  useEffect(() => {
    if (onuTemplate === undefined) {
      buscarTemplates();
    }
    if (allOlts === undefined) {
      buscarOLT();
    }

    if (onuTemplate !== undefined && allOlts !== undefined) {
      setLoading(false);
    }


  }, [loading, onuTemplate, allOlts, idModalEditar]);

  if (loading === false) {
    onuTemplate.map((item, index) => {
      dataInfo.push({
        id: item.id,
        add_cmd: item.add_cmd,
        del_cmd: item.del_cmd,
        parametros: item.parametros,
        descricao: item.descricao,
        olt_modo: item.olt_modo,
        olts: item.olts,
        slot: item.slot,
        pon: item.pon,
        key: index,
      });

      return null;
    });
  }

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <CardDark title="Template OLT">
          <Row justify={"end"} style={{ marginBottom: 20 }}>
            <Link to={"/olt-template/adicionar"} /*state={{ olt: e }}*/>
              <Button variant="contained" size="small">
                Adicionar Template
              </Button>
            </Link>
          </Row>
          <Row>
            <Col sx={24} sm={24} md={24} lg={24} xl={24}>
              <Table columns={columns} dataSource={dataInfo} />
            </Col>
          </Row>
          <Modal
            title="Excluir Template OLTs"
            open={isModalOpenExcluir}
            onOk={handleOkExcluir}
            onCancel={handleCancelExcluir}
            destroyOnClose={true}
          >
            <p>
              Realmente deseja excluir o Template:{" "}
              <strong style={{ color: "red" }}>{idModalExcluir}</strong>
            </p>
          </Modal>
          <Modal
            title="Editar Template OLTs"
            open={isModalOpenEdit}
            onOk={handleOkEditar}
            onCancel={handleCancelEditar}
            destroyOnClose={true}
            width={1000}
            footer={[
              <Button
                block
                onClick={handleCancelEditar}
                variant="outlined"
                style={{ marginRight: "10px" }}
                size="small"
              >
                cancel
              </Button>,
              <Button
                block
                key="submit"
                type="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                size="small"
              >
                Salvar
              </Button>,
            ]}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                fullWidth
                style={{ marginTop: "20px", marginBottom: 20 }}
                id="category-editor-form"
              >
                <TextField
                  id="outlined-basic"
                  label="Descrição"
                  required
                  variant="outlined"
                  size="small"
                  defaultValue={idModalEditar?.descricao === undefined ? "" : idModalEditar.descricao}
                  {...register("descricao", { required: true })}
                ></TextField>
                {errors?.descricao?.types?.required && (
                  <span style={{ color: "red" }}>Descrição obrigatório</span>
                )}
              </FormControl>

              <FormLabel component="legend"> Parâmetros - <strong style={{ color: "red" }}>(SHIFT+ALT+F) para formatar json</strong></FormLabel>

              <Editor
                title="Parametros"
                height={200}
                theme="vs-dark"
                defaultLanguage="json"
                defaultValue={idModalEditar?.parametros === undefined ? "" : idModalEditar.parametros}
                onChange={(e) => {
                  setParametros(e);
                }}
              />

              <div style={{ marginTop: 20 }}></div>
              <TextField
                id="outlined-multiline-static"
                label="AddCmd"
                multiline
                rows={5}
                defaultValue={idModalEditar?.add_cmd === undefined ? "" : idModalEditar.add_cmd}
                fullWidth
                {...register("AddCmd", { required: true })}
              />
              {errors?.AddCmd?.types?.required && (
                <span style={{ color: "red" }}>Número obrigatório</span>
              )}
              <div style={{ marginBottom: 20 }}></div>
              <div style={{ marginTop: 20 }}></div>
              <TextField
                id="outlined-multiline-static"
                label="DelCmd"
                multiline
                rows={5}
                defaultValue={idModalEditar?.del_cmd === undefined ? "" : idModalEditar.del_cmd}
                fullWidth
                {...register("DelCmd", { required: true })}
              />
              {errors?.DelCmd?.types?.required && (
                <span style={{ color: "red" }}>Número obrigatório</span>
              )}
              <div style={{ marginBottom: 20 }}></div>
              <FormLabel component="legend">Olt Modo</FormLabel>
              <FormControlLabel
                style={separadorInput20}
                control={<Checkbox defaultChecked={idModalEditar?.olt_modo === 0 ? false : true} />}
                {...register("oltmodo")}
                label="Ativo"
              />

              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="demo-multiple-name-label" size="small">
                  OLTs
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  defaultValue={idModalEditar?.olts === undefined ? [] : idModalEditar?.olts.split(",").map((i) => { return Number(i) })}
                  onChange={handleChange}
                  required
                  size="small"
                  input={<OutlinedInput label="OLTs" />}
                >
                  {allOlts.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="slot" size="small">
                  Slot
                </InputLabel>
                <Select
                  labelId="slot"
                  id="slot"
                  label="Slot"
                  defaultValue={returnSlotPon(idModalEditar?.slot)}
                  {...register("slot")}
                  size="small"
                >
                  {["Todos", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="pon" size="small">
                  PON
                </InputLabel>
                <Select
                  labelId="pon"
                  id="pon"
                  label="PON"
                  defaultValue={returnSlotPon(idModalEditar?.pon)}
                  {...register("pon")}
                  size="small"
                >
                  {["Todos", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider></Divider>
            </form>
          </Modal>
        </CardDark>
      )}
    </>
  );
}

export default OltTemplate;

const returnSlotPon = (idModalEditar) => {

  if (idModalEditar === undefined) {
    return ""
  } else {

    if (idModalEditar === "Todos") {
      return "Todos"
    } else {
      return Number(idModalEditar)
    }
  }

}

const buttonsTable = {
  marginRight: 5,
  marginBottom: 5,
  marginTop: 5,
  marginLeft: 5,
};

const separadorInput20 = {
  marginBottom: 20,
};
