import { TextField } from "@mui/material";
import { Button, Card, Col, Image, Row } from "antd";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context";
import background from "../../../services/randomImagesBackground";
import logo from "./../../../assets/logo/Logo Completo.png";
import { useEffect } from "react";
import { RedefinirSenhaAPI } from "../../../services/http/RedefinirSenhaAPI";
import { useState } from "react";
import { message, Space } from "antd";

function RegisterPage() {
  const { isExternal, setIsExternal } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Email enviado com sucesso",
    });
  };

  const onConfirmRefresh = function (event) {
    if (event.keyCode === 116) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onConfirmRefresh, {
      capture: true,
    });
  }, []);

  const recoveryLogin = (email) => {
    const { http } = RedefinirSenhaAPI();

    http.get(`/email-recovery?email=${email}`).then((res) => {
      if (res.status) {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Row
        style={{
          backgroundImage: `url(${background})`,
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(2.5px)",
        }}
      ></Row>
      <Row
        justify="center"
        style={{
          position: "absolute",
          top: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <Col md={18} lg={12}>
          <Card
            style={{
              marginTop: 50,
              marginBottom: 50,
              marginLeft: 20,
              marginRight: 20,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <center>
              <Image src={logo} preview={false} width={180}></Image>
            </center>
            <center>
              <h1 style={{ fontFamily: "Segoe UI Emoji" }}>
                Olá, tudo bem? ☺️
              </h1>
            </center>
            <h4>
              Notei que você esqueceu sua senha de acesso, para que possamos
              ajuda-lo informe seu email cadastrado no sistema para o link de
              redefinição de senha
            </h4>

            <TextField
              fullWidth
              label="E-mail"
              id="outlined-size-small"
              size="small"
              style={{ marginBottom: 20 }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <Button
              onClick={() => {
                setIsExternal(false);
                recoveryLogin(email);
                success();
              }}
            >
              Enviar
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RegisterPage;
