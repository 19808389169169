import axios from "axios";

export function WebsTelegram() {
    const http = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL_WEBSTELEGRAM}/api`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });

    return {
        http,
    };
}