import { TextField } from "@mui/material";
import { Button, Card, Col, Image, Row } from "antd";
import background from "../../../services/randomImagesBackground";
import logo from "./../../../assets/logo/Logo Completo.png";
import { useState } from "react";
import { message, Space } from "antd";
import { AuthContext } from "../../../context";
import { useContext } from "react";
import { ExternalAPI } from "../../../services/http/ExternalAPI";
import { Link, useNavigate } from "react-router-dom";

function Register() {
    const {
        setQrCode,
        setCodeAuthentication
    } = useContext(AuthContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [senhaConf, setSenhaConf] = useState("");
    const navigate = useNavigate();

    const registrarConta = () => {
        const { http } = ExternalAPI();

        http.post(`/register`, { name: nome, email: email, password: senha, password_confirmation: senhaConf }).then((res) => {
            if (res.status) {

                setCodeAuthentication(res.data[0].QR_Image)
                setQrCode(res.data[0].secret)

                setTimeout(() => {
                    navigate("/external/register-confirmation");
                }, 2000);

            } else {
                console.log(res)
            }
        });
    };

    return (
        <>
            {contextHolder}
            <Row
                style={styleRowBackground}
            ></Row>
            <Row
                justify="center"
                style={styleRow}
            >
                <Col md={18} lg={12}>
                    <Card
                        style={styleCard}
                    >
                        <center>
                            <Image src={logo} preview={false} width={180}></Image>
                        </center>
                        <center>
                            <h2 style={{ fontFamily: "Segoe UI Emoji" }}>
                                Cadastro de novo usuário
                            </h2>
                        </center>

                        <TextField
                            fullWidth
                            label="Nome"
                            id="outlined-size-small"
                            size="small"
                            style={inputPadding}
                            onChange={(e) => {
                                setNome(e.target.value);
                            }}
                        />

                        <TextField
                            fullWidth
                            label="E-mail"
                            id="outlined-size-small"
                            size="small"
                            style={inputPadding}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Senha"
                            id="outlined-size-small"
                            size="small"
                            style={inputPadding}
                            onChange={(e) => {
                                setSenha(e.target.value);
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Senha confirmação"
                            id="outlined-size-small"
                            size="small"
                            style={inputPadding}
                            onChange={(e) => {
                                setSenhaConf(e.target.value);
                            }}
                        />


                        <Button
                            onClick={() => {
                                registrarConta();
                            }}
                        >
                            Enviar
                        </Button>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Register;

const inputPadding = {
    paddingBottom: 20
}

const styleCard = {
    marginTop: 50,
    marginBottom: 50,
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
}

const styleRow = {
    position: "absolute",
    top: 0,
    height: "100vh",
    width: "100vw",
}

const styleRowBackground = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "blur(2.5px)",
}