import { Card, Col, Divider, Image, Row } from "antd";
import gif from "./../../../assets/img/84726-business-meeting-animation.gif";
import { TypeAnimation } from "react-type-animation";
import { useEffect, useState } from "react";

function Home() {
  return (
    <Card>
      <Row wrap={true}>
        <Col
          md={24}
          lg={12}
          xl={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Image src={gif} preview={false}></Image>
        </Col>
        <Col md={24} lg={12} xl={12}>
          <h1 style={imageStyle}>RADNET APPS 👨🏻‍💻</h1>
          <TypeAnimation
            sequence={[
              "Sistema feito para agrupar todos os apps da Radnet Telecom, incluindo o viabilidade, envio de emails, todos os tipos de telas envolvendo geolocalização e mapas e gestão de informações  internas da empresa.", // Types 'One'
              5000, // Waits 1s
              "Qualquer dúvida sobre o sistema contate o desenvolvedor",
              5000,
            ]}
            wrapper="div"
            cursor={true}
            repeat={Infinity}
            style={{ fontSize: "2em", marginRight: 30, fontFamily: "IBM Plex Sans Condensed", }}
          />
          <p style={paragrafStyle}>Radnet Telecom ©2023</p>
        </Col>
      </Row>
    </Card>
  );
}

export default Home;

const imageStyle = {
  fontFamily: "IBM Plex Sans Condensed",
  fontSize: "50px",
  textAlign: "left",
  color: "#002140",
};

const paragrafStyle = {
  fontFamily: "IBM Plex Sans Condensed",
  marginTop: 40,
  fontSize: "20px",
  textAlign: "left",
  color: "#002140",
};
