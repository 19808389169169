
import { Button, FormControl, TextField, Select, InputLabel, MenuItem } from "@mui/material";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Card, Col, Image, Row, } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ExternalAPI } from "../../../../../services/http/ExternalAPI";
import { useEffect, useState } from "react";
import { Switch } from 'antd';
import { useForm } from "react-hook-form";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { loadState } from "../../../../../services/localStorage";
import LoadingImage from "../../../../../component/loadingImage";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { icon } from "leaflet";
import PngIcon from "./../../../../../assets/img/placeholder.png";
import { notification } from "antd";

const ICON = icon({
    iconUrl: PngIcon,
    iconSize: [32, 32],
});

function TicketDescription() {
    const location = useLocation();
    const navigate = useNavigate();
    const ticket = location.state;
    const ticketUpdate = ticket.id
    const arrayLoc = ticket.latlon === null ? [null, null] : ticket.latlon.split(",")
    const loc = [Number(arrayLoc[0]), Number(arrayLoc[1])]
    const [images, setImages] = useState()
    const [imagesAll, setImagesAll] = useState()
    const [arrayImages, setArrayImages] = useState([])
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState();
    const [setores, setSetores] = useState();
    let userLogged = loadState("session_user");
    const [map, setMap] = useState(null)


    const [api, contextHolder] = notification.useNotification();
    const alertMessage = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };


    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "onChange" });

    const onSubmit = (data) => {


        atualizarTicket(data)
    };

    const pegarImagesId = (data) => {
        const { http } = ExternalAPI();

        http
            .get(`ticket-getAllImages`)
            .then((res) => {
                setImagesAll(res.data)
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const atualizarTicket = (data) => {
        const { http } = ExternalAPI();

        http
            .put(`ticket-update/${ticketUpdate}`, {
                concluida: data.concluido,
                description: data.descricao,
                user_id: data.responsavel,
                setor_responsavel: data.setor,
                prioridade: data.prioridade,
                titulo: data.titulo,
                user_logged: userLogged.data.id
            })
            .then((res) => {
                alertMessage(
                    "success",
                    "Atualização Ticket",
                    "Ticket atualizado com sucesso!"
                );
            })
            .catch((erro) => {
                console.log(erro);
            });
    };


    const pegarTodasImagesId = () => {
        const { http } = ExternalAPI();

        http
            .get(`ticket-getAllImagesId?id=${ticket.id}`)
            .then((res) => {
                if (res.data) {
                    console.log(res.data)
                    for (const item of res.data) {
                        arrayImages.push(item.image_id)
                    }
                }
                setImages(res.data)
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getallmobile`)
            .then((res) => {
                if (res.status) {
                    let users = res.data
                    users.unshift({ id: 0, name: "Todos" })
                    setUsers(users);
                    console.log(res.data);

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const pegarTodosSetores = () => {
        const { http } = GetAPI();

        http
            .get("sector-getall")
            .then((res) => {
                if (res.status) {
                    let setor = res.data
                    setor.unshift({ id: 0, description: "Todos" })
                    setSetores(setor);
                } else {
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    useEffect(() => {
        if (images === undefined) {
            pegarTodasImagesId()
        }
        if (imagesAll === undefined) {
            pegarImagesId()
        }
        if (users === undefined) {
            buscarTodosUsuarios()
        }

        if (setores === undefined) {
            pegarTodosSetores()
        }

        if (images !== undefined && imagesAll !== undefined && users !== undefined && setores !== undefined) {
            setLoading(false)
        }
    }, [images, imagesAll, arrayImages])

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage></LoadingImage>}
            {loading === false && (
                <Card title="Ticket descrição">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Card style={{ marginBottom: 20 }}>
                            <Row gutter={[16, 16]}>
                                <Col span={4} >
                                    <TextField id="outlined-basic" label="Id" variant="outlined" size="small" defaultValue={ticket.id} fullWidth disabled />
                                </Col>
                                <Col span={20} >
                                    <FormControl fullWidth>
                                        <TextField
                                            id="outlined-basic"
                                            label="Titulo"
                                            defaultValue={ticket.titulo}
                                            variant="outlined"
                                            fullWidth
                                            error={errors?.titulo}
                                            size="small"
                                            {...register("titulo", {
                                                required: true,
                                            })}
                                        ></TextField>
                                        {errors?.titulo?.types?.required && (
                                            <span style={{ color: "red" }}>Campo obrigatório</span>
                                        )}
                                    </FormControl>
                                </Col>
                                <Col span={6} >


                                    <FormControl fullWidth >
                                        <InputLabel id="prioridade" size="small">
                                            Prioridade
                                        </InputLabel>
                                        <Select
                                            labelId="prioridade"
                                            id="prioridade"
                                            label="Prioridade"
                                            defaultValue={ticket.prioridade}
                                            {...register("prioridade")}
                                            fullWidth
                                            size="small"
                                        >
                                            {[{ id: 1, value: "baixa", color: "green" }, { id: 2, value: "normal", color: "blue" }, { id: 3, value: "alta", color: "orange" }, { id: 4, value: "urgente", color: "red" }].map((item, key) => (
                                                <MenuItem value={item.value} key={key}>
                                                    <span style={{ color: item.color }}>{item.value.toLocaleUpperCase()}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Col>

                                <Col span={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="concluido" size="small">
                                            Concluído
                                        </InputLabel>
                                        <Select
                                            labelId="concluido"
                                            id="concluido"
                                            label="Concluído"
                                            defaultValue={Number(ticket.concluida)}
                                            {...register("concluido")}
                                            fullWidth
                                            size="small"
                                        >
                                            {[{ label: "Sim", value: 1 }, { label: "Não", value: 0 }].map((item, key) => (
                                                <MenuItem value={item.value} key={key}>
                                                    <span>{item.label}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>

                                <Col span={6} >
                                    <FormControl fullWidth >
                                        <InputLabel id="responsavel" size="small">
                                            Setor
                                        </InputLabel>
                                        <Select
                                            labelId="setor"
                                            id="setor"
                                            label="setor"
                                            defaultValue={ticket.setor_responsavel}
                                            {...register("setor")}
                                            fullWidth
                                            size="small"
                                        >
                                            {setores.map((item, key) => (
                                                <MenuItem value={item.id} key={key}>
                                                    <span>{item.description}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </Col>
                                <Col span={6} >

                                    <FormControl fullWidth >
                                        <InputLabel id="responsavel" size="small">
                                            Responsável
                                        </InputLabel>
                                        <Select
                                            labelId="responsavel"
                                            id="responsavel"
                                            label="responsavel"
                                            defaultValue={ticket.user_id}
                                            {...register("responsavel")}
                                            fullWidth
                                            size="small"
                                        >
                                            {users.map((item, key) => (
                                                <MenuItem value={item.id} key={key}>
                                                    <span>{item.name}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </Col>
                                <Col span={24} >

                                    <FormControl fullWidth >
                                        <TextField
                                            id="outlined-basic"
                                            label="Data da criação"
                                            variant="outlined"
                                            defaultValue={moment(ticket.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                            fullWidth
                                            // error={errors?.created_at}
                                            size="small"
                                            disabled
                                        // {...register("created_at", {
                                        //     required: true,
                                        //     pattern: /(0[1-9]|[1-2][0-9]|3[0-1])[/](0[1-9]|1[0-2])[/][0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/g
                                        // })}
                                        ></TextField>
                                        {/* {errors?.created_at?.types?.required && (
                                            <span style={{ color: "red" }}>Campo obrigatório</span>
                                        )}
                                        {errors?.created_at?.types?.pattern && (
                                            <span style={{ color: "red" }}>Valor inválido</span>
                                        )} */}
                                    </FormControl>

                                </Col>

                                <Col span={24} >

                                    <FormControl fullWidth >
                                        <TextField
                                            id="outlined-basic"
                                            label="Descrição"
                                            variant="outlined"
                                            defaultValue={ticket.description}
                                            multiline
                                            rows={5}
                                            error={errors?.descricao}
                                            fullWidth
                                            size="small"
                                            {...register("descricao", {
                                                required: true,
                                            })}
                                        ></TextField>
                                        {errors?.descricao?.types?.required && (
                                            <span style={{ color: "red" }}>Campo obrigatório</span>
                                        )}
                                    </FormControl>
                                </Col>

                                <Col span={24} >
                                    <h1>Anexos</h1>
                                </Col>

                                <Col span={24}>
                                    {imagesAll.map((element, index) => {
                                        return (arrayImages.includes(`${element.id}`) ? (
                                            <span style={{ marginLeft: 10, marginRight: 10 }} key={index}>
                                                <Image src={`${process.env.REACT_APP_BASE_URL_BACKEND}/images/tickets/${element.image_name}`} preview={true} width={100} height={100}></Image>
                                            </span>
                                        ) : null)
                                        // if (arrayImages.includes(`${element.id} `)) {
                                        //     console.log(arrayImages)
                                        //         return (

                                        // <span style={{ marginLeft: 10, marginRight: 10 }} key={index}>
                                        //     <Image src={`${process.env.REACT_APP_BASE_URL_BACKEND}/images/tickets/${element.image_name}`} preview={true} width={100} height={100}></Image>
                                        // </span>

                                        // )
                                        //     }
                                    })}
                                </Col>

                                {loc[0] !== 0 && (
                                    <Col span={24}>
                                        {console.log(loc)}
                                        <MapContainer
                                            center={loc}
                                            zoom={17}
                                            ref={setMap}
                                            scrollWheelZoom={true}
                                            style={{
                                                width: "100%",
                                                marginTop: 20,
                                                height: "calc(100vh - 4rem)",
                                            }}
                                        >
                                            <TileLayer
                                                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                            />

                                            <Marker position={loc} icon={ICON}>
                                            </Marker>

                                        </MapContainer>
                                        {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_KEY_GOOGLE}>
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={loc}
                                                mapTypeControl={true}
                                                zoom={17}
                                                options={{ disableDefaultUI: false, mapTypeId: "satellite" }}
                                            >
                                                <Marker
                                                    position={loc}
                                                    draggable={false}
                                                />
                                            </GoogleMap>
                                        </LoadScript> */}
                                    </Col>
                                )}
                            </Row>
                        </Card>
                        <Row justify={"space-between"}>
                            <Button variant="contained" color="success" size="small" onClick={() => { navigate(-1) }} style={{ marginRight: 10 }}>Voltar</Button>
                            <Button
                                key="submit"
                                color="success"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                size="small"
                            >
                                Salvar
                            </Button>
                        </Row>
                    </form >
                </Card >
            )
            }

        </>
    );
}
const containerStyle = {
    width: "100%",
    height: "600px",
    marginBottom: "20px",
};

const styles = {
    input: {
        "&:invalid": {
            border: "red solid 2px"
        }
    }
};
export default TicketDescription;