import { Card, Col, Divider, Image, Row, Transfer } from "antd";
import * as React from "react";

import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { PostAPI } from "./../../../../../services/http/PostAPI";
import { useEffect } from "react";
import TransferList from "./../../../../../component/formComponents/transferList";
import gif from "./../../../../../assets/img/112874-security.gif";
import { message } from "antd";
import LoadingImage from "./../../../../../component/loadingImage";
import { useState } from "react";
import CardDark from "./../../../../../component/cardDark";

function CadastrarPermissoesWeb() {

    const [user, setUser] = useState();
    const [pages, setPages] = useState()
    const [loading, setLoading] = useState(true);
    const [userSelect, setUserSelect] = useState();
    const [permModal, setPermModal] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [reset, setReset] = useState(false)


    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                    setUserSelect(res.data[0].id);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodasPages = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    setPages(pages)

                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const cadastrarPermissoes = () => {
        const { http } = PostAPI();

        http
            .post(`user-permission-create`, {
                user_id: userSelect,
                permissions: permModal,
            })
            .then((res) => {
                if (res.status) {
                    success(res.data);
                    setReset(true)
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    useEffect(() => {
        if (user === undefined) {
            buscarTodosUsuarios();

        }
        if (pages === undefined) {
            buscarTodasPages()
        }

        if (user !== undefined && pages !== undefined) {
            setLoading(false);
        }

    }, [user, pages]);

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Cadastrar Permissões">
                        <Row justify="center">
                            <Col
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                                style={{
                                    paddingBottom: 20,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Image src={gif} preview={false}></Image>
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={1} xl={1}>
                                <Divider type="vertical" style={{ height: "100%" }}></Divider>
                            </Col>
                            <Col sm={24} md={24} lg={18} xl={18}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Usuário</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Usuário"
                                        size="small"
                                        defaultValue={user[Object.keys(user)[0]].id}
                                        onChange={(e) => {
                                            setUserSelect(e.target.value);
                                        }}
                                    >
                                        {user.map((item, key) => {
                                            return <MenuItem value={item.id} key={key}>{item.name}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>

                                <TransferList
                                    labelLeft={"Permissões"}
                                    labelRight={"Permissões Usuário"}
                                    itensLeft={pages}
                                    itensRight={[]}
                                    setPermArray={setPermModal}
                                    permArray={permModal}
                                    reset={reset}
                                    resetSet={setReset}
                                ></TransferList>
                            </Col>
                        </Row>
                        <Divider></Divider>
                        <Row>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => cadastrarPermissoes()}
                            >
                                Salvar
                            </Button>
                        </Row>
                    </CardDark>
                </>
            )}
        </>
    );
}

export default CadastrarPermissoesWeb;