import { Button, Menu, MenuItem } from "@mui/material";
import { Card, Space, Table, Tag, notification, Modal } from "antd";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import { GetAPI } from "../../../../../services/http/GetAPI";
import { NodeRequests } from "../../../../../services/http/NodeRequests";
import { useLocation } from "react-router-dom";
import LoadingImage from "../../../../../component/loadingImage";
import CardDark from "../../../../../component/cardDark";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function OnuAutorizadas() {
  const dataInfo = [];
  const location = useLocation();
  const { cadastro } = location.state;
  const { onuAnterior } = location.state;
  const [onu, setOnu] = useState();
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [detalhes, setDetalhes] = useState();
  const [maisDetalhes, setMaisDetalhes] = useState();
  const [exibirInfo, setExibirInfo] = useState(false);
  const [msgBanner, setMsgBanner] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editOlt, setEditOlt] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const alertMessage = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setExibirInfo(false);
  };

  const columns = [
    {
      title: "OLT",
      dataIndex: "olt",
      key: "olt",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Slot",
      dataIndex: "slot",
      key: "slot",
    },
    {
      title: "PON",
      dataIndex: "pon",
      key: "pon",
    },
    {
      title: "ONU",
      dataIndex: "onu",
      key: "onu",
    },
    {
      title: "Modelo",
      dataIndex: "modelo",
      key: "modelo",
    },
    {
      title: "VLAN",
      dataIndex: "vlan",
      key: "vlan",
    },
    {
      title: "Modo",
      dataIndex: "modo",
      key: "modo",
    },
    {
      title: "Id",
      dataIndex: "id_aparelho",
      key: "id",
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Cadastro",
      dataIndex: "cadastro",
      key: "cadastro",
    },
    {
      title: "Opções",
      key: "opcoes",
      render: (e) => (
        <>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            color="success"
            size="small"
            disableElevation
            onClick={(event) => {
              handleClick(event)
              setEditOlt(e)
            }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Opções
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                detalhesOnu(editOlt);
                setAnchorEl(null);
              }}
              disableRipple
            >
              Detalhes ONU
            </MenuItem>
            <MenuItem
              onClick={() => {
                removerOnu(editOlt);
                setAnchorEl(null);
              }}
              disableRipple
            >
              Remover ONU
            </MenuItem>
          </StyledMenu>
        </>
      ),
    },
  ];

  const detalhesOnu = (item) => {
    const { http } = NodeRequests();

    http
      .post(`/fttx-detalhes/${item.onu}`, {
        X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET,
        onu: item,
      })
      .then((res) => {
        if (res.status) {
          let resp = res.data.olts.split(
            "Configuration console exit, please retry to log on"
          );
          setDetalhes(resp[0]);
          setMaisDetalhes(resp[1]);
          //   alertMessage('success', 'Remover ONU', res.data.result)
          showModal();
        }
      })
      .catch(async function (err) {
        console.log(err);
        // alertMessage('error', 'Remover ONU', err)
      });
  };

  const removerOnu = (item) => {
    const { http } = NodeRequests();

    http
      .post("/olt-gerenciar/desaltorizar", {
        X_KEY_RADNET: process.env.REACT_APP_X_KEY_RADNET,
        onu: item,
      })
      .then((res) => {
        if (res.status) {
          buscarOnu();
          alertMessage("success", "Remover ONU", res.data.result);
        }
      })
      .catch(async function (err) {
        console.log(err);
        alertMessage("error", "Remover ONU", err);
      });
  };

  const buscarOnu = () => {
    const { http } = GetAPI();

    http
      .get("/onu-getall")
      .then((res) => {
        if (res.status) {

          let olts = []

          for (const i of res.data) {

            olts.push(i)

          }

          setOnu(res.data);
          setLoading(false);
        }
      })
      .catch(async function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    if (onu === undefined) {
      buscarOnu();
    }
    if (cadastro === true && msgBanner === undefined) {
      alertMessage("success", "Cadastro ONU", "ONU cadastrada com sucesso");
      setMsgBanner(true);
    }
  }, []);

  if (loading === false) {
    onu.map((item, index) => {
      dataInfo.push({
        id: item.id,
        id_aparelho: item.id_aparelho,
        id_olt: item.id_olt,
        olt: item.olt,
        slot: item.slot,
        pon: item.pon,
        onu: item.onu,
        modelo: item.modelo,
        vlan: item.vlan,
        modo: item.modo,
        descricao: item.descricao,
        cadastro: item.dt_cadastro,
        key: index,
      });

      return null;
    });
  }

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <>
          <CardDark title="ONU autorizadas">
            <Table columns={columns} dataSource={dataInfo} size="small" />
          </CardDark>
          <Modal
            title="Detalhamento ONU"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
            bodyStyle={{
              backgroundColor: "black",
              color: "green",
              height: "100%",
            }}
            footer={<></>}
          >
            <span>
              <pre>{detalhes}</pre>
            </span>
            <span
              style={exibirInfo === false ? buttonVisible : buttonHidden}
              onClick={() => {
                setExibirInfo(!exibirInfo);
              }}
            >
              <button>Mais informações</button>
            </span>
            <span style={exibirInfo === false ? infoHidden : infoVisible}>
              <pre>{maisDetalhes}</pre>
            </span>
          </Modal>
        </>
      )}
    </>
  );
}

export default OnuAutorizadas;

const buttonVisible = {
  display: "flex",
  justifyContent: "center",
};

const buttonHidden = {
  display: "none",
  justifyContent: "center",
};
const infoVisible = {
  display: "flex",
};
const infoHidden = {
  display: "none",
};
