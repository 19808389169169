import { Card, Modal, Table, Tabs, Tag } from "antd";
import { message } from "antd";
import { useEffect, useState } from "react";
import { DeleteAPI } from "./../../../../../services/http/DeleteAPI";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { Button } from "@mui/material";
import TransferListEdit from "./../../../../../component/formComponents/transferListEdit";
import { PutAPI } from "./../../../../../services/http/PutAPI";
import LoadingImage from "./../../../../../component/loadingImage";
import CardDark from "../../../../../component/cardDark";


function GerenciarSetorWeb() {

    const dataInfo = [];

    const [setores, setSetores] = useState();
    const [loading, setLoading] = useState(true);
    const [permissoes, setPermissoes] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [idModalExcluir, setIdModalExcluir] = useState();
    const [idExcluir, setIdExcluir] = useState();
    const [idEditar, setIdEditar] = useState();
    const [idPermUser, setIdPermUser] = useState();
    const [idPermSetor, setIdPermSetor] = useState();
    const [permArray, setPermArray] = useState()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);


    const showModal = (e) => {
        setIdModalExcluir(e.description);
        setIdExcluir(e.id);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        deletarSetor(idExcluir);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalEditar = (e) => {

        let arrayPerm = [];
        let crud = e.crud.split('|');

        permissoes.map((element, index) => {
            if ((e.permissions.split(',')).indexOf(`${element.id}`) > -1) {
                arrayPerm.push(element.id);
            }
            return null;
        });

        setPermArray(crud)
        setIdPermSetor(arrayPerm);
        setIdEditar(e.id);
        setIsModalOpenEdit(true);
    };

    const handleOkEditar = () => {
        editarSetor(idExcluir);
        setIsModalOpenEdit(false);
    };

    const handleCancelEditar = () => {
        setIsModalOpenEdit(false);
    };

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            width: 30,
        },
        {
            title: "Descrição",
            dataIndex: "description",
            width: 50,
        },
        {
            title: "Permissões",
            key: "permission",
            width: 250,
            render: (e) => (
                <span>
                    {
                        permissoes.map((element, index) => {
                            return (e.permissions.split(',')).indexOf(`${element.id}`) > -1 ? (

                                <Tag key={index}>{element.description_label}</Tag>
                            ) : null;
                        })
                    }
                </span >
            ),
        },
        {
            title: "Ações",
            key: "operation",
            width: 50,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModalEditar(e);
                        }}
                        color="success"
                        style={buttonsTable}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModal(e);
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const deletarSetor = (e) => {
        const { http } = DeleteAPI();

        http
            .delete(`sector-delete/${e}`)
            .then((res) => {
                if (res.status) {
                    pegarTodosSetores();
                    //success(res.data)
                    //console.log(res.data)
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const editarSetor = (e) => {
        const { http } = PutAPI();

        http
            .put(`sector-edit/${idEditar}`, {
                user_id: idEditar,
                ids_permission: idPermUser,
            })
            .then((res) => {
                if (res.status) {
                    buscarTodasPermissoesUsuario();
                    pegarTodosSetores();

                    //success(res.data)
                } else {
                    //error(res.data)
                }
            })
            .catch((erro) => {
                //error(erro.response.data)
            });
    };

    const buscarTodasPermissoesUsuario = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }

                    setPermissoes(pages);

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const pegarTodosSetores = () => {
        const { http } = GetAPI();

        http
            .get(`sector-getall`)
            .then((res) => {
                if (res.status) {
                    setSetores(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    useEffect(() => {
        if (setores === undefined) {
            pegarTodosSetores();
        }
        if (permissoes === undefined) {
            buscarTodasPermissoesUsuario();
        }

        if (setores !== undefined && permissoes !== undefined) {
            setLoading(false);
        }


    }, [setores, permissoes]);

    if (loading === false) {
        setores.map((item, index) => {
            dataInfo.push({
                id: item.id,
                description: item.description,
                key: index,
                permissions: item.ids_permission,
                crud: item.permissions
            });
            return null;
        });
    }

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Gerenciar Setor" style={{ marginBottom: 20 }}>
                        <Table
                            columns={columns}
                            dataSource={dataInfo}
                            scroll={{
                                y: 500,
                            }}
                        />
                    </CardDark>

                    <Modal
                        title="Editar setor"
                        open={isModalOpenEdit}
                        onOk={handleOkEditar}
                        onCancel={handleCancelEditar}
                        destroyOnClose={true}
                        width={1200}
                    >
                        <TransferListEdit
                            labelLeft={"Permissões"}
                            labelRight={"Permissões Usuário"}
                            itensLeft={permissoes}
                            itensRight={idPermSetor}
                            setItemsList={setIdPermUser}
                            itemsList={idPermUser}
                            permArray={permArray}
                            setPermArray={setPermArray}
                        ></TransferListEdit>
                    </Modal>
                    <Modal
                        title="Excluir Setor"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        destroyOnClose={true}
                    >
                        <p>
                            Realmente deseja excluir o setor:{" "}
                            <strong style={{ color: "red" }}>{idModalExcluir}</strong>
                        </p>
                    </Modal>
                </>
            )}
        </>
    );
}

export default GerenciarSetorWeb;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};