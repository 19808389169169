
import { Card, Table, Tag, Modal, Transfer } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { DeleteAPI } from "./../../../../../services/http/DeleteAPI";
import { PutAPI } from "./../../../../../services/http/PutAPI";
import { Button, useMediaQuery } from "@mui/material";
import * as React from "react";
import { message } from "antd";
import LoadingImage from "./../../../../../component/loadingImage";
import TransferListEdit from "./../../../../../component/formComponents/transferListEdit";
import CardDark from "../../../../../component/cardDark";

function GerenciarPermissoesWeb() {

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [idUserClickMobile, setUserIdClickMobile] = useState();
    const matches = useMediaQuery("(max-width:922px)");
    const [permissoesMobile, setPermissoesMobile] = useState()
    const [permissoesUserMobile, setPermissoesUserMobile] = useState()
    const [isModalOpenMobile, setIsModalOpenMobile] = useState(false);

    const [mockData, setMockData] = useState();
    const [targetKeys, setTargetKeys] = useState();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const [messageApi, contextHolder] = message.useMessage();

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };


    const showModalMobile = (e) => {

        let idPermissaoUserMobile = [];

        permissoesUserMobile.map((i) => {
            if (e.id === Number(i.user_id)) {
                idPermissaoUserMobile.push(Number(i.page_id));
            }
        });

        setTargetKeys(idPermissaoUserMobile)
        setUserIdClickMobile(e.id);
        setIsModalOpenMobile(true);
    };

    const handleOkMobile = () => {
        salvarEdicaoPermissoesMobile()
        setIsModalOpenMobile(false);
    };

    const handleCancelMobile = () => {
        setIsModalOpenMobile(false);
    };

    const columns2 = [
        {
            title: "Id",
            dataIndex: "id",
            width: 30,
        },
        {
            title: "Usuário",
            dataIndex: "user",
            width: 40,
        },
        {
            title: "Permissões",
            key: "permission",
            width: 250,
            render: (e) => (
                <span>
                    {permissoesUserMobile.map((item, index) => {
                        if (e.id === Number(item.user_id)) {
                            {
                                return (
                                    <Tag key={index}>
                                        {permissoesMobile.map((element) => {
                                            if (Number(item.page_id) === element.id) {
                                                return element.description_label;
                                            }
                                        })}
                                    </Tag>
                                );
                            }
                        }
                    })}
                </span>
            ),
        },
        {
            title: "Ações",
            key: "operation",
            width: 50,
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            showModalMobile(e);
                        }}
                        color="success"
                        style={buttonsTable}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            deletarTodasPermissoesIdMobile(e);
                        }}
                        color="error"
                        style={buttonsTable}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const dataInfo = [];

    const salvarEdicaoPermissoesMobile = () => {
        const { http } = PutAPI();

        http
            .put(`user-permission-edit-mobile/${idUserClickMobile}`, {
                user_id: idUserClickMobile,
                permissions: targetKeys,
            })
            .then((res) => {
                if (res.status) {
                    buscarTodasPermissoesUsuariosMobile();
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    const buscarTodasPermissoesUsuariosMobile = () => {
        const { http } = GetAPI();

        http
            .get(`user-permissions-getall-mobile`)
            .then((res) => {
                if (res.status) {

                    setPermissoesUserMobile(res.data);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);
                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const buscarTodasPermissoesMobile = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall-mobile`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }


                    const mockData2 = res.data.map((item) => ({
                        key: item.id,
                        title: item.name,
                        description: item.name,
                    }));

                    setMockData(mockData2);
                    // setTargetKeys([]);

                    setPermissoesMobile(pages)

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const deletarTodasPermissoesIdMobile = (e) => {
        const { http } = DeleteAPI();

        http
            .delete(`user-permission-deleteall-mobile/${e.id}`)
            .then((res) => {
                if (res.status) {
                    buscarTodasPermissoesUsuariosMobile();
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data);
            });
    };

    useEffect(() => {

        if (user === undefined) {
            buscarTodosUsuarios();
        }

        if (permissoesMobile === undefined) {
            buscarTodasPermissoesMobile()
        }

        if (permissoesUserMobile === undefined) {
            buscarTodasPermissoesUsuariosMobile()
        }

        if (user !== undefined && permissoesMobile !== undefined && permissoesUserMobile !== undefined) {
            setLoading(false);
        }


    }, [user, loading, permissoesMobile, permissoesUserMobile]);

    if (loading === false) {
        user.map((item, index) => {
            dataInfo.push({
                id: item.id,
                user: item.name,
                key: index,
            });
        });
    }

    return (
        <>
            <>
                {contextHolder}
                {loading === true && <LoadingImage />}
                {loading === false && (
                    <>
                        <CardDark title="Gerenciar Permissões Mobile">
                            <Table
                                columns={columns2}
                                dataSource={dataInfo}
                                scroll={{
                                    y: 500,
                                }}
                            />
                        </CardDark>

                        <Modal
                            title="Editar Permissões Mobile"
                            open={isModalOpenMobile}
                            onOk={handleOkMobile}
                            onCancel={handleCancelMobile}
                            destroyOnClose={true}
                            width={1200}
                            style={{ marginBottom: 20 }}
                        >

                            <Transfer
                                style={{
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginRight: 10,
                                    marginLeft: 10,
                                }}
                                listStyle={{
                                    width: 500,
                                    height: 400
                                }}
                                dataSource={mockData}
                                titles={['Permissões Gerais', 'Permissões Usuário Mobile']}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onChange={onChange}
                                onSelectChange={onSelectChange}
                                render={(item) => item.title}
                            />
                        </Modal>
                    </>
                )}
            </>
        </>
    );
}

export default GerenciarPermissoesWeb;

const buttonsTable = {
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    marginLeft: 5,
};