import { Button, Checkbox, FormControl, FormControlLabel, TextField, useMediaQuery } from "@mui/material";
import { Card, Divider, Modal, Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { GetAPI } from "./../../../../services/http/GetAPI";
import { DeleteAPI } from "./../../../../services/http/DeleteAPI";
import { PutAPI } from "./../../../../services/http/PutAPI";
import { message } from "antd";
import { useForm } from "react-hook-form";
import LoadingImage from "./../../../../component/loadingImage";
import CardDark from "./../../../../component/cardDark";

function GerenciarCadastros() {

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const matches = useMediaQuery("(max-width:922px)");
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [isModalOpenEditar, setIsModalOpenEditar] = useState(false);
  const [isModalOpenExcluir, setIsModalOpenExcluir] = useState(false);
  const [userEdit, setUserEdit] = useState();
  const [idModalExcluir, setIdModalExcluir] = useState();
  const [idModalEditar, setIdModalEditar] = useState();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const success = (text) => {
    messageApi.open({
      type: "success",
      content: text,
    });
  };

  const onSubmit = (data) => {
    data.editPassword = checked
    editarUsuario(data);
  };

  const showModalEditar = async (e) => {
    await new Promise((resolve, reject) => {
      setIdModalEditar(e.id);
      setUserEdit(e);
      setNome(e.name);
      setEmail(e.email);
      resolve(true);
    });
    setIsModalOpenEditar(true);
  };

  const handleCancelEditar = (e) => {
    reset();
    setIsModalOpenEditar(false);
  };

  const showModalExcluir = (e) => {
    setIdModalExcluir(e.id);
    setIsModalOpenExcluir(true);
  };

  const handleOkExcluir = () => {
    deletarUsuario();
    setIsModalOpenExcluir(false);
  };

  const handleCancelExcluir = () => {
    setIsModalOpenExcluir(false);
  };

  const editarUsuario = (data) => {
    const { http } = PutAPI();

    http
      .put(`user-edit/${idModalEditar}`, {
        name: data.nome,
        email: data.email,
        NotEditPassword: data.editPassword,
        password: data.senha,
        password_confirmation: data.senhaConf,
      })
      .then((res) => {
        if (res.status) {
          buscarTodosUsuarios();
          success("Usuário editado com sucesso");
          setIsModalOpenEditar(false);
          reset();
        } else {
          //error(res.response.data);
        }
      })
      .catch((erro) => {
        console.log(erro)
        error(erro.response.data.message)
        //error(erro.response.data);
      });
  };

  const deletarUsuario = () => {
    const { http } = DeleteAPI();

    http
      .delete(`user-delete/${idModalExcluir}`)
      .then((res) => {
        if (res.status) {
          buscarTodosUsuarios();
          success("Usuário deletado com sucesso");
        } else {
          error(res.response.data);
        }
      })
      .catch((erro) => {
        error(erro.response.data);
      });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Nome",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      width: 200,
      render: (e) => (
        <>
          <Button
            variant="contained"
            onClick={() => {
              showModalEditar(e);
            }}
            color="success"
            size="small"
            style={matches === true ? botoesTableSmall : botoesTableLarge}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              showModalExcluir(e);
            }}
            size="small"
            color="error"
          >
            Deletar
          </Button>
        </>
      ),
    },
  ];

  const buscarTodosUsuarios = () => {
    const { http } = GetAPI();

    http
      .get(`user-getall`)
      .then((res) => {
        if (res.status) {
          setUser(res.data);

          setLoading(false);
        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const onConfirmRefresh = function (event) {
    if (event.keyCode === 116) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (user === undefined) {
      buscarTodosUsuarios();
    }

    window.addEventListener("keydown", onConfirmRefresh, {
      capture: true,
    });
  }, [user, userEdit, errors, checked]);

  const dataInfo = [];
  if (loading === false) {
    user.map((item, index) =>
      dataInfo.push({
        id: item.id,
        name: item.name,
        email: item.email,
        key: index,
      })
    );
  }

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <CardDark title="Gerenciar Cadastro">
          <Table
            columns={columns}
            dataSource={dataInfo}
            scroll={{
              y: 240,
            }}
          />
        </CardDark>
      )}
      <Modal
        title="Editar usuário"
        open={isModalOpenEditar}
        onCancel={handleCancelEditar}
        destroyOnClose={true}
        preserve={false}
        footer={[
          <div>
            <Button
              block
              onClick={handleCancelEditar}
              variant="outlined"
              style={{ marginRight: "10px" }}
              size="small"
            >
              cancel
            </Button>
            ,
            <Button
              block
              key="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              size="small"
            >
              Salvar
            </Button>
          </div>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            fullWidth
            id="category-editor-form"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <TextField
              label={"Nome"}
              id="outlined-basic"
              variant="outlined"
              size="small"
              defaultValue={userEdit === undefined ? "" : userEdit.name}
              error={errors.nome !== undefined}
              {...register("nome", {
                required: true,
              })}
            />
            {errors?.nome?.type === "required" && (
              <span style={{ color: "red" }}>
                A confirmação de senha é obrigatória
              </span>
            )}
          </FormControl>

          <FormControl
            fullWidth
            id="category-editor-form"
            style={{ marginBottom: 20 }}
          >
            <TextField
              label={"E-mail"}
              id="outlined-basic"
              variant="outlined"
              size="small"
              type="email"
              defaultValue={userEdit === undefined ? "" : userEdit.email}
              error={errors.email !== undefined}
              {...register("email", {
                required: true,
                pattern: /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i,
              })}
            />
            {errors?.email?.type === "required" && (
              <span style={{ color: "red" }}>O email é obrigatória</span>
            )}
            {errors?.email?.type === "pattern" && (
              <span style={{ color: "red" }}>
                O e-mail informado não é valido
              </span>
            )}
          </FormControl>

          <FormControlLabel style={{ marginBottom: 20 }} control={
            <Checkbox defaultChecked onChange={handleChange} />
          } label="Não editar senha" />


          <FormControl
            fullWidth
            id="category-editor-form"
            style={{ marginBottom: 20 }}
          >
            <TextField
              label={"Senha"}
              id="outlined-basic"
              variant="outlined"
              size="small"
              type="password"
              disabled={checked === true ? true : false}
              error={errors.senha !== undefined}
              {...register("senha", {
                minLength: 8,
              })}
            />
            {errors?.senha?.type === "required" && (
              <span style={{ color: "red" }}>
                A confirmação de senha é obrigatória
              </span>
            )}

            {errors?.senha?.type === "minLength" && (
              <span style={{ color: "red" }}>
                A senha deve conter ao menos 8 caracteres
              </span>
            )}
          </FormControl>

          <FormControl
            fullWidth
            id="category-editor-form"
            style={{ marginBottom: 20 }}
          >
            <TextField
              label={"Confirmação de senha"}
              id="outlined-basic"
              variant="outlined"
              size="small"
              disabled={checked === true ? true : false}
              type="password"
              error={errors.senhaConf !== undefined}
              {...register("senhaConf", {
                minLength: 8,
              })}
            />
            {errors?.senhaConf?.type === "required" && (
              <span style={{ color: "red" }}>
                A confirmação de senha é obrigatória
              </span>
            )}

            {errors?.senhaConf?.type === "minLength" && (
              <span style={{ color: "red" }}>
                A senha deve conter ao menos 8 caracteres
              </span>
            )}
          </FormControl>
        </form>
      </Modal>

      <Modal
        title="Excluir usuário"
        open={isModalOpenExcluir}
        onOk={handleOkExcluir}
        onCancel={handleCancelExcluir}
      >
        <p>
          Realmente deseja excluir o registro:{" "}
          <strong style={{ color: "red" }}>{idModalExcluir}</strong>
        </p>
      </Modal>
    </>
  );
}

export default GerenciarCadastros;

const botoesTableSmall = {
  marginRight: 10,
  marginBottom: 10,
};

const botoesTableLarge = {
  marginRight: 10,
};


