import { Card, Col, Divider, Row, Transfer } from "antd";
import { useEffect, useState } from "react";
import TransferList from "./../../../../../component/formComponents/transferList";
import { GetAPI } from "./../../../../../services/http/GetAPI";
import { PostAPI } from "./../../../../../services/http/PostAPI";
import { useForm, Controller } from "react-hook-form";
import { message } from "antd";
import { Button, FormControl, TextField } from "@mui/material";
import LoadingImage from "./../../../../../component/loadingImage";
import SetorTextField from "./../../../../../component/setorTextField";
import CardDark from "../../../../../component/cardDark";

function CadastrarSetorWeb() {

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({ criteriaMode: "all", mode: "onChange" });

    const [permission, setPermission] = useState();
    const [loading, setLoading] = useState(true);
    const [permModal, setPermModal] = useState([]);

    const [messageApi, contextHolder] = message.useMessage();

    const error = (text) => {
        messageApi.open({
            type: "error",
            content: text,
        });
    };

    const success = (text) => {
        messageApi.open({
            type: "success",
            content: text,
        });
    };

    const onSubmit = (data) => {
        criarSetor(data);
        reset();
    };

    const buscarTodasPermissoesUsuario = () => {
        const { http } = GetAPI();

        http
            .get(`user-page-getall`)
            .then((res) => {
                if (res.status) {

                    let pages = []
                    for (const item of res.data) {
                        pages.push({ id: item.id, description_label: `(${item.sub}) - ${item.name}` })
                    }
                    setPermission(pages);

                } else {
                    console.log(res);
                }
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    const criarSetor = (data) => {
        const { http } = PostAPI();

        http
            .post(`sector-create`, {
                description: data.setor,
                ids_permission: permModal,
            })
            .then((res) => {
                if (res.status) {
                    success(res.data);
                } else {
                    error(res.data);
                }
            })
            .catch((erro) => {
                error(erro.response.data.message);
            });
    };

    useEffect(() => {
        if (permission === undefined) {
            buscarTodasPermissoesUsuario();
        }

        if (permission !== undefined) {
            setLoading(false);
        }

    }, [permModal, permission]);

    return (
        <>
            {contextHolder}
            {loading === true && <LoadingImage />}
            {loading === false && (
                <>
                    <CardDark title="Cadastrar Setor" style={{ marginBottom: 20 }}>
                        <Row justify={"center"} style={{ marginBottom: 10 }}>
                            <Col>
                                <TransferList
                                    labelLeft={"Permissões"}
                                    labelRight={"Permissões Usuário"}
                                    itensLeft={permission}
                                    itensRight={[]}
                                    setPermArray={setPermModal}
                                    permArray={permModal}
                                ></TransferList>
                            </Col>
                        </Row>

                        <Row justify={"center"}>
                            <Col xs={20} sm={20} md={20} lg={12} xl={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormControl
                                        fullWidth
                                        id="category-editor-form"
                                        style={{ marginBottom: 20 }}
                                    >
                                        <TextField
                                            label={"Setor"}
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            error={errors.setor !== undefined}
                                            {...register("setor", {
                                                required: true,
                                                minLength: 4,
                                            })}
                                        />
                                        {errors?.setor?.type === "required" && (
                                            <span style={{ color: "red" }}>
                                                O campo Setor é obrigatória
                                            </span>
                                        )}
                                        {errors?.setor?.type === "minLength" && (
                                            <span style={{ color: "red" }}>
                                                O Setor deve conter ao menos 4 caracteres
                                            </span>
                                        )}
                                    </FormControl>

                                    <Button type="submit" variant="contained" size="small">
                                        Enviar
                                    </Button>
                                </form>
                            </Col>
                        </Row>
                    </CardDark>
                </>
            )}
        </>
    );
}

export default CadastrarSetorWeb;