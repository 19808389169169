import { Button } from "@mui/material";
import { Badge, Card, Descriptions, Image } from "antd"
import { useEffect, useState } from "react";
import { WebsTelegram } from "../../../../services/http/WebsTelegram";
import CardDark from "../../../../component/cardDark";
import LoadingImage from "../../../../component/loadingImage";

function ConfiguracaoWebsTelegram() {

    const [connected, setConnected] = useState()
    const [qrCode, setQrCode] = useState()
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(false)

    const verificarConexao = () => {
        const { http } = WebsTelegram();

        http
            .get(`/verify-connection`)
            .then((res) => {
                console.log(res.data);
                setConnected(res.data)
                setQrCode(res.data)
                if (res.data.res === 'conectado') {
                    setStatus(true)
                }
                setLoading(false)
            })
            .catch((erro) => {
                console.log(erro);
            });
    };

    useEffect(() => {

        if (connected === undefined) {
            verificarConexao()
        }

    }, [connected])

    return (

        <>
            {loading === true && (<LoadingImage></LoadingImage>)}
            {loading === false && (
                <CardDark title="Configurações">
                    <Descriptions title="RASPAGEM DOWNDETECTOR" bordered>
                        <Descriptions.Item
                            label="Status"
                            span={3}
                            style={{ textAlign: "end" }}
                        >
                            <span>
                                {status === true ? (
                                    <Badge status="success" text="On" />
                                ) : (
                                    <Badge status="error" text="Off" />
                                )}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Get QrCode"
                            span={3}
                            style={{ textAlign: "end" }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    verificarConexao();
                                }}
                            >
                                QrCode / Verificar Conexão
                            </Button>
                        </Descriptions.Item>
                        {qrCode?.res !== 'conectado' && (
                            <Descriptions.Item
                                label="Qrcode Conection"
                                span={3}
                                style={{ textAlign: "end" }}
                            >
                                <Image src={qrCode.res}></Image>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </CardDark>
            )}
        </>
    );
}

export default ConfiguracaoWebsTelegram;