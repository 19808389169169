import axios from "axios";
import { loadState } from "./../localStorage/index";

export function UploadAPI() {
    const key = loadState("token");
    const user = loadState("session_user");

    const http = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL_BACKEND}/api`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${key.token.plainTextToken}`,
            "User": user.data.id

        },
    });

    return {
        http,
    };
}
