import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Card, Col, Row, Statistic, Table, Tag } from "antd";
import { useForm } from "react-hook-form";
import CardDark from "../../../../component/cardDark";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { useReactToPrint } from "react-to-print";
import CountUp from "react-countup";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Pie } from "react-chartjs-2";

function ComissaoIndividual() {
  const [infosData, setInfosData] = useState();

  const [ano, setAno] = useState(moment().format("YYYY"));
  const [mes, setMes] = useState(
    moment().format("MMMM")[0].toLocaleUpperCase() +
      moment().format("MMMM").substring(1)
  );
  const [func, setFunc] = useState();
  const [data, setData] = useState([]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getAllFuncionarios = (dataMes, dataAno) => {
    const { http } = GetAPI();

    http
      .get(`comissao-getall-funcionario/${dataMes}/${dataAno}`)
      .then((res) => {
        let func = [];
        let funcDeta = [];

        let labels = [];
        let datasets = [];

        if (res.data.length > 0) {
          for (const item of res.data) {
            let tempF = {};
            tempF.nome = item.nome;
            tempF.decrescimo = item.decrescimo;

            tempF.metaVendaERe =
              item.meta_de_vendas + " / " + item.venda_realizada;
            tempF.metaUpERe =
              item.meta_de_upgrade + " / " + item.upgrade_realizada;

            tempF.porc_vendaUp_ating =
              item.porc_venda_ating * 100 +
              "%" +
              " / " +
              item.porc_upgrade_ating * 100 +
              "%";
            tempF.receita_venda_up =
              item.receita_venda + " / " + item.receita_upgrade;
            tempF.valor_venda_up =
              item.valor_venda + " / " + item.valor_upgrade;
            tempF.total = "R$ " + item.total;
            tempF.porcV = item.porc_venda_ating * 100;
            tempF.porcU = item.porc_upgrade_ating * 100;
            tempF.coberturaV =
              (Number(item.venda_realizada) / Number(item.meta_de_vendas)) *
              100;
            tempF.coberturaU =
              (Number(item.upgrade_realizada) / Number(item.meta_de_upgrade)) *
              100;
            func.push(tempF);

            labels.push(item.nome);
            datasets.push(item.total);
          }
        }

        setInfosData({
          labels: labels,
          datasets: [
            {
              label: "Total de vendas",
              data: datasets,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        });

        setFunc(func);
      });
  };

  //const formatter = (value) => <CountUp end={value} separator="," />

  useEffect(() => {
    if (func === undefined) {
      getAllFuncionarios(mes, ano);
    }
  }, [func, ano, mes, data, infosData]);

  return (
    <>
      <Card title={"Planilha de comissionamento de vendas".toUpperCase()}>
        <Row gutter={[10]}>
          <Col span={12}>
            <FormControl fullWidth style={{ marginBottom: 20 }}>
              <InputLabel id="usuario" size="small">
                Funcionário
              </InputLabel>
              <Select
                labelId="funcionario"
                id="funcionario"
                label="Funcionário"
                onChange={(value) => {
                  for (const item of func) {
                    if (item.nome === value.target.value) {
                      setData([item]);
                    }
                  }
                }}
                size="small"
              >
                {func?.map((item, index) => {
                  return (
                    <MenuItem value={item.nome} key={item.nome}>
                      {item.nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col span={6}>
            <FormControl fullWidth>
              <InputLabel id="mes" size="small">
                Ano
              </InputLabel>
              <Select
                labelId="ano"
                id="ano"
                label="Ano"
                defaultValue={moment().format("YYYY")}
                onChange={(value) => {
                  setAno(value.target.value);
                  getAllFuncionarios(mes, value.target.value);
                }}
                size="small"
              >
                {[
                  moment().format("YYYY"),
                  moment().subtract(1, "years").format("YYYY"),
                  moment().subtract(2, "years").format("YYYY"),
                  moment().subtract(3, "years").format("YYYY"),
                  moment().subtract(4, "years").format("YYYY"),
                  moment().subtract(5, "years").format("YYYY"),
                ].map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col span={6}>
            <FormControl fullWidth>
              <InputLabel id="mes" size="small">
                Mês
              </InputLabel>
              <Select
                labelId="mes"
                id="mes"
                label="Mes"
                defaultValue={
                  moment().format("MMMM")[0].toLocaleUpperCase() +
                  moment().format("MMMM").substring(1)
                }
                onChange={(value) => {
                  setMes(value.target.value);
                  getAllFuncionarios(value.target.value, ano);
                }}
                size="small"
              >
                {[
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ].map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
        </Row>
      </Card>

      {data.length > 0 && (
        <div ref={componentRef} style={{ marginTop: 20 }}>
          <Row gutter={16}>
            <Col span={6}>
              <Card style={{ height: "100%" }}>
                <Pie data={infosData} />
              </Card>
            </Col>
            <Col span={18}>
              <Row gutter={16} justify={"end"}>
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Funcionário"
                      value={data[0]?.nome === undefined ? "?" : data[0]?.nome}
                      //formatter={formatter}
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Total a receber"
                      value={
                        data[0]?.total === undefined ? "?" : data[0]?.total
                      }
                      //formatter={formatter}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 20 }} justify={"end"}>
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Metas Vendas e realizadas"
                      value={
                        data[0]?.metaVendaERe === undefined
                          ? "?"
                          : data[0]?.metaVendaERe
                      }
                      //formatter={formatter}
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Meta Upgrade e realizadas"
                      value={
                        data[0]?.metaUpERe === undefined
                          ? "?"
                          : data[0]?.metaUpERe
                      }
                      //formatter={formatter}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 20 }} justify={"end"}>
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Cobertura meta vendas"
                      value={
                        data[0]?.coberturaV === undefined
                          ? "?"
                          : data[0]?.coberturaV
                      }
                      precision={2}
                      valueStyle={{
                        color:
                          data[0]?.coberturaV === undefined
                            ? "#fff"
                            : data[0]?.coberturaV >= 100
                            ? "#3f8600"
                            : "#cf1322",
                      }}
                      prefix={
                        data[0]?.coberturaV >= 100 ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="%"
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false}>
                    <Statistic
                      title="Cobertura meta upgrade"
                      value={
                        data[0]?.coberturaU === undefined
                          ? "?"
                          : data[0]?.coberturaU
                      }
                      precision={2}
                      valueStyle={{
                        color:
                          data[0]?.coberturaU === undefined
                            ? "#fff"
                            : data[0]?.coberturaU >= 100
                            ? "#3f8600"
                            : "#cf1322",
                      }}
                      prefix={
                        data[0]?.coberturaU >= 100 ? (
                          <ArrowUpOutlined />
                        ) : (
                          <ArrowDownOutlined />
                        )
                      }
                      suffix="%"
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default ComissaoIndividual;
