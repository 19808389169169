import { Button, FormControl, TextField } from "@mui/material";
import { Card, Col, Row } from "antd";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useRef, useState } from "react";
import { socket } from './socket';
import { v4 as uuidv4 } from 'uuid';
import { loadState } from "../../../../../services/localStorage";
import { ExternalAPI } from "../../../../../services/http/ExternalAPI";
import { useLocation } from 'react-router-dom';
import { GetAPI } from "../../../../../services/http/GetAPI";
import LoadingImage from "../../../../../component/loadingImage";

function ChatTicket() {

    const [messageFrom, setMessageFrom] = useState([])
    const [alignDown, setAlignDown] = useState(false);
    const location = useLocation(true);
    const ticket = location.state;
    const ticketUpdate = ticket.id
    const [userId, setUserID] = useState()
    let divDown = useRef(null);
    const [user, setUser] = useState()
    const usuarioSistema = loadState("session_user");

    const [allMessages, setAllMessages] = useState()
    const [loading, setLoading] = useState()

    const pegarMessagensTicketId = () => {
        const { http } = ExternalAPI();

        http
            .get(`ticket-getAllMessageTicketId/${location.state.id}`)
            .then(async (res) => {

                let mensagens = []
                //[{ text: e.text, user: { _id: e.user._id }, createdAt: moment().toISOString(), _id: uuidv4() }]
                for (const item of res.data) {
                    mensagens.push({ from: item.user, message: item.text, created_at: item.created_at, system: item.system })
                }
                setMessageFrom(mensagens)
                setAllMessages(true)

            })
            .catch((erro) => {
                //console.log(erro);
            });
    };

    const buscarTodosUsuarios = () => {
        const { http } = GetAPI();

        http
            .get(`user-getall`)
            .then((res) => {
                if (res.status) {
                    setUser(res.data);

                    //console.log(res.data)


                } else {
                    //console.log(res);
                }
            })
            .catch((erro) => {
                //console.log(erro);
            });
    };

    const {
        register,
        reset,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({});

    const onSubmit = (data) => {

        socket.emit("send-message", { text: data.message, user: userId, ticket_id: location.state.id, created_at: moment().toISOString(), message_id: uuidv4(), remotoUser: usuarioSistema.data.name, system: "web" })
        messageFrom.push({ from: userId, message: data.message, system: "web" })
        setMessageFrom(messageFrom)
        setAlignDown(true)
        reset();
    };

    useEffect(() => {

        if (allMessages === undefined && messageFrom.length === 0) {
            pegarMessagensTicketId()
        }

        if (userId === undefined) {
            const id = loadState("session_user");
            setUserID(id.data.id)
        }

        if (user === undefined) {
            buscarTodosUsuarios()
        }

        if (allMessages !== undefined && userId !== undefined && user !== undefined) {
            setLoading(false);
        }

        async function onConnect(e) {

            console.log(e)

            //recebimento de mensagem
            //localpara terminar de arrumar
            if (e[0].ticket_id === ticketUpdate) {
                messageFrom.push({ from: e[0].user._id, message: `${e[0]?.remotoUser}: ${e[0].text}`, created_at: e[0].created_at, system: "mobile" })
                setMessageFrom(messageFrom)
                setAlignDown(true)
            }
        }

        socket.on('send-mobile', onConnect);

        return () => {
            socket.off('send-mobile', onConnect);
        };
    }, [messageFrom, allMessages, userId, user])

    return (
        <>
            {userId !== undefined && (
                <>
                    {loading === true && (<LoadingImage></LoadingImage>)}
                    {loading === false && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Card>
                                <Row justify={"space-between"} style={{ marginBottom: 20 }}>
                                    <Col span={24}>
                                        <Row justify={"space-between"}>
                                            <Col>{ticket.titulo}</Col>
                                            {user.map((item, index) => {
                                                return (
                                                    Number(item.id) === Number(ticket.user_id) ? <Col key={index}>Responsável: {item.name}</Col> : null
                                                )
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ height: "50vh" }}>
                                        <Card style={{ height: "50vh" }}>
                                            <Scrollbars
                                                style={{
                                                    height: "44vh",
                                                }}
                                                ref={divDown}
                                                onUpdate={() => {
                                                    if (alignDown === true) {
                                                        divDown.current.scrollToBottom();
                                                    }
                                                    setAlignDown(false);
                                                }}
                                            >
                                                {messageFrom.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {item.system !== "mobile" && (
                                                                <Row justify={"end"} style={{ marginRight: 20 }}>
                                                                    <Col
                                                                        key={index}
                                                                        style={{
                                                                            marginBottom: 10,
                                                                            marginTop: 10,
                                                                            backgroundColor: "#FDF7E2",
                                                                            paddingBottom: 8,
                                                                            paddingTop: 6,
                                                                            paddingRight: 10,
                                                                            paddingLeft: 10,
                                                                            borderRadius: 5,
                                                                            justifyContent: "space-between",
                                                                            maxWidth: "70%",
                                                                        }}
                                                                    >
                                                                        <Row justify={"start"}>
                                                                            <Col>
                                                                                <span>{item.message}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row justify={"end"}>
                                                                            <Col>
                                                                                <sub
                                                                                    style={{
                                                                                        fontSize: 9,
                                                                                        marginLeft: 10,
                                                                                    }}
                                                                                >
                                                                                    {moment(item.created_at).format(
                                                                                        "LT"
                                                                                    )}
                                                                                </sub>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            )}

                                                            {item.system === "mobile" && (
                                                                <Row justify={"start"}>
                                                                    <Col
                                                                        key={index}
                                                                        style={{
                                                                            marginBottom: 10,
                                                                            marginTop: 10,
                                                                            backgroundColor: "#F5F5F5",
                                                                            paddingBottom: 8,
                                                                            paddingTop: 6,
                                                                            paddingRight: 10,
                                                                            paddingLeft: 10,
                                                                            borderRadius: 5,
                                                                            justifyContent: "space-between",
                                                                            maxWidth: "70%",
                                                                        }}
                                                                    >
                                                                        <Row justify={"start"}>
                                                                            <Col>
                                                                                <span>{item.message}</span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row justify={"end"}>
                                                                            <Col>
                                                                                <sub
                                                                                    style={{
                                                                                        fontSize: 9,
                                                                                        marginLeft: 10,
                                                                                    }}
                                                                                >
                                                                                    {moment(item.created_at).format(
                                                                                        "LT"
                                                                                    )}
                                                                                </sub>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </Scrollbars>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={24}>
                                        <Row justify={"space-between"}>
                                            <Col flex={"auto"}>
                                                <FormControl fullWidth id="category-editor-form">
                                                    <TextField
                                                        fullWidth={true}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        size="small"
                                                        {...register("message", { required: true })}
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col flex="80px" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <Button
                                                    type="submit"
                                                    size="small"
                                                    variant="contained"

                                                >
                                                    <SendIcon />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </form>
                    )}
                </>
            )}
        </>
    );
}

export default ChatTicket;




