import axios from "axios";

export function RedefinirSenhaAPI() {
  const key = process.env.REACT_APP_KEY_FRONTEND;

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL_BACKEND}/api`,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${key}`,
    },
  });

  return {
    http,
  };
}
