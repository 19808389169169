function FooterLayout() {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <span>Radnet Telecom ©2023 Created by Radnet Telecom</span>
    </div>
  );
}

export default FooterLayout;
