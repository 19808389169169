import { Button, FormControl, TextField } from "@mui/material";
import { Card, Col, Image, Modal, Row, Table } from "antd";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import { PostAPI } from "../../../../services/http/PostAPI";
import { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { DeleteAPI } from "../../../../services/http/DeleteAPI";
import ModalEditarTipoFuncionario from "./modalEditarTipoFuncionario";
import gif from "./../../../../assets/video/CPT2405201314-466x322.gif"

function CadastrarTipoFuncionario() {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: 'Meta Vendas',
            dataIndex: 'meta_de_vendas',
            key: 'meta_de_vendas',
        },
        {
            title: 'Meta Upgrade',
            dataIndex: 'meta_de_upgrade',
            key: 'meta_de_upgrade',
        },
        {
            title: 'Valor Venda',
            dataIndex: 'valor_venda',
            key: 'valor_venda',
        },
        {
            title: 'Valor Upgrade',
            dataIndex: 'valor_upgrade',
            key: 'valor_upgrade',
        },
        {
            title: "Ações",
            key: "operation",
            render: (e) => (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            showModalEditar(e);
                        }}
                        color="success"
                        size="small"
                        style={{ margin: 5 }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            showModalExcluir(e);
                        }}
                        size="small"
                        color="error"
                        style={{ margin: 5 }}
                    >
                        Deletar
                    </Button>
                </>
            ),
        },
    ];

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        criteriaMode: "all", mode: "onChange"
    });

    const alertMessage = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };

    const [api, contextHolder] = notification.useNotification();
    const [tipos, setTipos] = useState()
    const [isModalOpenExcluir, setIsModalOpenExcluir] = useState(false);
    const [idModalExcluir, setIdModalExcluir] = useState();

    const [isModalOpenEditar, setIsModalOpenEditar] = useState(false);
    const [idModalEditar, setIdModalEditar] = useState();
    const [funcEditar, setFuncEditar] = useState()

    const showModalEditar = async (e) => {

        for (const item of tipos) {
            if (Number(e.id) === Number(item.id)) {
                setFuncEditar(item)
            }
        }
        setIdModalEditar(e.id);
        setIsModalOpenEditar(true);
    };

    const showModalExcluir = (e) => {
        setIdModalExcluir(e.id);
        setIsModalOpenExcluir(true);
    };
    const handleOkExcluir = () => {
        deletarNomeFuncionario();
        setIsModalOpenExcluir(false);
    };
    const handleCancelExcluir = () => {
        setIsModalOpenExcluir(false);
    };

    const setTipoFuncionario = (data) => {
        const { http } = PostAPI();

        console.log(data)

        http
            .post(`comissao-set-tipo-funcionario`, {
                nome: data.nome,
                meta_de_vendas: data.meta_venda,
                meta_de_upgrade: data.meta_upgrade,
                valor_venda: data.valor_venda,
                valor_upgrade: data.valor_upgrade,
            })
            .then((res) => {
                if (res.status) {
                    alertMessage("success", "Adicionar Tipo funcionario", "Tipo funcionario cadastrado com sucesso")
                    getAllTipos()
                } else {
                    alertMessage("error", "Excluir funcionario", "Não foi possivel excluido com sucesso")
                }
            })
            .catch((erro) => {
                alertMessage("error", "Excluir funcionario", "Não foi possivel excluido com sucesso")
            });
    };

    const deletarNomeFuncionario = () => {
        const { http } = DeleteAPI();

        http
            .delete(`comissao-delete-tipo-funcionario/${idModalExcluir}`)
            .then((res) => {
                if (res.status) {
                    alertMessage("success", "Excluir nome funcionario", "Funcionario excluido com sucesso")
                    getAllTipos()

                } else {
                    alertMessage("error", "Excluir nome funcionario", "Não foi possivel excluido com sucesso")
                }
            })
            .catch((erro) => {
                alertMessage("error", "Excluir nome funcionario", "Não foi possivel excluido com sucesso")
            });
    };

    const getAllTipos = () => {
        const { http } = GetAPI();

        http
            .get(`comissao-all-tipo-funcionario`)
            .then((res) => {
                console.log(res)
                if (res.status) {
                    setTipos(res.data)
                } else {
                    alertMessage("error", "Carregar funcionario", "Não foi possivel carregar com sucesso")
                }
            })
            .catch((erro) => {
                alertMessage("error", "Carregar funcionario", "Não foi possivel carregar com sucesso")
            });
    };

    const onSubmit = (data) => {
        setTipoFuncionario(data)
    };

    useEffect(() => {

        if (tipos === undefined) {
            getAllTipos()
        }

    }, [tipos, idModalEditar, isModalOpenEditar, funcEditar])

    return (
        <>
            {contextHolder}
            <Card title={"Cadastrar Funcionário Comissao".toLocaleUpperCase()}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row justify={"center"} gutter={16}>
                        <Col flex={"auto"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                            >

                                <TextField
                                    label={"Nome Tipo"}
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    //error={errors.setor !== undefined}
                                    {...register("nome", {
                                        required: true,
                                    })}
                                />
                                {errors?.nome?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo Nome é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                            >

                                <TextField
                                    label="Meta de vendas"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    error={errors.meta_venda !== undefined}
                                    {...register("meta_venda", {
                                        required: true,
                                    })}
                                />
                                {errors?.meta_venda?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo "META VENDA" é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                            >

                                <TextField
                                    label="Meta de upgrade"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    error={errors.meta_upgrade !== undefined}
                                    {...register("meta_upgrade", {
                                        required: true,
                                    })}
                                />
                                {errors?.meta_upgrade?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo "META UPGRADE" é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col span={3}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                            >

                                <TextField
                                    label="Valor Venda"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    error={errors.valor_venda !== undefined}
                                    {...register("valor_venda", {
                                        required: true,
                                    })}
                                />
                                {errors?.valor_venda?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo "VALOR VENDA" é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col span={3}>
                            <FormControl
                                fullWidth
                                id="category-editor-form"
                            >

                                <TextField
                                    label="Valor Upgrade"
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    error={errors.valor_upgrade !== undefined}
                                    {...register("valor_upgrade", {
                                        required: true,
                                    })}
                                />
                                {errors?.valor_upgrade?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                        O campo "VALOR UPGRADE" é obrigatório
                                    </span>
                                )}
                            </FormControl>
                        </Col>
                        <Col flex={"200px"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button type="submit" variant="contained" size="small" >
                                Gravar Informações
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Card>

            <Card style={{ marginTop: 20 }} title={"Funcionarios cadastrados".toLocaleUpperCase()}>
                <Row gutter={12}>
                    <Col span={8}>
                        <Image src={gif} preview={false}></Image>
                    </Col>
                    <Col span={16}>
                        <Table columns={columns} dataSource={tipos} />
                    </Col>
                </Row>

            </Card>

            <Modal
                title="Excluir usuário"
                open={isModalOpenExcluir}
                onOk={handleOkExcluir}
                onCancel={handleCancelExcluir}
            >
                <p>
                    Realmente deseja excluir o registro:{" "}
                    <strong style={{ color: "red" }}>{idModalExcluir}</strong>
                </p>
            </Modal>

            <ModalEditarTipoFuncionario
                idModalEditar={idModalEditar}
                isModalOpenEditar={isModalOpenEditar}
                setIsModalOpenEditar={setIsModalOpenEditar}
                getAllTipos={getAllTipos}
                alertMessage={alertMessage}
                funcEditar={funcEditar}
            />
        </>
    );
}

export default CadastrarTipoFuncionario;