import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Card, Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { GetAPI } from "../../../../services/http/GetAPI";
import { PostAPI } from "../../../../services/http/PostAPI";
import { loadState } from "../../../../services/localStorage";
import { useForm, Controller } from "react-hook-form";
import { message } from "antd";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import LoadingImage from "../../../../component/loadingImage";
import useKeypress from "../../../../services/hook/keypress";

function MessagesAll() {
  const { handleSubmit, control, reset } = useForm();
  const [destinatario, setDestinatario] = useState("");
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [mensagem, setMensagem] = useState();
  const [assunto, setAssunto] = useState();
  const userLogued = loadState("session_user");
  const [allMensagens, setAllMensagens] = useState();

  const [messageApi, contextHolder] = message.useMessage();

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const success = (text) => {
    messageApi.open({
      type: "success",
      content: text,
    });
  };

  const onSubmit = (data) => {
    enviarMensagem(data);
    reset();
  };

  const handleChange = (event) => {
    setDestinatario(event.target.value);
  };

  const buscarTodosUsuarios = () => {
    const { http } = GetAPI();

    http
      .get(`user-getall`)
      .then((res) => {
        if (res.status) {
          setUsers(res.data);
          setDestinatario(res.data[Object.keys(res.data)[0]].id);
        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const buscarTodasMensagens = () => {
    const { http } = GetAPI();

    http
      .get(`message-getall`)
      .then((res) => {
        if (res.status) {
          setAllMensagens(res.data);

        } else {
          console.log(res);
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const enviarMensagem = (data) => {
    const { http } = PostAPI();

    http
      .post(`message-send`, {
        remetente: userLogued.data.id,
        destinatario: data.destinatario,
        assunto: data.assunto,
        mensagem: data.mensagem,
      })
      .then((res) => {
        if (res.status) {
          buscarTodasMensagens();
          success(res.data);
        } else {
          error(res.data);
        }
      })
      .catch((erro) => {
        error(erro.response.data);
      });
  };

  useEffect(() => {
    if (users === undefined) {
      buscarTodosUsuarios();
    }

    if (allMensagens === undefined) {
      buscarTodasMensagens();
    }

    if (users !== undefined && allMensagens !== undefined) {
      setLoading(false);
    }

  }, [users, allMensagens]);

  // useKeypress("Enter", () => {
  //   handleSubmit(onSubmit);
  // });

  return (
    <>
      {contextHolder}
      {loading === true && <LoadingImage />}
      {loading === false && (
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={9}
            xl={9}
            style={{ marginLeft: 5, marginRight: 5, marginBottom: 20 }}
          >
            <Card title="Enviar Mensagem">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="destinatario"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      fullWidth
                      style={{ marginBottom: 20, marginTop: 20 }}
                      error={error}
                      helperText={error ? error.message : null}
                    >
                      <InputLabel id="demo-simple-select-label" size="small">
                        Destinatário
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Destinatário"
                        onChange={onChange}
                        size="small"
                        value={value}
                      >
                        {users.map((item) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error ? (
                        <FormHelperText>
                          Destinatário Obrigatório
                        </FormHelperText>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  )}
                  rules={{ required: "First name required" }}
                />
                <Controller
                  name="assunto"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Assunto"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      size="small"
                      fullWidth
                      style={{ marginBottom: 20 }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Assunto obrigatório" }}
                />
                <Controller
                  name="mensagem"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      style={{ marginBottom: 20 }}
                      label="Mensagem"
                      name="Mensagem"
                      multiline
                      onChange={onChange}
                      value={value}
                      size="small"
                      rows={4}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Mensagem obrigatória" }}
                />

                <Button type="submit" variant="contained" size="small">
                  Enviar
                </Button>
              </form>
            </Card>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={14}
            xl={14}
            style={{ marginLeft: 5, marginRight: 5, marginBottom: 20 }}
          >
            <Card style={{ backgroundColor: "#7fa5d3" }}>
              {/* // */}
              {/* <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              ></div> */}
              <Scrollbars style={{ height: 400 }}>
                <Space size={20} direction="vertical" style={{ width: "95%" }}>
                  {allMensagens.map((item, index) => {
                    if (item.id_from === userLogued.data.id) {
                      return (
                        <Card
                          size="small"
                          title={`De: ${users
                            .map((element) => {
                              if (element.id === item.id_to) {
                                return element.name.toUpperCase();
                              }
                            })
                            .join("")} - ${item.assunto.toUpperCase()}`}
                          extra={`${moment(item.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}`}
                          key={index}
                        >
                          {item.mensagem}
                        </Card>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Space>
              </Scrollbars>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default MessagesAll;
